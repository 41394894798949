import { DialogBase } from 'A_SHARED_COMPONENT/DialogComponent'
import SelectComponent from 'A_SHARED_COMPONENT/SelectComponent'
import TextInputComponent from 'A_SHARED_COMPONENT/TextInputComponent'
import {
  generalListYearsV2,
  validateBackgroundColorMonth,
  validateBackgroundColorYear
} from 'F_UTILS/SchedulingUtils/backgroundUtils'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { settingBackgroundMonthSchema, settingBackgroundYearSchema } from './setingBackground.schema'
import { ToastError } from 'F_UTILS/ToastUtils'
import { addNewBackgroundColor, changeBackgroundColor } from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'

export default function SettingBackgroundModal({ getValues, backgroundName, initData, open, handleClose }) {
  const dispatch = useDispatch()
  const { includeYear, startYear, startMonth, numberOfMonths, backgroundColors } = useSelector(
    (state) => state.versionSetting.current
  )
  const { startYear: startYearForm, startMonth: startMonthForm, numberOfMonths: numberOfMonthsForm } = getValues()

  const handleSubmit = (values) => {
    console.log('🚀 ~ file: index.js:29 ~ handleSubmit ~ values:', values)
    if (includeYear) {
      const start = new Date(`${values.startYear}-${values.startMonth}-${values.startDate}`)
      const to = new Date(`${values.toYear}-${values.toMonth}-${values.toDate}`)
      if (start >= to) {
        ToastError('入力された文字が間違っています。もう一度やり直してください')
        return
      }

      const isAdd = validateBackgroundColorYear(backgroundColors, initData?.displayText || '', values)
      if (!isAdd) {
        ToastError('入力された文字が間違っています。もう一度やり直してください')
        return
      }

      const displayText = `${values.startYear}/${values.startMonth}/${values.startDate} - ${values.toYear}/${values.toMonth}/${values.toDate}`
      if (initData !== null) {
        dispatch(
          changeBackgroundColor({
            displayText: initData.displayText,
            background: { ...values, displayText }
          })
        )
      } else {
        dispatch(
          addNewBackgroundColor({
            backgroundName: backgroundName,
            background: { ...values, displayText }
          })
        )
      }
      formik.resetForm()
      handleClose()
    } else {
      if (Number(values.startMonth) > Number(numberOfMonths) || Number(values.startMonth) > Number(numberOfMonths)) {
        ToastError('入力された文字が間違っています。もう一度やり直してください')
        return
      }

      if (Number(values.startMonth) > Number(values.toMonth)) {
        ToastError('入力された文字が間違っています。もう一度やり直してください')
        return
      }
      if (Number(values.startMonth) == Number(values.toMonth)) {
        if (Number(values.startDate) >= Number(values.toDate)) {
          ToastError('入力された文字が間違っています。もう一度やり直してください')
          return
        }
      }

      if (
        !validateBackgroundColorMonth(
          values,
          numberOfMonthsForm || numberOfMonths,
          backgroundColors,
          initData?.displayText || ''
        )
      ) {
        ToastError('入力された文字が間違っています。もう一度やり直してください')
        return
      }

      const displayText = `${values.startMonth}/${values.startDate} - ${values.toMonth}/${values.toDate}`
      if (initData !== null) {
        dispatch(
          changeBackgroundColor({
            displayText: initData.displayText,
            background: { ...values, displayText }
          })
        )
      } else {
        dispatch(
          addNewBackgroundColor({
            backgroundName: backgroundName,
            background: { ...values, displayText }
          })
        )
      }
      formik.resetForm()
      handleClose()
    }
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startYear: initData?.startYear || startYearForm,
      startMonth: initData?.startMonth || 1,
      startDate: initData?.startDate || 10,
      toYear: initData?.toYear || startYearForm,
      toMonth: initData?.toMonth || 1,
      toDate: initData?.toDate || 10
    },
    validationSchema: includeYear ? settingBackgroundYearSchema : null,
    onSubmit: handleSubmit
  })
  useEffect(() => {
    if (formik.errors.toMonth) {
      ToastError('入力された文字が間違っています。もう一度やり直してください')
    }
    if (formik.errors.startMonth) {
      ToastError('入力された文字が間違っています。もう一度やり直してください')
    }
  }, [formik.errors.toMonth, formik.errors.startMonth])

  const listYears = generalListYearsV2(
    startYearForm || startYear,
    startMonthForm || startMonth,
    numberOfMonthsForm || numberOfMonths
  )

  let listNumberMonths = Array.from({ length: numberOfMonthsForm }, (_, i) => i + 1)

  const handleOK = () => {
    formik.handleSubmit()
  }

  return (
    <>
      <DialogBase
        open={open}
        handleOK={handleOK}
        handleClose={() => {
          handleClose()
          formik.resetForm()
        }}
        title={'背景の色リスト'}
        width={360}
      >
        <div className='w-full'>
          {!includeYear && (
            <>
              <label className='text-base font-normal leading-4'>開始日</label>
              <div className='flex justify-between items-center gap-5 border px-[38px] rounded-lg pb-5 mb-3'>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>月</label>
                  <SelectComponent
                    id={'startMonth'}
                    width={30}
                    value={formik.values.startMonth}
                    onChange={formik.handleChange}
                    listOption={listNumberMonths}
                  ></SelectComponent>

                  {/* <TextInputComponent
                    id={'startMonth'}
                    value={formik.values.startMonth}
                    onChange={formik.handleChange}
                  /> */}
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>日</label>
                  <SelectComponent
                    id={'startDate'}
                    width={30}
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    listOption={[1, 10, 20, 30]}
                  ></SelectComponent>
                </div>
              </div>

              <label className='text-base font-normal leading-4'>終了日</label>
              <div className='flex justify-between items-center gap-5 border px-[38px] rounded-lg pb-5'>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>月</label>
                  <SelectComponent
                    id={'toMonth'}
                    width={30}
                    value={formik.values.toMonth}
                    onChange={formik.handleChange}
                    listOption={listNumberMonths}
                  ></SelectComponent>

                  {/* <TextInputComponent id={'toMonth'} value={formik.values.toMonth} onChange={formik.handleChange} /> */}
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>日</label>
                  <SelectComponent
                    id={'toDate'}
                    width={30}
                    value={formik.values.toDate}
                    onChange={formik.handleChange}
                    listOption={[1, 10, 20, 30]}
                  ></SelectComponent>
                </div>
              </div>
            </>
          )}
          {includeYear && (
            <>
              <label className='text-base font-normal leading-4'>開始日</label>
              <div className='flex justify-between items-center gap-5 border px-[20px] rounded-lg pb-5 mb-3'>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>年</label>
                  <SelectComponent
                    id={'startYear'}
                    width={40}
                    value={formik.values.startYear}
                    onChange={formik.handleChange}
                    listOption={listYears}
                  ></SelectComponent>
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>月</label>
                  <SelectComponent
                    id={'startMonth'}
                    value={formik.values.startMonth}
                    onChange={formik.handleChange}
                    listOption={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  ></SelectComponent>

                  {/* <TextInputComponent
                    id={'startMonth'}
                    value={formik.values.startMonth}
                    onChange={formik.handleChange}
                  /> */}
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>日</label>
                  <SelectComponent
                    id={'startDate'}
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    listOption={[1, 10, 20, 30]}
                  ></SelectComponent>
                </div>
              </div>

              <label className='text-base font-normal leading-4'>終了日</label>
              <div className='flex justify-between items-center gap-5 border px-[20px] rounded-lg pb-5'>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>年</label>
                  <SelectComponent
                    id={'toYear'}
                    width={40}
                    value={formik.values.toYear}
                    onChange={formik.handleChange}
                    listOption={listYears}
                  ></SelectComponent>
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>月</label>
                  <SelectComponent
                    id={'toMonth'}
                    value={formik.values.toMonth}
                    onChange={formik.handleChange}
                    listOption={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  ></SelectComponent>

                  {/* <TextInputComponent id={'toMonth'} value={formik.values.toMonth} onChange={formik.handleChange} /> */}
                </div>
                <div className=' flex flex-col flex-1 mt-3'>
                  <label className='text-base font-normal leading-4'>日</label>
                  <SelectComponent
                    id={'toDate'}
                    value={formik.values.toDate}
                    onChange={formik.handleChange}
                    listOption={[1, 10, 20, 30]}
                  ></SelectComponent>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogBase>
    </>
  )
}
