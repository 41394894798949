import React from 'react'
import styles from './ViewListModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { useSelector } from 'react-redux'
import ViewsApi from 'B_API/scheduleApis/ViewsApi'
import { toast } from 'react-toastify'
import { Button } from 'antd'
import { PlusSquareFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'

export default function ViewListModal({ setShowViewList, setView, setShowCreateView }) {
  const [views, setViews] = React.useState([])
  const versionId = useSelector((state) => state.currentVersion.versionId)
  const navigate = useNavigate()

  React.useEffect(() => {
    const fetchViewInfo = async () => {
      try {
        const res = await ViewsApi.GetCustomViewsByVersionId(versionId)
        if (res.status === 200) {
          setViews(res.data)
        } else {
          console.log(res.status)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchViewInfo()
  }, [])

  const deleteCustomView = async (view) => {
    try {
      const res = await ViewsApi.DeleteCustomView(view.viewId)
      if (res.status === 204) {
        toast.success('ビューの削除に成功しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
        setViews((prev) => {
          const newState = prev.filter((v) => v.viewId !== view.viewId)
          return newState
        })
      } else {
        toast.error('ビューの削除に失敗しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    } catch (error) {
      toast.error('ビューの削除に失敗しました。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }
  }

  const handleSetting = (view) => {
    setShowViewList(false)
    setView(view)
  }

  const handleOpenView = (view) => {
    if (view?.viewId) {
      navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${view?.viewId}`)
      setShowCreateView(false)
    }
  }

  const handleCloseClick = () => {
    setShowCreateView(false)
  }

  const handleNewCustomView = () => {
    setView(undefined)
    setShowViewList(false)
  }
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className={styles.heading}>ビューリスト：</div>
        <Button
          type='primary'
          icon={<PlusSquareFilled></PlusSquareFilled>}
          onClick={handleNewCustomView}
          style={{ marginLeft: '30px', marginBottom: '15px' }}
          className='flex items-center justify-center'
        >
          作成
        </Button>
        <div className={styles.views_container}>
          {views?.length !== 0 &&
            views.map((v) => {
              return (
                <div className={styles.views_item} key={v.viewId}>
                  <span>{v.viewName}</span>
                  <div className={styles.icons_container}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className={`${styles.icons_item}`}
                      onClick={() => deleteCustomView(v)}
                    />
                    <FontAwesomeIcon icon={faGear} className={styles.icons_item} onClick={() => handleSetting(v)} />
                    <FontAwesomeIcon
                      icon={faFolderOpen}
                      className={styles.icons_item}
                      onClick={() => handleOpenView(v)}
                    />
                  </div>
                </div>
              )
            })}
        </div>
        <div className={styles.button_container}>
          <button className='btn-secondary' onClick={handleCloseClick}>
            閉じる
          </button>
        </div>
      </div>
    </div>
  )
}
