import React from 'react'
import { BarDisplay } from './bar-display'
import { TaskItemProps } from '../task-item'
import styles from './bar.module.css'

export const Bar: React.FC<TaskItemProps> = ({
  task,
  // isProgressChangeable,
  isDateChangeable,
  // rtl,
  onEventStart,
  isSelected
}) => {

  return (
    <g className={styles.barWrapper} tabIndex={0}>
      <BarDisplay
        x={task.start}
        y={task.y}
        taskId={task.id}
        task = {task}
        width={task.end - task.start}
        height={task.height}
        barCornerRadius={task.barCornerRadius}
        styles={task.styles}
        isSelected={isSelected}
        colorId={task.colorId}
        onMouseDown={(e) => {
          isDateChangeable && onEventStart('move', task, e)
        }}
      />
    </g>
  )
}
