import React, { MouseEventHandler, ReactChild, useEffect, useState } from 'react'
import { Task } from '../../types/public-types'
import styles from './grid.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { predecessorSlice } from 'C_REDUX_STORE/scheduleTool/predecessorSlice'
import { BarTask } from '../../types/bar-task'
import {
  getAllLocationHaveBackgroundColor,
  getAllLocationHaveMonthBackgroundColor
} from 'F_UTILS/SchedulingUtils/backgroundUtils'
import { uniq } from 'lodash'

export type GridBodyProps = {
  tasks: Task[]
  barTasks: BarTask[]
  svgWidth: number
  rowHeight: number
  gridHeight?: number
  columnWidth: number
  todayColor: string
  rtl: boolean
  onClickEvent: MouseEventHandler<SVGGElement> | undefined
  onRightClickGrid: (index: number, event: React.MouseEvent<SVGRectElement, MouseEvent>) => void
}

export const GridBody: React.FC<GridBodyProps> = ({
  tasks,
  barTasks,
  rowHeight,
  svgWidth,
  columnWidth,
  todayColor,
  gridHeight,
  rtl,
  onClickEvent,
  onRightClickGrid
}) => {
  const selectedTaskIds = useSelector((state: RootState) => state.selectedTask)
  const [listSelectedTaskIndex, setListSelectedTaskIndex] = useState<any[]>([])
  useEffect(() => {
    let listSelectedTask: any[] = []
    if (selectedTaskIds?.length !== 0) {
      // try {
      //   selectedTaskIds.forEach((taskId: any) => {
      //     tasks.forEach((task, index) => {
      //       if (task.id === taskId && !listSelectedTask.includes(index)) {
      //         listSelectedTask.push(index)
      //       }
      //       if (task?.stepworks) {
      //         const findIndex = task.stepworks.findIndex((st) => st.id === taskId)
      //         if (findIndex !== -1 && !listSelectedTask.includes(index)) {
      //           listSelectedTask.push(index)
      //         }
      //       }
      //     })
      //   })
      // } catch (error) {}
      try {
        selectedTaskIds.forEach((taskId: any) => {
          barTasks.forEach((task, index) => {
            if ((task.id === taskId || task.parentTaskId === taskId) && !listSelectedTask.includes(index)) {
              listSelectedTask.push(task.index)
            }
          })
        })
      } catch (error) {}
    }
    listSelectedTask = uniq(listSelectedTask)
    setListSelectedTaskIndex(listSelectedTask)
  }, [selectedTaskIds, tasks, barTasks])

  let y = 0
  const gridRows: ReactChild[] = []
  const rowLines: ReactChild[] = [
    <line key='RowLineFirst' x='0' y1={0} x2={svgWidth || 100} y2={0} className={styles.gridRowLine} />
  ]
  const { backgroundColors, numberOfMonths, includeYear, startYear, startMonth } = useSelector(
    (state: RootState) => state.versionSetting.current
  )
  const dispatch = useDispatch()
  let backgroundColorInfo: any[] = []
  if (includeYear) {
    if (backgroundColors !== undefined) {
      backgroundColors.forEach((backgroundColor: any) => {
        backgroundColor?.dateRanges?.forEach((dateRange: any) => {
          const listIndex = getAllLocationHaveBackgroundColor(dateRange, startYear, startMonth, numberOfMonths)
          backgroundColorInfo.push({
            color: backgroundColor.code,
            listIndex
          })
        })
      })
    }
  } else {
    if (backgroundColors !== undefined) {
      backgroundColors.forEach((backgroundColor: any) => {
        backgroundColor?.dateRanges?.forEach((dateRange: any) => {
          const listIndex = getAllLocationHaveMonthBackgroundColor(dateRange, numberOfMonths)
          backgroundColorInfo.push({
            color: backgroundColor.code,
            listIndex
          })
        })
      })
    }
  }

  for (let index = 0; index < svgWidth / (columnWidth / 3); index++) {
    let tasksCount = tasks.length
    let color = '#fff'
    backgroundColorInfo.forEach((x) => {
      if (x.listIndex.includes(index)) {
        color = x.color
      }
    })
    gridRows.push(
      <rect
        key={'Row' + index}
        x={(index * columnWidth) / 3}
        y={0}
        width={columnWidth / 3}
        height={gridHeight || tasksCount * rowHeight}
        fill={color}
        onContextMenu={(e) => {
          e.preventDefault()
          onRightClickGrid(index + 1, e)
        }}
      />
    )
  }
  if (gridHeight) {
    for (let index = 0; index < gridHeight / rowHeight; index++) {
      rowLines.push(
        <line
          key={'RowLine' + index}
          x='0'
          y1={y + rowHeight}
          x2={svgWidth || 100}
          y2={y + rowHeight}
          className={styles.gridRowLine}
        />
      )
      y += rowHeight
    }
  } else {
    for (const task of tasks) {
      rowLines.push(
        <line
          key={'RowLine' + task.id}
          x='0'
          y1={y + rowHeight}
          x2={svgWidth || 100}
          y2={y + rowHeight}
          className={styles.gridRowLine}
        />
      )
      y += rowHeight
    }
  }

  let tickX = 0
  const ticks: ReactChild[] = []
  let todayC = todayColor
  const rtl2 = rtl
  tickX = 0
  for (let i = 0; i < numberOfMonths * 3 + 1; i++) {
    if (i % 3 === 0) {
      ticks.push(<line key={i} x1={tickX} y1={0} x2={tickX} y2={y} className={styles.gridTickMonth} />)
    } else {
      ticks.push(<line key={i} x1={tickX} y1={0} x2={tickX} y2={y} className={styles.gridTick} />)
    }
    tickX += columnWidth / 3
  }
  const handleGridLeftClick = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if (onClickEvent) {
      onClickEvent(e)
    }
    dispatch(predecessorSlice.actions.setParentStepWorkId('-1'))
  }
  return (
    <g className='gridBody' onClick={handleGridLeftClick}>
      <g className='rows'>{gridRows}</g>
      <g className='rowLines'>{rowLines}</g>
      <g className='ticks'>{ticks}</g>
      {listSelectedTaskIndex.length !== 0 &&
        listSelectedTaskIndex?.map((index) => (
          <rect
            key={index}
            x={0}
            width={svgWidth}
            y={index * rowHeight}
            height={rowHeight}
            fill='rgba(161, 216, 255, 0.5)'
            strokeWidth={0}
            stroke='white'
          />
        ))}
    </g>
  )
}
