import React from 'react'
import './main.css'

export default function ColorComponent({color, setColor, setColorRgb}) {
    const colorClick = (e) =>{
      const listTableColor = document.querySelectorAll('.table--color td');
      listTableColor.forEach(element => {
          if(element.classList.contains('color--selected')){
              element.classList.remove('color--selected');
          }
      });
      e?.target?.classList.add("color--selected");
      setColor(color.index);
      setColorRgb(color.color)
  }
  return (
    <>
        <td onClick={colorClick} style={{backgroundColor : color.color}} className='color--container'></td>
    </>
  )
}
