import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "jobId",
  initialState: {
    current: null,
    status: "idle",
    error: null,
  },

  reducers: {
    setJobId(state, action) {
      state.current = action.payload;
      state.status = "updated";
    },
    clearJobId(state) {
      state.current = null;
      state.status = "idle";
    },
  },
});

const { actions, reducer } = jobSlice;
export const { setJobId, clearJobId } = actions;
export default reducer;
