import axiosClient from "./axiosClient";

const RenameBlockApi = {
  async CreateJob(file) {
    const url = `/renameblock`;
    return axiosClient.request({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: url,
      data: file,
    });
  },

  async DownloadFile(jobId) {
    const url = `/renameblock/${jobId}/result/download`;
    return axiosClient.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },

  async RunRenameBlocks(jobId, data) {
    const url = `/renameblock/${jobId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },

  async GetResultUrn(jobId) {
    const url = `/renameblock/${jobId}/result/urn`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  async RunGetBlockNameList(jobId) {
    const url = `renameblock/block-name-list/${jobId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
    });
  },
  async GetAllBlockName(jobId) {
    const url = `renameblock/block-name-list/${jobId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};

export default RenameBlockApi;
