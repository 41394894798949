import StorageKeys from "../F_UTILS/Constants/StorageKeys";

class TokenService {
  addToken(data) {
    localStorage.setItem(StorageKeys.access, data.accessToken);
    localStorage.setItem(StorageKeys.refresh, data.refreshToken);
    localStorage.setItem(StorageKeys.expiration_access, data.accessExpiration);
    localStorage.setItem(
      StorageKeys.expiration_refresh,
      data.refreshExpiration
    );
  }
  getLocalRefreshToken() {
    return localStorage.getItem(StorageKeys.refresh);
  }
  getLocalAccessToken() {
    return localStorage.getItem(StorageKeys.access);
  }
  updateLocalAccessToken(token) {
    localStorage.setItem(StorageKeys.access, token);
  }
  updateLocalRefreshToken(token) {
    localStorage.setItem(StorageKeys.refresh, token);
  }

  getUser() {
    return JSON.parse(localStorage.getItem(StorageKeys.user));
  }
  setUser(user) {
    localStorage.setItem(StorageKeys.user, JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem(StorageKeys.user);
    localStorage.removeItem(StorageKeys.access);
    localStorage.removeItem(StorageKeys.refresh);
    localStorage.removeItem(StorageKeys.expiration_access);
    localStorage.removeItem(StorageKeys.expiration_refresh);
    // localStorage.removeItem("RememberAccCloudSystem");
  }

  getLocalStorageRemember() {
    return localStorage.getItem("RememberAccCloudSystem");
  }
  setLocalStorageRemember(state) {
    return localStorage.setItem("RememberAccCloudSystem", state);
  }
}
export default new TokenService();
