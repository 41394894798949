import React from 'react';
import StorageKeys from '../../F_UTILS/Constants/StorageKeys';
import './Avatar.css';
import noAvatar from './no-avatar.png'

export default function Avatar({className, src, alt}) {
    const handleOnError = (e) =>{
        e.target.src = {noAvatar};
    }
    const user = JSON.parse(localStorage?.getItem(StorageKeys.user));
    return (
        <div className='avatar--container'>
            {src ? (
                <>
                    <img className={`defaultClass ${className}`} src={src} alt ={alt} onError={handleOnError}></img>
                    <p>{user?.lastName || ''}</p>
                </>
            ): (
                <>
                    <img 
                        className={`defaultClass ${className}`}
                        src={noAvatar}
                        alt ={alt}>
                    </img>
                    <p>{user?.lastName || ''}</p>
                </>
            )}
        </div>
    )
}
