import React from 'react'

export default function ButtonSecondary({ children, onClick }) {
  return (
    <button
      onClick={onClick}
      className='bg-white text-black h-[36px] w-[96px] border border-solid border-[#0199fa] rounded-md font-semibold text-[14px] hover:bg-[#f9f6f6] '
    >
      {children}
    </button>
  )
}
