import axiosClient from "./axiosClient";

const CreateLayerApi = {
    //ok
  async CreateJob(file) {
    const url = `/createlayer`;
    return axiosClient.request({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: url,
      data: file,
    });
  },

  async DownloadFile(jobId) {
    const url = `/createlayer/${jobId}/result/download`;
    return axiosClient.request({
      method: "GET",
      url: url,
      responseType: "blob",
    });
  },

  async RunCreateLayers(jobId, data) {
    const url = `/createlayer/${jobId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },

  async GetResultUrn(jobId) {
    const url = `/createlayer/${jobId}/result/urn`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
//ok
  async RunGetLayerNameList(jobId) {
    const url = `createlayer/layer-name-list/${jobId}`;
    return axiosClient.request({
      method: "POST",
      url: url,
    });
  },
  //ok
  async GetAllLayerName(jobId) {
    const url = `createlayer/layer-name-list/${jobId}`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};

export default CreateLayerApi;
