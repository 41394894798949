import React from "react";

export default function TableComponent({ rowCount }) {
  return (
    <div className="outer-wrapper">
      <div className="table-wrapper">
        <table
          className="table--header"
          style={{ width: "100%", position: "sticky", top: "0px" }}
        >
          <thead>
            <th
              style={{
                width: "75%",
                height: "31px",
                border: "1px solid white",
              }}
            >
              画層名
            </th>
            <th colSpan="3">色</th>
          </thead>
        </table>
        <table id="contents-table">
          <tbody>
            {new Array(rowCount).fill(null).map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      height: "31px",
                      width: "75%",
                      wordBreak: "break-word",
                    }}
                    className="editAbleRow"
                  ></td>
                  <td style={{ borderRight: "none" }}></td>
                  <td style={{ borderRight: "none", borderLeft: "none" }}></td>
                  <td style={{ borderLeft: "none" }}></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
