import { createSlice } from '@reduxjs/toolkit'

export const predecessorSlice = createSlice({
  name: 'predecessor',
  initialState: '-1',
  reducers: {
    setParentStepWorkId: (state, action) => {
      return action.payload
    }
  }
})
const { actions, reducer } = predecessorSlice
export default reducer
