import * as yup from 'yup'

export const settingBackgroundYearSchema = yup.object({
  startMonth: yup
    .number()
    .required('Hãy nhập tháng')
    .integer()
    .min(1, 'Tháng nhập giá trị từ 1 đến 12')
    .max(12, 'Tháng nhập giá trị từ 1 đến 12'),
  toMonth: yup
    .number()
    .required('Hãy nhập tháng')
    .integer()
    .min(1, 'Tháng nhập giá trị từ 1 đến 12')
    .max(12, 'Tháng nhập giá trị từ 1 đến 12')
})

export const settingBackgroundMonthSchema = (month) => {
  yup.object({
    startMonth: yup
      .number()
      .required('Hãy nhập tháng')
      .integer()
      .min(1, 'Tháng nhập giá trị từ 1 đến 12')
      .max(Number(month), 'Tháng nhập giá trị từ 1 đến 12'),
    toMonth: yup
      .number()
      .required('Hãy nhập tháng')
      .integer()
      .min(1, 'Tháng nhập giá trị từ 1 đến 12')
      .max(Number(month), 'Tháng nhập giá trị từ 1 đến 12')
  })
}
