import { DateRange, Predecessors } from './../types/public-types'
import { Task, PredecessorType } from '../types/public-types'
import { BarTask, TaskTypeInternal } from '../types/bar-task'
import { getDurationScale, getTrueParentToCalculatePosition } from './other-helper'

export const convertToBarTasks = (
  tasks: Task[],
  columnWidth: number,
  rowHeight: number,
  taskHeight: number,
  barCornerRadius: number,
  handleWidth: number,
  barBackgroundColor: string,
  barBackgroundSelectedColor: string,
  amplifiedFactor: number
) => {
  //recalculate task location before convert

  calculateStartEndRecursion(tasks, columnWidth, amplifiedFactor)
  let barTasks: BarTask[] = []
  // convert
  let countTaskIsSubStepWork = 0
  for (let index = 0; index < tasks.length; index++) {
    const task = tasks[index]
    if (task?.stepworks && task?.stepworks?.length !== 0) {
      const foundStepWorkIsSubSt = task.stepworks.filter((st) => st?.isSubStepWork === true)

      for (let j = 0; j < task?.stepworks?.length; j++) {
        const taskElement = task.stepworks[j]
        let y: number = 0
        y = taskYCoordinate(index + 1, rowHeight, taskHeight) + rowHeight * (countTaskIsSubStepWork - 1)
        if (taskElement.isSubStepWork === true) {
          y = taskYCoordinate(index, rowHeight, taskHeight) + rowHeight * (countTaskIsSubStepWork + 1)
        }
        taskElement.duration = task.duration
        barTasks.push(
          convertToStepWorkBar(
            taskElement,
            task,
            index,
            columnWidth,
            taskHeight,
            barCornerRadius,
            handleWidth,
            barBackgroundColor,
            barBackgroundSelectedColor,
            amplifiedFactor,
            y
          )
        )
      }
      if (foundStepWorkIsSubSt.length > 0) {
        countTaskIsSubStepWork = countTaskIsSubStepWork + 1
      }
    } else {
      const y = taskYCoordinate(index, rowHeight, taskHeight) + rowHeight * countTaskIsSubStepWork
      barTasks.push(
        convertToBar(
          task,
          index,
          columnWidth,
          taskHeight,
          barCornerRadius,
          handleWidth,
          barBackgroundColor,
          barBackgroundSelectedColor,
          amplifiedFactor,
          y
        )
      )
    }
  }
  // calculate predecessors
  barTasks = barTasks.map((task) => {
    //task cha
    const dependencies = task?.predecessors

    if (dependencies) {
      for (let j = 0; j < dependencies.length; j++) {
        //task con
        const dependence = barTasks.findIndex((value) => value.id === dependencies[j].id)
        if (dependence !== -1) {
          task.barChildren.push(barTasks[dependence])
          const childTask = barTasks[dependence]

          // find true parent
          let tempParentTask: Task
          let predecessorType: string
          let lagDays: number
          const trueParent = getTrueParentToCalculatePosition(tasks, childTask.id)
          // console.log(trueParent)

          if (trueParent) {
            tempParentTask = trueParent
            predecessorType = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
            lagDays = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.lagDays || 0
          } else {
            tempParentTask = task
            predecessorType = task.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
            lagDays = dependencies[j].lagDays
          }

          const durationScale = getDurationScale(childTask, amplifiedFactor)

          if (predecessorType === 'FS') {
            if (lagDays) {
              childTask.start = tempParentTask.end + (lagDays * columnWidth) / 30
            } else {
              childTask.start = tempParentTask.end
            }

            if (childTask.percentStepWork) {
              childTask.end =
                childTask.start +
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
            }
          } else if (predecessorType === 'FF') {
            if (lagDays) {
              childTask.end = tempParentTask.end + (lagDays * columnWidth) / 30
            } else {
              childTask.end = tempParentTask.end
            }

            if (childTask.percentStepWork) {
              childTask.start =
                childTask.end -
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.start = childTask.end - (childTask.duration * durationScale * columnWidth) / 30
            }
          } else {
            if (lagDays) {
              childTask.start = tempParentTask.start + (lagDays * columnWidth) / 30
            } else {
              childTask.start = tempParentTask.start
            }

            if (childTask.percentStepWork) {
              childTask.end =
                childTask.start +
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
            }
          }
        }
      }
    }

    return task
  })
  calculateStartEndRecursion(barTasks, columnWidth, amplifiedFactor)
  barTasks.forEach((task) => (task.index = findIndexSelectedTaskInChart(task.y, rowHeight, taskHeight)))
  return barTasks
}

const findIndexSelectedTaskInChart = (y: number, rowHeight: number, taskHeight: number) => {
  return (y - (rowHeight - taskHeight) / 2) / rowHeight
}

const convertToStepWorkBar = (
  task: Task,
  parentTask: Task,
  index: number,
  columnWidth: number,
  taskHeight: number,
  barCornerRadius: number,
  handleWidth: number,
  barBackgroundColor: string,
  barBackgroundSelectedColor: string,
  amplifiedFactor: number,
  y: number
): BarTask => {
  let start: number
  let end: number

  start = task.start
  const durationScale = getDurationScale(parentTask, amplifiedFactor)
  if (task.percentStepWork) {
    end = start + (task.duration * durationScale * columnWidth * task.percentStepWork) / 100 / 30
  } else {
    end = start + (task.duration * durationScale * columnWidth) / 30
  }

  let typeInternal: TaskTypeInternal = task.type
  if (typeInternal === 'task' && end - start < handleWidth * 2) {
    typeInternal = 'smalltask'
    end = start + handleWidth * 2
  }

  const hideChildren = task.type === 'project' ? task.hideChildren : undefined

  const styles = {
    backgroundColor: barBackgroundColor,
    backgroundSelectedColor: barBackgroundSelectedColor,
    ...task.styles
  }
  const groupsNumber = parentTask?.groupsNumber || 0
  return {
    ...task,
    typeInternal,
    start,
    end,
    y,
    index,
    barCornerRadius,
    handleWidth,
    hideChildren,
    height: taskHeight,
    barChildren: [],
    childProcessBar: [],
    stepworks: task.stepworks,
    groupsNumber: groupsNumber,
    styles
  }
}

const convertToBar = (
  task: Task,
  index: number,
  columnWidth: number,
  taskHeight: number,
  barCornerRadius: number,
  handleWidth: number,
  barBackgroundColor: string,
  barBackgroundSelectedColor: string,
  amplifiedFactor: number,
  y: number
): BarTask => {
  let start: number
  let end: number
  start = task.start
  const durationScale = getDurationScale(task, amplifiedFactor)
  if (task.percentStepWork) {
    end = start + (task.duration * durationScale * columnWidth * task.percentStepWork) / 100 / 30
  } else {
    end = start + (task.duration * durationScale * columnWidth) / 30
  }

  let typeInternal: TaskTypeInternal = task.type
  if (typeInternal === 'task' && end - start < handleWidth * 2) {
    typeInternal = 'smalltask'
    end = start + handleWidth * 2
  }

  const hideChildren = task.type === 'project' ? task.hideChildren : undefined

  const styles = {
    backgroundColor: barBackgroundColor,
    backgroundSelectedColor: barBackgroundSelectedColor,
    ...task.styles
  }
  return {
    ...task,
    typeInternal,
    start,
    end,
    y,
    index,
    barCornerRadius,
    handleWidth,
    hideChildren,
    height: taskHeight,
    barChildren: [],
    childProcessBar: [],
    stepworks: task.stepworks,
    styles
  }
}

export const calculateTaskPositionForSendDataToServer = (
  tasks: Task[],
  columnWidth: number,
  amplifiedFactor: number
) => {
  let barTasks: Task[] = []
  for (let index = 0; index < tasks.length; index++) {
    const task = tasks[index]
    if (task?.stepworks && task?.stepworks?.length !== 0) {
      const newStepWorks = []
      for (let j = 0; j < task?.stepworks?.length; j++) {
        const taskElement = task.stepworks[j]
        taskElement.duration = task.duration

        let start: number
        let end: number
        start = taskElement.start
        const durationScale = getDurationScale(task, amplifiedFactor)
        if (taskElement.percentStepWork) {
          end = start + (taskElement.duration * durationScale * columnWidth * taskElement.percentStepWork) / 100 / 30
        } else {
          end = start + (taskElement.duration * durationScale * columnWidth) / 30
        }
        newStepWorks.push({ ...taskElement, start: start, end: end })
      }
      task.stepworks = newStepWorks
      barTasks.push(task)
    } else {
      let start: number
      let end: number
      start = task.start
      const durationScale = getDurationScale(task, amplifiedFactor)
      if (task.percentStepWork) {
        end = start + (task.duration * durationScale * columnWidth * task.percentStepWork) / 100 / 30
      } else {
        end = start + (task.duration * durationScale * columnWidth) / 30
      }
      barTasks.push({ ...task, start: start, end: end })
    }
  }

  barTasks = barTasks.map((task) => {
    //task cha
    const dependencies = task?.predecessors

    if (dependencies) {
      for (let j = 0; j < dependencies.length; j++) {
        //task con
        const dependence = barTasks.findIndex((value) => value.id === dependencies[j].id)
        if (dependence !== -1) {
          const childTask = barTasks[dependence]

          // find true parent
          let tempParentTask: Task
          let predecessorType: string
          let lagDays: number
          const trueParent = getTrueParentToCalculatePosition(tasks, childTask.id)
          // console.log(trueParent)

          if (trueParent) {
            tempParentTask = trueParent
            predecessorType = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
            lagDays = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.lagDays || 0
          } else {
            tempParentTask = task
            predecessorType = task.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
            lagDays = dependencies[j].lagDays
          }

          const durationScale = getDurationScale(childTask, amplifiedFactor)
          if (predecessorType === 'FS') {
            if (lagDays) {
              childTask.start = tempParentTask.end + (lagDays * columnWidth) / 30
            } else {
              childTask.start = tempParentTask.end
            }

            if (childTask.percentStepWork) {
              childTask.end =
                childTask.start +
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
            }
          } else if (predecessorType === 'FF') {
            if (lagDays) {
              childTask.end = tempParentTask.end + (lagDays * columnWidth) / 30
            } else {
              childTask.end = tempParentTask.end
            }

            if (childTask.percentStepWork) {
              childTask.start =
                childTask.end -
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.start = childTask.end - (childTask.duration * durationScale * columnWidth) / 30
            }
          } else {
            if (lagDays) {
              childTask.start = tempParentTask.start + (lagDays * columnWidth) / 30
            } else {
              childTask.start = tempParentTask.start
            }

            if (childTask.percentStepWork) {
              childTask.end =
                childTask.start +
                (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
            } else {
              childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
            }
          }
        }
      }
    }

    return task
  })

  return barTasks
}

export const calculateStartEndRecursion = (tasks: Task[], columnWidth: number, amplifiedFactor: number) => {
  let listChildTask: string[] = []
  let listParentTask: string[] = []
  let listTasks: Task[] = []

  tasks.forEach((task) => {
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        stepWork.groupsNumber = task?.groupsNumber || 0
        if (stepWork?.predecessors) {
          stepWork.predecessors.map((st) => listChildTask.push(st.id))
          listTasks.push(stepWork)
        }
      })
    } else {
      if (task?.predecessors) {
        task.predecessors.map((st) => listChildTask.push(st.id))
      }
      //fix chỗ này
      listTasks.push(task)
    }
  })

  listTasks.forEach((task) => {
    if (task.type === 'task' && !listChildTask.includes(task.id) && task?.predecessors?.length !== 0) {
      listParentTask.push(task.id)
    }
  })

  const recursion = (taskIds: string[]): any => {
    let arrayResult: string[] = []
    for (let index = 0; index < taskIds.length; index++) {
      const taskId = taskIds[index]
      const task = listTasks.find((t) => t.id === taskId)
      if (task?.predecessors?.length === 0 || task?.predecessors === undefined || task === undefined) {
        if (taskIds.length === 1) {
          return arrayResult
        } else {
          continue
        }
      } else {
        const newTaskId = task?.predecessors?.map((x) => x.id)
        if (newTaskId) {
          arrayResult = arrayResult.concat(newTaskId)
          return arrayResult.concat(recursion(newTaskId))
        } else {
          return arrayResult
        }
      }
    }
  }

  const reRecursion = (taskIds: string[]): any => {
    let newList = [...taskIds]
    for (let index = 0; index < taskIds.length; index++) {
      const taskId = taskIds[index]
      const task = listTasks.find((t) => t.id === taskId)
      if (task?.predecessors?.length === 0 || task?.predecessors === undefined || task === undefined) {
        continue
      } else {
        const newTaskId = task?.predecessors?.map((x) => x.id)
        if (newTaskId) {
          let isRecursion = true
          newTaskId.forEach((id) => {
            if (!newList.includes(id)) {
              isRecursion = false
            }
          })
          if (!isRecursion) {
            const otherIds = recursion([taskId])
            newList = newList.concat(otherIds)
          }
        }
      }
    }
    return newList
  }

  const updateStartEndTask = (listTaskIds: string[], columnWidth: number) => {
    listTaskIds.forEach((id) => {
      const task = listTasks.find((x) => x.id === id)
      if (task) {
        const dependencies = task?.predecessors
        if (dependencies) {
          for (let j = 0; j < dependencies.length; j++) {
            //task con
            const dependence = listTasks.findIndex((value) => value.id === dependencies[j].id)
            if (dependence !== -1) {
              const childTask = listTasks[dependence]
              const durationScale = getDurationScale(childTask, amplifiedFactor)

              // find true parent
              let tempParentTask: Task
              let predecessorType: string
              let lagDays: number
              const trueParent = getTrueParentToCalculatePosition(tasks, childTask.id)
              // console.log(trueParent)

              if (trueParent) {
                tempParentTask = trueParent
                predecessorType = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
                lagDays = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.lagDays || 0
              } else {
                tempParentTask = task
                predecessorType = task.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
                lagDays = dependencies[j].lagDays
              }

              if (predecessorType === 'FS') {
                if (lagDays) {
                  childTask.start = tempParentTask.end + (lagDays * columnWidth) / 30
                } else {
                  childTask.start = tempParentTask.end
                }

                if (childTask.percentStepWork) {
                  childTask.end =
                    childTask.start +
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
                }
              } else if (predecessorType === 'FF') {
                if (lagDays) {
                  childTask.end = tempParentTask.end + (lagDays * columnWidth) / 30
                } else {
                  childTask.end = tempParentTask.end
                }

                if (childTask.percentStepWork) {
                  childTask.start =
                    childTask.end -
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.start = childTask.end - (childTask.duration * durationScale * columnWidth) / 30
                }
              } else {
                if (lagDays) {
                  childTask.start = tempParentTask.start + (lagDays * columnWidth) / 30
                } else {
                  childTask.start = tempParentTask.start
                }

                if (childTask.percentStepWork) {
                  childTask.end =
                    childTask.start +
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
                }
              }
            }
          }
        }
      }
    })
  }

  for (let index = 0; index < listParentTask.length; index++) {
    const taskId = listParentTask[index]
    let listIds = [taskId].concat(recursion([taskId]))

    listIds = listIds.filter(function (element) {
      return element !== undefined
    })

    listIds = reRecursion(listIds)

    listIds = listIds.filter(function (element) {
      return element !== undefined
    })
    updateStartEndTask(listIds, columnWidth)
  }
}

export const calculateStartEndTasks = (tasks: Task[], columnWidth: number, amplifiedFactor: number) => {
  let newTasks: Task[] = []
  newTasks = tasks.map((task) => {
    //task cha
    //TODO : sửa lại hàm này
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        const dependencies = stepWork?.predecessors
        if (dependencies) {
          for (let j = 0; j < dependencies.length; j++) {
            //task con
            const dependence = tasks.findIndex((value) => value.id === dependencies[j].id)
            if (dependence !== -1) {
              const childTask = tasks[dependence]
              const durationScale = getDurationScale(childTask, amplifiedFactor)

              // find true parent
              let tempParentTask: Task
              let predecessorType: string
              let lagDays: number
              const trueParent = getTrueParentToCalculatePosition(tasks, childTask.id)
              // console.log(trueParent)

              if (trueParent) {
                tempParentTask = trueParent
                predecessorType = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
                lagDays = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.lagDays || 0
              } else {
                tempParentTask = task
                predecessorType = task.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
                lagDays = dependencies[j].lagDays
              }

              if (predecessorType === 'FS') {
                if (lagDays) {
                  childTask.start = tempParentTask.end + (lagDays * columnWidth) / 30
                } else {
                  childTask.start = tempParentTask.end
                }

                if (childTask.percentStepWork) {
                  childTask.end =
                    childTask.start +
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
                }
              } else if (predecessorType === 'FF') {
                if (lagDays) {
                  childTask.end = tempParentTask.end + (lagDays * columnWidth) / 30
                } else {
                  childTask.end = tempParentTask.end
                }

                if (childTask.percentStepWork) {
                  childTask.start =
                    childTask.end -
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.start = childTask.end - (childTask.duration * durationScale * columnWidth) / 30
                }
              } else {
                if (lagDays) {
                  childTask.start = tempParentTask.start + (lagDays * columnWidth) / 30
                } else {
                  childTask.start = tempParentTask.start
                }

                if (childTask.percentStepWork) {
                  childTask.end =
                    childTask.start +
                    (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
                } else {
                  childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
                }
              }
            }
          }
        }
      })
      return task
    } else {
      const dependencies = task?.predecessors
      if (dependencies) {
        for (let j = 0; j < dependencies.length; j++) {
          //task con
          const dependence = tasks.findIndex((value) => value.id === dependencies[j].id)
          if (dependence !== -1) {
            const childTask = tasks[dependence]
            const durationScale = getDurationScale(childTask, amplifiedFactor)

            // find true parent
            let tempParentTask: Task
            let predecessorType: string
            let lagDays: number
            const trueParent = getTrueParentToCalculatePosition(tasks, childTask.id)
            // console.log(trueParent)

            if (trueParent) {
              tempParentTask = trueParent
              predecessorType = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
              lagDays = trueParent.predecessors?.find((pre) => pre.id === dependencies[j].id)?.lagDays || 0
            } else {
              tempParentTask = task
              predecessorType = task.predecessors?.find((pre) => pre.id === dependencies[j].id)?.type || 'FS'
              lagDays = dependencies[j].lagDays
            }

            if (predecessorType === 'FS') {
              if (lagDays) {
                childTask.start = tempParentTask.end + (lagDays * columnWidth) / 30
              } else {
                childTask.start = tempParentTask.end
              }

              if (childTask.percentStepWork) {
                childTask.end =
                  childTask.start +
                  (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
              } else {
                childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
              }
            } else if (predecessorType === 'FF') {
              if (lagDays) {
                childTask.end = tempParentTask.end + (lagDays * columnWidth) / 30
              } else {
                childTask.end = tempParentTask.end
              }

              if (childTask.percentStepWork) {
                childTask.start =
                  childTask.end -
                  (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
              } else {
                childTask.start = childTask.end - (childTask.duration * durationScale * columnWidth) / 30
              }
            } else {
              if (lagDays) {
                childTask.start = tempParentTask.start + (lagDays * columnWidth) / 30
              } else {
                childTask.start = tempParentTask.start
              }

              if (childTask.percentStepWork) {
                childTask.end =
                  childTask.start +
                  (childTask.duration * durationScale * columnWidth * childTask.percentStepWork) / 100 / 30
              } else {
                childTask.end = childTask.start + (childTask.duration * durationScale * columnWidth) / 30
              }
            }
          }
        }
      }
      return task
    }
  })
  return newTasks
}

export const convertBarTasksToTasks = (barTasks: BarTask[], copyTasks: Task[]) => {
  const newTasks = copyTasks.map((task) => {
    if (task?.stepworks) {
      const newStepWorks = task.stepworks.map((stepWork) => {
        const barTask = barTasks.find((t) => t.id === stepWork.id)
        if (barTask) {
          return {
            ...stepWork,
            start: barTask.start,
            end: barTask.end,
            predecessors: barTask.predecessors || undefined
          }
        }
        return { ...stepWork }
      })
      return { ...task, stepworks: newStepWorks }
    } else {
      const barTask = barTasks.find((t) => task.id === t.id)
      if (barTask) {
        return {
          ...task,
          start: barTask.start,
          end: barTask.end,
          predecessors: barTask.predecessors || undefined
        }
      }
      return { ...task }
    }
  })
  return newTasks
}

export const convertBarTasksToTasks2 = (barTasks: BarTask[], copyTasks: Task[]) => {
  const listTasksLocation = barTasks.map((task) => ({ id: task.id, start: task.start, end: task.end }))
  //console.log('🚀 ~ file: bar-helper.ts:640 ~ convertBarTasksToTasks2 ~ listTasksLocation:', listTasksLocation)
  const newTasks = [...copyTasks].map((task) => {
    if (task?.stepworks) {
      const newStepWorks = task.stepworks.map((stepWork) => {
        const barTask = listTasksLocation.find((t) => t.id === stepWork.id)
        if (barTask) {
          return {
            ...stepWork,
            start: barTask.start,
            end: barTask.end
          }
        }
        return { ...stepWork }
      })
      return { ...task, stepworks: newStepWorks }
    } else {
      const barTask = listTasksLocation.find((t) => t.id === task.id)
      if (barTask) {
        return {
          ...task,
          start: barTask.start,
          end: barTask.end
        }
      }
      return { ...task }
    }
  })
  return newTasks
}

export const isUpdateTasks = (barTasks: BarTask[], copytasks: Task[]) => {
  let result = false
  copytasks.forEach((task) => {
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        const barTask = barTasks.find((t) => t.id === stepWork.id)
        if (barTask) {
          if (barTask.start !== stepWork.start) {
            result = true
          }
        }
      })
    } else {
      const barTask = barTasks.find((t) => task.id === t.id)
      if (barTask) {
        if (barTask.start !== task.start) {
          result = true
        }
      }
    }
  })
  return result
}
// Kiểm tra task có start hoặc end bằng thỏa mãn điều kiện không
export const isValidateTasks = (tasks: Task[], svgWidth: number) => {
  let isValidate = true
  tasks.forEach((task) => {
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        if (stepWork.start < 0 || stepWork.end > svgWidth) {
          isValidate = false
          console.log('Task ', task)
          console.log('End VALUE ', stepWork.end)
        }
      })
    } else {
      if (task.start < 0 || task.end > svgWidth) {
        isValidate = false
        console.log('Task ', task)
        console.log('Task ', svgWidth)
      }
    }
  })
  return isValidate
}

const taskYCoordinate = (index: number, rowHeight: number, taskHeight: number) => {
  const y = index * rowHeight + (rowHeight - taskHeight) / 2
  return y
}

const moveByX = (x: number, xStep: number, task: BarTask) => {
  const steps = Math.round((x - task.start) / xStep)
  const additionalXValue = steps * xStep
  const newX1 = task.start + additionalXValue
  const newX2 = newX1 + task.end - task.start
  return [newX1, newX2]
}

/**
 * Method handles event in real time(mousemove) and on finish(mouseup)
 */
export const handleTaskBySVGMouseEvent = (
  svgX: number,
  selectedTask: BarTask,
  otherSelectedTask: BarTask[],
  xStep: number,
  initEventX1Delta: number,
  month: number,
  columnsWidth: number,
  amplifiedFactor: number
): { isChanged: boolean; changedTask: BarTask; otherChangedTask: BarTask[] } => {
  let result: { isChanged: boolean; changedTask: BarTask }
  result = handleTaskBySVGMouseEventForBar(
    svgX,
    selectedTask,
    xStep,
    initEventX1Delta,
    month,
    columnsWidth,
    amplifiedFactor
  )
  let otherChangedTask: BarTask[] = []
  const deltaX = result.changedTask.start - selectedTask.start
  if (result.isChanged) {
    otherSelectedTask.forEach((task) => {
      let newTask = changeOtherTask(task, deltaX, month, columnsWidth, amplifiedFactor)
      otherChangedTask.push(newTask)
    })
  }

  return { ...result, otherChangedTask }
}

const changeOtherTask = (
  task: BarTask,
  deltaX: number,
  month: number,
  columnsWidth: number,
  amplifiedFactor: number
) => {
  const newTask = { ...task }
  let newEnd: number
  let newStart = newTask.start + deltaX
  const durationScale = getDurationScale(newTask, amplifiedFactor)
  if (newTask.percentStepWork) {
    newEnd = newStart + (newTask.duration * durationScale * columnsWidth * newTask.percentStepWork) / 100 / 30
  } else {
    newEnd = newStart + (newTask.duration * durationScale * columnsWidth) / 30
  }
  if (newStart < 0) {
    newStart = 0
    if (newTask.percentStepWork) {
      newEnd = newStart + (newTask.duration * durationScale * columnsWidth * newTask.percentStepWork) / 100 / 30
    } else {
      newEnd = newStart + (newTask.duration * durationScale * columnsWidth) / 30
    }
    newTask.start = newStart
    newTask.end = newEnd
    return newTask
  }
  if (newEnd > month * columnsWidth) {
    newEnd = month * columnsWidth
    if (newTask.percentStepWork) {
      newStart = newEnd - (newTask.duration * durationScale * columnsWidth * newTask.percentStepWork) / 100 / 30
    } else {
      newStart = newEnd - (newTask.duration * durationScale * columnsWidth) / 30
    }
    newTask.start = newStart
    newTask.end = newEnd
    return newTask
  }
  newTask.start = newStart
  newTask.end = newEnd
  return newTask
}

const handleTaskBySVGMouseEventForBar = (
  svgX: number,
  selectedTask: BarTask,
  xStep: number,
  initEventX1Delta: number,
  month: number,
  columnsWidth: number,
  amplifiedFactor: number
): { isChanged: boolean; changedTask: BarTask } => {
  const [newMoveX11, newMoveX22] = moveByX(svgX - initEventX1Delta, xStep, selectedTask)
  const changedTask: BarTask = { ...selectedTask }
  let isChanged = false
  let newEnd: number
  let newStart = svgX - initEventX1Delta
  const durationScale = getDurationScale(selectedTask, amplifiedFactor)
  if (selectedTask.percentStepWork) {
    newEnd = newStart + (selectedTask.duration * durationScale * columnsWidth * selectedTask.percentStepWork) / 100 / 30
  } else {
    newEnd = newStart + (selectedTask.duration * durationScale * columnsWidth) / 30
  }

  if (newEnd > month * columnsWidth) {
    return { isChanged, changedTask }
  }
  if (newStart < 0) {
    return { isChanged, changedTask }
  }
  isChanged = newStart !== selectedTask.start

  if (isChanged) {
    changedTask.start = newStart
    changedTask.end = newEnd
  }

  return { isChanged, changedTask }
}
