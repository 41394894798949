export function initTasks() {
  const tasks = [
    {
      start: 0,
      duration: 30,
      name: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      id: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      type: 'project',
      hideChildren: false,
      displayOrder: 1,
      colorId: '',
      groupsNumber: 1,
      end: 119
    },
    {
      start: 0,
      duration: 2.95,
      name: 'ﾍﾞﾝﾄ基礎工',
      id: 'ﾍﾞﾝﾄ基礎工',
      type: 'task',
      detail: '',
      groupId: 'g1',
      displayOrder: 2,
      note: '',
      colorId: '',
      groupsNumber: 1,
      stepworks: [
        {
          start: 33.71666666666667,
          duration: 2.95,
          percentStepWork: 60,
          name: 'Idea1',
          parentTaskId: 'ﾍﾞﾝﾄ基礎工',
          id: 'Idea1',
          type: 'task',
          groupId: 'g1',
          displayOrder: 2,
          colorId: 476,
          end: 40.73766666666667,
          predecessors: [
            {
              type: 'FS',
              id: 'tXH-2YM0aAvGOwjBPNHzC',
              lagDays: 0
            }
          ]
        },
        {
          start: 422.92599999999993,
          duration: 2.95,
          percentStepWork: 40,
          name: 'Idea2',
          parentTaskId: 't1',
          id: 't1-2',
          type: 'task',
          groupId: 'g1',
          displayOrder: 2,
          predecessors: [],
          colorId: 477,
          end: 427.6066666666666
        }
      ]
    },
    {
      start: 7.373333333333335,
      duration: 28,
      name: 'ﾍﾞﾝﾄ設備工',
      id: 't2',
      predecessors: [
        {
          type: 'FS',
          id: 't1-1',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      displayOrder: 3,
      note: '',
      groupsNumber: 1,
      colorId: '',
      detail: '',
      end: 72.70666666666666,
      stepworks: [
        {
          duration: 28,
          percentStepWork: 60,
          parentTaskId: 't2',
          id: 'tXH-2YM0aAvGOwjBPNHzC',
          type: 'task',
          groupId: 'g1',
          displayOrder: 3,
          predecessors: [
            {
              type: 'FS',
              id: 't8',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 40.73766666666667,
          end: 107.37766666666667
        },
        {
          duration: 28,
          percentStepWork: 40,
          parentTaskId: 't2',
          id: 'FCaeLP735N4mA4PxcLksi',
          type: 'task',
          groupId: 'g1',
          displayOrder: 3,
          predecessors: [
            {
              type: 'FS',
              id: 't1-2',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 378.49933333333325,
          end: 422.92599999999993
        }
      ]
    },
    {
      start: null,
      duration: 4.3,
      name: '軌条設備工',
      id: 't3',
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      displayOrder: 4,
      colorId: '',
      groupsNumber: 1,
      predecessors: [],
      stepworks: [
        {
          duration: 4.3,
          percentStepWork: 40,
          parentTaskId: 't3',
          id: 'w0tcflxnvfmKh3yrkrKnq',
          type: 'task',
          groupId: 'g1',
          displayOrder: 4,
          predecessors: [
            {
              type: 'FS',
              id: 't7',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 748.303,
          end: 755.1256666666667
        },
        {
          duration: 4.3,
          percentStepWork: 60,
          parentTaskId: 't3',
          id: 'cCeGyUs1JlpDMUVNQigQf',
          type: 'task',
          groupId: 'g1',
          displayOrder: 4,
          predecessors: [
            {
              type: 'FS',
              id: 'vihUysm87FX0YyalsuI1s',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 640.2803333333333,
          end: 650.5143333333333
        }
      ],
      note: ''
    },
    {
      start: 70,
      duration: 15.9,
      name: '軌条桁設備工',
      id: 't4',
      predecessors: [
        {
          type: 'FF',
          id: 'w0tcflxnvfmKh3yrkrKnq',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      displayOrder: 5,
      groupsNumber: 1,
      colorId: '',
      stepworks: [
        {
          duration: 15.9,
          percentStepWork: 60,
          parentTaskId: 't4',
          id: 'HbwnbPByFm-EXDYQw3wDg',
          type: 'task',
          groupId: 'g1',
          displayOrder: 5,
          predecessors: [
            {
              type: 'FS',
              id: 't6',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 523.8983333333333,
          end: 561.7403333333333
        },
        {
          duration: 15.9,
          percentStepWork: 40,
          parentTaskId: 't4',
          id: 'XOKgvuCpHjbWCyK6jEkUu',
          type: 'task',
          groupId: 'g1',
          displayOrder: 5,
          predecessors: [
            {
              type: 'FS',
              id: 'N690Z0Mjdt0aNYCkY-QLL',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 802.7256666666667,
          end: 827.9536666666667
        }
      ]
    },
    {
      start: 0,
      duration: 2.5,
      name: '台車設備工',
      id: 't5',
      predecessors: [
        {
          type: 'FS',
          id: 'Onu0Dka7q6_qV9HjM-ntO',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      groupsNumber: 1,
      displayOrder: 6,
      colorId: '',
      stepworks: [
        {
          duration: 2.5,
          percentStepWork: 60,
          parentTaskId: 't5',
          id: 'vihUysm87FX0YyalsuI1s',
          type: 'task',
          groupId: 'g1',
          displayOrder: 6,
          predecessors: [
            {
              type: 'FS',
              id: 'fX4e_fW1osWIWd-Z4Xz9T',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 650.5143333333333,
          end: 656.4643333333333
        },
        {
          duration: 2.5,
          percentStepWork: 40,
          parentTaskId: 't5',
          id: 'nMehzMywNShhYzonsYM0F',
          type: 'task',
          groupId: 'g1',
          displayOrder: 6,
          predecessors: [
            {
              type: 'FS',
              id: 'w0tcflxnvfmKh3yrkrKnq',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 742.303,
          end: 746.2696666666667
        }
      ],
      note: ''
    },
    {
      start: 561.7403333333333,
      duration: 19.8,
      name: '覆工板',
      id: 't6',
      predecessors: [
        {
          type: 'FS',
          id: 'cCeGyUs1JlpDMUVNQigQf',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      groupsNumber: 1,
      displayOrder: 7,
      colorId: '',
      end: 640.2803333333333,
      note: '設置'
    },
    {
      duration: 12,
      name: '覆工板',
      id: 't7',
      predecessors: [
        {
          type: 'FS',
          id: 'XOKgvuCpHjbWCyK6jEkUu',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ｸﾚｰﾝﾍﾞﾝﾄ架設',
      groupsNumber: 1,
      displayOrder: 8,
      colorId: 477,
      end: 802.7256666666667,
      start: 755.1256666666667,
      note: '撤去'
    },
    {
      start: 107.37766666666667,
      duration: 16.7,
      name: '地組工',
      id: 't8',
      predecessors: [
        {
          type: 'FS',
          id: '8-j0GHr5R5YFsuATBcULl',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      groupsNumber: 1,
      displayOrder: 9,
      colorId: '',
      detail: '少数I桁',
      end: 173.62099999999998
    },
    {
      start: 190.87599999999998,
      duration: 15.1,
      name: '桁架設工',
      id: 't9',
      predecessors: [
        {
          type: 'FS',
          id: 'UbAeL4bGi0yGTvOz14AVL',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      groupsNumber: 1,
      displayOrder: 10,
      colorId: '',
      detail: '少数I桁',
      end: 250.77266666666662
    },
    {
      duration: 6.65,
      name: '支承据付工',
      id: 't10',
      predecessors: [
        {
          type: 'FS',
          id: 't1-1',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      groupsNumber: 2,
      displayOrder: 11,
      colorId: '',
      end: 33.71666666666667,
      detail: '',
      start: 7.338333333333334,
      note: 'W=4.2t/基'
    },
    {
      start: 327.92433333333327,
      duration: 12.75,
      name: '高力ﾎﾞﾙﾄ本締工',
      predecessors: [
        {
          type: 'FS',
          id: 'FCaeLP735N4mA4PxcLksi',
          lagDays: 0
        },
        {
          type: 'FS',
          id: 't12',
          lagDays: 0
        }
      ],
      id: 't11',
      type: 'task',
      groupId: 'g1',
      groupsNumber: 2,
      displayOrder: 12,
      colorId: '',
      end: 378.49933333333325
    },
    {
      start: 378.49933333333325,
      duration: 5.85,
      name: 'ﾋﾟﾝﾃｰﾙ処理工',
      id: 't12',
      predecessors: [
        {
          type: 'FS',
          id: 'vfgju3_Xv8BSwqx8VU2kB',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      groupsNumber: 2,
      displayOrder: 13,
      colorId: '',
      end: 401.70433333333324,
      detail: '',
      note: ''
    },
    {
      start: 656.4643333333333,
      end: 871.061,
      duration: 54.1,
      groupsNumber: 1,
      name: '合成床版工',
      id: 'Eq9GTb1AxGmXr6WiydspV',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'nMehzMywNShhYzonsYM0F',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 14,
      detail: '合成床版架設工',
      stepworks: [
        {
          duration: 54.1,
          percentStepWork: 60,
          parentTaskId: 'Eq9GTb1AxGmXr6WiydspV',
          name: '合成床版工yttk59BbFkGL_kuuIptBp',
          id: 'N690Z0Mjdt0aNYCkY-QLL',
          type: 'task',
          groupId: 'g1',
          displayOrder: 14,
          predecessors: [
            {
              type: 'FS',
              id: 't17',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 827.9536666666667,
          end: 956.7116666666666
        },
        {
          duration: 54.1,
          percentStepWork: 40,
          parentTaskId: 'Eq9GTb1AxGmXr6WiydspV',
          name: '合成床版工E2op-h5Q3vOx0Jm9PLJnG',
          id: 'fX4e_fW1osWIWd-Z4Xz9T',
          type: 'task',
          groupId: 'g1',
          displayOrder: 14,
          predecessors: [
            {
              type: 'FS',
              id: 'nMehzMywNShhYzonsYM0F',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 656.4643333333333,
          end: 742.303
        }
      ]
    },
    {
      start: 250.77266666666662,
      end: 290.83599999999996,
      duration: 10.1,
      groupsNumber: 2,
      name: '足場工',
      id: 'UbAeL4bGi0yGTvOz14AVL',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'mnN9T1XsI1I4rVkChnB_X',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 15,
      detail: '主体足場',
      note: '設置'
    },
    {
      start: 1394.385,
      end: 1423.1433333333334,
      duration: 7.25,
      groupsNumber: 2,
      name: '足場工',
      id: '2QbiIuPtkV63KVtdJVCHk',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'SWtjVE8aLlpVbuQmkN6tx',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 16,
      detail: '主体足場',
      note: '撤去',
      colorId: 477
    },
    {
      start: 310.66933333333327,
      end: 327.92433333333327,
      duration: 4.35,
      groupsNumber: 2,
      name: '足場工',
      id: 'gRv2iAFk0dNZ3m-Cw2lOt',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 't11',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 17,
      detail: '中段足場',
      note: '設置'
    },
    {
      start: 1363.99,
      end: 1376.8816666666667,
      duration: 3.25,
      groupsNumber: 2,
      name: '足場工',
      id: 'u3mdIgHnpi5mHH5ekQ6bb',
      progress: 100,
      predecessors: [],
      type: 'task',
      groupId: 'g1',
      displayOrder: 18,
      detail: '中段足場',
      note: '撤去',
      colorId: 477
    },
    {
      start: 0,
      end: 37.28666666666667,
      duration: 4.7,
      groupsNumber: 2,
      name: '足場工',
      id: 'jNgliJ0EEJD1KjKhHq_Ln',
      progress: 100,
      predecessors: [],
      type: 'task',
      groupId: 'g1',
      displayOrder: 19,
      detail: '安全通路',
      note: '設置･撤去',
      stepworks: [
        {
          duration: 4.7,
          percentStepWork: 60,
          parentTaskId: 'jNgliJ0EEJD1KjKhHq_Ln',
          name: '足場工d2aV_fH9HBGltq1yBwwbR',
          id: '8-j0GHr5R5YFsuATBcULl',
          type: 'task',
          groupId: 'g1',
          displayOrder: 19,
          predecessors: [
            {
              type: 'FS',
              id: 't20',
              lagDays: 0
            },
            {
              type: 'FS',
              id: 'lbNQzwCoWXXiB7FbWw9Bl',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 173.62099999999998,
          end: 184.807
        },
        {
          duration: 4.7,
          percentStepWork: 40,
          parentTaskId: 'jNgliJ0EEJD1KjKhHq_Ln',
          name: '足場工CAdRjken1Iyy9Lqd2p4fR',
          id: 'f97oxSXLEyIUbb3ZvGjZT',
          type: 'task',
          groupId: 'g1',
          displayOrder: 19,
          predecessors: [
            {
              type: 'FS',
              id: 'GmSJ-RFiVfEhUQ5nk7615',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 479.1043333333332,
          end: 486.56166666666655
        }
      ]
    },
    {
      start: 0,
      end: 2100,
      duration: 2.55,
      groupsNumber: 2,
      name: '足場工',
      id: 'sZTG6BdhM1A47swrGzY7j',
      progress: 100,
      predecessors: [],
      type: 'task',
      groupId: 'g1',
      displayOrder: 20,
      detail: '部分作業床',
      stepworks: [
        {
          duration: 2.55,
          percentStepWork: 60,
          parentTaskId: 'sZTG6BdhM1A47swrGzY7j',
          name: '足場工k4VtwwBdYQod3We8Nujqm',
          id: 'lbNQzwCoWXXiB7FbWw9Bl',
          type: 'task',
          groupId: 'g1',
          displayOrder: 20,
          predecessors: [
            {
              type: 'FS',
              id: 't9',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 184.807,
          end: 190.87599999999998
        },
        {
          duration: 2.55,
          percentStepWork: 40,
          parentTaskId: 'sZTG6BdhM1A47swrGzY7j',
          name: '足場工OSfXanpbEUDVS_jR8OEHx',
          id: 'vMbBqfGWl5yHWBJFV1C0p',
          type: 'task',
          groupId: 'g1',
          displayOrder: 20,
          predecessors: [
            {
              type: 'FS',
              id: 'f97oxSXLEyIUbb3ZvGjZT',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 473.1043333333332,
          end: 477.1503333333332
        }
      ],
      note: '設置･撤去'
    },
    {
      start: 290.83599999999996,
      end: 308.09099999999995,
      duration: 4.35,
      groupsNumber: 2,
      name: '足場工',
      id: 'mnN9T1XsI1I4rVkChnB_X',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: '8W31AcMyqQcuqvrTk6kUo',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 21,
      detail: '朝顔',
      note: '設置'
    },
    {
      start: 1382.8816666666667,
      end: 1394.385,
      duration: 2.9,
      groupsNumber: 2,
      name: '足場工',
      id: 'pnl4063vWpZcJKoDRHE97',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: '2QbiIuPtkV63KVtdJVCHk',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 22,
      detail: '朝顔',
      note: '撤去',
      colorId: 477
    },
    {
      start: 308.09099999999995,
      end: 310.66933333333327,
      duration: 0.65,
      groupsNumber: 2,
      name: '防護工',
      id: '8W31AcMyqQcuqvrTk6kUo',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'gRv2iAFk0dNZ3m-Cw2lOt',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 23,
      detail: '板張防護工',
      note: '設置'
    },
    {
      start: 1376.8816666666667,
      end: 1382.8816666666667,
      duration: 0.3,
      groupsNumber: 2,
      name: '防護工',
      id: 'l8JzKk3OEmK1_Cl5hJHwu',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'pnl4063vWpZcJKoDRHE97',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 24,
      detail: '板張防護工',
      note: '撤去',
      colorId: 477
    },
    {
      start: 0,
      end: 7.338333333333334,
      duration: 1.85,
      groupsNumber: 2,
      name: '登り桟橋工',
      id: '_P5PVqbd0dmrfMiYgVH1d',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 't10',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 25,
      detail: '手摺先行工法有り',
      note: '設置'
    },
    {
      start: 1423.1433333333334,
      end: 1428.6966666666667,
      duration: 1.4,
      groupsNumber: 2,
      name: '登り桟橋工',
      id: 'SWtjVE8aLlpVbuQmkN6tx',
      progress: 100,
      predecessors: [],
      type: 'task',
      groupId: 'g1',
      displayOrder: 26,
      detail: '手摺先行工法有り',
      note: '撤去'
    },
    {
      start: 401.70433333333324,
      end: 473.1043333333332,
      duration: 18,
      groupsNumber: 2,
      name: '現場塗装工',
      id: 'vfgju3_Xv8BSwqx8VU2kB',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'vMbBqfGWl5yHWBJFV1C0p',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g1',
      displayOrder: 27,
      detail: '',
      note: ''
    },
    {
      start: 0,
      duration: 30,
      name: '巻立てｺﾝｸﾘｰﾄ工',
      id: '巻立てｺﾝｸﾘｰﾄ工',
      predecessors: [],
      type: 'project',
      hideChildren: false,
      groupsNumber: 1,
      displayOrder: 28,
      colorId: '',
      end: 119
    },
    {
      start: 494.6536666666666,
      duration: 0.7,
      name: 'ｺﾝｸﾘｰﾄ工',
      id: 'ｺﾝｸﾘｰﾄ工',
      type: 'task',
      predecessors: [
        {
          type: 'FS',
          id: 't14',
          lagDays: 0
        }
      ],
      groupId: 'g2',
      groupsNumber: 1,
      displayOrder: 29,
      colorId: '',
      end: 497.43033333333324
    },
    {
      start: 500.6536666666666,
      duration: 5,
      name: '',
      id: 't14',
      predecessors: [
        {
          type: 'FS',
          id: 'UUVPDRgRxIi7Mfxa1gHfh',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: '巻立てｺﾝｸﾘｰﾄ工',
      groupsNumber: 1,
      displayOrder: 30,
      colorId: '',
      end: 520.487,
      note: '稼働率100%'
    },
    {
      start: 0,
      duration: 2.15,
      name: '型枠工',
      id: '型枠工',
      predecessors: [],
      type: 'task',
      groupId: 'g2',
      groupsNumber: 2,
      displayOrder: 31,
      colorId: '',
      end: 17.056666666666665,
      stepworks: [
        {
          duration: 2.15,
          percentStepWork: 60,
          parentTaskId: 't15',
          name: '型枠工V6R5-uKyQdIYzf1wJGJD-',
          id: 'GmSJ-RFiVfEhUQ5nk7615',
          type: 'task',
          groupId: 'g2',
          displayOrder: 31,
          predecessors: [
            {
              type: 'FS',
              id: 't16',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 486.56166666666655,
          end: 491.6786666666666
        },
        {
          duration: 2.15,
          percentStepWork: 40,
          parentTaskId: 't15',
          name: '型枠工vV_DQz92fZbLpXkn_lPGf',
          id: 'UUVPDRgRxIi7Mfxa1gHfh',
          type: 'task',
          groupId: 'g2',
          displayOrder: 31,
          predecessors: [
            {
              type: 'FS',
              id: 'HbwnbPByFm-EXDYQw3wDg',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 520.487,
          end: 523.8983333333333
        }
      ],
      note: '設置･撤去'
    },
    {
      start: 491.6786666666666,
      duration: 0.75,
      name: '鉄筋工',
      id: '鉄筋工',
      type: 'task',
      predecessors: [
        {
          type: 'FS',
          id: 't13',
          lagDays: 0
        }
      ],
      groupId: 'g2',
      groupsNumber: 2,
      displayOrder: 32,
      colorId: '',
      end: 494.6536666666666
    },
    {
      start: 0,
      duration: 30,
      name: '床板工',
      id: 'g3',
      type: 'project',
      predecessors: [],
      hideChildren: false,
      groupsNumber: 1,
      displayOrder: 33,
      colorId: '',
      end: 119
    },
    {
      start: 956.7116666666666,
      duration: 12.2,
      name: '鉄筋工',
      id: 't17',
      predecessors: [
        {
          type: 'FS',
          id: '55At0RMc1KFtY8TRQeT6w',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 2,
      displayOrder: 34,
      colorId: '',
      end: 1005.1049999999999
    },
    {
      start: null,
      duration: 19.05,
      name: '地覆・壁高欄部型枠工',
      id: 't18',
      predecessors: [],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 2,
      displayOrder: 35,
      colorId: '',
      end: null,
      stepworks: [
        {
          duration: 19.05,
          percentStepWork: 60,
          parentTaskId: 't18',
          id: 'mUQLJcPf30zyUIbG7o0mY',
          type: 'task',
          groupId: 'g3',
          displayOrder: 21,
          predecessors: [
            {
              type: 'FS',
              id: 'cl3PLgoXJ0f69tbCkAomz',
              lagDays: 0
            }
          ],
          colorId: 476,
          start: 1108.635,
          end: 1153.974
        },
        {
          duration: 19.05,
          percentStepWork: 40,
          parentTaskId: 't18',
          id: 'PiVvphzo9cxnvWuYZOx9D',
          type: 'task',
          groupId: 'g3',
          displayOrder: 21,
          predecessors: [
            {
              type: 'FS',
              id: 'nsc_IjnnsEfho2BpZ6DYe',
              lagDays: 0
            }
          ],
          colorId: 477,
          start: 1205.9373333333333,
          end: 1236.1633333333334
        }
      ],
      note: '設置･撤去'
    },
    {
      start: 1096.735,
      duration: 3,
      name: '地覆・壁高欄部鉄筋工',
      id: 't19',
      predecessors: [
        {
          type: 'FS',
          id: 'mUQLJcPf30zyUIbG7o0mY',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 2,
      displayOrder: 36,
      colorId: '',
      end: 1108.635
    },
    {
      start: 184.807,
      duration: 0.2,
      name: '目地板設置工',
      id: 't20',
      predecessors: [],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 1,
      displayOrder: 37,
      colorId: '',
      end: 185.6003333333333
    },
    {
      start: 1018.9883333333332,
      duration: 2,
      name: '床版ｺﾝｸﾘｰﾄ工',
      id: 't21',
      predecessors: [
        {
          type: 'FS',
          id: 't22',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 1,
      displayOrder: 38,
      colorId: '',
      end: 1026.9216666666666,
      detail: '',
      note: '稼働率100%'
    },
    {
      start: 1026.9216666666666,
      duration: 12.6,
      name: '',
      id: 't22',
      predecessors: [
        {
          type: 'FS',
          id: '9dPU_-VLsZHT3o31F8Bic',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      groupsNumber: 1,
      displayOrder: 39,
      colorId: '',
      end: 1076.9016666666666,
      detail: ''
    },
    {
      start: 1076.9016666666666,
      end: 1096.735,
      duration: 5,
      groupsNumber: 1,
      name: '床版ｺﾝｸﾘｰﾄ養生工',
      id: '9dPU_-VLsZHT3o31F8Bic',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 't19',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      displayOrder: 40
    },
    {
      start: 1153.974,
      end: 1186.104,
      duration: 8.1,
      groupsNumber: 1,
      name: '地覆・壁高欄部ｺﾝｸﾘｰﾄ工',
      id: 'cl3PLgoXJ0f69tbCkAomz',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'TitB0V4m3TEFvnYcLZvcc',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      displayOrder: 41,
      detail: ''
    },
    {
      start: 1186.104,
      end: 1205.9373333333333,
      duration: 5,
      groupsNumber: 1,
      name: '地覆・壁高欄部養生工',
      id: 'TitB0V4m3TEFvnYcLZvcc',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'PiVvphzo9cxnvWuYZOx9D',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'g3',
      displayOrder: 42,
      detail: '',
      note: '稼働率100%'
    },
    {
      start: 0,
      end: 119,
      duration: 30,
      name: '橋面工',
      id: 'ubtt7c1KUe2xo5SqF7N8C',
      type: 'project',
      hideChildren: false,
      displayOrder: 43
    },
    {
      start: 1357.99,
      end: 1360.3700000000001,
      duration: 0.6,
      name: 'ｱｽﾌｧﾙﾄ舗装',
      id: 'task43',
      progress: 100,
      dependencies: [],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 44,
      groups: 1,
      groupsNumber: 2,
      detail: '表層t=40mm',
      predecessors: [
        {
          type: 'FS',
          id: 'u3mdIgHnpi5mHH5ekQ6bb',
          lagDays: 0
        }
      ]
    },
    {
      start: 1351.99,
      end: 1354.3700000000001,
      duration: 0.6,
      groupsNumber: 2,
      name: '',
      id: 'TrZ5iaZvwCLZpIEKvIqyW',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'task43',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 45,
      detail: '表層t=40mm'
    },
    {
      start: 1345.445,
      end: 1351.99,
      duration: 1.65,
      groupsNumber: 2,
      name: 'ｺﾝｸﾘｰﾄ舗装',
      id: 'Cf-Tjfq9ut0FBEC8QECFA',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'TrZ5iaZvwCLZpIEKvIqyW',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 46,
      detail: ''
    },
    {
      start: 1317.0833333333333,
      end: 1345.445,
      duration: 7.15,
      groupsNumber: 2,
      name: '防水層',
      id: 'mkulJMUu3dkiyC-W4900K',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'Cf-Tjfq9ut0FBEC8QECFA',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 47,
      detail: 'ｼｰﾄ系',
      note: ''
    },
    {
      start: 1005.1049999999999,
      end: 1013.0383333333332,
      duration: 2,
      groupsNumber: 2,
      name: '排水装置',
      id: '55At0RMc1KFtY8TRQeT6w',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'AZqsPxw-kKnXJlpQlhxX1',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 48,
      detail: '排水桝'
    },
    {
      start: 1247.4683333333332,
      end: 1307.1666666666665,
      duration: 15.05,
      groupsNumber: 2,
      name: '',
      id: 'hMLxykCUOu0YM0eaMj2Ct',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: '91GfFjLnalWogQiDWBEf1',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 49,
      detail: '排水桝'
    },
    {
      start: 1236.3616666666667,
      end: 1247.4683333333332,
      duration: 2.8,
      groupsNumber: 2,
      name: '',
      id: '2Lh9BDyvau1FvP1zdNdwU',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'hMLxykCUOu0YM0eaMj2Ct',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 50,
      detail: 'ｺﾝｸﾘｰﾄｱﾝｶｰﾎﾞﾙﾄ'
    },
    {
      start: 1236.1633333333334,
      end: 1236.3616666666667,
      duration: 0.05,
      groupsNumber: 2,
      name: '橋面排水',
      id: 'nsc_IjnnsEfho2BpZ6DYe',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: '2Lh9BDyvau1FvP1zdNdwU',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 51,
      detail: 'ﾌﾚｷｼﾌﾞﾙﾁｭｰﾌﾞ'
    },
    {
      start: 1307.1666666666665,
      end: 1317.0833333333333,
      duration: 2.5,
      groupsNumber: 1,
      name: '落橋防止構造',
      id: '91GfFjLnalWogQiDWBEf1',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 'mkulJMUu3dkiyC-W4900K',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 52,
      detail: ''
    },
    {
      start: 1013.0383333333332,
      end: 1018.9883333333332,
      duration: 1.5,
      groupsNumber: 1,
      name: '伸縮装置継手設置工',
      id: 'AZqsPxw-kKnXJlpQlhxX1',
      progress: 100,
      predecessors: [
        {
          type: 'FS',
          id: 't21',
          lagDays: 0
        }
      ],
      type: 'task',
      groupId: 'ubtt7c1KUe2xo5SqF7N8C',
      displayOrder: 53,
      detail: '鋼ﾌｨﾝｶﾞｰｼﾞｮｲﾝﾄ'
    }
  ]
  return tasks
}

export function getStartEndDateForProject(tasks, versionId) {
  const projectTasks = tasks.filter((t) => t.groupId === versionId)
  let start = projectTasks[0].start
  let end = projectTasks[0].end

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i]
    if (start.getTime() > task.start.getTime()) {
      start = task.start
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end
    }
  }
  return [start, end]
}
