import React from 'react'
import styles from './MoveGroupTaskModal.module.scss'
import { MoveGroupTo } from 'F_UTILS/SchedulingUtils/GroupTaskUtils'
import { MoveTaskTo } from 'F_UTILS/SchedulingUtils/TaskUtils'

const MoveGroupTaskModal = ({ task, tasks, setTasks, setShowMoveGroupTask }) => {
  const [selectedTask, setSelectedTask] = React.useState(null)
  const [copyTasks, setCopyTasks] = React.useState([])
  const handleOkClick = () => {
    if (task.type === 'task') {
      const newTasks = MoveTaskTo(task, selectedTask, tasks)
      setTasks(newTasks)
    } else {
      const newTasks = MoveGroupTo(task, selectedTask, tasks)

      setTasks(newTasks)
    }
    setShowMoveGroupTask(false)
  }
  const handleCancelClick = () => {
    setShowMoveGroupTask(false)
  }
  React.useEffect(() => {
    let newCopyTasks = [...tasks]
    newCopyTasks = newCopyTasks.filter((t) => t.id !== task.id)
    setCopyTasks(newCopyTasks)
  }, [tasks, task])
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className={styles.heading}>{task.type === 'task' ? 'タスク移動' : 'グループ移動'}</div>
        <div className={styles.title}>{task.type === 'task' ? '下にタスク移動：' : '下にグループ移動：'}</div>
        <div className={styles.taskContainer}>
          {copyTasks.map((t) => {
            if (task.type === 'task') {
              if (t.type === 'project') {
                return (
                  <div
                    key={t.id}
                    className={`${selectedTask?.id === t.id ? styles.active : ''} ${styles.group}`}
                    onClick={() => setSelectedTask(t)}
                    style={{ minHeight: '33px' }}
                  >
                    {t.name}
                  </div>
                )
              } else {
                return (
                  <div
                    key={t.id}
                    className={`${selectedTask?.id === t.id ? styles.active : ''} ${styles.task}`}
                    onClick={() => setSelectedTask(t)}
                    style={{ minHeight: '33px' }}
                  >
                    {t.name}
                  </div>
                )
              }
            } else {
              if (t.type === 'project') {
                return (
                  <div
                    key={t.id}
                    className={`${selectedTask?.id === t.id ? styles.active : ''} ${styles.group}`}
                    onClick={() => setSelectedTask(t)}
                    style={{ minHeight: '33px' }}
                  >
                    {t.name}
                  </div>
                )
              }
            }
          })}
        </div>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOkClick}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
           キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}

export default MoveGroupTaskModal
