import React from 'react'
import styles from './Checkbox.module.scss'

export default function Checkbox({ name, handleCheckBoxChange }) {
  return (
    <div className='h-full flex'>
      <input
        type='checkbox'
        name=''
        id={name}
        className={styles.checkbox_input}
        onChange={(e) => handleCheckBoxChange(e, name)}
      ></input>
      <label htmlFor={name} className={styles.checkbox_label}>
        {name}
      </label>
    </div>
  )
}
