import React from 'react'
import style from './bar.module.css'
import { useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { shadeColor } from 'F_UTILS/SchedulingUtils/ColorUtils'
import { BarTask } from 'Z_SERVICES/j_MainChart/grant-base/types/bar-task'
import { getDurationScale } from 'Z_SERVICES/j_MainChart/grant-base/helpers/other-helper'

type BarDisplayProps = {
  x: number
  y: number
  width: number
  task: BarTask
  height: number
  isSelected: boolean
  taskId: string
  /* progress start point */
  barCornerRadius: number
  colorId?: number
  styles: {
    backgroundColor: string
    backgroundSelectedColor: string
  }
  onMouseDown: (event: React.MouseEvent<SVGPolygonElement, MouseEvent>) => void
}
export const BarDisplay: React.FC<BarDisplayProps> = ({
  x,
  y,
  taskId,
  width,
  height,
  isSelected,
  barCornerRadius,
  colorId,
  styles,
  task,
  onMouseDown
}) => {
  const percentSelected = -50
  const stepWorkColors = useSelector((state: RootState) => state.versionSetting.current.stepworkColors)
  const amplifiedFactor = useSelector((state: RootState) => state.versionSetting.current.amplifiedFactor)
  const predecessorParent = useSelector((state: RootState) => state.predecessor)

  const getBarColor = () => {
    if (colorId) {
      const color = stepWorkColors?.find((x: any) => x.colorId === colorId)
      if (color !== undefined) {
        return isSelected ? shadeColor(color.code, percentSelected) : color.code
      } else {
        const colorInstall = stepWorkColors?.find((x: any) => x.isInstall === 0)
        if (colorInstall) {
          return isSelected ? shadeColor(colorInstall.code, percentSelected) : colorInstall.code
        }
      }
    } else {
      return isSelected ? shadeColor(styles.backgroundColor, percentSelected) : styles.backgroundColor
    }
  }

  const getStrokeWidth = () => {
    if (predecessorParent === '-1') {
      return 0
    }
    if (predecessorParent === taskId) {
      return 2
    }

    return isSelected ? 1 : 0
  }

  const getTaskHeight = () => {
    if (predecessorParent === '-1') {
      return height - 6
    }
    if (predecessorParent === taskId) {
      return height - 3
    }

    return height - 6
  }
  const getContentDuration = (t: BarTask) => {
    const durationScale = getDurationScale(task, amplifiedFactor)
    if (t?.percentStepWork) {
      return (t.duration * durationScale * t.percentStepWork) / 100
    }
    return t.duration * durationScale
  }

  const getWidthPixel = (number: number) => {
    const text = number.toFixed(1)

    let length = text.length * 7.5
    if (text.includes('.') || text.includes(',')) {
      length -= 3.5
    }
    return length
  }

  return (
    <g onMouseDown={onMouseDown}>
      <rect
        x={x}
        width={width}
        y={y + 3}
        height={getTaskHeight()}
        ry={barCornerRadius}
        rx={barCornerRadius}
        fill={getBarColor()}
        strokeWidth={getStrokeWidth()}
        stroke='white'
        className={style.barBackground}
      />
      <text
        x={x + width / 2 - getWidthPixel(getContentDuration(task)) / 2}
        y={y + 1}
        fill='black'
        style={{ fontFamily: 'Noto Sans JP', userSelect: "none" }}
      >
        {getContentDuration(task).toFixed(1)}
      </text>
    </g>
  )
}
