import { toast } from "react-toastify";
import { ListAllColorsIndex } from "../F_UTILS/Constants/ListColorsIndex";

function downloadCSV(csv, filename) {
  var csvFile;
  var downloadLink;
  const textEncoder = new TextEncoder("windows-1252");
  var csvContentEncoded = textEncoder.encode([csv]);
  // CSV file
  csvFile = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContentEncoded], {
    type: "text/csv;charset=utf-8",
  });

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();
}

export function RemoveDeleteButton() {
  var table = document.getElementById("contents-table");
  var rowCount = table.rows.length - 1;
  var rows = document.querySelectorAll("#contents-table tr");
  for (let i = 0; i < rowCount; i++) {
    var cols = rows[i].querySelectorAll("td, th");
    if (cols[0].innerHTML !== "") {
      cols[0].setAttribute("contenteditable", false);
      cols[1].classList.remove("clickAble");
      cols[2].classList.remove("clickAble");
      var new_element1 = cols[1].cloneNode(true);
      cols[1].parentNode.replaceChild(new_element1, cols[1]);

      var new_element2 = cols[2].cloneNode(true);
      cols[2].parentNode.replaceChild(new_element2, cols[2]);
      cols[3].innerHTML = "";
    }
  }
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function WriteDataToTableLayerWhenLoadFile(data) {
  if (data.length === 0) {
    return;
  }
  var table = document.getElementById("contents-table");
  var rowCount = table.rows.length;

  if (data.length > rowCount) {
    var rows = document.querySelectorAll("#contents-table tr");

    for (var i = 0; i < rowCount; i++) {
      var cols = rows[i].querySelectorAll("td, th");

      cols[0].classList.remove("error-cell");
      cols[0].classList.remove("warning-cell");
      rows[i].classList.remove("row-draw-layer");
      cols[0].innerText = data[i].LayerName;
      let colorRgb = "";
      if (
        data[i]?.NewIndexColor !== "" &&
        data[i]?.NewIndexColor !== undefined
      ) {
        if (data[i]?.NewIndexColor.includes(",")) {
          colorRgb = `rgb(${data[i].NewIndexColor})`;
          cols[2].innerText = data[i].NewIndexColor.replace("\r", "");
        } else {
          colorRgb = ListAllColorsIndex.find(
            (ele) =>
              ele.index === data[i].NewIndexColor.replace("\r", "").toString()
          ).color;
          cols[2].innerText = data[i].NewIndexColor.replace("\r", "");
        }
      } else if (
        data[i]?.IndexColor !== "" &&
        data[i]?.IndexColor !== undefined
      ) {
        if (data[i]?.IndexColor.includes(",")) {
          colorRgb = `rgb(${data[i]?.IndexColor})`;
          cols[2].innerText = data[i].IndexColor.replace("\r", "");
        } else {
          colorRgb = ListAllColorsIndex.find(
            (ele) =>
              ele.index === data[i].IndexColor.toString().replace("\r", "")
          ).color;
          cols[2].innerText = data[i].IndexColor.toString().replace("\r", "");
        }
      }

      if (colorRgb)
        cols[1].innerHTML = `<div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div>`;
      if (data[i]?.isExitLayer !== "yes") {
        cols[3].innerHTML =
          '<button class="button-delete"><i class="fa-regular fa-trash-can"></i></button>';
        cols[1].classList.add("clickAble");
        cols[2].classList.add("clickAble");
        cols[0].setAttribute("contenteditable", true);
      } else {
        cols[0].setAttribute("contenteditable", false);
        cols[1].classList.remove("clickAble");
        cols[2].classList.remove("clickAble");
        cols[3].innerHTML = "";
        rows[i].classList.add("row-draw-layer");
      }
    }

    for (let index = rowCount; index < data.length; index++) {
      if (data[index]) {
        let colorRgb = "";
        if (
          data[index]?.NewIndexColor !== "" &&
          data[index]?.NewIndexColor !== undefined
        ) {
          if (data[index]?.NewIndexColor.includes(",")) {
            colorRgb = `rgb(${data[index].NewIndexColor})`;
          } else {
            colorRgb = ListAllColorsIndex.find(
              (ele) =>
                ele.index ===
                data[index].NewIndexColor.replace("\r", "").toString()
            ).color;
          }
        } else if (
          data[index]?.IndexColor !== "" &&
          data[index]?.IndexColor !== undefined
        ) {
          if (data[index]?.IndexColor.includes(",")) {
            colorRgb = `rgb(${data[index]?.IndexColor})`;
          } else {
            colorRgb = ListAllColorsIndex.find(
              (ele) =>
                ele.index ===
                data[index].IndexColor.toString().replace("\r", "")
            ).color;
          }
        }

        let content = "";
        if (data[index]?.isExitLayer !== "yes") {
          content = `<td contenteditable="true" style="height:31px; word-break:break-word;">${
            data[index].LayerName
          }</td> 
          <td class="clickAble" style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
          <td class="clickAble" style="border-left: none; border-right: none">${data[
            index
          ].IndexColor.toString().replace("\r", "")}</td>
          <td  style="border-left: none"><button class="button-delete"><i class="fa-regular fa-trash-can"></i></button></td>`;

          if (data[index].NewIndexColor) {
            content = `<td contenteditable="true" style="height:31px; word-break:break-word;">${
              data[index].LayerName
            }</td> 
            <td class="clickAble" style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td class="clickAble" style="border-left: none; border-right: none">${data[
              index
            ].NewIndexColor.replace("\r", "")}</td>
            <td style="border-left: none"><button class="button-delete"><i class="fa-regular fa-trash-can"></i></button></td>`;
          }
        } else {
          content = `<td style="height:31px; word-break:break-word;">${
            data[index].LayerName
          }</td> 
            <td  style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td  style="border-left: none; border-right: none">${data[
              index
            ].IndexColor.toString()
              .replace("\r", "")
              .toString()}</td>
            <td  style="border-left: none"></td>`;
        }

        const trElement = document.createElement("tr");
        trElement.innerHTML = content;
        if (trElement.innerText !== "") {
          document.querySelector("tbody").append(trElement);
          if (data[index]?.isExitLayer === "yes") {
            trElement.classList.add("row-draw-layer");
          }
        }
      }
    }
    let lastRow = `<td contenteditable="true" style="height:31px; word-break:break-word;"}}></td> 
    <td class="clickAble" style="border-right:none;"></td> 
    <td class="clickAble" style="border-left: none; border-right: none"></td>
    <td  style="border-left: none"></td>`;
    const trElement = document.createElement("tr");
    trElement.innerHTML = lastRow;
    document.querySelector("tbody").append(trElement);
  } else {
    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < data.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[0].classList.remove("error-cell");
      cols[0].classList.remove("warning-cell");
      rows[i].classList.remove("row-draw-layer");
      if (data[i]) {
        cols[0].innerText = data[i].LayerName;
        let colorRgb = "";
        if (
          data[i]?.NewIndexColor !== "" &&
          data[i]?.NewIndexColor !== undefined
        ) {
          if (data[i]?.NewIndexColor.includes(",")) {
            colorRgb = `rgb(${data[i].NewIndexColor})`;
            cols[2].innerText = data[i].NewIndexColor.replace("\r", "");
          } else {
            colorRgb = ListAllColorsIndex.find(
              (ele) =>
                ele.index === data[i].NewIndexColor.replace("\r", "").toString()
            ).color;
            cols[2].innerText = data[i].NewIndexColor.replace(
              "\r",
              ""
            ).toString();
          }
        } else if (
          data[i]?.IndexColor !== "" &&
          data[i]?.IndexColor !== undefined
        ) {
          if (data[i]?.IndexColor.includes(",")) {
            colorRgb = `rgb(${data[i]?.IndexColor})`;
            cols[2].innerText = data[i].IndexColor.replace("\r", "");
          } else {
            colorRgb = ListAllColorsIndex.find(
              (ele) =>
                ele.index === data[i].IndexColor.toString().replace("\r", "")
            ).color;
            cols[2].innerText = data[i].IndexColor.toString().replace("\r", "");
          }
        }
        if (colorRgb)
          cols[1].innerHTML = `<div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div>`;

        if (data[i]?.isExitLayer !== "yes") {
          cols[3].innerHTML =
            '<button class="button-delete"><i class="fa-regular fa-trash-can"></i></button>';
          cols[1].classList.add("clickAble");
          cols[2].classList.add("clickAble");
          cols[0].setAttribute("contenteditable", true);
        } else {
          cols[0].setAttribute("contenteditable", false);
          cols[1].classList.remove("clickAble");
          cols[2].classList.remove("clickAble");
          cols[3].innerHTML = "";
          rows[i].classList.add("row-draw-layer");
        }
      }
    }

    if (rowCount !== data.length) {
      for (let index = rowCount - 1; index >= data.length; index--) {
        table.deleteRow(index);
      }
    }
    let lastRow = `<td contenteditable="true" style="height:31px; word-break:break-word;"></td> 
    <td class="clickAble" style="border-right:none;"></td> 
    <td class="clickAble" style="border-left: none; border-right: none"></td>
    <td  style="border-left: none"></td>`;
    const trElement = document.createElement("tr");
    trElement.innerHTML = lastRow;
    document.querySelector("tbody").append(trElement);
  }
}

export function ExportTableToCSV(filename, listLayerName) {
  var csv = [];
  const preWord = document.getElementById("pre").value;
  const subWord = document.getElementById("sub").value;
  var data = [];

  //Nếu có prefix và subfix
  if (preWord !== "" || subWord !== "") {
    for (let i = 0; i < listLayerName.length; i++) {
      const layerName = listLayerName[i];
      if (layerName?.isExitLayer !== undefined) {
        if (
          layerName?.NewIndexColor !== "" &&
          layerName?.NewIndexColor !== undefined
        ) {
          data.push({
            LayerName: layerName.LayerName,
            colorIndex: layerName.NewIndexColor.replace("\r", "").toString(),
          });
        } else if (
          layerName?.IndexColor !== "" &&
          layerName?.IndexColor !== undefined
        ) {
          data.push({
            LayerName: layerName.LayerName,
            colorIndex: layerName.IndexColor.toString()
              .replace("\r", "")
              .toString(),
          });
        }
      } else {
        if (
          layerName?.NewIndexColor !== "" &&
          layerName?.NewIndexColor !== undefined
        ) {
          data.push({
            LayerName: preWord + layerName.LayerName + subWord,
            colorIndex: layerName.NewIndexColor.replace("\r", "").toString(),
          });
        } else if (
          layerName?.IndexColor !== "" &&
          layerName?.IndexColor !== undefined
        ) {
          data.push({
            LayerName: preWord + layerName.LayerName + subWord,
            colorIndex: layerName.IndexColor.toString()
              .replace("\r", "")
              .toString(),
          });
        }
      }
    }
  } else {
    for (let i = 0; i < listLayerName.length; i++) {
      const layerName = listLayerName[i];
      if (
        layerName?.NewIndexColor !== "" &&
        layerName?.NewIndexColor !== undefined
      ) {
        data.push({
          LayerName: layerName.LayerName,
          colorIndex: layerName.NewIndexColor.replace("\r", "").toString(),
        });
      } else if (
        layerName?.IndexColor !== "" &&
        layerName?.IndexColor !== undefined
      ) {
        data.push({
          LayerName: layerName.LayerName,
          colorIndex: layerName.IndexColor.toString()
            .replace("\r", "")
            .toString(),
        });
      }
    }
  }
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element.colorIndex.includes(",")) {
      csv.push(element.LayerName + `,"${element.colorIndex}"`);
    } else {
      csv.push(element.LayerName + "," + element.colorIndex);
    }
  }
  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
}
