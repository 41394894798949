import React from 'react'
import styles from './MenuTrash.module.scss'
import { useEffect } from 'react'
import { useState } from 'react'
import DeleteProjectComponent from '../../components/DeleteProjectComponent'
import { ToastContainer } from 'react-toastify'
import { Button, Pagination, Select, Skeleton } from 'antd'
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'
import { DialogConfirm } from 'A_SHARED_COMPONENT/DialogComponent'
import SideBar from '../../components/SideBar'
import TableRowShared from 'Z_SERVICES/i_MenuSchedulingTool/components/TableRowShared'

export default function MenuTrash() {
  const [isLoading, setIsLoading] = useState(false)
  const [listProjects, setListProject] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [modifiedProject, setModifiedProject] = useState({})
  const [openDeleteVersion, setOpenDeleteVersion] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState('My Project')

  useEffect(() => {
    async function fetchListProject() {
      try {
        setIsLoading(true)
        let response
        if (selectedFolder === 'My Project') {
          response = await VersionApi.GetDeactiveProjectsByPage(currentPage, 10)
        } else {
          response = await VersionApi.GetDeactiveSharedProjectsByPage(currentPage, 10)
        }
        setListProject(response.data.data)
        setPageCount(response.data.pageCount)
        setIsLoading(false)
      } catch (error) {
        console.log('Get List Project ', error)
      }
    }
    fetchListProject()
  }, [currentPage, modifiedProject, selectedFolder])

  const onChange = (page) => {
    setCurrentPage(page)
  }

  const handleRestore = async () => {
    try {
      const listVersion = listProjects.reduce(
        (listVers, currentProject) => (listVers = listVers.concat(currentProject.versions)),
        []
      )
      const restoreListIds = listVersion.filter((x) => x.isChecked).map((x) => x.versionId)
      if (restoreListIds.length === 0) {
        return
      }
      const res = await VersionApi.RestoreVersions(restoreListIds)
      if (res.status !== 204) {
        ToastError('バージョンの修復に失敗しました。')
      } else {
        ToastSuccess('バージョンの修復に成功しました。')
        let response
        if (selectedFolder === 'My Project') {
          response = await VersionApi.GetDeactiveProjectsByPage(currentPage, 10)
        } else {
          response = await VersionApi.GetDeactiveSharedProjectsByPage(currentPage, 10)
        }
        setListProject(response.data.data)
        setPageCount(response.data.pageCount)
      }
    } catch (error) {
      ToastSuccess('バージョンの修復に成功しました。')
    }
  }

  const handleDeleteDeActiveVersionEvent = () => {
    setOpenDeleteVersion(true)
    setCurrentPage(1)
  }

  const getAllSelectedVersion = () => {
    const listVersion = listProjects.reduce(
      (listVers, currentProject) => (listVers = listVers.concat(currentProject.versions)),
      []
    )
    const deleteListIds = listVersion.filter((x) => x.isChecked).map((x) => x.versionId)

    return deleteListIds
  }

  const handleDeleteDeActiveVersion = async () => {
    try {
      const deleteListIds = getAllSelectedVersion()
      if (deleteListIds.length === 0) {
        return
      }
      const res = await VersionApi.DeleteVersions(deleteListIds)
      if (res.status !== 204) {
        ToastError('バージョンの削除に失敗しました。')
        setOpenDeleteVersion(false)
      } else {
        ToastSuccess('バージョンの削除に成功しました。')
        if (selectedFolder === 'My Project') {
          const response = await VersionApi.GetDeactiveProjectsByPage(currentPage, 10)
          setListProject(response.data.data)
          setPageCount(response.data.pageCount)
        } else {
          const response = await VersionApi.GetDeactiveSharedProjectsByPage(currentPage, 10)
          setListProject(response.data.data)
          setPageCount(response.data.pageCount)
        }
        setOpenDeleteVersion(false)
      }
    } catch (error) {
      ToastSuccess('バージョンの削除に成功しました。')
    }
    setOpenDeleteVersion(false)
  }

  const handleSelectedChange = (value) => {
    setSelectedFolder(value)
  }

  return (
    <>
      <ToastContainer style={{ width: '650px', zIndex: 10000000 }} />

      <DialogConfirm
        title={'バージョンの削除'}
        open={openDeleteVersion && getAllSelectedVersion().length > 0}
        handleOK={handleDeleteDeActiveVersion}
        handleClose={() => setOpenDeleteVersion(false)}
      >
        <h3 className=' text-base'>選択したバージョンを削除しますか。</h3>
      </DialogConfirm>

      <div className={styles.container}>
        <SideBar></SideBar>
        <div className='flex-1 px-10 py-4'>
          <div className={styles.heading}>
            <h2 className={styles.title}>ごみ箱</h2>
            <div className={styles.actions_container}>
              <div className={styles.actions}>
                <Select
                  value={selectedFolder}
                  style={{ width: 250, fontWeight: 600 }}
                  onChange={handleSelectedChange}
                  options={[
                    { value: 'My Project', label: '個人プロジェクト' },
                    { value: 'Shared project', label: '共有プロジェクト' }
                  ]}
                />
                <div className='flex'>
                  <Button type='primary' className='flex items-center' icon={<RedoOutlined />} onClick={handleRestore}>
                    修復
                  </Button>

                  <Button
                    type='primary'
                    className='flex items-center'
                    style={{ marginLeft: '10px' }}
                    icon={<DeleteOutlined />}
                    onClick={handleDeleteDeActiveVersionEvent}
                  >
                    削除
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.menuTable}>
            <table>
              <thead style={{ backgroundColor: '#dbdbdb' }}>
                <tr>
                  <th style={{ width: '77%', minWidth: '50px', height: '55px' }}>プロジェクト名</th>
                  <th style={{ width: '10%', minWidth: '160px' }}>ユーザー編集</th>
                  <th style={{ width: '10%', minWidth: '160px' }}>最後更新日</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  listProjects &&
                  listProjects.length > 0 &&
                  listProjects.map((pro) => (
                    <TableRowShared
                      disable={true}
                      key={pro.projectId}
                      isChecked={pro?.isChecked ? true : false}
                      project={pro}
                      currentPage={currentPage}
                      ListProject={listProjects}
                      setListProject={setListProject}
                      setModifiedProject={setModifiedProject}
                      setCurrentPage={setCurrentPage}
                    ></TableRowShared>
                  ))}
              </tbody>
            </table>
            {isLoading && <Skeleton title={false} paragraph={{ rows: 18, width: '100%' }} />}
          </div>
          <div className={styles.pagination}>
            <Pagination current={currentPage} onChange={onChange} pageSize={10} total={pageCount * 10} />
          </div>
        </div>
      </div>
    </>
  )
}
