import ViewerRenameLayer from "./components/ViewerRenameLayer";

const RenameLayersServices = () => {
  return (
    <>
      <ViewerRenameLayer></ViewerRenameLayer>
    </>
  );
};
export default RenameLayersServices;
