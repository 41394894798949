import { nanoid } from '@reduxjs/toolkit'
import { omit } from 'lodash'

const createNewTask = (data, columnWidth) => {
  let newTask = {
    start: 0,
    end: 30 * 70,
    duration: 30,
    groupsNumber: 1,
    name: '新しいタスク' + nanoid(),
    id: nanoid(),
    predecessors: [],
    type: 'task',
    groupId: '',
    colorId: 0,
    displayOrder: 0
  }
  const omitedData = omit(data, ['change', 'id'])
  newTask = { ...newTask, ...omitedData }
  newTask.end = newTask.start + (columnWidth / 30) * newTask.duration
  return newTask
}
const createNewProject = (data) => {
  let newGroup = {
    start: 0,
    end: 70,
    duration: 30,
    name: '新グループ' + nanoid(),
    id: nanoid(),
    type: 'project',
    hideChildren: false,
    displayOrder: 0
  }
  const omitedData = omit(data, ['change', 'id'])
  return { ...newGroup, ...omitedData }
}
const updateTask = (newTaskData, tasks, groupId, columnWidth) => {
  let newTask
  if (newTaskData.change === 'none') {
    const foundTask = tasks.find((t) => t.id === newTaskData.id)
    if (foundTask) {
      newTask = { ...foundTask }
    }
  }
  // update lại id
  if (newTaskData.change === 'new') {
    const newData = omit(newTaskData, ['stepworks'])
    newTask = createNewTask(newData, columnWidth)
    newTask.groupId = groupId
    if (newTaskData.stepworks) {
      let endLocation = 0
      let stepworks = newTaskData.stepworks.map((st) => {
        const newSt = createNewTask(st, columnWidth)
        newSt.groupId = groupId
        newSt.parentTaskId = newTask.id
        if (endLocation === 0) {
          endLocation = newSt.end
        } else {
          newSt.start = endLocation
          newSt.end = endLocation + (columnWidth / 30) * newSt.duration
        }
        return newSt
      })
      newTask.stepworks = stepworks
    }
  }

  if (newTaskData.change === 'update') {
    // tìm task theo id.
    // nều có task => update theo data mới : loại bỏ stepworks, change,
    const task = findTaskById(tasks, 'task', newTaskData.id)
    console.log('🚀 ~ file: updateTaskUtils.js:72 ~ updateTask ~ tasks:', tasks)
    console.log('🚀 ~ file: updateTaskUtils.js:72 ~ updateTask ~ newTaskData:', newTaskData)
    console.log('🚀 ~ file: updateTaskUtils.js:72 ~ updateTask ~ task:', task)
    const newTaskDataOmit = omit(newTaskData, ['stepworks', 'change'])
    newTask = { ...task, ...newTaskDataOmit }

    // update stepworks, kiềm tra xem newTaskData.stepworks !== null ?
    if (newTaskData.stepworks !== null) {
      // Nếu có stepworks
      let endLocation = 0
      const newSt = newTaskData.stepworks.map((st) => {
        // nếu k có id =>
        // tạo task mới với data mới loại bỏ id.
        if (st.id === null) {
          const newSt = createNewTask(st, columnWidth)
          newSt.groupId = groupId
          newSt.parentTaskId = newTask.id
          if (endLocation === 0) {
            endLocation = newSt.end
          } else {
            newSt.start = endLocation
            newSt.end = endLocation + (columnWidth / 30) * newSt.duration
          }
          return newSt
        } else {
          // nếu stepwork có id =>
          // tìm task => update theo theo data mới: loại bỏ id
          const stepWork = findTaskById(tasks, 'task', st.id)
          const newStepWorkDataOmit = omit(st, ['id'])
          return { ...stepWork, ...newStepWorkDataOmit }
        }
      })
      newTask.stepworks = newSt
    }
  }
  return newTask
}

const findTaskById = (tasks, type, id) => {
  let task
  if (type === 'project') {
    const foundGroup = tasks.find((t) => t.id === id && t.type === 'project')
    if (foundGroup) {
      task = foundGroup
    }
  } else if (type === 'task') {
    tasks.forEach((t) => {
      if (t.id === id) {
        task = t
      }
      if (t?.stepworks && t?.stepworks.length > 0) {
        const foundTask = t.stepworks.find((t) => t.id === id)
        if (foundTask) {
          task = foundTask
        }
      }
    })
  }
  return task
}

export const updateTasksFromExcel = (tasks, updateTaskData, columnWidth) => {
  let newTasks = []
  updateTaskData.forEach((group) => {
    if (group.change === 'none') {
      const foundTask = tasks.find((t) => t.id === group.id)
      if (foundTask) {
        newTasks.push({ ...foundTask })
        if (group?.tasks === null) {
          const groupTasks = tasks.filter((t) => t.groupId === foundTask.id)
          newTasks = [...newTasks, ...groupTasks]
        }
      }
    }
    if (group.change === 'new') {
      let newGroup = createNewProject(group)
      newGroup = omit(newGroup, ['tasks'])
      newTasks.push(newGroup)
      group?.tasks?.forEach((t) => {
        const task = updateTask(t, tasks, newGroup.id, columnWidth)
        if (task) {
          newTasks.push(task)
        }
      })
    }

    if (group.change === 'update') {
      const foundGroup = findTaskById(tasks, 'project', group.id)
      const omitedData = omit(group, ['tasks', 'change'])
      const newGroup = { ...foundGroup, ...omitedData }
      newTasks.push(newGroup)
      group?.tasks?.forEach((t) => {
        const task = updateTask(t, tasks, newGroup.id, columnWidth)
        if (task) {
          newTasks.push(task)
        }
      })
    }
  })
  console.log('🚀 ~ file: updateTaskUtils.js:93 ~ updateTasksFromExcel ~ newTasks:', newTasks)

  const updateDisplayOrdersTasks = updateTaskDisplayOrder(newTasks)
  return updateDisplayOrdersTasks
}

const updateTaskDisplayOrder = (tasks) => {
  let displayOrder = 1
  const newTasks = tasks.map((task) => {
    const newTask = { ...task, displayOrder }
    if (task?.stepworks) {
      const newStepWorks = task.stepworks.map((st) => {
        return { ...st, displayOrder }
      })
      newTask.stepworks = newStepWorks
    }
    displayOrder = displayOrder + 1
    return newTask
  })
  return newTasks
}
