import React from "react";

export default function TableComponent({ rowCount }) {
  return (
    <div className="outer-wrapper">
      <div className="table-wrapper">
        <table
          className="table--header"
          style={{ width: "100%", position: "sticky", top: "0px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "50%" }}>現在のブロック名</th>
              <th style={{ width: "50%" }}>新しいブロック名</th>
            </tr>
          </thead>
        </table>
        <table id="contents-table">
          <tbody>
            {new Array(rowCount).fill(null).map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ height: "31px", width: "50%" }}></td>
                  <td className="editAbleRow"></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
