import React, { ReactChild } from 'react'
import { ViewMode } from '../../types/public-types'
import styles from './calendar.module.css'
import { useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { nanoid } from 'nanoid'

export type CalendarProps = {
  locale: string
  viewMode: ViewMode
  rtl: boolean
  headerHeight: number
  columnWidth: number
  fontFamily: string
  fontSize: string
}

export const Calendar: React.FC<CalendarProps> = ({ headerHeight, columnWidth, fontFamily, fontSize }) => {
  const versionSetting = useSelector((state: RootState) => state.versionSetting.current)
  // const month = versionSetting.numberOfMonths
  const { numberOfMonths: month, startYear, startMonth, includeYear } = versionSetting
  const ticks: ReactChild[] = []
  const getCalendarValuesForMonth = () => {
    let tickX = 0
    const bottomValues: ReactChild[] = []
    for (let i = 0; i < month; i++) {
      const bottomValue = i + 1 + '月'
      ticks.push(<line key={i} x1={tickX} y1={0} x2={tickX} y2={headerHeight} className={styles.calendarTopTick} />)
      tickX = tickX + columnWidth
      bottomValues.push(
        <text
          key={bottomValue}
          y={headerHeight * 0.6}
          x={columnWidth * i + columnWidth * 0.5}
          className={styles.calendarBottomText}
        >
          {bottomValue}
        </text>
      )
    }
    return bottomValues
  }
  const calculateFontWidth = (word: string) => {
    return word.length < 4 ? 0 : word.length * 11
  }
  const getCalendarValuesWithIncludeYear = () => {
    let tickX = 0
    const bottomValues: ReactChild[] = []
    //tương ứng những tháng đầu tiên của năm đầu tiên
    let countMonth = 0
    for (let index = startMonth; index <= 12; index++) {
      const bottomValue = index + '月'
      ticks.push(<line key={index} x1={tickX} y1={0} x2={tickX} y2={headerHeight} className={styles.calendarTopTick} />)
      tickX = tickX + columnWidth
      if (index === startMonth) {
        bottomValues.push(
          <text
            key={bottomValue + nanoid()}
            y={headerHeight * 0.35}
            x={columnWidth * countMonth + columnWidth * 0.35 + calculateFontWidth(startYear + '年') / 5}
            className={styles.calendarBottomText}
            style={{ fontWeight: 'bold' }}
          >
            {startYear + '年'}
          </text>
        )
      }
      bottomValues.push(
        <text
          key={bottomValue + nanoid()}
          y={headerHeight * 0.85}
          x={columnWidth * countMonth + columnWidth * 0.5}
          className={styles.calendarBottomText}
        >
          {bottomValue}
        </text>
      )
      countMonth++
    }
    const countMonthFirsYear = 12 - startMonth + 1
    const countYears = Math.floor((month - countMonthFirsYear) / 12)

    const lastMonth = (month - countMonthFirsYear) % 12
    for (let index = 0; index <= countYears; index++) {
      if (index !== countYears) {
        Array(12)
          .fill(1)
          // eslint-disable-next-line no-loop-func
          .forEach((_, i) => {
            let bottomValue = i + 1 + '月'
            ticks.push(
              <line
                key={i + countMonthFirsYear + nanoid()}
                x1={tickX}
                y1={0}
                x2={tickX}
                y2={headerHeight}
                className={styles.calendarTopTick}
              />
            )
            tickX = tickX + columnWidth
            if (i === 0) {
              bottomValues.push(
                <text
                  key={bottomValue + startYear + nanoid() + index}
                  y={headerHeight * 0.35}
                  x={
                    columnWidth * countMonth + columnWidth * 0.35 + calculateFontWidth(startYear + index + 1 + '年') / 5
                  }
                  className={styles.calendarBottomText}
                  style={{ fontWeight: 'bold' }}
                >
                  {startYear + index + 1 + '年'}
                </text>
              )
            }
            bottomValues.push(
              <text
                key={bottomValue + nanoid()}
                y={headerHeight * 0.85}
                x={columnWidth * countMonth + columnWidth * 0.5}
                className={styles.calendarBottomText}
              >
                {bottomValue}
              </text>
            )
            countMonth++
          })
      } else {
        Array(lastMonth)
          .fill(1)
          // eslint-disable-next-line no-loop-func
          .forEach((_, i) => {
            let bottomValue = i + 1 + '月'
            ticks.push(
              <line
                key={i + countMonthFirsYear + nanoid()}
                x1={tickX}
                y1={0}
                x2={tickX}
                y2={headerHeight}
                className={styles.calendarTopTick}
              />
            )
            tickX = tickX + columnWidth
            if (i === 0) {
              bottomValues.push(
                <text
                  key={bottomValue + startYear + index + nanoid()}
                  y={headerHeight * 0.35}
                  x={
                    columnWidth * countMonth + columnWidth * 0.35 + calculateFontWidth(startYear + index + 1 + '年') / 5
                  }
                  className={styles.calendarBottomText}
                  style={{ fontWeight: 'bold' }}
                >
                  {startYear + index + 1 + '年'}
                </text>
              )
            }
            bottomValues.push(
              <text
                key={bottomValue + nanoid()}
                y={headerHeight * 0.85}
                x={columnWidth * countMonth + columnWidth * 0.5}
                className={styles.calendarBottomText}
              >
                {bottomValue}
              </text>
            )
            countMonth++
          })
      }
    }
    return bottomValues
  }

  let bottomValues: ReactChild[] = []
  bottomValues = includeYear ? getCalendarValuesWithIncludeYear() : getCalendarValuesForMonth()

  return (
    <g className='calendar' fontSize={fontSize} fontFamily={fontFamily}>
      <rect x={0} y={0} width={columnWidth * month || 10} height={headerHeight + 1} className={styles.calendarHeader} />
      {ticks}
      {bottomValues}
    </g>
  )
}
