import React, { useEffect, useState } from 'react'
import styles from './TextInput.module.scss'
import ProjectApi from 'B_API/scheduleApis/ProjectApi'
import { ToastError } from 'F_UTILS/ToastUtils'

export default function TextInput({ value, onChange, listData = [], style = {}, disable, isShared = false }) {
  const [currentValue, setCurrentValue] = useState(value)
  const inputRef = React.useRef(null)

  useEffect(() => {
    setCurrentValue(value)

    return () => {
      setCurrentValue('')
    }
  }, [value])

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur()
    }
  }
  return (
    <input
      disabled={disable}
      type='text'
      ref={inputRef}
      value={currentValue}
      className={styles.input}
      onChange={(e) => {
        setCurrentValue(e.target.value)
      }}
      style={{ ...style }}
      onKeyDown={handlePressEnter}
      onBlur={async (e) => {
        if (e.target.value) {
          if (e.target.value !== value) {
            try {
              if (listData.length === 0) {
                if (isShared) {
                  const resProjectName = await ProjectApi.GetAllSharedProjectName()
                  listData = resProjectName.data.map((pr) => pr.projectName)
                } else {
                  const resProjectName = await ProjectApi.GetAllProjectName()
                  listData = resProjectName.data.map((pr) => pr.projectName)
                }
              }
              if (listData.includes(e.target.value)) {
                setCurrentValue(value)
                if (listData.length !== 0) {
                  ToastError('バージョン名は既に存在します。もう一度やり直してください。')
                } else {
                  ToastError('プロジェクト名は既に存在します。もう一度やり直してください。')
                }
                return
              }
              onChange(e.target.value)
            } catch (error) {}
          }
        } else {
          setCurrentValue(value)
        }
      }}
    />
  )
}
