import React from "react";
import {
  ExportTableToCSV,
  RemoveDeleteButton,
  WriteDataToTableLayerWhenLoadFile,
} from "../../../../F_UTILS/CSVCreateLayerUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ForgeAPI from "../../../../B_API/ForgeAPI";
import {
  faDownload,
  faFileExport,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import CreateLayerApi from "../../../../B_API/CreateLayerApi";
import classNames from "classnames";
import { useSelector } from "react-redux";

export default function FooterComponent({
  setProgressIsRunning,
  setMessageProgress,
  setShowDialog,
  setMessageCompleted,
  setListNames,
  ListNames,
  setViewInfor,
  originName,
  btnDisable,
}) {
  const jobId = useSelector((state) => state.jobId.current);

  function handleInputCsvClick() {
    const realFileInput = document.getElementById("input-file");
    realFileInput.value = "";
  }

  function handleInputCSVChange() {
    const realFileInput = document.getElementById("input-file");
    if (realFileInput.value) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        let dataCSV = fileReader.result.split("\n").map((e) => {
          return e.split(",");
        });
        let listLayers = [];
        for (let index = 0; index < dataCSV.length; index++) {
          const arr = dataCSV[index];
          if (arr.length === 4) {
            const colorIndex =
            Number(arr[1]?.toString().replace("\r", "").replace('"', "")) +
              "," +
              Number(arr[2]?.toString().replace("\r", "").replace('"', "")) +
              "," +
              Number(arr[3]?.toString().replace("\r", "").replace('"', ""));
            if (arr[0] !== "") {
              if (colorIndex !== "") {
                listLayers.push({
                  LayerName: arr[0],
                  IndexColor: colorIndex,
                });
              } else {
                listLayers.push({
                  LayerName: arr[0],
                  IndexColor: "7",
                });
              }
            }
          } else {
            const colorIndex = arr[1]?.toString().replace("\r", "") || "";
            if (arr[0] !== "") {
              if (colorIndex !== "") {
                listLayers.push({
                  LayerName: arr[0],
                  IndexColor: colorIndex,
                });
              } else {
                listLayers.push({
                  LayerName: arr[0],
                  IndexColor: "7",
                });
              }
            }
          }
        }

        function isNumeric(str) {
          if (typeof str != "string") return false;
          return !isNaN(str) && !isNaN(parseFloat(str));
        }

        for (let index = 0; index < listLayers.length; index++) {
          const element = listLayers[index];

          if (element?.IndexColor) {
            if (element.IndexColor.includes(".")) {
              toast.error(
                "CSVのデータが間違っています。もう一度チェックしてください。",
                {
                  position: "bottom-center",
                  autoClose: 4000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
              return;
            }
            if (element.IndexColor.includes(",")) {
              const arrColors = element.IndexColor.split(",");
              if (arrColors.length !== 3) {
                toast.error(
                  "CSVのデータが間違っています。もう一度チェックしてください。",
                  {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
                return;
              }
              if (
                (element?.IndexColor !== undefined &&
                  !isNumeric(arrColors[0].replace("\r", ""))) ||
                  Number(arrColors[0].replace("\r", "")) < 0 ||
                  Number(arrColors[0].replace("\r", "")) > 255 ||
                !isNumeric(arrColors[1].replace("\r", "")) ||
                Number(arrColors[1].replace("\r", "")) < 0 ||
                Number(arrColors[1].replace("\r", "")) > 255 ||
                !isNumeric(arrColors[2].replace("\r", "")) ||
                Number(arrColors[2].replace("\r", "")) < 0 ||
                Number(arrColors[2].replace("\r", "")) > 255
              ) {
                toast.error(
                  "CSVのデータが間違っています。もう一度チェックしてください。",
                  {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
                return;
              }
            } else {
              if (
                (element?.IndexColor !== undefined &&
                  !isNumeric(
                    element?.IndexColor.toString().replace("\r", "")
                  )) ||
                  Number(element?.IndexColor.toString().replace("\r", "")) <
                  0 ||
                  Number(element?.IndexColor.toString().replace("\r", "")) > 255
              ) {
                toast.error(
                  "CSVのデータが間違っています。もう一度チェックしてください。",
                  {
                    position: "bottom-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  }
                );
                return;
              }
            }
          }
        }

        let newListName = ListNames.concat(listLayers);
        WriteDataToTableLayerWhenLoadFile(newListName);
        setListNames([...ListNames]);
        toast.success("CSV読み込み完了。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        var rows = document.querySelectorAll("#contents-table tr");
        rows[rows.length - 1].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      };
      fileReader.readAsText(realFileInput.files[0]);
    }
  }

  function handleImportReadCsvClick() {
    const realFileInput = document.getElementById("input-file");
    realFileInput.click();
  }

  function handleExportCSV() {
    const labelFileName = document.querySelector(".file--name label");
    console.log(labelFileName.innerHTML);
    if (labelFileName.innerHTML !== "ファイル名: ") {
      ExportTableToCSV(
        labelFileName.innerHTML
          .replace("ファイル名: ", "")
          .replace(".dwg", "") + ".csv",
        ListNames
      );
    } else {
      ExportTableToCSV("renameBlocks.csv", ListNames);
    }
  }

  async function handleInputDownloadFolder(e) {
    var files = e.target.files;
    var path = files[0].webkitRelativePath;
    const dirHandle = await window.showDirectoryPicker();
    console.log(files[0], dirHandle);
    var Folder = path.split("/");
    console.log(Folder[0]);
  }

  const handleDownload = async () => {
    try {
      console.log("click");
      setProgressIsRunning(true);
      setMessageProgress("ダウンロードの準備中。しばらくお待ちください。");
      setShowDialog(true);

      const res = await CreateLayerApi.DownloadFile(jobId);
      console.log(res.data);
      if (res.status === 200) {
        var date = new Date();
        var newName = `${date.getFullYear()}${
          date.getMonth() + 1
        }${date.getDate()}${date.getHours()}${date.getMinutes()}_${originName}`;
        fileDownload(res.data, newName);

        setProgressIsRunning(false);
        setMessageCompleted("ダウンロードが完了しました。");
      } else {
        toast.error("ダウンロード出来ませんでした。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setProgressIsRunning(false);
        setMessageCompleted("ダウンロード出来ませんでした。");
      }
    } catch (error) {
      console.log(error);
      toast.error("ダウンロード出来ませんでした。", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setProgressIsRunning(false);
      setMessageCompleted("ダウンロード出来ませんでした。");
    }
  };

  const handleCreate = async () => {
    const preWord = document.getElementById("pre").value;
    const subWord = document.getElementById("sub").value;
    var listObjLayers = [];
    var listLayerNames = [];
    var listNewLayer = [];
    var listDuplicate = [];

    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[0].classList.remove("error-cell");
      cols[0].classList.remove("warning-cell");
      const layerName = cols[0].innerText;

      if (cols[0].getAttribute("contenteditable") === "true") {
        if (layerName && layerName !== "") {
          if (preWord !== "" || subWord !== "") {
            const colorIndex = cols[2].innerText;
            if (colorIndex !== "") {
              listNewLayer.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: colorIndex,
                isExitLayer: "yes",
              });
              listObjLayers.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: colorIndex,
                isExitLayer: "yes",
              });
            } else {
              listNewLayer.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: "7",
                isExitLayer: "yes",
              });
              listObjLayers.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: "7",
                isExitLayer: "yes",
              });
            }
            listLayerNames.push(preWord + layerName + subWord);
          } else {
            const colorIndex = cols[2].innerText;
            if (colorIndex !== "") {
              listNewLayer.push({
                LayerName: layerName,
                IndexColor: colorIndex,
                isExitLayer: "yes",
              });
              listObjLayers.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: colorIndex,
                isExitLayer: "yes",
              });
            } else {
              listNewLayer.push({
                LayerName: layerName,
                IndexColor: "7",
                isExitLayer: "yes",
              });
              listObjLayers.push({
                LayerName: preWord + layerName + subWord,
                IndexColor: "7",
                isExitLayer: "yes",
              });
            }
            listLayerNames.push(layerName);
          }
        }
      } else {
        if (layerName) {
          const colorIndex = cols[2].innerText;
          listObjLayers.push({
            LayerName: layerName,
            IndexColor: colorIndex,
            isExitLayer: "yes",
          });
          listLayerNames.push(layerName);
        }
      }
    }

    if (listLayerNames.length !== 0) {
      listDuplicate = FindTheSameBlockNameInArray(listLayerNames);
    }

    if (listDuplicate.length > 0) {
      console.log("error");
    } else {
      const labelFileName = document.querySelector(".file--name label");
      if (labelFileName.innerHTML === "ファイル名: ") {
        toast.error(
          "AutoCADファイルを見つかりません。ファイルを選択してください。",
          {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      var formatData = [];
      if (listNewLayer.length === 0) {
        toast.error(
          "データが変換されていません。もう一度チェックしてください。",
          {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      listNewLayer.forEach((e) => {
        formatData.push({
          LayerName: e.LayerName,
          IndexColor: e.IndexColor,
        });
      });

      const inputParams = { InputParams: formatData };
      console.log(inputParams);
      // tạo task...
      setProgressIsRunning(true);
      setMessageProgress("画層を作成しています。しばらくお待ちください。");
      setShowDialog(true);

      const responseRunReNameLayer = await runCreateLayers(jobId, inputParams);
      if (responseRunReNameLayer?.status !== 200) {
        setProgressIsRunning(false);
        setMessageCompleted("画層を作成出来ませんでした。");
        return;
      }
      // check status by id
      const workId = responseRunReNameLayer.data.id;

      const checkIntervalID = setInterval(async () => {
        try {
          const responseWorkStatus = await ForgeAPI.GetWorkStatus(workId);
          if (responseWorkStatus.status !== 200) {
            clearInterval(checkIntervalID);
            setProgressIsRunning(false);
            setMessageCompleted("画層を作成出来ませんでした。");
          }

          const listSuccessStatus = [1, 2, 9];
          if (!listSuccessStatus.includes(responseWorkStatus.data.status)) {
            clearInterval(checkIntervalID);
            setProgressIsRunning(false);
            setMessageCompleted("画層を作成出来ませんでした。");
          } else {
            if (responseWorkStatus.data.status === 9) {
              console.log("responseWorkStatus", responseWorkStatus);
              clearInterval(checkIntervalID);
              await UpdateViewWhenWorkDone();
              // ToDo Work success
            }
          }
        } catch (error) {
          console.log(error);
          clearInterval(checkIntervalID);
          setProgressIsRunning(false);
          setMessageCompleted("画層を作成出来ませんでした。");
        }
      }, 3000);

      async function UpdateViewWhenWorkDone() {
        //call api mới get new urn
        let newUrn = "";
        const response = await getResultUrn(jobId);
        console.log(response);
        if (response?.status === 200) {
          newUrn = response.data.encodedSourceUrn;
        } else {
          console.log(newUrn);
          setProgressIsRunning(false);
          setMessageCompleted("画層を作成出来ませんでした。");
        }
        // interval get manifest
        const manifestID = setInterval(async () => {
          console.log("manifestID");
          if (newUrn !== "") {
            const status = await getManifest(newUrn);

            if (status === "success") {
              console.log("update list name");
              setProgressIsRunning(false);
              setMessageCompleted("画層の作成が完了しました。");
              const urn = "urn:" + newUrn;
              const accessToken = await getAccessToken();
              setViewInfor({
                urn: urn,
                accessToken: accessToken,
              });
              console.log("listObjLayers", listObjLayers);
              WriteDataToTableLayerWhenLoadFile(listObjLayers);
              setListNames(listObjLayers);
              RemoveDeleteButton();
              clearInterval(manifestID);
            }
          }
        }, 3000);

        // Clear Interval when failed
        const idTimeOut = setTimeout(() => {
          clearInterval(checkIntervalID);
          clearInterval(manifestID);
          clearTimeout(idTimeOut);
          setProgressIsRunning(false);
          setMessageCompleted("画層を作成出来ませんでした。");
        }, 60000 * 3);
      }
    }
    document.getElementById("pre").value = "";
    document.getElementById("sub").value = "";
  };

  const FindTheSameBlockNameInArray = (listBlockNames) => {
    const toFindDuplicates = (listBlockNames) => {
      let lowerList = [...listBlockNames];
      lowerList = lowerList.map((x) => x.toLowerCase());
      return listBlockNames.filter(
        (item, index) => lowerList.indexOf(item.toLowerCase()) !== index
      );
    };
    function getAllIndexes(arr, val) {
      let arrLowerCase = [...arr].map((x) => x.toLowerCase());
      var indexes = [],
        i = -1;
      while ((i = arrLowerCase.indexOf(val.toLowerCase(), i + 1)) !== -1) {
        indexes.push(i);
      }
      return indexes;
    }
    const duplicateElement = toFindDuplicates(listBlockNames);

    function validateName(listNames) {
      let listNameError = [];
      var format = /[*="|<>?,./\\;]/;
      for (let index = 0; index < listNames.length; index++) {
        const element = listNames[index];
        if (format.test(element)) {
          listNameError.push(element);
        }
      }
      return listNameError;
    }

    const listNameError = validateName(listBlockNames);

    if (listNameError?.length > 0) {
      toast.error(
        "ブロック名が間違っています。もう一度チェックしてください。",
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      var rows = document.querySelectorAll("#contents-table tr");
      for (let index = 0; index < listNameError.length; index++) {
        const value = listNameError[index];
        const listIndex = getAllIndexes(listBlockNames, value);
        for (let jIndex = 0; jIndex < listIndex.length; jIndex++) {
          const j = listIndex[jIndex];
          var cell = rows[j].querySelectorAll("td, th")[0];
          cell.className += " warning-cell";
        }
      }
    }

    if (duplicateElement?.length > 0) {
      toast.error(
        "指定された新しい画層名が他と重複しています。もう一度チェックしてください。",
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
    var rows = document.querySelectorAll("#contents-table tr");
    for (let index = 0; index < duplicateElement.length; index++) {
      const value = duplicateElement[index];
      const listIndex = getAllIndexes(listBlockNames, value);
      for (let jIndex = 0; jIndex < listIndex.length; jIndex++) {
        const j = listIndex[jIndex];
        var cell = rows[j].querySelectorAll("td, th")[0];
        cell.className += " error-cell";
      }
    }

    return duplicateElement.concat(listNameError);
  };
  // function API

  async function runCreateLayers(jobId, data) {
    try {
      const response = await CreateLayerApi.RunCreateLayers(jobId, data);
      return response;
    } catch (error) {
      console.log("Run Rename Layers Name", error);
      return null;
    }
  }
  async function getResultUrn(jobId) {
    try {
      const response = await CreateLayerApi.GetResultUrn(jobId);
      return response;
    } catch (error) {
      console.log("get Result Urn", error);
      return null;
    }
  }
  async function getManifest(urn) {
    try {
      const response = await ForgeAPI.GetManifest(urn);
      return response.data.status;
    } catch (error) {
      console.log("Get Manifest ", error);
      return null;
    }
  }
  async function getAccessToken() {
    try {
      const response = await ForgeAPI.GetViewerToken();
      return response.data.accessToken;
    } catch (error) {
      console.log("Get Viewer Token ", error);
      return null;
    }
  }

  return (
    <div className="other--btn">
      <div className="import-csv">
        <input
          type="file"
          id="input-file"
          accept=".csv"
          onClick={handleInputCsvClick}
          onChange={handleInputCSVChange}
        />
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{ width: "135px" }}
          onClick={handleExportCSV}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faFileExport} />
          CSV書き出し
        </button>
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          id="custom-button"
          style={{ width: "135px" }}
          onClick={handleImportReadCsvClick}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faFileImport} />
          CSV読み込み
        </button>
      </div>

      <div className="btn-api">
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{ width: "135px", minWidth: "135px" }}
          onClick={handleCreate}
          disabled={btnDisable}
        >
          作成
        </button>
        <input
          type="file"
          id="download-file"
          webkitdirectory="true"
          multiple
          onChange={handleInputDownloadFolder}
        />
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{ width: "135px", minWidth: "135px" }}
          onClick={handleDownload}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faDownload} />
          ダウンロード
        </button>
      </div>
    </div>
  );
}
