import React, { useRef } from 'react'
import styles from './ImportUpdateDataModal.module.scss'
import ExcelApi from 'B_API/scheduleApis/ExcelApi'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'
import { updateTasksFromExcel } from 'F_UTILS/SchedulingUtils/updateTaskUtils'

export default function ImportUpdateDataModal({ excelInfo, setExcelInfo, tasks, setTasks }) {
  const [sheetNames, setSheetName] = React.useState([])
  const currentVersion = useSelector((state) => state.currentVersion)
  const currentSetting = useSelector((state) => state.versionSetting.current)
  const btnRef = useRef(null)

  const getDisplayOrder = () => {
    if (tasks.length === 0) {
      return 0
    }
    const listDisplayOrders = tasks.map((task) => {
      if (task?.stepworks) {
        const listStepWorkId = task.stepworks.map((st) => {
          return st?.displayOrder
        })
        return listStepWorkId
      } else {
        return task.displayOrder
      }
    })
    return Math.max(...listDisplayOrders.flat(1))
  }

  const handleOkClick = async () => {
    btnRef.current.classList.add('button_disabled')
    var data = new FormData()
    let maxDisplayOrder = getDisplayOrder()
    if (maxDisplayOrder === Infinity || isNaN(maxDisplayOrder)) {
      maxDisplayOrder = 1
    }
    data.append('File', excelInfo.file)
    data.append('SheetName', sheetNames)
    console.log(data)
    try {
      const res = await ExcelApi.UpdateImportExcel(currentVersion.versionId, data)
      if (res.status === 200) {
        const data = res.data
        console.log('🚀 ~ file: index.js:46 ~ handleOkClick ~ data:', data)
        if (data && data?.length !== 0) {
          const newTasks = updateTasksFromExcel(tasks, data, currentSetting.columnWidth)
          setTasks(newTasks)
          ToastSuccess('エクセルファイルの挿入に成功しました。')
        } else {
          ToastError('エクセルファイルの挿入に失敗しました。')
        }
      } else {
        ToastError('エクセルファイルの挿入に失敗しました。')
      }
    } catch (error) {
      console.log('🚀 ~ file: index.js:59 ~ handleOkClick ~ error:', error)
      ToastError('エクセルファイルの挿入に失敗しました。')
    }
    setExcelInfo((prev) => {
      return { ...prev, isOpen: false }
    })
    setTimeout(() => {
      btnRef?.current?.classList?.remove('button_disabled')
    }, 3000)
  }

  const handleCancelClick = () => {
    setExcelInfo((prev) => {
      return { ...prev, isOpen: false }
    })
  }
  const handleCheckBoxChange = (event, sheetName) => {
    if (event.target.checked) {
      setSheetName((prev) => {
        const newState = [...prev]
        newState.push(sheetName)
        return newState
      })
    } else {
      setSheetName((prev) => {
        const newState = prev.filter((x) => x !== sheetName)
        return newState
      })
    }
  }
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <h3 className={styles.header}>データを挿入</h3>
        <h4 className={styles.title}>シートを選択：</h4>
        <div className={styles.tableContainer}>
          {excelInfo?.sheetNames &&
            excelInfo.sheetNames.sort().map((sheet) => {
              return (
                <div key={sheet}>
                  <input
                    type='checkbox'
                    name=''
                    id={sheet}
                    className={styles.checkbox_input}
                    onChange={(e) => handleCheckBoxChange(e, sheet)}
                  ></input>
                  <label htmlFor={sheet} className={styles.checkbox_label}>
                    {sheet}
                  </label>
                </div>
              )
            })}
        </div>
        <div className={styles.button_container}>
          {/* <button className='btn-primary' onClick={handleOkClick} ref={btnRef}> */}
          <button className={`btn-primary`} onClick={handleOkClick} ref={btnRef}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
