import React from 'react'
import styles from './ViewSettingModal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'
import TextInput from 'A_SHARED_COMPONENT/TextInput'
import { number } from 'yup'
import { toast } from 'react-toastify'
import ViewsApi from 'B_API/scheduleApis/ViewsApi'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import TaskToolTip from '../TaskToolTip'
import { ReactComponent as IconUnGroup } from '../../../../../../../X_ASSETS/icons/icon_ungroup.svg'

export default function ViewSettingModal({ view, tasks, setShowViewList, setShowCreateView }) {
  console.log('🚀 ~ file: index.js:22 ~ ViewSettingModal ~ view:', view)
  const [taskList, setTaskList] = React.useState([...tasks])
  const [taskDisplay, setTaskDisplay] = React.useState([])
  const [viewName, setViewName] = React.useState('')
  const checkBoxListRef = React.useRef([])
  const checkBoxDisplayRef = React.useRef([])
  const taskListTempRef = React.useRef([])
  const versionId = useSelector((state) => state.currentVersion.versionId)
  const navigate = useNavigate()
  let taskDisplayTemp = []

  React.useEffect(() => {
    if (checkBoxListRef.current.length !== 0) {
      checkBoxListRef.current.forEach((x) => {
        if (x) {
          x.checked = false
        }
      })
    }
    if (checkBoxDisplayRef.current.length !== 0) {
      checkBoxDisplayRef.current.forEach((x) => {
        const index = taskListTempRef.current.findIndex((t) => t?.id === x?.id)
        if (x && index === -1) {
          x.checked = false
        }
      })
    }
  }, [taskList, taskDisplay])

  React.useEffect(() => {
    if (view?.viewTasks) {
      let viewTaskIds = view.viewTasks.map((x) => x?.id)
      const copyTasks = [...tasks]

      let listTaskDisplay = viewTaskIds.map((viewId) => {
        const task = copyTasks.find((x) => x?.id === viewId)
        if (task) {
          return task
        }
      })

      listTaskDisplay = listTaskDisplay.filter(function (element) {
        return element !== undefined
      })

      listTaskDisplay = listTaskDisplay.map((t) => {
        const groupI = view.viewTasks.find((x) => x?.id === t?.id)?.group
        return { ...t, group: groupI }
      })

      const listTaskList = copyTasks.filter((t) => {
        if (!viewTaskIds.includes(t?.id)) {
          return t
        }
      })

      setTaskList(listTaskList)
      setTaskDisplay(listTaskDisplay)
      setViewName(view.viewName)
    }
  }, [view, tasks])
  var handleOkClick = async () => {
    if (!viewName) {
      toast.error('入力されたビュー名が間違っています。もう一度確認てください。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      return
    }
    //update view
    if (view) {
      let copyTaskDisplay = [...taskDisplay]
      console.log('🚀 ~ file: index.js:95 ~ handleOkClick ~ taskDisplay:', taskDisplay)
      let displayOrder = 0
      copyTaskDisplay = copyTaskDisplay.map((t) => {
        displayOrder++
        if (!t?.group) {
          return { ...t, group: 0, displayOrder: displayOrder }
        } else {
          return { ...t, displayOrder: displayOrder }
        }
      })

      const data = {
        viewName: viewName,
        tasks: copyTaskDisplay
      }
      if (copyTaskDisplay.length === 0) {
        toast.error('タスクが選択されていません。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
        return
      }
      try {
        const res = await ViewsApi.UpdateCustomView(versionId, view.viewId, data)
        if (res.status == 200 && res.data?.message?.includes('existed')) {
          toast.error('ビュー名は既に存在します。もう一度やり直してください。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })

          return;
        }

        if (res.status === 204) {
          toast.success('ビューの作成に成功しました。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
          if (view?.viewId) {
            navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${view?.viewId}`)
            setShowCreateView(false)
          }
        } else {
          toast.error('ビューの作成に失敗しました。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
        }
      } catch (error) {
        toast.error('ビューの作成に失敗しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    } else {
      // create view
      let copyTaskDisplay = [...taskDisplay]
      let displayOrder = 0
      copyTaskDisplay = copyTaskDisplay.map((t) => {
        displayOrder++
        if (!t?.group) {
          return { ...t, group: 0, displayOrder: displayOrder }
        } else {
          return { ...t, displayOrder: displayOrder }
        }
      })
      const data = {
        viewName: viewName,
        tasks: copyTaskDisplay
      }
      if (copyTaskDisplay.length === 0) {
        toast.error('タスクが選択されていません。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
        return
      }
      try {
        const res = await ViewsApi.CreateCustomView(versionId, data)
        if (res.status === 200) {
          if( res.data?.message?.includes("existed")){
            toast.error('ビュー名は既に存在します。もう一度やり直してください。', {
              position: 'bottom-center',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored'
            })

            return;
          }

          toast.success('ビューの作成に成功しました。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })

          const newView = res.data
          if (newView?.viewId) {
            navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${newView?.viewId}`)
            setShowCreateView(false)
          }
        } else {
          toast.error('ビューの作成に失敗しました。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
        }
      } catch (error) {
        toast.error('ビューの作成に失敗しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    }
  }

  var handleCancelClick = () => {
    setShowViewList(true)
  }

  const handleInputListChange = (event, task) => {
    if (event.target.checked) {
      taskDisplayTemp.push(task)
    } else {
      taskDisplayTemp = taskDisplayTemp.filter((x) => x?.id !== task?.id)
    }
  }

  const handleInputDisplayChange = (event, task) => {
    if (event.target.checked) {
      const index = taskListTempRef.current.findIndex((x) => x?.id === task?.id)
      if (index === -1) {
        taskListTempRef.current.push(task)
      }
    } else {
      taskListTempRef.current = taskListTempRef.current.filter((x) => {
        return x?.id !== task?.id
      })
    }
  }

  const handleAddToDisplay = () => {
    // tasks id sẽ được thêm mới
    const taskDisplayTempIds = taskDisplayTemp.map((x) => x?.id)
    // tasks id có sẵn
    let taskDisplayIds = taskDisplay.map((x) => x?.id)
    taskDisplayIds = taskDisplayIds.concat(taskDisplayTempIds)

    const newTaskList = tasks.filter((x) => !taskDisplayIds.includes(x?.id) && x.type === 'task')

    let newTaskDisplay = taskDisplayIds.map((id) => {
      const task = tasks.find((x) => x?.id === id)
      if (task) {
        return task
      }
    })
    // let newTaskDisplay = tasks.filter((x) => taskDisplayIds.includes(x?.id) && x.type === 'task')

    newTaskDisplay = newTaskDisplay.map((t) => {
      if (taskDisplayIds.includes(t?.id)) {
        if (taskDisplay.length === 0) {
          return t
        }
        const currentTask = taskDisplay?.find((x) => x?.id === t?.id)
        if (currentTask && currentTask?.group) {
          return { ...t, group: currentTask?.group }
        } else {
          return t
        }
      } else {
        return t
      }
    })
    setTaskList(newTaskList)
    setTaskDisplay(newTaskDisplay)
    taskListTempRef.current = []
  }

  const handleAddToList = () => {
    const taskListInterIds = taskListTempRef.current.map((x) => x?.id)
    let taskListIds = taskList.map((x) => x?.id)
    taskListIds = taskListIds.concat(taskListInterIds)
    const newTaskList = tasks.filter((x) => taskListIds.includes(x?.id) && x.type === 'task')
    let newTaskDisplay = tasks.filter((x) => !taskListIds.includes(x?.id) && x.type === 'task')

    let taskDisplayIds = taskDisplay.map((x) => x?.id)
    newTaskDisplay = newTaskDisplay.map((t) => {
      if (taskDisplayIds.includes(t?.id)) {
        if (taskDisplay.length === 0) {
          return t
        }
        const currentTask = taskDisplay?.find((x) => x?.id === t?.id)
        if (currentTask && currentTask?.group) {
          return { ...t, group: currentTask?.group }
        } else {
          return t
        }
      } else {
        return t
      }
    })
    setTaskList(newTaskList)
    setTaskDisplay(newTaskDisplay)
    taskListTempRef.current = []
  }

  const handleTextInputChangeGroup = (value, task) => {
    if (!isNaN(number(value))) {
      console.log('không phải là số,')
      return
    }
    setTaskDisplay((prev) => {
      const newTaskDisplay = prev.map((t) => {
        if (t?.id === task?.id) {
          return { ...t, group: value }
        } else {
          return t
        }
      })
      return newTaskDisplay
    })
  }

  const handleChangeGroup = () => {
    const listGroups = taskDisplay.filter((x) => x?.group).map((x) => x.group)
    if (listGroups.length === 0) {
      const maxGroup = 0
      const taskDisplayInterIds = taskListTempRef.current.map((x) => x?.id)
      setTaskDisplay((prev) => {
        const newTaskDisplay = prev.map((t) => {
          if (taskDisplayInterIds.includes(t?.id)) {
            return { ...t, group: maxGroup + 1 }
          } else {
            return t
          }
        })
        return newTaskDisplay
      })
    } else {
      const maxGroup = Math.max(...listGroups)
      const taskDisplayInterIds = taskListTempRef.current.map((x) => x?.id)
      setTaskDisplay((prev) => {
        const newTaskDisplay = prev.map((t) => {
          if (taskDisplayInterIds.includes(t?.id)) {
            return { ...t, group: maxGroup + 1 }
          } else {
            return t
          }
        })
        return newTaskDisplay
      })
    }
    taskListTempRef.current = []
  }

  const handleUnGroup = () => {
    const taskDisplayInterIds = taskListTempRef.current.map((x) => x?.id)
    setTaskDisplay((prev) => {
      const newTaskDisplay = prev.map((t) => {
        if (taskDisplayInterIds.includes(t?.id)) {
          return { ...t, group: 0 }
        } else {
          return t
        }
      })
      return newTaskDisplay
    })
    taskListTempRef.current = []
  }

  const arrayMove = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length
    }
    while (new_index < 0) {
      new_index += arr.length
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1
      while (k--) {
        arr.push(undefined)
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    return arr
  }

  const handleMoveTaskUp = () => {
    if (taskListTempRef.current.length === 1) {
      const currentTask = taskListTempRef.current[0]
      const currentIndex = taskDisplay.findIndex((x) => x?.id === currentTask?.id)
      if (currentIndex === 0) {
        return
      }
      let newTaskDisplay = [...taskDisplay]
      newTaskDisplay = arrayMove(newTaskDisplay, currentIndex, currentIndex - 1)
      setTaskDisplay(newTaskDisplay)
    }
  }

  const handleMoveTaskDown = () => {
    if (taskListTempRef.current.length === 1) {
      const currentTask = taskListTempRef.current[0]
      const currentIndex = taskDisplay.findIndex((x) => x?.id === currentTask?.id)
      if (currentIndex === taskDisplay.length - 1) {
        return
      }
      let newTaskDisplay = [...taskDisplay]
      newTaskDisplay = arrayMove(newTaskDisplay, currentIndex, currentIndex + 1)
      setTaskDisplay(newTaskDisplay)
    }
  }

  const getGroupNameByTaskId = (task) => {
    const groups = tasks.filter((t) => t.type === 'project')
    const group = groups.filter((t) => t?.id === task.groupId)
    if (group) {
      return group[0].name
    }
    return ''
  }
  console.log(taskList)
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className={styles.heading}>ビュー設定</div>
        <div className={styles.title}>ビュー名</div>
        <TextInput
          style={{
            width: '505px',
            border: '1px solid gray',
            borderRadius: '6px',
            padding: '0px 0px 0px 10px',
            marginLeft: '20px',
            height: '30px'
          }}
          value={viewName}
          onChange={setViewName}
        ></TextInput>
        <div className={styles.tasks_container}>
          <div className={styles.tasks_items}>
            <span className={styles.tasks_title}>タスクリスト</span>
            <div className={styles.tasks_item}>
              <div className={styles.tasks_header}>タスク名</div>
              {taskList.map((task, i) => {
                if (task?.type === 'task') {
                  return (
                    <div key={task?.id}>
                      <>
                        <input
                          ref={(el) => (checkBoxListRef.current[i] = el)}
                          type='checkbox'
                          name=''
                          id={task?.id}
                          className={styles.checkbox_input}
                          onChange={(e) => handleInputListChange(e, task)}
                        ></input>
                        <TaskToolTip task={task} groupName={getGroupNameByTaskId(task)}>
                          <label htmlFor={task?.id} className={styles.checkbox_label}>
                            {task.name} ( {getGroupNameByTaskId(task)} - {task.duration}日 )
                          </label>
                        </TaskToolTip>
                      </>
                    </div>
                  )
                }
              })}
            </div>
          </div>

          <div className={styles.tasks_move_icons}>
            <FontAwesomeIcon icon={faChevronRight} className={styles.tasks_icon} onClick={handleAddToDisplay} />
            <FontAwesomeIcon icon={faChevronLeft} className={styles.tasks_icon} onClick={handleAddToList} />
            <FontAwesomeIcon
              icon={faChevronUp}
              className={styles.tasks_icon}
              style={{ marginTop: '10px' }}
              onClick={handleMoveTaskUp}
            />
            <FontAwesomeIcon icon={faChevronDown} className={styles.tasks_icon} onClick={handleMoveTaskDown} />
          </div>

          <div className={styles.tasks_items}>
            <div className={styles.tasks_header_icons}>
              <span className={styles.tasks_title}>ビューのタスク</span>
              <div className='flex items-center '>
                <span onClick={handleUnGroup}>
                  <IconUnGroup className={styles.tasks_iconSvg}></IconUnGroup>
                </span>

                <FontAwesomeIcon
                  icon={faLayerGroup}
                  className={styles.tasks_icon}
                  style={{ display: 'inline-block', marginLeft: '10px' }}
                  onClick={handleChangeGroup}
                />
              </div>
            </div>
            <div className={styles.tasks_item}>
              <div className={styles.tasks_header}>
                <span>タスク名</span>
                <span style={{ marginRight: '15px' }}>グループ</span>
              </div>
              {taskDisplay.length !== 0 &&
                taskDisplay.map((task, i) => {
                  if (task?.type === 'task') {
                    return (
                      <div key={task?.id} className={styles.tasks_itemContainer}>
                        <>
                          <input
                            ref={(el) => (checkBoxDisplayRef.current[i] = el)}
                            type='checkbox'
                            name=''
                            id={task?.id}
                            className={styles.checkbox_input}
                            onClick={(e) => handleInputDisplayChange(e, task)}
                          ></input>
                          <TaskToolTip task={task} groupName={getGroupNameByTaskId(task)}>
                            <label htmlFor={task?.id} className={styles.checkbox_label}>
                              {task.name} ( {getGroupNameByTaskId(task)} - {task.duration}日 )
                            </label>
                          </TaskToolTip>

                          <TextInput
                            value={task?.group !== 0 ? task?.group : ''}
                            onChange={(value) => {
                              handleTextInputChangeGroup(value, task)
                            }}
                            style={{
                              width: '40px',
                              border: '1px solid gray',
                              borderRadius: '6px',
                              textAlign: 'center',
                              padding: '0',
                              height: '25px'
                            }}
                          ></TextInput>
                        </>
                      </div>
                    )
                  }
                })}
            </div>
          </div>
        </div>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOkClick}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
