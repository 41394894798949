import React, { useState, SyntheticEvent, useRef, useEffect, useMemo } from 'react'
import { ViewMode, GanttProps, Task } from '../../types/public-types'
import { GridProps } from '../grid/grid'
import { CalendarProps } from '../calendar/calendar'
import { TaskGanttContentProps } from './task-gantt-content'
import { TaskListHeaderDefault } from '../task-list/task-list-header'
import { TaskListTableDefault } from '../task-list/task-list-table'
import { VerticalScroll } from '../other/vertical-scroll'
import { TaskListProps, TaskList } from '../task-list/task-list'
import { TaskGantt } from './task-gantt'
import { BarTask } from '../../types/bar-task'
import {
  calculateStartEndRecursion,
  calculateStartEndTasks,
  convertBarTasksToTasks,
  convertBarTasksToTasks2,
  convertToBarTasks,
  isUpdateTasks
} from '../../helpers/bar-helper'
import { GanttEvent } from '../../types/gantt-task-actions'
import { HorizontalScroll } from '../other/horizontal-scroll'
import { removeHiddenTasks, sortTasks } from '../../helpers/other-helper'
import styles from './gantt.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex'
import { columnsSlice } from 'C_REDUX_STORE/scheduleTool/columnsSlice'
import { tableWidthSlice } from 'C_REDUX_STORE/scheduleTool/tableWidthSlice'
import { calculateGridHeight } from 'F_UTILS/SchedulingUtils/TaskUtils'

export const Gantt: React.FunctionComponent<GanttProps> = ({
  tasks,
  setTasks,
  headerHeight = 80,
  columnWidth = 60,
  listCellWidth = '155px',
  rowHeight = 30,
  ganttHeight = 0,
  viewMode = ViewMode.Day,
  preStepsCount = 1,
  locale = 'en-GB',
  barFill = 60,
  barCornerRadius = 3,
  barProgressColor = '#a3a3ff',
  barProgressSelectedColor = '#8282f5',
  barBackgroundColor = '#B02171',
  barBackgroundSelectedColor = 'red',
  projectProgressColor = '#7db59a',
  projectProgressSelectedColor = '#59a985',
  projectBackgroundColor = '#fac465',
  projectBackgroundSelectedColor = '#f7bb53',
  milestoneBackgroundColor = '#f1c453',
  milestoneBackgroundSelectedColor = '#f29e4c',
  rtl = false,
  handleWidth = 8,
  timeStep = 300000,
  arrowColor = 'black',
  fontFamily = 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue',
  fontSize = '14px',
  arrowIndent = 20,
  todayColor = 'rgba(252, 248, 227, 0.5)',
  TaskListHeader = TaskListHeaderDefault,
  TaskListTable = TaskListTableDefault,
  onDateChange,
  onProgressChange,
  onDoubleClick,
  onClick,
  onDelete,
  onSelect,
  onExpanderClick,
  onRightClick,
  onClickEvent,
  onRightClickGrid,
  onRightClickHeader,
  onRightClickCell,
  onLeftClickCell,
  onLeftClickHeader,
  onChangeTaskName
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const taskListRef = useRef<HTMLDivElement>(null)
  var dispatch = useDispatch()
  const [taskListWidth, setTaskListWidth] = useState(0)
  const [svgContainerWidth, setSvgContainerWidth] = useState(0)
  const [svgContainerHeight, setSvgContainerHeight] = useState(ganttHeight)
  const [barTasks, setBarTasks] = useState<BarTask[]>([])
  const [ganttEvent, setGanttEvent] = useState<GanttEvent>({
    action: ''
  })
  const taskHeight = useMemo(() => (rowHeight * barFill) / 100, [rowHeight, barFill])

  const [selectedTask, setSelectedTask] = useState<BarTask>()
  const [failedTask, setFailedTask] = useState<BarTask | null>(null)
  //Todo
  const month = useSelector((state: RootState) => state.versionSetting.current.numberOfMonths)
  const currentSetting = useSelector((state: RootState) => state.versionSetting.current)
  const stepWorkColors = useSelector((state: RootState) => state.versionSetting.current.stepworkColors)
  const amplifiedFactor = useSelector((state: RootState) => state.versionSetting.current.amplifiedFactor)

  const svgWidth = month * columnWidth
  const ganttFullHeight = calculateGridHeight(barTasks) * rowHeight

  const [scrollY, setScrollY] = useState(0)
  const [scrollX, setScrollX] = useState(-1)
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(false)
  const [filterTasks, setFilterTasks] = useState<Task[]>([])
  // task change events
  useEffect(() => {
    let filteredTask: Task[]
    if (onExpanderClick) {
      filteredTask = removeHiddenTasks(tasks)
    } else {
      filteredTask = tasks
    }

    let sortedTasks: Task[]
    sortedTasks = [...filteredTask]
    sortedTasks = sortedTasks.sort(sortTasks)
    filteredTask = [...sortedTasks]
    setFilterTasks(filteredTask)
    setBarTasks(
      convertToBarTasks(
        filteredTask,
        columnWidth,
        rowHeight,
        taskHeight,
        barCornerRadius,
        handleWidth,
        barBackgroundColor,
        barBackgroundSelectedColor,
        amplifiedFactor
      )
    )
  }, [
    tasks,
    viewMode,
    preStepsCount,
    rowHeight,
    barCornerRadius,
    columnWidth,
    taskHeight,
    handleWidth,
    barProgressColor,
    barProgressSelectedColor,
    barBackgroundColor,
    barBackgroundSelectedColor,
    projectProgressColor,
    projectProgressSelectedColor,
    projectBackgroundColor,
    projectBackgroundSelectedColor,
    milestoneBackgroundColor,
    milestoneBackgroundSelectedColor,
    rtl,
    scrollX,
    onExpanderClick,
    amplifiedFactor
  ])

  useEffect(() => {
    const { changedTask, action, otherChangedTask } = ganttEvent
    let newTasks: Task[] = []
    if (changedTask) {
      if (action === 'delete') {
        setGanttEvent({ action: '' })
        setBarTasks(barTasks.filter((t) => t.id !== changedTask.id))
      } else if (action === 'move' || action === 'end' || action === 'start' || action === 'progress') {
        const newTaskList = barTasks.map((t) => (t.id === changedTask.id ? changedTask : t))
        otherChangedTask?.forEach((task) => {
          const foundTaskIndex = newTaskList.findIndex((x) => x.id === task.id)
          if (foundTaskIndex) {
            newTaskList[foundTaskIndex] = task
          }
        })
        setBarTasks(newTaskList)
        let copyTasks = [...tasks]
        for (let index = 0; index < barTasks.length; index++) {
          const barTask = barTasks[index]
          for (let j = 0; j < copyTasks.length; j++) {
            const task = copyTasks[j]
            if (task?.stepworks) {
              let foundTask = task.stepworks?.find((x: any) => x.id === barTask.id)
              if (foundTask !== undefined) {
                if (foundTask.start !== barTask.start) {
                  foundTask.start = barTask.start
                  foundTask.end = barTask.end
                }
              }
            } else if (task.id === barTask.id) {
              if (task.start !== barTask.start) {
                task.start = barTask.start
                task.end = barTask.end
              }
            }
          }
        }
        newTasks = convertBarTasksToTasks(newTaskList, tasks)
        calculateStartEndRecursion(newTasks, columnWidth, amplifiedFactor)

        setTasks(newTasks)
      } else if (action === 'deletePredecessor') {
        const prevStateTask = barTasks.find((t) => t.id === changedTask.id)
        if (prevStateTask && prevStateTask.predecessors?.length !== changedTask.predecessors?.length) {
          const newTaskList = barTasks.map((t) => (t.id === changedTask.id ? changedTask : t))
          setBarTasks(newTaskList)
          let copyTasks = [...tasks]
          for (let j = 0; j < copyTasks.length; j++) {
            const task = copyTasks[j]
            if (task?.stepworks) {
              let foundTask = task.stepworks?.find((x: any) => x.id === changedTask.id)
              if (foundTask !== undefined) {
                foundTask.predecessors = changedTask.predecessors
                foundTask.start = changedTask.start
                foundTask.end = changedTask.end
              }
            } else if (task.id === changedTask.id) {
              task.predecessors = changedTask.predecessors
              task.start = changedTask.start
              task.end = changedTask.end
            }
          }
          newTasks = convertBarTasksToTasks(newTaskList, copyTasks)
          calculateStartEndRecursion(newTasks, columnWidth, amplifiedFactor)
          setTasks(newTasks)

          // const newTasks = convertBarTasksToTasks(newTaskList, copyTasks)
          // calculateStartEnd(copyTasks, columnWidth)
          // setTasks(copyTasks)
        }
      } else if (action === 'select') {
        const prevStateTask = barTasks.find((t) => t.id === changedTask.id)
        if (prevStateTask) {
          const newTaskList = barTasks.map((t) => (t.id === changedTask.id ? changedTask : t))
          setBarTasks(newTaskList)
          let copyTasks = [...tasks]
          for (let j = 0; j < copyTasks.length; j++) {
            const task = copyTasks[j]
            if (task?.stepworks) {
              let foundTask = task.stepworks?.find((x: any) => x.id === changedTask.id)
              if (foundTask !== undefined && changedTask.predecessors) {
                foundTask.predecessors = [...changedTask.predecessors]
                foundTask.start = changedTask.start
                foundTask.end = changedTask.end
              }
            } else if (task.id === changedTask.id && changedTask.predecessors) {
              task.predecessors = [...changedTask.predecessors]
              task.start = changedTask.start
              task.end = changedTask.end
            }
          }
          const calculatedTasks = calculateStartEndTasks(copyTasks, columnWidth, amplifiedFactor)
          newTasks = convertBarTasksToTasks(newTaskList, calculatedTasks)
          calculateStartEndRecursion(newTasks, columnWidth, amplifiedFactor)
          setTasks(newTasks)

          // const calculatedTasks = calculateStartEndTasks(copyTasks, columnWidth)
          // calculateStartEnd(calculatedTasks, columnWidth)
          // setTasks(calculatedTasks)
        }
      }
    }
  }, [ganttEvent, barTasks])

  useEffect(() => {}, [failedTask, barTasks])

  useEffect(() => {
    if (!listCellWidth) {
      setTaskListWidth(0)
    }
    if (taskListRef.current) {
      setTaskListWidth(taskListRef.current.offsetWidth)
    }
  }, [taskListRef, listCellWidth])

  useEffect(() => {
    if (wrapperRef.current) {
      setSvgContainerWidth(wrapperRef.current.offsetWidth - taskListWidth)
    }
  }, [wrapperRef, taskListWidth])

  useEffect(() => {
    if (ganttHeight) {
      setSvgContainerHeight(ganttHeight + headerHeight)
    } else {
      setSvgContainerHeight(tasks.length * rowHeight + headerHeight)
    }
  }, [ganttHeight, tasks, headerHeight, rowHeight])
  // console.log('🚀 ~ file: gantt.tsx:286 ~ tasks:', tasks)
  // console.log('🚀 ~ file: gantt.tsx:286 ~ bartasks:', barTasks)

  //scroll events
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.shiftKey || event.deltaX) {
        const scrollMove = event.deltaX ? event.deltaX : event.deltaY
        let newScrollX = scrollX + scrollMove
        if (newScrollX < 0) {
          newScrollX = 0
        } else if (newScrollX > svgWidth) {
          newScrollX = svgWidth
        }
        setScrollX(newScrollX)
        event.preventDefault()
      } else if (ganttHeight) {
        let newScrollY = scrollY + event.deltaY
        if (newScrollY < 0) {
          newScrollY = 0
        } else if (newScrollY > ganttFullHeight - ganttHeight) {
          newScrollY = ganttFullHeight - ganttHeight
        }
        if (newScrollY !== scrollY) {
          setScrollY(newScrollY)
          event.preventDefault()
        }
      }

      setIgnoreScrollEvent(true)
    }

    // subscribe if scroll is necessary
    wrapperRef.current?.addEventListener('wheel', handleWheel, {
      passive: false
    })
    return () => {
      wrapperRef.current?.removeEventListener('wheel', handleWheel)
    }
  }, [wrapperRef, scrollY, scrollX, ganttHeight, svgWidth, rtl, ganttFullHeight])

  useEffect(() => {
    if (isUpdateTasks(barTasks, tasks)) {
      const newTasks = convertBarTasksToTasks(barTasks, tasks)
      calculateStartEndRecursion(newTasks, columnWidth, amplifiedFactor)
      const newTasks2 = convertBarTasksToTasks2(barTasks, newTasks)
      setTasks(newTasks2)
    }
  }, [barTasks])

  // console.log('🚀 ~ file: gantt.tsx:259 ~ barTasks:', tasks)

  const handleScrollY = (event: SyntheticEvent<HTMLDivElement>) => {
    console.log('handleScrollY')

    if (scrollY !== event.currentTarget.scrollTop && !ignoreScrollEvent) {
      setScrollY(event.currentTarget.scrollTop)
      setIgnoreScrollEvent(true)
    } else {
      setIgnoreScrollEvent(false)
    }
  }

  const handleScrollX = (event: SyntheticEvent<HTMLDivElement>) => {
    if (scrollX !== event.currentTarget.scrollLeft && !ignoreScrollEvent) {
      setScrollX(event.currentTarget.scrollLeft)
      setIgnoreScrollEvent(true)
    } else {
      setIgnoreScrollEvent(false)
    }
  }
  /**
   * Handles arrow keys events and transform it to new scroll
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // event.preventDefault()
    let newScrollY = scrollY
    let newScrollX = scrollX
    let isX = true
    switch (event.key) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        newScrollY += rowHeight
        isX = false
        break
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        newScrollY -= rowHeight
        isX = false
        break
      case 'Left':
      case 'ArrowLeft':
        newScrollX -= columnWidth
        break
      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        newScrollX += columnWidth
        break
    }
    if (isX) {
      if (newScrollX < 0) {
        newScrollX = 0
      } else if (newScrollX > svgWidth) {
        newScrollX = svgWidth
      }
      setScrollX(newScrollX)
    } else {
      if (newScrollY < 0) {
        newScrollY = 0
      } else if (newScrollY > ganttFullHeight - ganttHeight) {
        newScrollY = ganttFullHeight - ganttHeight
      }
      setScrollY(newScrollY)
    }
    setIgnoreScrollEvent(true)
  }

  /**
   * Task select event
   */
  const handleSelectedTask = (taskId: string) => {
    const newSelectedTask = barTasks.find((t) => t.id === taskId)
    const oldSelectedTask = barTasks.find((t) => !!selectedTask && t.id === selectedTask.id)
    if (onSelect) {
      if (oldSelectedTask) {
        onSelect(oldSelectedTask, false)
      }
      if (newSelectedTask) {
        onSelect(newSelectedTask, true)
      }
    }
    setSelectedTask(newSelectedTask)
  }
  const handleExpanderClick = (task: Task) => {
    if (onExpanderClick && task.hideChildren !== undefined) {
      onExpanderClick({ ...task, hideChildren: !task.hideChildren })
    }
  }
  const gridProps: GridProps = {
    columnWidth,
    svgWidth,
    tasks: filterTasks,
    barTasks: barTasks,
    rowHeight,
    todayColor,
    rtl,
    onClickEvent,
    onRightClickGrid
  }
  const calendarProps: CalendarProps = {
    locale,
    viewMode,
    headerHeight,
    columnWidth,
    fontFamily,
    fontSize,
    rtl
  }
  const barProps: TaskGanttContentProps = {
    tasks: barTasks,
    setTasks,
    ganttEvent,
    selectedTask,
    rowHeight,
    taskHeight,
    columnWidth,
    arrowColor,
    timeStep,
    fontFamily,
    fontSize,
    arrowIndent,
    svgWidth,
    rtl,
    setGanttEvent,
    setFailedTask,
    setSelectedTask: handleSelectedTask,
    onDateChange,
    onProgressChange,
    onDoubleClick,
    onClick,
    onDelete,
    onRightClick,
    onRightClickGrid,
    onRightClickHeader,
    onRightClickCell,
    onLeftClickCell,
    onLeftClickHeader,
    onChangeTaskName
  }

  const tableProps: TaskListProps = {
    rowHeight,
    rowWidth: listCellWidth,
    fontFamily,
    fontSize,
    tasks: filterTasks,
    locale,
    headerHeight,
    scrollY,
    ganttHeight,
    horizontalContainerClass: styles.horizontalContainer,
    selectedTask,
    taskListRef,
    setSelectedTask: handleSelectedTask,
    onExpanderClick: handleExpanderClick,
    onRightClickHeader: onRightClickHeader,
    onRightClickCell: onRightClickCell,
    onLeftClickCell: onLeftClickCell,
    onLeftClickHeader: onLeftClickHeader,
    onChangeTaskName: onChangeTaskName,
    TaskListHeader,
    TaskListTable,
    scroll: scrollY,
    onScroll: handleScrollY,
    ganttFullHeight: ganttFullHeight,
    setScrollY: setScrollY,
    setIgnoreScrollEvent: setIgnoreScrollEvent,
    handleKeyDown: handleKeyDown
  }
  const minSizeLeftPanel = 790
  const maxSizeLeftPanel = window.innerWidth * 0.7
  const columnsInfo = useSelector((state: RootState) => state.columnsInfo)
  const numberOfMonths = useSelector((state: RootState) => state.versionSetting.current.numberOfMonths)

  const handleResize = (e: any) => {
    if (e.domElement) {
      let tbWidth = window.innerWidth - e.domElement.getBoundingClientRect().width - 22.25
      dispatch(tableWidthSlice.actions.setTableWidth(tbWidth))
      let totalColumnsWidth = 0

      columnsInfo.forEach((column: any) => {
        if (column.isHidden === false) {
          totalColumnsWidth += column.width
        }
      })
      const displayColumns = columnsInfo.filter((x: any) => x.isHidden === false)
      if (totalColumnsWidth < tbWidth) {
        totalColumnsWidth = totalColumnsWidth - displayColumns.at(-1).width
        if (displayColumns.at(-1).minWidth < tbWidth - totalColumnsWidth) {
          dispatch(
            columnsSlice.actions.changeColumnsWidth({
              name: displayColumns.at(-1).name,
              width: tbWidth - totalColumnsWidth
            })
          )
          return
        }
      }

      const note = columnsInfo.filter((x: any) => x.isHidden === false && x.width > x.minWidth).at(-1)
      if (note !== undefined) {
        totalColumnsWidth = totalColumnsWidth - note.width
        if (note.minWidth < tbWidth - totalColumnsWidth) {
          dispatch(
            columnsSlice.actions.changeColumnsWidth({
              name: note.name,
              width: tbWidth - totalColumnsWidth
            })
          )
        } else {
          dispatch(
            columnsSlice.actions.changeColumnsWidth({
              name: note.name,
              width: note.minWidth
            })
          )
        }
      }
    }
  }

  return (
    <>
      <ReflexContainer orientation='vertical'>
        <ReflexElement minSize={minSizeLeftPanel} maxSize={maxSizeLeftPanel} flex={0.3}>
          {listCellWidth && <TaskList {...tableProps} />}
        </ReflexElement>
        <ReflexSplitter
          propagate={true}
          className=' customSplitter'
          style={{ height: `${Math.min(headerHeight + ganttHeight + 3, headerHeight + ganttFullHeight + 3)}px` }}
        />
        <ReflexElement onResize={handleResize} onStopResize={handleResize}>
          <div className={`${styles.wrapper} `} onKeyDown={handleKeyDown} tabIndex={0} ref={wrapperRef}>
            <TaskGantt
              gridProps={gridProps}
              calendarProps={calendarProps}
              barProps={barProps}
              ganttHeight={ganttHeight}
              scrollY={scrollY}
              scrollX={scrollX}
            />
            <VerticalScroll
              ganttFullHeight={ganttFullHeight}
              ganttHeight={ganttHeight}
              headerHeight={headerHeight}
              scroll={scrollY}
              onScroll={handleScrollY}
              rtl={rtl}
            />
          </div>
          {/* <HorizontalScroll
            svgWidth={numberOfMonths * columnWidth}
            taskListWidth={taskListWidth}
            scroll={scrollX}
            rtl={rtl}
            onScroll={handleScrollX}
          /> */}
        </ReflexElement>
      </ReflexContainer>
    </>
  )
}
