import React, { useEffect, useRef } from 'react'
import styles from './TaskListHeader.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { columnsSlice } from '../../../../C_REDUX_STORE/scheduleTool/columnsSlice'
import { columnsWidthSlice } from 'C_REDUX_STORE/scheduleTool/tableWidthSlice'

const HeaderCell = ({ headerHeight, columnWidth, content, name, minWidth, onRightClickHeader }) => {
  const handleOnContextMenu = (e) => {
    e.preventDefault()
    onRightClickHeader(name, e)
  }
  var dispatch = useDispatch()
  const columnsInfo = useSelector((state) => state.columnsInfo)
  const separator = useRef(null)
  const header = useRef(null)

  useEffect(() => {
    var isHidden = columnsInfo.find((x) => x.name === name).isHidden
    if (separator.current != null) {
      if (isHidden) {
        separator.current.style.display = 'none'
      } else {
        separator.current.style.display = ''
      }
    }
    if (header.current != null) {
      if (isHidden) {
        header.current.style.display = 'none'
      } else {
        header.current.style.display = ''
      }
    }

    let x = 0
    let w = 0

    const mouseDownHandler = function (e) {
      x = e.clientX
      w = parseInt(header.current.style.minWidth.replace('px', ''), 10)
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }
    const CheckCanChangeColumnWidth = (columnsInfo, columnName, width) => {
      const currentColumns = columnsInfo.find((co) => co.name === columnName)
      if (columnsInfo.indexOf(currentColumns) === columnsInfo.length - 1) {
        if (currentColumns.minWidth < width && currentColumns) {
          return true
        }
      } else {
        const deltaWidth = currentColumns.width - width
        const listDisplayColumns = columnsInfo.filter((x) => x.isHidden === false)
        const lastVisibleWidth = listDisplayColumns.at(-1).width + deltaWidth
        if (lastVisibleWidth > listDisplayColumns.at(-1).minWidth) {
          return true
        }
      }
      return false
    }

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x
      const newWidth = dx + w
      if (newWidth > minWidth) {
        if (CheckCanChangeColumnWidth(columnsInfo, name, newWidth)) {
          dispatch(
            columnsSlice.actions.changeColumnsWidth({
              name: name,
              width: newWidth
            })
          )
          // Update the width of column
          header.current.style.minWidth = `${w + dx}px`
        } else {
          e.preventDefault()
        }
      }
    }

    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    if (separator.current != null) {
      separator.current.addEventListener('mousedown', mouseDownHandler)
    }

    return () => {
      if (separator.current != null) {
        separator.current.removeEventListener('mousemove', mouseMoveHandler)
        separator.current.removeEventListener('mousedown', mouseDownHandler)
      }
    }
  }, [columnsInfo])
  return (
    <>
      <div
        ref={header}
        className={styles.ganttTable_HeaderItem}
        onContextMenu={handleOnContextMenu}
        style={{
          minWidth: columnWidth,
          textAlign: 'center',
          userSelect: 'none'
        }}
      >
        &nbsp;{content}
      </div>
      <div
        className={styles.ganttTable_HeaderSeparator}
        style={{
          height: headerHeight
        }}
        ref={separator}
      ></div>
    </>
  )
}

export const TaskListHeader = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
  onRightClickHeader,
  onLeftClickHeader,
  tableWidth
}) => {
  const separator = useRef(null)
  const header = useRef(null)
  var dispatch = useDispatch()
  const columnsInfo = useSelector((state) => state.columnsInfo)
  const task = columnsInfo.find((x) => x.name === 'task')
  const detailInfo = columnsInfo.find((x) => x.name === 'detail')
  const duration = columnsInfo.find((x) => x.name === 'duration')
  const groups = columnsInfo.find((x) => x.name === 'groups')
  const amplified = columnsInfo.find((x) => x.name === 'amplified')
  const assembly = columnsInfo.find((x) => x.name === 'assembly')
  const removal = columnsInfo.find((x) => x.name === 'removal')
  const note = columnsInfo.find((x) => x.name === 'note')
  const currentSetting = useSelector((state) => state.versionSetting.current)

  useEffect(() => {
    let x = 0
    let w = 0

    const mouseDownHandler = function (e) {
      x = e.clientX
      w = parseInt(header.current.style.minWidth.replace('px', ''), 10)
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }
    const CheckCanChangeColumnWidth = (columnsInfo, columnName, width) => {
      const currentColumns = columnsInfo.find((co) => co.name === columnName)
      if (columnsInfo.indexOf(currentColumns) === columnsInfo.length - 1) {
        if (currentColumns.minWidth < width && currentColumns) {
          return true
        }
      } else {
        const deltaWidth = currentColumns.width - width
        const listDisplayColumns = columnsInfo.filter((x) => x.isHidden === false)
        const lastVisibleWidth = listDisplayColumns.at(-1).width + deltaWidth
        if (lastVisibleWidth > listDisplayColumns.at(-1).minWidth) {
          return true
        }
      }
      return false
    }
    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x
      const newWidth = dx + w
      if (newWidth > task.minWidth) {
        if (CheckCanChangeColumnWidth(columnsInfo, task.name, newWidth)) {
          dispatch(
            columnsSlice.actions.changeColumnsWidth({
              name: task.name,
              width: newWidth
            })
          )
          // Update the width of column
          header.current.style.minWidth = `${w + dx}px`
        } else {
          e.preventDefault()
        }
      }
    }

    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    if (separator.current != null) {
      separator.current.addEventListener('mousedown', mouseDownHandler)
    }
    return () => {
      if (separator.current != null) {
        separator.current.removeEventListener('mousemove', mouseMoveHandler)
        separator.current.removeEventListener('mousedown', mouseDownHandler)
      }
    }
  }, [columnsInfo])

  return (
    <div
      className={styles.ganttTable}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize
      }}
      onClick={onLeftClickHeader}
    >
      <div
        className={styles.ganttTable_Header}
        style={{
          height: headerHeight
        }}
      >
        <div
          className={styles.ganttTable_HeaderItem}
          style={{
            minWidth: task.width,
            width: task.width,
            textAlign: 'center'
          }}
          ref={header}
        >
          &nbsp;工種
        </div>
        <div
          className={styles.ganttTable_HeaderSeparator}
          style={{
            height: headerHeight
          }}
          ref={separator}
        />
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={detailInfo.width}
          name={detailInfo.name}
          minWidth={detailInfo.minWidth}
          content='細目'
          onRightClickHeader={onRightClickHeader}
        ></HeaderCell>

        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={duration.width - 1}
          name={duration.name}
          minWidth={duration.minWidth}
          onRightClickHeader={onRightClickHeader}
          content={
            <>
              所要日数(A)<br></br>(日)
            </>
          }
        ></HeaderCell>
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={groups.width}
          name={groups.name}
          minWidth={groups.minWidth}
          onRightClickHeader={onRightClickHeader}
          content='台数班数'
        ></HeaderCell>
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={amplified.width}
          name={amplified.name}
          minWidth={amplified.minWidth}
          onRightClickHeader={onRightClickHeader}
          content={
            <>
              所要日数(B)<br></br>(Ax{currentSetting.amplifiedFactor}/班数)
              <br></br>(日)
            </>
          }
        ></HeaderCell>
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={assembly.width + 1}
          name={assembly.name}
          minWidth={assembly.minWidth}
          onRightClickHeader={onRightClickHeader}
          content={
            <>
              設置日数(C)<br></br>(Bx{currentSetting.assemblyDurationRatio})<br></br>(日)
            </>
          }
        ></HeaderCell>
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={removal.width}
          name={removal.name}
          minWidth={removal.minWidth}
          onRightClickHeader={onRightClickHeader}
          content={
            <>
              撤去日数(D)<br></br>(Bx{currentSetting.removalDurationRatio})<br></br>(日)
            </>
          }
        ></HeaderCell>
        <HeaderCell
          headerHeight={headerHeight}
          columnWidth={note.width}
          name={note.name}
          minWidth={note.minWidth}
          onRightClickHeader={onRightClickHeader}
          content='備考'
        ></HeaderCell>
      </div>
    </div>
  )
}
