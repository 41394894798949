import React from 'react'
import styles from './MenuShared.module.scss'
import TableRowShared from '../../components/TableRowShared'
import { useEffect } from 'react'
import { useState } from 'react'
import CreateProjectComponent from '../../components/CreateProjectComponent'
import DeleteProjectComponent from '../../components/DeleteProjectComponent'
import ProjectApi from '../../../../B_API/scheduleApis/ProjectApi'
import { ToastContainer } from 'react-toastify'
import { Button, Pagination, Skeleton } from 'antd'
import { CopyOutlined, DeleteOutlined, FileAddOutlined } from '@ant-design/icons'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'
import SideBar from '../../components/SideBar'
import MoveToComponent from 'Z_SERVICES/i_MenuSchedulingTool/components/MoveToComponent'

export default function MenuShared() {
  const [isNewProject, setIsNewProject] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleDeleteProject, setVisibleDeleteProject] = useState(false)
  const [listProjects, setListProject] = useState([])
  const [listDeleteVersionID, setListDeleteVersionID] = useState([])
  const [listSelectedProjectID, setListSelectedProjectID] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [modifiedProject, setModifiedProject] = useState({})
  const [openMoveTo, setOpenMoveTo] = useState(false)

  useEffect(() => {
    async function fetchListProject() {
      try {
        const response = await ProjectApi.GetActiveSharedProjectsByPage(currentPage, 10)
        setListProject(response.data.data)
        setPageCount(response.data.pageCount)
      } catch (error) {
        console.log('Get List Project ', error)
      }
    }
    if (!openMoveTo) {
      fetchListProject()
    }
  }, [openMoveTo])

  useEffect(() => {
    async function fetchListProject() {
      try {
        setIsLoading(true)
        const response = await ProjectApi.GetActiveSharedProjectsByPage(currentPage, 10)
        setListProject(response.data.data)
        setPageCount(response.data.pageCount)
        setIsLoading(false)
      } catch (error) {
        console.log('Get List Project ', error)
      }
    }
    fetchListProject()
  }, [isNewProject, currentPage, modifiedProject])

  const handleClickNewProject = () => {
    setIsNewProject(true)
  }

  const handleClickDeleteProject = () => {
    const listVersion = listProjects.reduce(
      (listVers, currentProject) => (listVers = listVers.concat(currentProject.versions)),
      []
    )
    const deleteListIds = listVersion.filter((x) => x.isChecked).map((x) => x.versionId)
    if (deleteListIds.length === 0) {
      return
    }

    setListDeleteVersionID(deleteListIds)
    setVisibleDeleteProject(true)
  }

  const handleMoveto = () => {
    const listVersion = listProjects.reduce(
      (listVers, currentProject) => (listVers = listVers.concat(currentProject.versions)),
      []
    )
    const deleteListIds = listVersion.filter((x) => x.isChecked).map((x) => x.versionId)
    if (deleteListIds.length === 0) {
      return
    }
    const listSelectedProject = listProjects.filter((project) => {
      return project.versions.some((x) => x.isChecked)
    })
    setListSelectedProjectID(listSelectedProject.map((x) => x.projectId))
    setListDeleteVersionID(deleteListIds)
    setOpenMoveTo(true)
  }

  const onChange = (page) => {
    setCurrentPage(page)
  }

  const handleOkClick = async () => {
    try {
      const res = await VersionApi.DeactiveVersions(listDeleteVersionID)
      if (res.status !== 204) {
        ToastError('バージョンの削除に失敗しました。')
        setVisibleDeleteProject(false)
      } else {
        ToastSuccess('バージョンの削除に成功しました。')
        const response = await ProjectApi.GetActiveSharedProjectsByPage(currentPage, 10)
        setListProject(response.data.data)
        setPageCount(response.data.pageCount)
        setVisibleDeleteProject(false)
      }
    } catch (error) {
      ToastSuccess('バージョンの削除に成功しました。')
      setVisibleDeleteProject(false)
    }
    setVisibleDeleteProject(false)
  }

  const handleCancelClick = () => {
    setVisibleDeleteProject(false)
  }

  return (
    <>
      <ToastContainer style={{ width: '650px', zIndex: 10000000 }} />

      <DeleteProjectComponent
        isVisibleDeleteProject={isVisibleDeleteProject && listDeleteVersionID.length > 0}
        handleOkClick={handleOkClick}
        handleCancelClick={handleCancelClick}
      />

      {isNewProject && (
        <CreateProjectComponent setIsNewProject={setIsNewProject} setListProject={setListProject} isShared={true} />
      )}

      <div className={styles.container}>
        <SideBar></SideBar>
        <div className='flex-1 px-10 py-4'>
          <div className={styles.heading}>
            <h2 className={styles.title}>プロジェクトリスト：</h2>
            <div className={styles.actions_container}>
              <div className={styles.actions}>
                <Button
                  type='primary'
                  className='flex items-center'
                  icon={<FileAddOutlined />}
                  onClick={handleClickNewProject}
                >
                  作成
                </Button>

                <Button
                  type='primary'
                  icon={<DeleteOutlined />}
                  onClick={handleClickDeleteProject}
                  className='flex items-center'
                >
                  削除
                </Button>

                <Button type='primary' icon={<CopyOutlined />} onClick={handleMoveto} className='flex items-center'>
                  移動
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.menuTable}>
            <table>
              <thead style={{ backgroundColor: '#dbdbdb' }}>
                <tr>
                  <th style={{ width: '77%', minWidth: '50px', height: '55px' }}>プロジェクト名</th>
                  <th style={{ width: '10%', minWidth: '160px' }}>ユーザー編集</th>
                  <th style={{ width: '10%', minWidth: '160px' }}>最後更新日</th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  listProjects &&
                  listProjects.length > 0 &&
                  listProjects.map((pro) => (
                    <TableRowShared
                      disable={false}
                      key={pro.projectId}
                      isChecked={pro?.isChecked ? true : false}
                      project={pro}
                      currentPage={currentPage}
                      ListProject={listProjects}
                      setListProject={setListProject}
                      setModifiedProject={setModifiedProject}
                      setCurrentPage={setCurrentPage}
                    ></TableRowShared>
                  ))}
              </tbody>
            </table>

            {isLoading && <Skeleton title={false} paragraph={{ rows: 18, width: '100%' }} />}
          </div>

          <div className={styles.pagination}>
            <Pagination current={currentPage} onChange={onChange} pageSize={10} total={pageCount * 10} />
          </div>
        </div>
        <MoveToComponent
          listSelectedProjectID={listSelectedProjectID}
          open={openMoveTo && listDeleteVersionID.length > 0}
          setOpenMoveTo={setOpenMoveTo}
          listSelectedProjectId={listDeleteVersionID}
        ></MoveToComponent>
      </div>
    </>
  )
}
