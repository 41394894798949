import { PlusCircleOutlined } from '@ant-design/icons'
import { faChevronRight, faPenClip, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep, uniq } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import SettingBackgroundModal from '../SettingBackgroundModal'

export default function BackgroundCheckbox({ getValues, backgroundColors, setBackground }) {
  const [expand, setExpand] = useState(false)
  const [modify, setModify] = useState(false)
  const [initData, setInitData] = useState(null)
  const inputRef = useRef(null)
  useEffect(() => {
    const listDateChecked = backgroundColors?.dateRanges?.map((x) => x.checked)
    const uniqueDate = uniq(listDateChecked)

    if (uniqueDate.length > 1 && inputRef.current) {
      inputRef.current.indeterminate = true
    } else if (uniqueDate.length === 1 && inputRef.current) {
      inputRef.current.indeterminate = false
      if (uniqueDate[0] === true) {
        setBackground((prev) => {
          const copyState = [...prev]
          const background = copyState.find((x) => x.name === backgroundColors.name)
          background.checked = true
          return copyState
        })
      } else {
        setBackground((prev) => {
          const copyState = [...prev]
          const background = copyState.find((x) => x.name === backgroundColors.name)
          background.checked = false
          return copyState
        })
      }
    }
  }, [backgroundColors])

  const handleChangeAll = (e) => {
    setBackground((prev) => {
      const copyState = cloneDeep(prev)
      const background = copyState.find((x) => x.name === backgroundColors.name)
      background.checked = e.target.checked
      background?.dateRanges?.forEach((color) => {
        color.checked = e.target.checked
      })
      return copyState
    })
  }
  const handleChangeDate = (e, displayText) => {
    setBackground((prev) => {
      const copyState = cloneDeep(prev)
      const background = copyState.find((x) => x.name === backgroundColors.name)
      const date = background?.dateRanges?.find((x) => x.displayText === displayText)
      date.checked = e.target.checked
      return copyState
    })
  }
  const handleModifyBackground = (e, date) => {
    setInitData(date)
    setModify(true)
  }

  const handleAddNewBackgroundColor = () => {
    setInitData(null)
    setModify(true)
  }
  return (
    <div>
      <SettingBackgroundModal
        getValues={getValues}
        backgroundName={backgroundColors?.name}
        initData={initData}
        open={modify}
        handleClose={() => {
          setModify(false)
        }}
        handleOK={() => {}}
      ></SettingBackgroundModal>
      <div className='flex items-center px-3 pt-2 gap-2 cursor-pointer'>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={() => setExpand(!expand)}
          className={`leading-[40px] flex items-center justify-center mt-1 ${
            expand ? 'rotate-90 transition-all duration-100' : ''
          }`}
        />
        <input
          type='checkbox'
          className='h-4 w-4 cursor-pointer'
          onChange={handleChangeAll}
          checked={backgroundColors.checked}
          ref={inputRef}
        />
        <div className=' flex-1 flex justify-between items-center'>
          <span className='text-base '>{backgroundColors?.name}</span>
          <PlusCircleOutlined
            className='w-4 h-4 cursor-pointer text-[#02457a]'
            onClick={handleAddNewBackgroundColor}
          ></PlusCircleOutlined>
        </div>
      </div>
      {expand &&
        backgroundColors?.dateRanges?.map((date) => (
          <div className='flex items-center pt-2 gap-2 pl-11' key={date?.displayText}>
            <input
              type='checkbox'
              className='h-4 w-4 cursor-pointer'
              onChange={(e) => handleChangeDate(e, date?.displayText)}
              checked={date.checked}
            />
            <div className=' flex-1 flex justify-between items-center pr-3'>
              <span className='text-base'>{date?.displayText}</span>
              <FontAwesomeIcon
                icon={faPencil}
                className='text-[#02457a] cursor-pointer'
                onClick={(e) => handleModifyBackground(e, date)}
              />
            </div>
          </div>
        ))}
    </div>
  )
}
