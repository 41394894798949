import React, { useState } from "react";
import { WriteDataToTableWhenLoadFile } from "../../../../F_UTILS/CSVUtils";
import "./MainWindows.css";

import SwitchComponent from "../../../../A_SHARED_COMPONENT/SwitchComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableComponent from "../TableComponent";
import HeaderComponent from "../HeaderComponent";
import FooterComponent from "../FooterComponent";
import { useEffect } from "react";

export default function MainWindows({
  setViewInfor,
  setProgressIsRunning,
  setShowDialog,
  setMessageProgress,
  setMessageCompleted,
}) {
  const [listNames, setListNames] = useState([]);
  const [listNameNotInModel, setListNameNotInModel] = useState([]);
  const [listReNames, setListReNames] = useState([]);
  const [originName, setOriginName] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const windowCurrentWidth = window.innerWidth;

  useEffect(() => {
    WriteDataToTableWhenLoadFile(listNames, listReNames);

    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll("td");
      for (let index = 0; index < cols.length; index++) {
        const element = cols[index];
        const brEle = element.querySelectorAll("br");
        if (brEle?.length) {
          brEle?.forEach((e) => element.removeChild(e));
        }
      }
    }
  }, [listNames, listReNames]);
  return (
    <>
      <div className="main--windows">
        <ToastContainer style={{ width: "650px" }} />

        <HeaderComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setMessageCompleted={setMessageCompleted}
          setShowDialog={setShowDialog}
          setListNames={setListNames}
          setListNameNotInModel={setListNameNotInModel}
          setOriginName={setOriginName}
          setViewInfor={setViewInfor}
          setBtnDisable={setBtnDisable}
        ></HeaderComponent>

        <div className="file--name">
          <label>ファイル名: </label>
          <SwitchComponent label={"ハイライト"}></SwitchComponent>
        </div>

        <TableComponent
          rowCount={
            windowCurrentWidth > 1919 ? 22 : windowCurrentWidth > 1365 ? 10 : 9
          }
        ></TableComponent>

        <FooterComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setMessageCompleted={setMessageCompleted}
          setShowDialog={setShowDialog}
          originName={originName}
          setListNames={setListNames}
          setListReNames={setListReNames}
          listNameNotInModel={listNameNotInModel}
          setViewInfor={setViewInfor}
          btnDisable={btnDisable}
        ></FooterComponent>
      </div>
    </>
  );
}
