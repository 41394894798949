import React, { useRef, useEffect, useState } from 'react'
import styles from './PopoverHeaderComponent.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { columnsSlice } from 'C_REDUX_STORE/scheduleTool/columnsSlice'

export default function PopoverHeaderComponent({ event, headerName, setRightHeaderClick, setShowWindowUnhideColumns }) {
  const tooltipRef = useRef(null)

  var dispatch = useDispatch()
  const tableWidth = useSelector((state) => state.tableWidth)

  const handleHideColumn = () => {
    event.preventDefault()
    dispatch(
      columnsSlice.actions.hideColumn({
        name: headerName,
        tableWidth: tableWidth
      })
    )
    setRightHeaderClick(false)
  }

  const handleUnhideAll = () => {
    dispatch(
      columnsSlice.actions.resetColumn({
        tableWidth: tableWidth
      })
    )
    setRightHeaderClick(false)
  }

  const handleUnhideColumn = () => {
    setShowWindowUnhideColumns(true)
    setRightHeaderClick(false)
  }
  useEffect(() => {
    tooltipRef.current.style.top = `${event.clientY + 10}px`
    tooltipRef.current.style.left = `${event.clientX - 20}px`
  }, [event])
  return (
    <>
      <div className={styles.popoverHeaderMenu} ref={tooltipRef}>
        <div className={styles.popoverHeaderItem} onClick={handleHideColumn}>
          列非表示
        </div>
        <div className={styles.popoverHeaderItem} onClick={handleUnhideAll}>
          全て例を表示
        </div>
        <div className={styles.popoverHeaderItem} onClick={handleUnhideColumn}>
          例を非表示
        </div>
      </div>
    </>
  )
}
