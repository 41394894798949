import UrlConstant from 'F_UTILS/Url/UrlConstant'
import axiosClientSchedule from './axiosClientSchedule'

const ProjectApi = {
  async GetActiveProjectsByPage(pageNumber, pageSize) {
    const url = `/projects?pageNumber=${pageNumber}&pageSize=${pageSize}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  async GetActiveSharedProjectsByPage(pageNumber, pageSize) {
    const url = `/projects/shared?pageNumber=${pageNumber}&pageSize=${pageSize}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },
  async GetAllProjectName() {
    const url = `/projects/name-list`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  async GetAllSharedProjectName() {
    const url = `/projects/shared/name-list`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  async CreateProject(params) {
    console.log('🚀 ~ file: ProjectApi.js:29 ~ CreateProject ~ params:', params)
    const url = `/projects`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url,
      data: params
    })
  },

  async UpdateProjectNameById(projectId, data) {
    const url = `/projects/${projectId}`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  },

  async downloadExcel(projectId) {
    const url = `/versions/${projectId}/download`
    return axiosClientSchedule.request({
      baseURL: UrlConstant.SCHEDULE_EXCEL_API_URL,
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
  }
}

export default ProjectApi
