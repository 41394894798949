import { createSlice } from '@reduxjs/toolkit'

export const selectedTaskSlice = createSlice({
  name: 'selectedTask',
  initialState: [],
  reducers: {
    setSelectedTaskId: (state, action) => {
      return action.payload
    },
    pushSelectedTaskIdToState: (state, action) => {
      const newState = [...state]
      action.payload.forEach((id) => {
        if (!newState.includes(id)) {
          newState.push(id)
        }
      })
      return newState
    }
  }
})
const { actions, reducer } = selectedTaskSlice
export default reducer
