import { DialogBase } from 'A_SHARED_COMPONENT/DialogComponent'
import { Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Scrollbar } from 'smooth-scrollbar-react'
import ProjectApi from 'B_API/scheduleApis/ProjectApi.js'
import RadioButton from 'A_SHARED_COMPONENT/RadioButton/index.js'
import VersionApi from 'B_API/scheduleApis/VersionApi.js'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils.js'

export default function MoveToComponent({ listSelectedProjectID, listSelectedProjectId, open, setOpenMoveTo }) {
  const [selectedFolder, setSelectedFolder] = useState('My Project')
  const [listProjects, setListProject] = useState([])
  const checkboxRef = useRef(null)
  const [selectedProjects, setSelectedProjects] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedFolder !== 'My Project') {
          const response = await ProjectApi.GetAllSharedProjectName()
          // const response = await ProjectApi.GetActiveSharedProjectsByPage(1, 100)
          setListProject(
            response.data.filter(
              (x) => x.projectName !== '新しいプロジェクト' && !listSelectedProjectID.includes(x.projectId)
            )
          )
        } else {
          const response = await ProjectApi.GetAllProjectName()
          // const response = await ProjectApi.GetActiveProjectsByPage(1, 100)
          setListProject(
            response.data.filter(
              (x) => x.projectName !== '新しいプロジェクト' && !listSelectedProjectID.includes(x.projectId)
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [selectedFolder, listSelectedProjectID])

  const handleSelectFolderChange = (value) => {
    setSelectedFolder(value)
  }
  const handleCheckboxChange = (e, project) => {
    if (project === 'newProjectTTD230822') {
      setSelectedProjects('newProjectTTD230822')
      return
    }
    setSelectedProjects(project.projectId)
  }
  const handleOK = async () => {
    const isCopyProject = checkboxRef.current.checked
    let toProjectId
    if (selectedProjects === null || selectedProjects === 'newProjectTTD230822') {
      toProjectId = null
    } else {
      toProjectId = selectedProjects
    }
    const data = {
      toProjectId: toProjectId,
      versionIds: listSelectedProjectId,
      sendToSharedProjectList: selectedFolder !== 'My Project',
      createCopy: isCopyProject
    }
    try {
      const response = await VersionApi.SendVersionToOtherProject(data)
      if (response.status === 204) {
        if (isCopyProject) {
          ToastSuccess('バージョンのコピーに成功しました。')
        } else {
          ToastSuccess('バージョンの移動に成功しました。')
        }
      } else {
        if (isCopyProject) {
          ToastError('バージョンのコピーに失敗しました。')
        } else {
          ToastError('バージョンの移動に失敗しました。')
        }
      }
    } catch (error) {
      if (isCopyProject) {
        ToastError('バージョンのコピーに失敗しました。')
      } else {
        ToastError('バージョンの移動に失敗しました。')
      }
    }
    setOpenMoveTo(false)
  }
  return (
    <>
      <DialogBase open={open} handleOK={handleOK} handleClose={() => setOpenMoveTo(false)} title={'移動'} width={700}>
        <div className=' flex-col flex gap-3 items-center w-[600px]'>
          <Select
            value={selectedFolder}
            style={{ width: 550, fontWeight: 600 }}
            onChange={handleSelectFolderChange}
            options={[
              { value: 'My Project', label: '個人プロジェクト' },
              { value: 'Shared project', label: '共有プロジェクト' }
            ]}
            dropdownStyle={{ zIndex: '1000002' }}
          />
          <div className=' w-full flex items-center flex-col gap-3 pl-3 pr-1 py-3 h-[290px] overflow-y-auto border rounded-md justify-start overflow-x-hidden Scrollbar-hidden-x'>
            <Scrollbar
              plugins={{
                overscroll: {
                  effect: 'glow'
                }
              }}
              className='w-full'
            >
              <RadioButton
                id={'新しいプロジェクト'}
                name={'project'}
                handleCheckBoxChange={(e) => handleCheckboxChange(e, 'newProjectTTD230822')}
              ></RadioButton>
              {listProjects.map((project) => (
                <RadioButton
                  id={project.projectName}
                  name={'project'}
                  key={project.projectId}
                  handleCheckBoxChange={(e) => handleCheckboxChange(e, project)}
                ></RadioButton>
              ))}
            </Scrollbar>
          </div>
          <div className='text-left w-full flex items-center gap-2'>
            <input type='checkbox' id='createCopy' className='h-5 w-5 cursor-pointer' ref={checkboxRef}></input>
            <label htmlFor='createCopy' className='text-base font-medium leading-[20px] m-0 select-none cursor-pointer'>
              コピーを作成
            </label>
          </div>
        </div>
      </DialogBase>
    </>
  )
}
