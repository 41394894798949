import React, { useEffect, useRef, useState } from 'react'
import './MainCreateLayer.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { WriteDataToTableLayerWhenLoadFile } from '../../../../F_UTILS/CSVCreateLayerUtils'
import ColorSelectorComponent from '../../../../A_SHARED_COMPONENT/ColorSelectorComponent'
import { ListAllColorsIndex } from '../../../../F_UTILS/Constants/ListColorsIndex'
import HeaderComponent from '../HeaderComponent'
import TableComponent from '../TableComponent'
import FooterComponent from '../FooterComponent'

export default function MainCreateLayer({
  setViewInfor,
  setProgressIsRunning,
  setShowDialog,
  setMessageProgress,
  setMessageCompleted
}) {
  const [listNames, setListNames] = useState([])
  const initLayer = useRef({})
  const [originName, setOriginName] = useState('')
  const [showColor, setShowColor] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const windowCurrentWidth = window.innerWidth
  const [nodeColor, setNodeColor] = useState(null)
  const [nodeIndex, setNodeIndex] = useState(null)

  console.log('listNames', listNames)
  console.log('component rendering ...')
  useEffect(() => {
    const deleteRows = (e) => {
      console.log(e)
      let index = undefined
      if (e.target.parentNode.localName === 'i') {
        index = e.target.parentNode.parentNode.parentNode.rowIndex
      } else {
        index = e.target.parentNode.parentNode.parentNode.rowIndex
      }
      var table = document.getElementById('contents-table')
      table.deleteRow(index)
    }

    const clickRowEvent = (e) => {
      let oldLayerName = null
      let colorIndexEle = null
      if (e.target.parentNode.localName === 'tr') {
        oldLayerName = e.target.parentNode.getElementsByTagName('td')[0]
        colorIndexEle = e.target.parentNode.getElementsByTagName('td')[2]
        setNodeColor(e.target.parentNode.getElementsByTagName('td')[1])
        setNodeIndex(colorIndexEle)
        if (oldLayerName.innerText !== '') {
          const buttonRow = e.target.parentNode.getElementsByTagName('td')[3]
          buttonRow.innerHTML = '<button class="button-delete"><i class="fa-regular fa-trash-can"></i></button>'
          buttonRow.querySelector('button').addEventListener('click', deleteRows)
        }
      } else {
        oldLayerName = e.target.parentNode.getElementsByTagName('td')[0]
        colorIndexEle = e.target.parentNode.getElementsByTagName('td')[2]
        setNodeColor(e.target.parentNode.getElementsByTagName('td')[1])
        setNodeIndex(colorIndexEle)
        if (oldLayerName.innerText !== '') {
          const buttonRow = e.target.parentNode.getElementsByTagName('td')[3]
          buttonRow.innerHTML = '<button class="button-delete">x</button>'
          buttonRow.querySelector('button').addEventListener('click', deleteRows)
        }
      }

      //add lastRow
      var table = document.getElementById('contents-table')
      var rowCount = table.rows.length
      var rows = document.querySelectorAll('#contents-table tr')

      if (rows[rowCount - 1].childNodes[0].innerHTML !== '') {
        let lastRow = `<td contenteditable="true" style="height:31px;"}}></td> 
        <td class="clickAble" style="border-right:none;"></td> 
        <td class="clickAble" style="border-left: none; border-right: none"></td>
        <td  style="border-left: none"></td>`
        const trElement = document.createElement('tr')
        trElement.innerHTML = lastRow
        document.querySelector('tbody').append(trElement)
        const listElements = trElement.querySelectorAll('.clickAble')
        listElements.forEach((e) => e.addEventListener('click', clickRowEvent))
      }

      if (oldLayerName?.innerText !== '') {
        var layer = listNames.find((element) => element.LayerName === oldLayerName.innerText.toString())
        console.log(listNames)
        if (layer?.isExitLayer) {
          toast.error('指定された新しい画層名が他と重複しています。もう一度チェックしてください。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
          return
        }
        setShowColor(true)
        let colorIndex = ''

        if (colorIndexEle?.innerText) {
          colorIndex = ListAllColorsIndex.find((element) => element.index === colorIndexEle.innerText.toString())
        }
        if (colorIndexEle.innerText.includes(',')) {
          initLayer.current = {
            LayerName: oldLayerName.innerText,
            index: colorIndexEle.innerText,
            color: `rgb(${colorIndexEle.innerText})`
          }
        } else {
          initLayer.current = {
            LayerName: oldLayerName.innerText,
            index: colorIndexEle.innerText,
            color: colorIndex?.color || ''
          }
        }
      }
    }

    const elements = document.getElementsByClassName('clickAble')
    for (let index = 0; index < elements.length; index++) {
      elements[index].addEventListener('click', clickRowEvent)
    }

    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll('td')
      for (let index = 0; index < cols.length; index++) {
        const element = cols[index]
        const brEle = element.querySelectorAll('br')
        if (brEle?.length) {
          brEle?.forEach((e) => element.removeChild(e))
        }
      }
    }

    const buttonDeletes = document.getElementsByClassName('button-delete')
    for (let index = 0; index < buttonDeletes.length; index++) {
      buttonDeletes[index].addEventListener('click', deleteRows)
    }

    return () => {
      const elements = document.getElementsByClassName('clickAble')
      for (let index = 0; index < elements.length; index++) {
        elements[index].removeEventListener('click', clickRowEvent)
      }

      const buttonDeletes = document.getElementsByClassName('button-delete')
      for (let index = 0; index < buttonDeletes.length; index++) {
        buttonDeletes[index].removeEventListener('click', deleteRows)
      }
    }
  }, [listNames])

  return (
    <>
      <div className='main--windows'>
        {showColor && (
          <ColorSelectorComponent
            initLayer={initLayer.current}
            setShowComponent={setShowColor}
            NodeColor={nodeColor}
            NodeIndex={nodeIndex}
          ></ColorSelectorComponent>
        )}
        <ToastContainer style={{ width: '650px' }} />

        <HeaderComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setShowDialog={setShowDialog}
          setMessageCompleted={setMessageCompleted}
          setListNames={setListNames}
          setOriginName={setOriginName}
          setViewInfor={setViewInfor}
          setBtnDisable={setBtnDisable}
        ></HeaderComponent>
        <div className='file--name'>
          <label>ファイル名: </label>
        </div>
        <TableComponent rowCount={windowCurrentWidth > 1919 ? 22 : windowCurrentWidth > 1365 ? 10 : 9}></TableComponent>
        <FooterComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setShowDialog={setShowDialog}
          setMessageCompleted={setMessageCompleted}
          setListNames={setListNames}
          ListNames={listNames}
          setViewInfor={setViewInfor}
          originName={originName}
          btnDisable={btnDisable}
        ></FooterComponent>
      </div>
    </>
  )
}
