import { ListAllColorsIndex } from '../F_UTILS/Constants/ListColorsIndex'

function downloadCSV(csv, filename) {
  var csvFile
  var downloadLink
  const textEncoder = new TextEncoder('windows-1252')
  var csvContentEncoded = textEncoder.encode([csv])
  // CSV file
  csvFile = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContentEncoded], {
    type: 'text/csv;charset=utf-8'
  })

  // Download link
  downloadLink = document.createElement('a')

  // File name
  downloadLink.download = filename

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile)

  // Hide download link
  downloadLink.style.display = 'none'

  // Add the link to DOM
  document.body.appendChild(downloadLink)

  // Click download link
  downloadLink.click()
}

export function RemoveColNewLayerName() {
  var table = document.getElementById('contents-table')
  var rowCount = table.rows.length
  var rows = document.querySelectorAll('#contents-table tr')
  for (let i = 0; i < rowCount; i++) {
    var cols = rows[i].querySelectorAll('td, th')
    cols[3].innerHTML = ''
    cols[4].innerHTML = ''
    cols[5].innerHTML = ''
  }
}

export function WriteDataToTableLayerWhenLoadFile(data) {
  console.log('table render...', data)
  if (data.length === 0) {
    return
  }
  var table = document.getElementById('contents-table')
  var rowCount = table.rows.length

  if (data.length > rowCount) {
    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < rowCount; i++) {
      var cols = rows[i].querySelectorAll('td, th')
      cols[3].setAttribute('contenteditable', true)
      cols[3].classList.remove('error-cell')
      cols[3].classList.remove('warning-cell')
      cols[0].innerText = data[i].LayerName
      if (data[i]?.IndexColor && data[i].IndexColor.includes(',')) {
        cols[1].innerHTML = `<div style="background-color:rgb(${data[i].IndexColor});height: 18px;width: 18px;border: 0.5px solid black; "></div>`

        cols[2].innerText = data[i].IndexColor.toString().replace('\r', '')
      } else {
        const colorRgb = ListAllColorsIndex.find((ele) => ele.index === data[i].IndexColor.toString().replace('\r', ''))
        if (colorRgb)
          cols[1].innerHTML = `<div style="background-color:${colorRgb.color};height: 18px;width: 18px;border: 0.5px solid black; "></div>`
        cols[2].innerText = data[i].IndexColor.toString().replace('\r', '')
      }
      if (data[i]?.NewLayerName) {
        cols[3].innerText = data[i].NewLayerName
      } else {
        cols[3].innerText = ''
      }

      if (data[i]?.NewIndexColor) {
        if (data[i].NewIndexColor.includes(',')) {
          cols[4].innerHTML = `<div style="background-color:rgb(${data[i]?.NewIndexColor});height: 18px;width: 18px;border: 0.5px solid black; "></div>`
          cols[5].innerText = data[i].NewIndexColor.toString().replace('\r', '')
        } else {
          const colorRgbNew = ListAllColorsIndex.find(
            (ele) => ele.index === data[i].NewIndexColor.replace('\r', '').toString()
          )
          if (colorRgbNew) {
            cols[4].innerHTML = `<div style="background-color:${colorRgbNew.color};height: 18px;width: 18px;border: 0.5px solid black; "></div>`
          }
          cols[5].innerText = data[i].NewIndexColor.toString().replace('\r', '')
        }
      } else {
        cols[4].innerHTML = ''
        cols[5].innerText = ''
      }

      if (data[i].NumberOfObject !== undefined) {
        cols[6].innerText = data[i]?.NumberOfObject.toString()
      }
    }

    for (let index = rowCount; index < data.length; index++) {
      if (data[index]) {
        let content = ''
        let colorRgb = ''
        if (data[index].IndexColor.includes(',')) {
          colorRgb = `rgb(${data[index].IndexColor})`
          content = `<td style="cursor: pointer">${data[index].LayerName}</td> 
            <td style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td style="border-left: none;">${data[index].IndexColor.toString().replace('\r', '')}</td> 
            <td class='editAbleRow' contenteditable='true'>${data[index].NewLayerName}</td> 
            <td class="clickAble" style="border-right: none; cursor: pointer"></td> 
            <td class="clickAble" style="border-left: none; cursor: pointer"></td>`
          if (data[index].NumberOfObject !== undefined) {
            content = content + `<td style="text-align: center;">${data[index]?.NumberOfObject.toString()}</td>`
          } else {
            content = content + `<td style="text-align: center;">0</td>`
          }
        } else {
          colorRgb = ListAllColorsIndex.find(
            (ele) => ele.index === data[index].IndexColor.toString().replace('\r', '')
          )?.color
          content = `<td style="cursor: pointer">${data[index].LayerName}</td> 
            <td style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td style="border-left: none;">${data[index].IndexColor.toString().replace('\r', '')}</td> 
            <td class='editAbleRow' contenteditable='true'>${data[index].NewLayerName}</td> 
            <td class="clickAble" style="border-right: none; cursor: pointer"></td> 
            <td class="clickAble" style="border-left: none; cursor: pointer"></td>`
          if (data[index].NumberOfObject !== undefined) {
            content = content + `<td style="text-align: center;">${data[index]?.NumberOfObject.toString()}</td>`
          } else {
            content = content + `<td style="text-align: center;">0</td>`
          }
        }

        if (data[index].NewIndexColor) {
          if (data[index].NewIndexColor.includes(',')) {
            const colorRgbNew = `rgb(${data[index].NewIndexColor})`
            content = `<td style="cursor: pointer">${data[index].LayerName}</td> 
            <td style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td style="border-left: none;">${data[index].IndexColor.toString().replace('\r', '')}</td> 
            <td class='editAbleRow' contenteditable='true'>${data[index].NewLayerName}</td> 
            <td class="clickAble" style="border-right: none; cursor: pointer;"><div style="background-color:${colorRgbNew};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td class="clickAble" style="border-left: none; cursor: pointer">${data[
              index
            ].NewIndexColor.toString().replace('\r', '')}</td>`

            if (data[index].NumberOfObject !== undefined) {
              content = content + `<td style="text-align: center;">${data[index]?.NumberOfObject.toString()}</td>`
            } else {
              content = content + `<td style="text-align: center;">0</td>`
            }
          } else {
            const colorRgbNew = ListAllColorsIndex.find(
              (ele) => ele.index === data[index].NewIndexColor.toString().replace('\r', '')
            )
            content = `<td style="cursor: pointer">${data[index].LayerName}</td> 
            <td style="border-right:none;"><div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td style="border-left: none;">${data[index].IndexColor.toString().replace('\r', '')}</td> 
            <td class='editAbleRow' contenteditable='true'>${data[index].NewLayerName}</td> 
            <td class="clickAble" style="border-right: none; cursor: pointer;"><div style="background-color:${
              colorRgbNew.color
            };height: 18px;width: 18px;border: 0.5px solid black; "></div></td> 
            <td class="clickAble" style="border-left: none; cursor: pointer">${data[
              index
            ].NewIndexColor.toString().replace('\r', '')}</td>`

            if (data[index].NumberOfObject !== undefined) {
              content = content + `<td style="text-align: center;">${data[index]?.NumberOfObject.toString()}</td>`
            } else {
              content = content + `<td style="text-align: center;">0</td>`
            }
          }
        }
        const trElement = document.createElement('tr')

        trElement.innerHTML = content
        if (trElement.innerText !== '') {
          document.querySelector('tbody').append(trElement)
        }
      }
    }
  } else {
    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < data.length; i++) {
      var cols = rows[i].querySelectorAll('td, th')
      cols[3].setAttribute('contenteditable', true)
      cols[3].classList.remove('error-cell')
      cols[3].classList.remove('warning-cell')
      if (data[i]) {
        cols[0].innerText = data[i].LayerName
        if (data[i]?.IndexColor.includes(',')) {
          cols[1].innerHTML = `<div style="background-color:rgb(${data[i]?.IndexColor});height: 18px;width: 18px;border: 0.5px solid black; "></div>`
          cols[2].innerText = data[i].IndexColor.toString().replace('\r', '')
        } else {
          const colorRgb = ListAllColorsIndex.find(
            (ele) => ele.index === data[i].IndexColor.toString().replace('\r', '')
          )
          if (colorRgb)
            cols[1].innerHTML = `<div style="background-color:${colorRgb.color};height: 18px;width: 18px;border: 0.5px solid black; "></div>`
          cols[2].innerText = data[i].IndexColor.toString().replace('\r', '')
        }

        if (data[i].NewLayerName) {
          cols[3].innerText = data[i].NewLayerName
        } else {
          cols[3].innerText = ''
        }
        if (data[i].NewIndexColor) {
          if (data[i].NewIndexColor.includes(',')) {
            cols[4].innerHTML = `<div style="background-color:rgb(${data[i].NewIndexColor});height: 18px;width: 18px;border: 0.5px solid black; "></div>`
            cols[5].innerText = data[i].NewIndexColor.toString().replace('\r', '')
          } else {
            const colorRgbNew = ListAllColorsIndex.find(
              (ele) => ele.index === data[i].NewIndexColor.replace('\r', '').toString()
            )
            if (colorRgbNew)
              cols[4].innerHTML = `<div style="background-color:${colorRgbNew.color};height: 18px;width: 18px;border: 0.5px solid black; "></div>`
            cols[5].innerText = data[i].NewIndexColor.toString().replace('\r', '')
          }
        } else {
          cols[4].innerHTML = ''
          cols[5].innerText = ''
        }

        if (data[i].NumberOfObject !== undefined) {
          cols[6].innerText = data[i]?.NumberOfObject.toString()
        }
      }
    }
    if (rowCount !== data.length) {
      for (let index = rowCount - 1; index >= data.length; index--) {
        table.deleteRow(index)
      }
    }
  }
}

export function ExportTableToCSV(filename) {
  var csv = []
  const preWord = document.getElementById('pre').value
  const subWord = document.getElementById('sub').value
  var data = []

  //Nếu có prefix và subfix
  if (preWord !== '' || subWord !== '') {
    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll('td, th')
      cols[3].classList.remove('error-cell')
      cols[3].classList.remove('warning-cell')
      const valueColOldName = cols[0].innerText
      const valueColOldColor = cols[2].innerText
      const valueColNewName = cols[3].innerText
      const valueColNewColor = cols[5].innerText
      // console.log("valueColNewColor", valueColNewColor);
      if (valueColNewName !== '' && valueColOldName !== '' && valueColNewColor !== '') {
        data.push({
          oldName: valueColOldName,
          oldColor: valueColOldColor,
          newName: preWord + valueColNewName + subWord,
          newColor: valueColNewColor
        })
      } else {
        if (valueColOldName !== '') {
          data.push({
            oldName: valueColOldName,
            oldColor: valueColOldColor,
            newName: preWord + valueColNewName + subWord,
            newColor: valueColOldColor
          })
        }
      }
    }
  } else {
    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll('td, th')
      cols[3].classList.remove('error-cell')
      cols[3].classList.remove('warning-cell')
      const valueColOldName = cols[0].innerText
      const valueColOldColor = cols[2].innerText
      const valueColNewName = cols[3].innerText
      const valueColNewColor = cols[5].innerText
      if (valueColOldName !== '') {
        data.push({
          oldName: valueColOldName,
          oldColor: valueColOldColor,
          newName: valueColNewName,
          newColor: valueColNewColor
        })
      }
    }
  }
  console.log('data', data)
  for (let index = 0; index < data.length; index++) {
    const element = data[index]
    let content = element.oldName + `,"${element.oldColor}"`

    if (element?.newName) {
      content = content + `,${element.newName}`
    }

    if (element?.newColor) {
      content = content + `,"${element.newColor}"`
    }
    csv.push(content)
  }
  // Download CSV file
  downloadCSV(csv.join('\n'), filename)
}
