import React, { useEffect, useState } from 'react'
import styles from './BackgroundTableRow.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import TextInput from 'A_SHARED_COMPONENT/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeBackgroundColorMonth,
  removeColorInBackgroundColorList,
  renameBackgroundColorList
} from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'
import { showModalSlice } from 'C_REDUX_STORE/scheduleTool/showModalSlice'
import { toast } from 'react-toastify'
import { ToastError } from 'F_UTILS/ToastUtils'

export default function BackgroundTableRow({ colorObject }) {
  const [name, setName] = useState('')
  const showModal = useSelector((state) => state.showModal)
  const backgroundColors = useSelector((state) => state.versionSetting.current.backgroundColors)
  const numberOfMonths = useSelector((state) => state.versionSetting.current.numberOfMonths)
  const dispatch = useDispatch()

  useEffect(() => {
    if (colorObject?.name) {
      setName(colorObject?.name)
    } else {
      setName(showModal?.name)
    }
  }, [colorObject])

  const handleDeleteColor = async () => {
    await dispatch(removeColorInBackgroundColorList(colorObject.id))
  }

  const handleSelectColor = (event) => {
    // sửa color
    if (name === '') {
      return
    }
    if (colorObject?.id === undefined) {
      dispatch(
        showModalSlice.actions.ShowWindowAddColor({
          name: 'VersionSettingComponent',
          event: event,
          nameColor: name,
          isTaskColor: false
        })
      )
    } else {
      dispatch(
        showModalSlice.actions.ShowWindow({
          name: 'VersionSettingComponent',
          event: event,
          id: colorObject.id,
          isTaskColor: false
        })
      )
    }
  }

  const handleTextBoxNameChange = async (newName) => {
    if (newName === '') {
      return
    }
    if (newName === colorObject.name) {
      return
    }
    const index = backgroundColors.findIndex((x) => x.name === newName)
    if (index !== -1) {
      ToastError('名は既に存在します。もう一度やり直してください。')

      return
    }
    if (colorObject?.id !== undefined) {
      await dispatch(renameBackgroundColorList({ id: colorObject.id, name: newName }))
    }
    setName(newName)
  }

  const validateMonth = (month) => {
    const listChar = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '-']
    for (var i = 0; i < month.length; i++) {
      if (!listChar.includes(month.charAt(i))) {
        return false
      }
    }
    var listMonthGroup = month.split(',')
    listMonthGroup.forEach((group) => {
      var listMonth = group.split('-')
      //TODO validate
      if (listMonth.length === 1 && group.includes('-')) {
        return false
      }
      if (listMonth.length !== 1 && listMonth.length !== 2) {
        return false
      }
      if (listMonth.length === 2) {
        const start = Number(listMonth[0])
        const end = Number(listMonth[1])
        if (start <= 0 || end <= 0 || start > end || end > numberOfMonths) {
          return false
        }
      }
      if (listMonth.length === 1) {
        const start = Number(listMonth[0])
        if (start > numberOfMonths) {
          return false
        }
      }
    })
    return true
  }

  const handleTextBoxMonthChange = async (month) => {
    if (!validateMonth(month)) {
      ToastError('入力された月数が間違っています。もう一度確認てください。')

      return
    }
    await dispatch(changeBackgroundColorMonth({ id: colorObject.id, months: month }))
  }
  return (
    <>
      <tr>
        <td style={{ padding: '5px' }}>
          <TextInput
            value={name || ''}
            style={{ height: '20px', borderRadius: '3px', padding: '5px', marginLeft: '3px', fontSize: '14px' }}
            disable={colorObject?.isDefault ? true : false}
            onChange={handleTextBoxNameChange}
          ></TextInput>
        </td>
        <td style={{ padding: '0px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            className='mx-3'
          >
            <button
              type='button'
              style={{
                backgroundColor: colorObject?.code,
                height: '20px',
                width: '75px',
                borderRadius: '3px'
              }}
              onClick={(event) => handleSelectColor(event)}
            ></button>
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={handleDeleteColor}
              opacity={colorObject?.isDefault ? 0 : 1}
              className={styles.deleteIcon}
            ></FontAwesomeIcon>
          </div>
        </td>
      </tr>
    </>
  )
}
