import { createSlice } from '@reduxjs/toolkit'

export const showModalSlice = createSlice({
  name: 'month',
  initialState: {
    isShow: false,
    isTaskColor: true,
    windowName: '',
    id: -1,
    event: null,
    name: ''
  },
  reducers: {
    ShowWindow: (state, action) => {
      state.isShow = true
      state.isTaskColor = true
      state.windowName = action.payload.name
      state.id = -1
      if (action.payload?.id) {
        state.id = action.payload?.id
      }
      if (action.payload?.event) {
        state.event = action.payload?.event
      }
      if (action.payload?.isTaskColor !== undefined) {
        state.isTaskColor = action.payload?.isTaskColor
      }
    },
    ShowWindowAddColor: (state, action) => {
      console.log(action)
      state.isShow = true
      state.isTaskColor = true
      state.windowName = action.payload.name
      state.name = ''
      state.id = -1
      if (action.payload?.id) {
        state.id = action.payload?.id
      }
      if (action.payload?.event) {
        state.event = action.payload?.event
      }
      if (action.payload?.nameColor) {
        state.name = action.payload?.nameColor
      }
      if (action.payload?.isTaskColor !== undefined) {
        state.isTaskColor = action.payload?.isTaskColor
      }
    },
    HideWindow: (state, action) => {
      state.isTaskColor = true
      state.isShow = false
      state.windowName = ''
      state.id = -1
      state.event = null
      state.name = ''
    },
    HideWindowChild: (state, action) => {
      state.isTaskColor = true
      state.id = -1
      state.event = null
      state.name = ''
    }
  }
})

const { actions, reducer } = showModalSlice
export default reducer
