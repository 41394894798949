import React, { useEffect } from 'react'
import ViewListModal from './components/ViewListModal'
import ViewSettingModal from './components/ViewSettingModal'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import VersionApi from 'B_API/scheduleApis/VersionApi'

export default function CreateViewModal({ setShowCreateView }) {
  const [showViewList, setShowViewList] = React.useState(true)
  const [view, setView] = React.useState(undefined)
  const [tasks, setTasks] = React.useState([])

  const currentVersion = useSelector((state) => state.currentVersion)
  const currentSetting = useSelector((state) => state.versionSetting.current)
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataSetting = {
          columnWidth: currentSetting.columnWidth || -1,
          amplifiedFactor: currentSetting.amplifiedFactor || -1
        }
        const versionTasksDetail = await VersionApi.GetVersionTaskDetails(currentVersion.versionId, dataSetting)
        setTasks(versionTasksDetail.data)
      } catch (error) {
        navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {showViewList ? (
        <ViewListModal
          setShowViewList={setShowViewList}
          setView={setView}
          setShowCreateView={setShowCreateView}
        ></ViewListModal>
      ) : (
        <ViewSettingModal
          view={view}
          tasks={tasks}
          setShowViewList={setShowViewList}
          setShowCreateView={setShowCreateView}
        ></ViewSettingModal>
      )}
    </>
  )
}
