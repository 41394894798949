import React from 'react'
import styles from './TextInputForm.module.scss'

export default function TextInputForm({ value, onChange, style, disable, register, name, rule: rules, errorMassage }) {
  const inputRef = React.useRef(null)
  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur()
    }
  }
  return (
    <input
      type='text'
      ref={inputRef}
      style={{ ...style, borderColor: errorMassage ? 'red' : '' }}
      className={styles.input}
      disabled={disable}
      onKeyDown={handlePressEnter}
      onBlur={(e) => {
        onChange(e.target.value)
      }}
      {...register(name, rules)}
    />
  )
}
