import { nanoid } from 'nanoid'
import { getMaxTaskName } from './TaskUtils'

const sortTasks = (taskA, taskB) => {
  const orderA = taskA.displayOrder || Number.MAX_VALUE
  const orderB = taskB.displayOrder || Number.MAX_VALUE
  if (orderA > orderB) {
    return 1
  } else if (orderA < orderB) {
    return -1
  } else {
    return 0
  }
}

export const DeleteGroup = (task, tasks) => {
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  let newTasks = []
  let countDisplayOrder = 1
  for (let index = 0; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    if (element.id !== task.id && element.groupId !== task.id) {
      element.displayOrder = countDisplayOrder
      countDisplayOrder++
      newTasks.push(element)
    }
  }
  newTasks = newTasks.sort(sortTasks)
  return newTasks
}

export const AddTaskInGroup = (currentGroup, tasks, defaultColorId) => {
  tasks.sort(sortTasks)
  let groupId = currentGroup.id
  let taskDisplayOrders = currentGroup.displayOrder
  let copyTasks = [...tasks]
  let countChildTask = copyTasks.filter((x) => x.groupId === groupId).length
  let newTask = {
    start: 0,
    end: 70,
    duration: 30,
    name: '新しいタスク' + getMaxTaskName(tasks),
    id: nanoid(),
    progress: 100,
    dependencies: [],
    type: 'task',
    groupId: groupId,
    colorId: defaultColorId,
    displayOrder: taskDisplayOrders + countChildTask + 1,
    groups: 1
  }
  for (let index = taskDisplayOrders + countChildTask; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    element.displayOrder = element.displayOrder + 1
  }

  copyTasks.push(newTask)
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const AddGroupAfter = (currentGroup, tasks) => {
  tasks.sort(sortTasks)
  let groupId = currentGroup.id
  let taskDisplayOrders = currentGroup.displayOrder
  let copyTasks = [...tasks]
  let countChildTask = copyTasks.filter((x) => x.groupId === groupId).length

  let newTask = {
    start: 0,
    end: 70,
    duration: 30,
    name: '新グループ' + getMaxGroupName(tasks),
    id: nanoid(),
    type: 'project',
    hideChildren: false,
    displayOrder: taskDisplayOrders + countChildTask + 1
  }
  for (let index = taskDisplayOrders + countChildTask; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    element.displayOrder = element.displayOrder + 1
  }

  copyTasks.push(newTask)
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const AddGroupBefore = (currentGroup, tasks) => {
  let taskDisplayOrders = currentGroup.displayOrder
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]

  let newTask = {
    start: 0,
    end: 70,
    duration: 30,
    name: '新グループ' + getMaxGroupName(tasks),
    id: nanoid(),
    type: 'project',
    hideChildren: false,
    displayOrder: taskDisplayOrders
  }
  for (let index = taskDisplayOrders - 1; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    element.displayOrder = element.displayOrder + 1
  }

  copyTasks.push(newTask)
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const AddNewGroup = (tasks) => {
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  let taskDisplayOrders = 1

  if (tasks.length !== 0) {
    taskDisplayOrders = tasks[tasks.length - 1].displayOrder + 1
  }

  let newTask = {
    start: 0,
    end: 70,
    duration: 30,
    name: '新グループ' + getMaxGroupName(tasks),
    id: nanoid(),
    type: 'project',
    hideChildren: false,
    displayOrder: taskDisplayOrders
  }

  copyTasks.push(newTask)
  copyTasks.sort(sortTasks)
  return copyTasks
}

// export const MoveGroupUp = (currentGroup, tasks) => {
//   console.log('move group up')
//   tasks.sort(sortTasks)
//   let copyTasks = [...tasks]
//   let currentGroupIndex = copyTasks.indexOf(currentGroup)
//   let subArr = copyTasks.slice(0, currentGroupIndex)
//   if (subArr.length === 0) {
//     return copyTasks
//   }
//   let lastGroup = subArr.filter((x) => x.type === 'project').at(-1)
//   if (!lastGroup) {
//     return copyTasks
//   } else {
//     currentGroup.displayOrder = lastGroup.displayOrder

//     //Change Display Order for project's task
//     let currentGroupChildTask = copyTasks.filter((x) => x.groupId === currentGroup.id)
//     let lastProjectChildTask = copyTasks.filter((x) => x.groupId === lastGroup.id)

//     for (let index = 0; index < currentGroupChildTask.length; index++) {
//       const element = currentGroupChildTask[index]
//       element.displayOrder = index + currentGroup.displayOrder + 1
//     }

//     lastGroup.displayOrder = lastGroup.displayOrder + currentGroupChildTask.length + 1
//     for (let i = 0; i < lastProjectChildTask.length; i++) {
//       const element = lastProjectChildTask[i]
//       element.displayOrder = i + lastGroup.displayOrder + 1
//     }
//   }
//   copyTasks.sort(sortTasks)
//   console.log('🚀 ~ file: GroupTaskUtils.js:172 ~ MoveGroupUp ~ copyTasks:', copyTasks)
//   return copyTasks
// }

export const MoveGroupUp = (currentGroup, tasks) => {
  tasks.sort(sortTasks)
  const resultTasks = []
  let copyTasks = [...tasks]

  let listGroups = copyTasks.filter((x) => x.type === 'project')
  let currentGroupIndex = listGroups.findIndex((x) => x.id === currentGroup.id)
  const temp = listGroups[currentGroupIndex]
  listGroups[currentGroupIndex] = listGroups[currentGroupIndex - 1]
  listGroups[currentGroupIndex - 1] = temp
  let displayOrder = 1
  for (let index = 0; index < listGroups.length; index++) {
    const group = listGroups[index]
    group.displayOrder = displayOrder
    displayOrder++
    resultTasks.push(group)
    let childTasks = copyTasks.filter((x) => x.groupId === group.id)
    for (let i = 0; i < childTasks.length; i++) {
      const childTask = childTasks[i]
      childTask.displayOrder = displayOrder
      displayOrder++
      resultTasks.push(childTask)
    }
  }
  return resultTasks
}

export const MoveGroupDown = (currentGroup, tasks) => {
  tasks.sort(sortTasks)
  const resultTasks = []
  let copyTasks = [...tasks]

  let listGroups = copyTasks.filter((x) => x.type === 'project')
  let currentGroupIndex = listGroups.findIndex((x) => x.id === currentGroup.id)
  const temp = listGroups[currentGroupIndex]
  listGroups[currentGroupIndex] = listGroups[currentGroupIndex + 1]
  listGroups[currentGroupIndex + 1] = temp
  let displayOrder = 1
  for (let index = 0; index < listGroups.length; index++) {
    const group = listGroups[index]
    group.displayOrder = displayOrder
    displayOrder++
    resultTasks.push(group)
    let childTasks = copyTasks.filter((x) => x.groupId === group.id)
    for (let i = 0; i < childTasks.length; i++) {
      const childTask = childTasks[i]
      childTask.displayOrder = displayOrder
      displayOrder++
      resultTasks.push(childTask)
    }
  }
  return resultTasks
}

// export const MoveGroupDown = (currentGroup, tasks) => {
//   console.log('hello')
//   tasks.sort(sortTasks)

//   let copyTasks = [...tasks]
//   let currentGroupIndex = copyTasks.indexOf(currentGroup)
//   let subArr = copyTasks.slice(currentGroupIndex + 1)
//   if (subArr.length === 0) {
//     return copyTasks
//   }
//   let lastGroup = subArr.filter((x) => x.type === 'project')[0]
//   if (!lastGroup) {
//     return copyTasks
//   } else {
//     lastGroup.displayOrder = currentGroup.displayOrder

//     //Change Display Order for project's task
//     let currentGroupChildTask = copyTasks.filter((x) => x.groupId === currentGroup.id)
//     let lastProjectChildTask = copyTasks.filter((x) => x.groupId === lastGroup.id)

//     for (let index = 0; index < lastProjectChildTask.length; index++) {
//       const element = lastProjectChildTask[index]
//       element.displayOrder = index + lastGroup.displayOrder + 1
//     }
//     currentGroup.displayOrder = lastGroup.displayOrder + lastProjectChildTask.length + 1

//     for (let i = 0; i < currentGroupChildTask.length; i++) {
//       const e = currentGroupChildTask[i]
//       e.displayOrder = i + currentGroup.displayOrder + 1
//     }
//   }
//   copyTasks.sort(sortTasks)
//   return copyTasks
// }

function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  if (new_index === 0) {
    const temp = arr[1]
    arr[1] = arr[0]
    arr[0] = temp
  }
  return arr
}

export const MoveGroupTo = (currentGroup, toGroup, tasks) => {
  console.log('task')
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  let listProjects = copyTasks.filter((x) => x.type === 'project')
  let currentGroupIndex = listProjects.indexOf(currentGroup)
  let toGroupIndex = listProjects.indexOf(toGroup)
  listProjects = array_move(listProjects, currentGroupIndex, toGroupIndex)

  let countDisplayOrder = 1
  listProjects.forEach((element) => {
    element.displayOrder = countDisplayOrder
    countDisplayOrder++
    let childTasks = copyTasks.filter((x) => x.groupId === element.id)
    childTasks.forEach((task) => {
      task.displayOrder = countDisplayOrder
      countDisplayOrder++
    })
  })
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const SortTasksByProjects = (tasks) => {
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  let listProjects = copyTasks.filter((x) => x.type === 'project')

  let countDisplayOrder = 1
  listProjects.forEach((task) => {
    task.displayOrder = countDisplayOrder
    countDisplayOrder++
    let childTasks = copyTasks.filter((x) => x.groupId === task.id)
    childTasks.forEach((task) => {
      task.displayOrder = countDisplayOrder
      countDisplayOrder++
    })
  })
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const CheckCanMoveGroup = (currentTask, tasks, isUp) => {
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  let currentGroupIndex = copyTasks.indexOf(currentTask)
  let currentGroups = copyTasks.filter((x) => x.type === 'project')

  if (currentTask.displayOrder === 1 && isUp) {
    return false
  }

  if (currentGroups.at(-1).displayOrder === currentGroupIndex + 1 && !isUp) {
    return false
  }

  return true
}

const getMaxGroupName = (tasks) => {
  const listTaskNameNewTask = tasks.filter((t) => t.type === 'project' && t.name.includes('新グループ'))

  const listNumbers = listTaskNameNewTask.map((task) => {
    const number = task.name.replace('新グループ', '').replace(/ /g, '')
    if (isNaN(number)) {
      return 0
    } else {
      return Number(number)
    }
  })
  if (listNumbers.length === 0) {
    return 0
  } else {
    return Math.max(...listNumbers) + 1
  }
}
