import axiosClient from './axiosClient'

const ForgeAPI = {
  async GetViewerToken() {
    const url = `/forgeauthentication/viewertoken`
    return axiosClient.request({
      method: 'GET',
      url: url
    })
  },

  async GetWorkStatus(workId) {
    const url = `/forgedesignautomation/work-status/${workId}`
    return axiosClient.request({
      method: 'GET',
      url: url
    })
  },

  async GetTranslationStatus(jobId) {
    const url = `/jobs/${jobId}/translation-status`
    return axiosClient.request({
      method: 'GET',
      url: url
    })
  },

  async GetManifest(urn) {
    const url = `/jobs/get-manifest/${urn}`
    return axiosClient.request({
      method: 'GET',
      url: url
    })
  }
}

export default ForgeAPI
