import React from 'react'
import TextField from '@mui/material/TextField'

export default function TextInputComponent({ value, id, onChange }) {
  return (
    <>
      <TextField
        id={id}
        className='flex-1'
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '14px',
            paddingX: '8px',
            paddingY: '6px'
          }
        }}
        name={id}
        value={value}
        onChange={onChange}
      />
    </>
  )
}
