import React, { useEffect, useState } from 'react'
import Avatar from '../Avatar'
import './Header.css'
import { logout } from '../../C_REDUX_STORE/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { RouterConstants } from '../../F_UTILS/Constants/RouterConstants'

import avatar from '../../X_ASSETS/avatar.jpg'
import TokenServices from '../../B_API/TokenServices'
import AuthApi from '../../B_API/AuthAPI'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import SocketConstant from '../../F_UTILS/Constants/SocketConstant'

import convertFile from '../../X_ASSETS/ConvertFile.png'
import createLayer from '../../X_ASSETS/CreateLayer.png'
import dataExtraction from '../../X_ASSETS/DataExtraction.png'
import exportBlock from '../../X_ASSETS/ExportBlock.png'
import renameBlock from '../../X_ASSETS/RenameBlock.png'
import renameLayer from '../../X_ASSETS/RenameLayer.png'
import UrlConstant from '../../F_UTILS/Url/UrlConstant'
import { DialogNotification } from 'A_SHARED_COMPONENT/DialogComponent'

const Header = () => {
  const listRouters = [
    {
      img: renameBlock,
      name: 'RENAME BLOCKS',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_RENAME_BLOCKS}`
    },
    {
      img: createLayer,
      name: 'CREATE LAYERS',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_CREATE_LAYERS}`
    },
    {
      img: renameLayer,
      name: 'RENAME LAYERS',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_RENAME_LAYERS}`
    },
    {
      img: convertFile,
      name: 'CONVERT FILE',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_CONVERT_FILE}`
    },
    {
      img: exportBlock,
      name: 'EXPORT BLOCKS',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_EXPORT_BLOCKS}`
    },
    {
      img: dataExtraction,
      name: 'DATA EXTRACTION',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_DATA_EXTRACTION}`
    },
    {
      img: dataExtraction,
      name: 'SCHEDULING TOOL',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`
    },
    {
      img: dataExtraction,
      name: 'SCHEDULING TOOL',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_SCHEDULING_TOOL}`
    },
    {
      img: dataExtraction,
      name: 'SCHEDULING TOOL',
      version: 'バージョン 1.0.0',
      navi: `/${RouterConstants.MENU_PAGE}/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}`
    },
    {
      img: null,
      name: 'ツールリスト',
      version: null,
      navi: `/${RouterConstants.MENU_PAGE}`
    }
  ]

  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const info = listRouters.find((e) => location.pathname.includes(e.navi))
  const headerInfo = {
    toolName: info?.name,
    version: info?.version,
    src: info?.img
  }

  const handleLogOut = async () => {
    var refreshToken = TokenServices.getLocalRefreshToken()
    await AuthApi.Logout({ token: refreshToken, eventLogOut: false })
    const action = logout()
    await dispatch(action)
    navigate(`${RouterConstants.HOME_PAGE}`)
  }

  const Logout = async () => {
    var refreshToken = TokenServices.getLocalRefreshToken()
    await AuthApi.Logout({ token: refreshToken, eventLogOut: true })
    const action = logout()
    dispatch(action)
    navigate(`${RouterConstants.HOME_PAGE}`)
  }

  useEffect(() => {
    const options = {
      transport: 0,
      accessTokenFactory: () => TokenServices.getLocalAccessToken()
    }

    const connection = new HubConnectionBuilder()
      .withUrl(`${UrlConstant.CLOUD_APP_HOST_URL}/${SocketConstant.url}`, options)
      .configureLogging(LogLevel.Information)
      .build()

    connection.on(SocketConstant.logoutUser, (value) => {
      if (parseInt(value) === 1) {
        setShowLogoutConfirm(true)
      }
    })

    try {
      connection
        .start()
        .then(() => connection.invoke(SocketConstant.joinRoom, TokenServices.getUser()?.userId?.toString() || '0'))
        .then(() => console.log('SignalR Connected.'))
    } catch (err) {
      console.log(err)
    }

    return () => {
      if (connection) {
        connection.stop()
      }
    }
  }, [])

  const handleClickTest = () => {
    navigate(`/${RouterConstants.MENU_PAGE}`)
  }

  return (
    <>
      <header>
        <div className='toolName-wrapper'>
          {headerInfo.src && (
            <div className='image-boder'>
              <img src={headerInfo.src} alt={headerInfo.toolName} className='logo-image'></img>
            </div>
          )}
          {headerInfo.src ? (
            <h2 className='header-toolName'>{headerInfo.toolName}</h2>
          ) : (
            <h2 className='header-toolName' style={{ paddingBottom: '10px', paddingLeft: '10px' }}>
              {headerInfo.toolName}
            </h2>
          )}
          {headerInfo.version && <p className='header-toolVersion'>{headerInfo.version}</p>}
        </div>
        <nav>
          <div className='header--ClickAble header-nav' onClick={handleClickTest}>
            Menu
          </div>
          <div className='header-nav'>About</div>
          <div className='header-nav'>FAQ</div>
          <div className='header-nav'>Contact</div>
          <>
            <Avatar src={avatar} alt={'HiepNguyen'}></Avatar>
          </>
          <button className='btn--logout' variant='text' style={{ color: 'white' }} onClick={handleLogOut}>
            ログアウト
          </button>
        </nav>
      </header>

      <DialogNotification open={showLogoutConfirm} handleClose={() => setShowLogoutConfirm(false)} title={'ログイン'}>
        このアカウントは他のデバイスにログインされています。
      </DialogNotification>
    </>
  )
}
export default Header
