import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RenameBlockApi from "../B_API/RenameBlockApi";

const initStateAllBlockName = {
  current: [],
  status: "idle",
  error: null,
};

export const getAllBlockName = createAsyncThunk(
  "get-all-block-name",
  async (payload) => {
    try {
      const response = await RenameBlockApi.GetAllBlockName(payload);
      return response.data;
    } catch (error) {
      console.log("Get All Block Name Failed: ", error);
      return error.message;
    }
  }
);

const allBlockName = createSlice({
  name: "getAllBlockName",
  initialState: initStateAllBlockName,

  reducers: {
    clearAllBlockName(state) {
      state = initStateAllBlockName;
    },
  },

  extraReducers: {
    [getAllBlockName.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllBlockName.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [getAllBlockName.rejected]: (state, action) => {
      state.current = [];
      state.status = "error";
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = allBlockName;
export const { clearJob } = actions;
export default reducer;
