import Select from '@mui/material/Select'
import React from 'react'

export default function SelectComponent({ value, listOption, id, onChange, width = null }) {
  var style = !!width? {
    fontSize: '14px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '6px',
    paddingBottom: '6px',
    width: width
  } : {
    fontSize: '14px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '6px',
    paddingBottom: '6px'
  }
  return (
    <>
      <Select
        native
        name={id}
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiInputBase-input': style
        }}
      >
        {listOption.map((op) => (
          <option key={op} value={op} className='h-4 bg-[#0199fa] cursor-pointer'>
            <span className='font-semibold '>{op}</span>
          </option>
        ))}
      </Select>
    </>
  )
}
