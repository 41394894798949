import queryString from 'query-string'
import axios from 'axios'
import TokenServices from '../TokenServices'
import { refreshToken } from '../../C_REDUX_STORE/userSlice'
import store from '../../C_REDUX_STORE/store'
import UrlConstant from '../../F_UTILS/Url/UrlConstant'

const axiosClientSchedule = axios.create({
  baseURL: `${UrlConstant.SCHEDULE_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type'
  },
  paramsSerializer: (params) => queryString.stringify(params)
})

axiosClientSchedule.interceptors.request.use(async (config) => {
  const accessToken = TokenServices.getLocalAccessToken()
  try {
    if (!!accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
  } catch (error) {
    return Promise.reject(error)
  }
  return config
})

axiosClientSchedule.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (!originalConfig.url.includes('login') && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const actions = refreshToken({
            token: TokenServices.getLocalRefreshToken(),
            userEmail: TokenServices.getUser()?.userName
          })

          await store.dispatch(actions)
          return axiosClientSchedule(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default axiosClientSchedule
