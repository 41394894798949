import React from 'react'
import { TaskItemProps } from '../task-item'
import styles from './milestone.module.css'

export const Milestone: React.FC<TaskItemProps> = ({ task, isDateChangeable, onEventStart, isSelected }) => {
  const transform = `rotate(45 ${task.start + task.height * 0.356} 
    ${task.y + task.height * 0.85})`
  const getBarColor = () => {
    return isSelected ? task.styles.backgroundSelectedColor : task.styles.backgroundColor
  }

  return (
    <g tabIndex={0} className={styles.milestoneWrapper}>
      <rect
        fill={getBarColor()}
        x={task.start}
        width={task.height}
        y={task.y}
        height={task.height}
        rx={task.barCornerRadius}
        ry={task.barCornerRadius}
        transform={transform}
        className={styles.milestoneBackground}
        onMouseDown={(e) => {
          isDateChangeable && onEventStart('move', task, e)
        }}
      />
    </g>
  )
}
