import { createSlice } from '@reduxjs/toolkit'

const columnsInfo = [
  {
    name: 'task',
    displayName: '工種',
    minWidth: 160,
    width: 160,
    isHidden: false
  },
  {
    name: 'detail',
    displayName: '細目',
    minWidth: 130,
    width: 130,
    isHidden: false
  },
  {
    name: 'duration',
    displayName: '所要日数 (A)',
    minWidth: 80,
    width: 80,
    isHidden: false
  },
  {
    name: 'groups',
    displayName: '台数班数',
    minWidth: 80,
    width: 80,
    isHidden: false
  },
  {
    name: 'amplified',
    displayName: '所要日数(B) (Ax1.7/班数)',
    minWidth: 80,
    width: 80,
    isHidden: false
  },
  {
    name: 'assembly',
    displayName: '設置日数(C) (Bx0.6)',
    minWidth: 80,
    width: 80,
    isHidden: false
  },
  {
    name: 'removal',
    displayName: '撤去日数(D) (Bx0.4)',
    minWidth: 80,
    width: 80,
    isHidden: false
  },
  {
    name: 'note',
    displayName: '備考',
    minWidth: 100,
    width: 100,
    isHidden: false
  }
]
export const columnsSlice = createSlice({
  name: 'columnsInfo',
  initialState: columnsInfo,
  reducers: {
    changeColumnsWidth: (state, action) => {
      const currentColumns = state.find((co) => co.name === action.payload.name)
      const displayColumns = state.filter((x) => x.isHidden === false)
      if (displayColumns.indexOf(currentColumns) === displayColumns.length - 1) {
        if (currentColumns.minWidth < action.payload.width && currentColumns) {
          currentColumns.width = action.payload.width
        } else {
          currentColumns.width = currentColumns.minWidth
        }
      } else {
        const deltaWidth = currentColumns.width - action.payload.width
        const listDisplayColumns = state.filter((x) => x.isHidden === false)
        const lastVisibleWidth = listDisplayColumns.at(-1).width + deltaWidth
        if (lastVisibleWidth > listDisplayColumns.at(-1).minWidth) {
          currentColumns.width = action.payload.width
          listDisplayColumns.at(-1).width = listDisplayColumns.at(-1).width + deltaWidth
        }
      }
    },
    hideColumn: (state, action) => {
      let listDisplayColumns = state.filter((x) => x.isHidden === false)
      if (listDisplayColumns.length === 2) {
        return
      }
      const currentColumns = state.find((co) => co.name === action.payload.name)
      const tableWidth = action.payload.tableWidth

      if (currentColumns) {
        currentColumns.isHidden = !currentColumns.isHidden
        currentColumns.width = currentColumns.minWidth
      }
      listDisplayColumns = state.filter((x) => x.isHidden === false)
      var totalDisplayColumnsWidth = 0
      listDisplayColumns.forEach((x) => (totalDisplayColumnsWidth = totalDisplayColumnsWidth + x.width))

      var newWidth = tableWidth - (totalDisplayColumnsWidth - listDisplayColumns.at(-1).width)
      listDisplayColumns.at(-1).width = newWidth
    },
    unhideColumn: (state, action) => {
      const currentColumns = state.find((co) => co.name === action.payload.name)
      const tableWidth = action.payload.tableWidth

      if (currentColumns) {
        currentColumns.isHidden = !currentColumns.isHidden
        currentColumns.width = currentColumns.minWidth
      }
      state.forEach((x) => {
        x.width = x.minWidth
      })

      const listDisplayColumns = state.filter((x) => x.isHidden === false)
      var totalDisplayColumnsWidth = 0
      listDisplayColumns.forEach((x) => (totalDisplayColumnsWidth = totalDisplayColumnsWidth + x.width))
      console.log(totalDisplayColumnsWidth)
      var newWidth = tableWidth - (totalDisplayColumnsWidth - listDisplayColumns.at(-1).width)
      listDisplayColumns.at(-1).width = newWidth
    },
    resetColumn: (state, action) => {
      state.forEach((x) => {
        x.width = x.minWidth
        x.isHidden = false
      })
      var newWidth = 0
      const tableWidth = action.payload.tableWidth
      state.forEach((x) => (newWidth = newWidth + x.width))
      if (newWidth < tableWidth) {
        var deltaWidth = tableWidth - newWidth
        state.at(-1).width = state.at(-1).width + deltaWidth
      }
    }
  }
})

const { actions, reducer } = columnsSlice
export default reducer
