import React from 'react'
import { DialogConfirm } from 'A_SHARED_COMPONENT/DialogComponent'

export default function DeleteProjectComponent({ isVisibleDeleteProject, handleOkClick, handleCancelClick }) {
  return (
    <DialogConfirm
      title={'バージョン削除'}
      open={isVisibleDeleteProject}
      handleOK={handleOkClick}
      handleClose={handleCancelClick}
    >
      <h3 className=' text-base'>選択されたバージョンを削除しますか?</h3>
    </DialogConfirm>
  )
}
