import React, { useEffect, useState } from 'react'
import Header from '../../A_SHARED_COMPONENT/Header'
import './MenuServices.css'

import { RouterConstants } from '../../F_UTILS/Constants/RouterConstants'
import { Route, Routes, useLocation } from 'react-router-dom'
import RenameBlockServices from '../c_RenameBlockServices'
import CreateLayersServices from '../d_CreateLayersServices'
import RenameLayersServices from '../e_RenameLayersServices'
import ConvertFileServices from '../f_ConvertFileServices'
import ExportBlocksServices from '../g_ExportBlocksServices'
import DataExtractionServices from '../h_DataExtractionServices'
import ListTool from './ListTool'
import MenuSchedulingTool from '../i_MenuSchedulingTool'
import MainChart from '../j_MainChart'
import CustomView from 'Z_SERVICES/k_CustomView'
import MenuShared from 'Z_SERVICES/i_MenuSchedulingTool/pages/MenuShared'
import MenuTrash from 'Z_SERVICES/i_MenuSchedulingTool/pages/MenuTrash'

export default function MenuServices() {
  const location = useLocation()
  const [mainClass, setMainClass] = useState('main-menu')
  useEffect(() => {
    if (location.pathname === '/menu-app/scheduling-tool') {
      setMainClass('main-menu-scheduling')
    } else {
      setMainClass('main-menu')
    }
  }, [location.pathname])

  return (
    <div className={mainClass}>
      <Header></Header>
      {/* Router */}
      <Routes>
        <Route path={`/`} element={<ListTool />} />
        <Route path={`${RouterConstants.APP_RENAME_BLOCKS}/*`} element={<RenameBlockServices />} />

        <Route path={`${RouterConstants.APP_CREATE_LAYERS}/*`} element={<CreateLayersServices />} />

        <Route path={`${RouterConstants.APP_RENAME_LAYERS}/*`} element={<RenameLayersServices />} />

        <Route path={`${RouterConstants.APP_CONVERT_FILE}/*`} element={<ConvertFileServices />} />

        <Route path={`${RouterConstants.APP_EXPORT_BLOCKS}/*`} element={<ExportBlocksServices />} />

        <Route path={`${RouterConstants.APP_DATA_EXTRACTION}/*`} element={<DataExtractionServices />} />

        <Route path={`${RouterConstants.APP_MENU_SCHEDULING_TOOL}/`} element={<MenuSchedulingTool />} />

        <Route path={`${RouterConstants.APP_MENU_SCHEDULING_TOOL_SHARED}/`} element={<MenuShared />} />

        <Route path={`${RouterConstants.APP_MENU_SCHEDULING_TOOL_TRASHED}/`} element={<MenuTrash />} />

        <Route path={`${RouterConstants.APP_SCHEDULING_TOOL}/`} element={<MainChart />} />

        <Route path={`${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/:viewId`} element={<CustomView />} />
      </Routes>
    </div>
  )
}
