import React, { useRef, useEffect, useState } from 'react'
import ProgressBarComponent from '../../../../A_SHARED_COMPONENT/ProgressBarComponent'
import MainRenameLayer from '../MainRenameLayer'
import { Autodesk } from './Autodesk'
import './ViewerRenameLayer.css'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import 'react-reflex/styles.css'
import { IsolateObjectByLayerName } from '../../../../F_UTILS/ViewUtils'
import ForgeAPI from '../../../../B_API/ForgeAPI'

function ViewerRenameLayer() {
  // Lưu biến view
  const [viewInfor, setViewInfor] = useState({ urn: '', accessToken: '' })
  const viewRef = useRef(null)

  const [showDialog, setShowDialog] = React.useState(false)
  const [progressIsRunning, setProgressIsRunning] = React.useState(true)
  const [messageProgress, setMessageProgress] = React.useState('')
  const [messageCompleted, setMessageCompleted] = React.useState('')
  const windowCurrentWidth = window.innerWidth
  const minSizeLeftPanel = windowCurrentWidth * 0.4
  const maxSizeLeftPanel = windowCurrentWidth * 0.7

  useEffect(() => {
    const createInitViewer = async () => {
      let viewer = null
      function onDocumentLoadSuccessInit(viewerDocument) {
        const viewables = viewerDocument.getRoot().search({ type: 'geometry', role: '3d' })

        let view
        if (viewables.length != 0) {
          view = viewables[0]
        } else {
          view = viewerDocument.getRoot().getDefaultGeometry()
        }
        // load viewable vào view.
        viewer.loadDocumentNode(viewerDocument, view, {
          keepCurrentModels: true,
          applyScaling: 'mm'
        })
        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
          viewer.fitToView()
        })

        viewer.loadExtension('Autodesk.DocumentBrowser')
      }

      function onDocumentLoadFailureInit() {
        console.error('Failed fetching Forge manifest')
      }

      async function getAccessTokens() {
        try {
          const response = await ForgeAPI.GetViewerToken()
          return response.data.accessToken
        } catch (error) {
          console.log('Get Viewer Token ', error)
          return null
        }
      }
      var accessToken = await getAccessTokens()
      const options = {
        env: 'AutodeskProduction',
        api: 'derivativeV2', //region
        getAccessToken: function (onTokenReady) {
          var token = accessToken
          var timeInSeconds = 3600
          onTokenReady(token, timeInSeconds)
        },
        language: 'ja'
      }

      Autodesk.Viewing.Initializer(options, function onInitialized() {
        let htmlElement = document.getElementById('viewer')

        if (htmlElement) {
          const config = {}
          viewer = new Autodesk.Viewing.GuiViewer3D(htmlElement, config)

          var startedCode = viewer.start()

          if (startedCode > 0) {
            console.error('Failed to create a Viewer: WebGL not supported.')
            return
          }
          let docId =
            'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dHRkX2JvZ3M4ODVib2EzcXBxdHU1ZWRwMmJ2NmNyZ3dtcWhsLXVzLWRlZmF1bHR2aWV3LzNEVmlld2VyMS5kd2c'

          Autodesk.Viewing.Document.load(docId, onDocumentLoadSuccessInit, onDocumentLoadFailureInit)
        }
      })
    }
    createInitViewer()
  }, [])

  useEffect(() => {
    if (viewInfor.urn !== '' && viewInfor.accessToken !== '') {
      const options = {
        env: 'AutodeskProduction',
        api: 'derivativeV2', //region
        getAccessToken: function (onTokenReady) {
          var token = viewInfor.accessToken
          var timeInSeconds = 3600
          onTokenReady(token, timeInSeconds)
        },
        language: 'ja'
      }
      Autodesk.Viewing.Initializer(options, function onInitialized() {
        let htmlElement = document.getElementById('viewer')

        if (htmlElement) {
          const config = {}
          viewRef.current = new Autodesk.Viewing.GuiViewer3D(htmlElement, config)

          var startedCode = viewRef.current.start()

          if (startedCode > 0) {
            console.error('Failed to create a Viewer: WebGL not supported.')
            return
          }
          let docId = viewInfor.urn

          Autodesk.Viewing.Document.load(docId, onDocumentLoadSuccess, onDocumentLoadFailure)
        }
      })
    }

    return () => {
      const elements = document.getElementsByTagName('tr')
      for (let index = 0; index < elements.length; index++) {
        elements[index].removeEventListener('click', clickRowEvent)
      }
    }
  }, [viewInfor])

  const clickRowEvent = (e) => {
    var cell = e.target.parentNode.getElementsByTagName('td')[0]
    IsolateObjectByLayerName(viewRef.current, cell.innerHTML)
  }

  function onDocumentLoadSuccess(viewerDocument) {
    const viewables = viewerDocument.getRoot().search({ type: 'geometry', role: '3d' })
    // load viewable vào view.
    let view
    if (viewables.length !== 0) {
      view = viewables[0]
    } else {
      view = viewerDocument.getRoot().getDefaultGeometry()
    }

    viewRef.current.loadDocumentNode(viewerDocument, view, {
      keepCurrentModels: true,
      applyScaling: 'mm'
    })
    viewRef.current.loadExtension('Autodesk.DocumentBrowser')

    viewRef.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
      const setTimeOutID = setTimeout(() => {
        const elements = document.getElementsByTagName('tr')
        for (let index = 0; index < elements.length; index++) {
          elements[index].addEventListener('click', clickRowEvent)
        }
        clearTimeout(setTimeOutID)
      }, 5000)

      GetAllObjectID(viewRef.current)
    })

    const switchHighline = document.getElementById('switch-highline')
    if (switchHighline) {
      switchHighline.addEventListener('change', (e) => {
        if (e.target.checked) {
          const elements = document.getElementsByTagName('tr')
          for (let index = 0; index < elements.length; index++) {
            elements[index].addEventListener('click', clickRowEvent)
          }
        } else {
          const elements = document.getElementsByTagName('tr')
          for (let index = 0; index < elements.length; index++) {
            elements[index].removeEventListener('click', clickRowEvent)
          }
        }
      })
    }

    function GetAllObjectID(viewer) {
      function getAlldbIds(rootId, instanceTree) {
        var alldbId = []
        if (!rootId) {
          return alldbId
        }
        var queue = []
        queue.push(rootId)
        while (queue.length > 0) {
          var node = queue.shift()
          alldbId.push(node)
          instanceTree.enumNodeChildren(node, function (childrenIds) {
            queue.push(childrenIds)
          })
        }
        return alldbId
      }

      const intervalID = setInterval(() => {
        var instanceTree = viewRef.current.model.getData().instanceTree
        if (instanceTree?.getRootId()) {
          var rootId = instanceTree.getRootId()
          var allDbIds = getAlldbIds(rootId, instanceTree)
          viewRef.current.fitToView(allDbIds)
          clearInterval(intervalID)
        }
      }, 1000)
    }
  }

  function onDocumentLoadFailure() {
    console.error('Failed fetching Forge manifest')
  }

  const handleResize = (e) => {
    if (e.domElement) {
      let height = e.domElement.getBoundingClientRect().height
      let width = e.domElement.getBoundingClientRect().width
      const viewWindow = viewRef.current?.clientContainer
      if (viewWindow) {
        viewWindow.style.width = `${width}px`
        viewWindow.style.height = `${height}px`
      }
      if (viewRef.current?.canvas) {
        viewRef.current.canvas.width = width - 10
        viewRef.current.canvas.height = height - 10
      }
      viewRef.current?.resize(width, height)
      viewRef.current?.fitToView()
    }
  }
  return (
    <>
      <div className='view--container'>
        <ReflexContainer orientation='vertical'>
          <ReflexElement className='SplitterHeight' minSize={minSizeLeftPanel} maxSize={maxSizeLeftPanel} flex={0.4}>
            <MainRenameLayer
              setViewInfor={setViewInfor}
              setProgressIsRunning={setProgressIsRunning}
              setShowDialog={setShowDialog}
              setMessageProgress={setMessageProgress}
              setMessageCompleted={setMessageCompleted}
            ></MainRenameLayer>
          </ReflexElement>
          <ReflexSplitter propagate={true} className='SplitterHeight customSplitter' />

          <ReflexElement renderOnResize className='SplitterHeight' onResize={handleResize} onStopResize={handleResize}>
            <div id='viewer'></div>
          </ReflexElement>
        </ReflexContainer>
      </div>

      {showDialog && (
        <ProgressBarComponent
          isRunning={progressIsRunning}
          setShowDialog={setShowDialog}
          messageCompleted={messageCompleted}
          messageProgress={messageProgress}
        ></ProgressBarComponent>
      )}
    </>
  )
}
export default ViewerRenameLayer
