import React from 'react'
import { isValidateTasks } from '../grant-base/helpers/bar-helper'
import { cloneDeep } from 'lodash'

export function usePrevious(newValue) {
  const previousRef = React.useRef({ currentValue: [], previous: [] })
  const getPrevious = () => previousRef.current.previous
  const setWithPrevious = (newValue) => {
    const previousValue = cloneDeep(previousRef.current.currentValue)
    previousRef.current = {
      currentValue: newValue,
      previous: previousValue
    }
  }
  return { setWithPrevious, getPrevious }
}
