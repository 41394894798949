import { Tooltip } from 'antd'
import React from 'react'
import styles from './TaskToolTip.module.scss'

export default function TaskToolTip({ task, groupName, children }) {
  const taskToolTip = (
    <div className={styles.container}>
      {groupName && (
        <span>
          <span style={{ width: '100px', fontWeight: '700', display: 'inline-block', borderBottom: 'none' }}>
            グループ名：
          </span>
          {`${groupName}`}
        </span>
      )}
      <span>
        <span style={{ width: '100px', fontWeight: '700', display: 'inline-block', borderBottom: 'none' }}>
          タスク名：
        </span>
        {task.name}
      </span>
      <span>
        <span style={{ width: '100px', fontWeight: '700', display: 'inline-block', borderBottom: 'none' }}>
          所要日数：
        </span>
        {task.duration}
      </span>
      {task.detail && (
        <span>
          <span style={{ width: '100px', fontWeight: '700', display: 'inline-block', borderBottom: 'none' }}>
            細目：
          </span>
          {task.detail}
        </span>
      )}
      {task.note && (
        <span>
          <span style={{ width: '100px', fontWeight: '700', display: 'inline-block', borderBottom: 'none' }}>
            備考：
          </span>
          {task.note}
        </span>
      )}
    </div>
  )
  return (
    <Tooltip
      placement='bottom'
      title={taskToolTip}
      zIndex={1000001}
      // overlayStyle={{ backgroundColor: 'white' }}
      mouseLeaveDelay={0}
      overlayInnerStyle={{ backgroundColor: 'white', width: '400px' }}
      arrow={false}
    >
      {children}
    </Tooltip>
  )
}
