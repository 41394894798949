import React from 'react'
import styles from './DropDown.module.scss'
import { useSelector } from 'react-redux'

export default function DropDown({ colorId, setColorId, isDisable }) {
  const [isActive, setIsActive] = React.useState(false)
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const dropDownRef = React.useRef(null)
  React.useEffect(() => {
    const handleClick = (e) => {
      if (!dropDownRef.current?.contains(e.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })

  let stepWorkColor
  for (let index = 0; index < stepWorkColors.length; index++) {
    const element = { ...stepWorkColors[index] }
    if (element.colorId === colorId) {
      stepWorkColor = element
    }
  }

  const handleColorChange = (color) => {
    setColorId(color.colorId)
    setIsActive(false)
  }
  return (
    <div className={styles.dropdown} ref={dropDownRef}>
      <div className={styles.dropdown_btn} onClick={() => setIsActive(!isActive)}>
        {/* Choose Color */}
        {stepWorkColor ? (
          <div className={styles.dropdown_selectedItem}>
            <span className={isDisable ? `${styles.dropdown_name}` : ''}>{stepWorkColor?.name || ''}</span>
            <div
              className={styles.dropdown_color}
              style={{ backgroundColor: stepWorkColor?.code, marginRight: '3px' }}
            ></div>
          </div>
        ) : (
          <span style={{ lineHeight: '36px', paddingLeft: '10px' }} className='text-sm'>
            タスク種類を選択
          </span>
        )}

        <span className='fas fa-caret-down'></span>
      </div>
      {isActive && !isDisable && (
        <div
          className={styles.dropdown_content}
          style={
            stepWorkColors?.length > 10 ? { height: '300px' } : { height: `${stepWorkColors?.length * 40 + 20}px` }
          }
        >
          {stepWorkColors.map((color) => {
            return (
              <div key={color.colorId} className={styles.dropdown_item} onClick={() => handleColorChange(color)}>
                <span>{color.name}</span>
                <div className={styles.dropdown_color} style={{ backgroundColor: color.code }}></div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
