import React, { useEffect, useRef, useState } from 'react'
import styles from './MainChartHeader.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp,
  faArrowUpWideShort,
  faBackward,
  faChartBar,
  faCirclePlus,
  faCloudArrowDown,
  faFileImport,
  faFileArrowUp,
  faFloppyDisk,
  faGear
} from '@fortawesome/free-solid-svg-icons'
import { faCaretSquareDown, faCaretSquareUp, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {
  AddTask,
  CheckCanMoveTask,
  CheckListTaskId,
  DeleteListTasks,
  DeleteTask,
  MoveListTasks,
  MoveTask
} from 'F_UTILS/SchedulingUtils/TaskUtils'
import {
  AddGroupAfter,
  AddGroupBefore,
  AddNewGroup,
  AddTaskInGroup,
  CheckCanMoveGroup,
  DeleteGroup,
  MoveGroupDown,
  MoveGroupUp
} from 'F_UTILS/SchedulingUtils/GroupTaskUtils'
import { showModalSlice } from 'C_REDUX_STORE/scheduleTool/showModalSlice'
import { useLocation, useNavigate, useParams } from 'react-router'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import { Select, Tooltip } from 'antd'
import ProjectApi from 'B_API/scheduleApis/ProjectApi'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'
import * as XLSX from 'xlsx'
import ViewsApi from 'B_API/scheduleApis/ViewsApi'
import { calculateTaskPositionForSendDataToServer } from 'Z_SERVICES/j_MainChart/grant-base/helpers/bar-helper'
import DownloadProcessModal from '../Modals/DownloadProcessModal'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import { ReactComponent as IconMoveGroup } from '../../../../X_ASSETS/icons/icon_move_group.svg'
import DialogApply, { DialogConfirm } from 'A_SHARED_COMPONENT/DialogComponent'
import { isEqual, omit } from 'lodash'
import MainHeaderContainer from '../MainHeaderContainer'

export default function MainChartHeader({
  task,
  tasks,
  setTasks,
  setTaskCell,
  setShowCreateView,
  setShowMoveGroupTask,
  setExcelInfo,
  setRightCellClick,
  showCreateView
}) {
  const mainViewValue = 'main view'
  const currentVersion = useSelector((state) => state.currentVersion)
  const [groupClass, setGroupClass] = useState()
  const [groupSvgClass, setGroupSvgClass] = useState()
  const [taskClass, setTaskClass] = useState()
  const [optionClass, setOptionClass] = useState()
  const [selectOption, setSelectOption] = useState([{ value: mainViewValue, label: 'メインビュー' }])
  const { viewId } = useParams()
  const [selectedView, setSelectedView] = useState('')

  const [taskMoveUpClass, setTaskMoveUpClass] = useState()
  const [taskMoveDownClass, setTaskMoveDownClass] = useState()

  const [groupMoveUpClass, setGroupMoveUpClass] = useState()
  const [groupMoveDownClass, setGroupMoveDownClass] = useState()

  const [showModal, setShowModal] = useState(false)
  const [downloadDone, setDownloadDone] = useState(false)
  const [showDialogBack, setShowDialogBack] = useState(false)
  const [messageCompleted, setMessageCompleted] = useState('ダウンロードが完了しました。')

  const selectedTaskIds = useSelector((state) => state.selectedTask)

  const inputExcelRef = React.useRef(null)
  const btnSaveRef = React.useRef(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const amplifiedFactor = useSelector((state) => state.versionSetting.current.amplifiedFactor)
  const columnWidth = useSelector((state) => state.versionSetting.current.columnWidth)
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)

  const [isConfirmChangeView, setConfirmChangeView] = useState(false)

  useEffect(() => {
    const fetchViewInfo = async () => {
      try {
        const res = await ViewsApi.GetCustomViewsByVersionId(currentVersion.versionId)
        if (res.status === 200) {
          const option = res.data.map((op) => {
            return {
              value: op.viewId,
              label: op.viewName
            }
          })
          option.unshift({ value: mainViewValue, label: 'メインビュー' })
          setSelectOption(option)

          if (viewId === undefined) {
            const value = option.find((op) => op.value == mainViewValue)?.label
            setSelectedView(value)
          } else {
            const value = option.find((op) => op.value == viewId)?.label
            setSelectedView(value)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchViewInfo()
  }, [viewId, currentVersion.versionId, showCreateView])

  useEffect(() => {
    if (location.pathname.includes('custom-view')) {
      setOptionClass(`${styles.header_icon} ${styles.header_icon__disabled}`)
    } else {
      setOptionClass(`${styles.header_icon}`)
    }

    if (task === undefined || task === null) {
      const group = `${styles.header_icon} ${styles.header_icon__disabled}`
      const task = `${styles.header_icon} ${styles.header_icon__disabled}`
      setGroupClass(group)
      setGroupSvgClass(`${styles.header_iconSvg} ${styles.header_icon__disabled}`)
      setTaskClass(task)
      setTaskMoveUpClass(task)
      setTaskMoveDownClass(task)
      setGroupMoveUpClass(group)
      setGroupMoveDownClass(group)
    }
    if (task?.type === 'project') {
      const group = `${styles.header_icon}`
      const task = `${styles.header_icon} ${styles.header_icon__disabled}`
      setGroupClass(group)
      setGroupSvgClass(`${styles.header_iconSvg} `)
      setTaskClass(task)
      setTaskMoveUpClass(task)
      setTaskMoveDownClass(task)
      setGroupMoveUpClass(group)
      setGroupMoveDownClass(group)
    } else if (task?.type === 'task') {
      const group = `${styles.header_icon} ${styles.header_icon__disabled}`
      const task = `${styles.header_icon}`

      setTaskClass(task)
      setTaskMoveUpClass(task)
      setTaskMoveDownClass(task)
      setGroupClass(group)
      setGroupSvgClass(`${styles.header_iconSvg} ${styles.header_icon__disabled}`)
      setGroupMoveUpClass(group)
      setGroupMoveDownClass(group)
    }

    if (task !== undefined && task !== null && !location.pathname.includes('custom-view')) {
      if (!CheckCanMoveTask(task, tasks, true) && task?.type === 'task') {
        const task = `${styles.header_icon} ${styles.header_icon__disabled}`
        setTaskMoveUpClass(task)
      }

      if (!CheckCanMoveTask(task, tasks, false) && task?.type === 'task') {
        const task = `${styles.header_icon} ${styles.header_icon__disabled}`
        setTaskMoveDownClass(task)
      }
      if (!CheckCanMoveGroup(task, tasks, true) && task?.type === 'project') {
        const group = `${styles.header_icon} ${styles.header_icon__disabled}`
        setGroupMoveUpClass(group)
      }
      if (!CheckCanMoveGroup(task, tasks, false) && task?.type === 'project') {
        const group = `${styles.header_icon} ${styles.header_icon__disabled}`
        setGroupMoveDownClass(group)
      }
    }

    if (location.pathname.includes('custom-view')) {
      const disabledClass = `${styles.header_icon} ${styles.header_icon__disabled}`
      setGroupClass(disabledClass)
      setTaskClass(disabledClass)
      setGroupSvgClass(`${styles.header_iconSvg} ${styles.header_icon__disabled}`)
      setTaskMoveUpClass(disabledClass)
      setTaskMoveDownClass(disabledClass)
      setGroupMoveUpClass(disabledClass)
      setGroupMoveDownClass(disabledClass)
    }
  }, [task, tasks])

  useEffect(() => {
    if (task !== undefined && task !== null && !location.pathname.includes('custom-view')) {
      if (!CheckCanMoveTask(task, tasks, true) && task?.type === 'task') {
        const task = `${styles.header_icon} ${styles.header_icon__disabled}`
        setTaskMoveUpClass(task)
      }

      if (!CheckCanMoveTask(task, tasks, false) && task?.type === 'task') {
        const task = `${styles.header_icon} ${styles.header_icon__disabled}`
        setTaskMoveDownClass(task)
      }
      if (!CheckCanMoveGroup(task, tasks, true) && task?.type === 'project') {
        const group = `${styles.header_icon} ${styles.header_icon__disabled}`
        setGroupMoveUpClass(group)
      }
      if (!CheckCanMoveGroup(task, tasks, false) && task?.type === 'project') {
        const group = `${styles.header_icon} ${styles.header_icon__disabled}`
        setGroupMoveDownClass(group)
      }
    }
  })

  const handleInputExcelClick = () => {
    inputExcelRef.current.value = ''
  }

  const handleInputExcelChange = async () => {
    const file = inputExcelRef.current?.files[0]
    if (file) {
      const sheetNames = await readExcel(file)
      if (sheetNames?.length !== 0) {
        setExcelInfo({ file: file, sheetNames: sheetNames, isOpen: true, type: 'import' })
      }
    }
  }

  const readExcel = async (file) => {
    const data = await file.arrayBuffer()
    const wb = XLSX.read(data)
    return wb.SheetNames
  }

  const handleImportExcel = () => {
    if (setRightCellClick) setRightCellClick(false)
    inputExcelRef.current.click()
  }
  const inputExcelUpdateRef = useRef(null)
  const handleInputExcelUpdateClick = () => {
    inputExcelUpdateRef.current.value = ''
  }

  const handleInputExcelUpdateChange = async () => {
    const file = inputExcelUpdateRef.current?.files[0]
    if (file) {
      const sheetNames = await readExcel(file)
      if (sheetNames?.length !== 0) {
        setExcelInfo({ file: file, sheetNames: sheetNames, isOpen: true, type: 'update' })
      }
    }
  }

  const handleImportExcelUpdate = () => {
    if (setRightCellClick) setRightCellClick(false)
    inputExcelUpdateRef.current.click()
  }

  const handleDownloadFile = async () => {
    setShowModal(true)
    setDownloadDone(false)
    try {
      if (!location.pathname.includes('custom-view')) {
        const convertedTasks = calculateTaskPositionForSendDataToServer([...tasks], columnWidth, amplifiedFactor)
        await VersionApi.UpdateVersionTaskDetails(currentVersion.versionId, convertedTasks)
      }
    } catch {}
    try {
      const res = await ProjectApi.downloadExcel(currentVersion.versionId)
      const date = new Date().toISOString().substr(0, 19).replaceAll('-', '').replaceAll(':', '')
      const fileName = `${date}_${currentVersion?.projectName}_${currentVersion?.versionName}.xlsx`
      fileDownload(res.data, fileName)
      setDownloadDone(true)
      setMessageCompleted('ダウンロードが完了しました。')
    } catch (err) {
      setDownloadDone(true)
      setMessageCompleted('ダウンロードが失敗しました。')
    }
  }

  const handleSaveVersion = async () => {
    if (setRightCellClick) setRightCellClick(false)
    btnSaveRef.current.classList.add(styles.header_icon__disabled)
    try {
      let res
      if (location.pathname.includes('custom-view')) {
        res = null
        var data = tasks.map((t) => {
          return {
            id: t.id,
            isHidden: t.isHidden,
            isDayFormat: t.isDayFormat,
            name: t.name,
            detail: t.detail,
            note: t.note,
            type: t.type
          }
        })
        res = await ViewsApi.SaveViewDetails(currentVersion.versionId, viewId, data)
      } else {
        //save data
        const convertedTasks = calculateTaskPositionForSendDataToServer([...tasks], columnWidth, amplifiedFactor)
        res = await VersionApi.UpdateVersionTaskDetails(currentVersion.versionId, convertedTasks)
      }
      if (res?.status === 204) {
        toast.success('プロジェクトの保存に成功しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      } else {
        toast.error('プロジェクトの保存に失敗しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    } catch (error) {
      console.log(error)
      toast.error('プロジェクトの保存に失敗しました。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }
    setTimeout(() => {
      btnSaveRef.current.classList.remove(styles.header_icon__disabled)
    }, 3000)
  }

  //handle Event Task
  const handleMoveTaskUp = () => {
    if (selectedTaskIds.length > 1 && CheckListTaskId(selectedTaskIds, tasks)) {
      const newTasks = MoveListTasks(selectedTaskIds, tasks, true)
      setTasks(newTasks)
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return
    if (taskMoveUpClass === `${styles.header_icon} ${styles.header_icon__disabled}`) {
      return
    }
    const newTasks = MoveTask(task, tasks, true)
    setTasks(newTasks)
  }

  const handleMoveTaskDown = () => {
    if (selectedTaskIds.length > 1 && CheckListTaskId(selectedTaskIds, tasks)) {
      const newTasks = MoveListTasks(selectedTaskIds, tasks, false)
      setTasks(newTasks)
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return

    if (taskMoveDownClass === `${styles.header_icon} ${styles.header_icon__disabled}`) {
      return
    }
    const newTasks = MoveTask(task, tasks, false)
    setTasks(newTasks)
  }

  const handleCreateNewTaskUp = () => {
    if (selectedTaskIds.length > 1) {
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    const newTasks = AddTask(task, tasks, true, assemblyColor.colorId)
    setTasks(newTasks)
  }

  const handleCreateNewTaskDown = () => {
    if (selectedTaskIds.length > 1) {
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    const newTasks = AddTask(task, tasks, false, assemblyColor.colorId)
    setTasks(newTasks)
  }

  const handleDeleteTask = () => {
    if (selectedTaskIds.length > 1) {
      const newTasks = DeleteListTasks(selectedTaskIds, tasks)
      setTasks(newTasks)
      setTaskCell(null)
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return
    const newTasks = DeleteTask(task, tasks)
    setTasks(newTasks)
    setTaskCell(null)
  }

  const handleMoveTaskTo = () => {
    if (selectedTaskIds.length > 1) {
      return
    }
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'project') return
    setShowMoveGroupTask(true)
  }

  //handle Group
  const handleMoveGroupUp = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const newTasks = MoveGroupUp(task, tasks)
    setTasks(newTasks)
  }

  const handleMoveGroupDown = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const newTasks = MoveGroupDown(task, tasks)
    setTasks(newTasks)
  }

  const handleCreateGroupUp = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const newTasks = AddGroupBefore(task, tasks)
    setTasks(newTasks)
  }

  const handleCreateGroupDown = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const newTasks = AddGroupAfter(task, tasks)
    setTasks(newTasks)
  }

  const handleCreateTaskInGroup = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    const newTasks = AddTaskInGroup(task, tasks, assemblyColor.colorId)
    setTasks(newTasks)
  }

  const handleNewGroup = () => {
    if (setRightCellClick) setRightCellClick(false)
    const newTasks = AddNewGroup(tasks)
    setTasks(newTasks)
  }

  const handleDeleteGroup = () => {
    if (setRightCellClick) setRightCellClick(false)
    if (task?.type === 'task') return
    const newTasks = DeleteGroup(task, tasks)
    setTasks(newTasks)
    setTaskCell(null)
  }

  const handleMoveGroupTo = () => {
    if (setRightCellClick) setRightCellClick(false)
    //TODO
    if (task === null || task?.type === 'task') return
    setShowMoveGroupTask(true)
  }
  const handleSettingVersion = () => {
    if (setRightCellClick) setRightCellClick(false)
    dispatch(showModalSlice.actions.ShowWindow({ name: 'VersionSettingComponent' }))
  }

  const handleBackToMenuEventBtn = () => {
    if (setRightCellClick) setRightCellClick(false)
    setShowDialogBack(true)
  }
  const handleSaveAndBackToMenu = () => {
    handleSaveVersion()
    navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
  }
  const handleDontSaveAndBackToMenu = () => {
    navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
  }
  const handleCreateView = () => {
    if (setRightCellClick) setRightCellClick(false)
    setShowCreateView(true)
  }

  const handleViewChange = async (value) => {
    if (setRightCellClick) setRightCellClick(false)

    try {
      const versionTasksDetail = await ViewsApi.GetViewDetails(currentVersion.versionId, viewId)
      if (versionTasksDetail.status == 200) {
        if (value === mainViewValue) {
          var serverData = versionTasksDetail.data.filter((t) => t.type !== 'project').map((x) => omit(x, 'groupId'))
          var currentData = tasks.filter((t) => t.type !== 'project').map((x) => omit(x, 'groupId'))
          var check = isEqual(serverData, currentData)
          console.log('🚀 ~ file: index.js:524 ~ handleViewChange ~ check:', check)
          if (!check) {
            setConfirmChangeView(true)
          } else {
            navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${value}`)
          }
        } else {
          navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${value}`)
        }
      } else {
        navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${value}`)
      }
    } catch (error) {
      navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_CUSTOM_CHART}/${value}`)
    }
  }

  const handleOkClickChangeView = () => {
    handleSaveVersion()
    navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_TOOL}`)
    setConfirmChangeView(false)
  }
  const handleCancelClickChangeView = () => {
    setConfirmChangeView(false)
    navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_TOOL}`)
  }

  return (
    <div className={styles.header_container}>
      <DownloadProcessModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        done={downloadDone}
        messageCompleted={messageCompleted}
        messageProgress={'ダウンロードの準備中。しばらくお待ちください。'}
      ></DownloadProcessModal>

      <div className={styles.projectName}>
        {currentVersion.projectName}_{currentVersion?.versionName}
      </div>

      <MainHeaderContainer groupName={'挿入'}>
        <Tooltip placement='bottom' title={'エクセルファイルを挿入'}>
          <div className={styles.header_button}>
            <div className={styles.header_button}>
              <FontAwesomeIcon icon={faFileImport} className={optionClass} onClick={handleImportExcel} />
              <input
                type='file'
                ref={inputExcelRef}
                accept='.xlsx, .xls'
                onClick={handleInputExcelClick}
                onChange={handleInputExcelChange}
              />
            </div>
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'エクセルダウンロード'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCloudArrowDown} className={styles.header_icon} onClick={handleDownloadFile} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'データを更新'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faFileArrowUp} className={optionClass} onClick={handleImportExcelUpdate} />
            <input
              type='file'
              ref={inputExcelUpdateRef}
              accept='.xlsx, .xls'
              onClick={handleInputExcelUpdateClick}
              onChange={handleInputExcelUpdateChange}
            />
          </div>
        </Tooltip>
      </MainHeaderContainer>

      <MainHeaderContainer groupName={'設定'}>
        <Tooltip placement='bottom' title={'保存'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon
              icon={faFloppyDisk}
              className={styles.header_icon}
              onClick={handleSaveVersion}
              ref={btnSaveRef}
            />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'設定'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faGear} className={optionClass} onClick={handleSettingVersion} />
          </div>
        </Tooltip>
      </MainHeaderContainer>

      {/* Task */}
      <MainHeaderContainer groupName={'タスク'}>
        <Tooltip placement='bottom' title={'タスクを上げする'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faAngleUp} className={taskMoveUpClass} onClick={handleMoveTaskUp} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'タスクを下げする'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faAngleDown} className={taskMoveDownClass} onClick={handleMoveTaskDown} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'新タスクを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCirclePlus} className={groupClass} onClick={handleCreateTaskInGroup} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'上にタスクを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCaretSquareUp} className={taskClass} onClick={handleCreateNewTaskUp} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'下にタスクを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCaretSquareDown} className={taskClass} onClick={handleCreateNewTaskDown} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'タスク削除'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faTrashAlt} className={taskClass} onClick={handleDeleteTask} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'タスク移動'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faArrowUpWideShort} className={taskClass} onClick={handleMoveTaskTo} />
          </div>
        </Tooltip>
      </MainHeaderContainer>

      {/* Group */}
      <MainHeaderContainer groupName={'グループ'}>
        <Tooltip placement='bottom' title={'上にグループを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faAngleUp} className={groupMoveUpClass} onClick={handleMoveGroupUp} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'下にグループを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faAngleDown} className={groupMoveDownClass} onClick={handleMoveGroupDown} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'新グループを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCirclePlus} className={optionClass} onClick={handleNewGroup} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'上にグループを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCaretSquareUp} className={groupClass} onClick={handleCreateGroupUp} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'下にグループを追加'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faCaretSquareDown} className={groupClass} onClick={handleCreateGroupDown} />
          </div>
        </Tooltip>

        <Tooltip placement='bottom' title={'グループを削除'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faTrashAlt} className={groupClass} onClick={handleDeleteGroup} />
          </div>
        </Tooltip>
        <Tooltip placement='bottom' title={'グループ移動'}>
          <div className={styles.header_button}>
            <span onClick={() => handleMoveGroupTo()}>
              <IconMoveGroup className={groupSvgClass}></IconMoveGroup>
            </span>
          </div>
        </Tooltip>
      </MainHeaderContainer>

      {/* list view */}
      <MainHeaderContainer groupName={'ビュー'}>
        <Tooltip placement='bottom' title={'ビュー作成'}>
          <div className={styles.header_button}>
            <FontAwesomeIcon icon={faChartBar} className={styles.header_icon} onClick={handleCreateView} />
          </div>
        </Tooltip>
        <Select
          // defaultValue={mainViewValue}
          value={selectedView}
          style={{ width: 200 }}
          onChange={handleViewChange}
          options={selectOption}
        />
      </MainHeaderContainer>
      <div className={styles.header_listView}></div>

      {/* navigate back to menu */}
      <div className={styles.header_backMenu}>
        <FontAwesomeIcon
          icon={faBackward}
          className={`${styles.header_icon} ${styles.header_backMenu_icon}`}
          onClick={handleBackToMenuEventBtn}
        ></FontAwesomeIcon>
      </div>

      <DialogApply
        open={showDialogBack}
        handleClose={() => setShowDialogBack(false)}
        handleOK={handleSaveAndBackToMenu}
        handleNotOk={handleDontSaveAndBackToMenu}
        title={'確認'}
      >
        <span className=' text-base text-center'>プロジェクトリストを戻る前に、プロジェクトを保存しますか。</span>
      </DialogApply>
      <DialogConfirm
        title={'確認'}
        open={isConfirmChangeView}
        handleOK={handleOkClickChangeView}
        handleClose={handleCancelClickChangeView}
        lable1={'いいえ'}
        lable2={'はい'}
      >
        <h3 className=' text-base'>ビューを保存しますか。</h3>
      </DialogConfirm>
    </div>
  )
}
