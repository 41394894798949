import { BarTask } from '../types/bar-task'
import { Predecessors, Task } from '../types/public-types'

export function isKeyboardEvent(
  event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent
): event is React.KeyboardEvent {
  return (event as React.KeyboardEvent).key !== undefined
}

export function isMouseEvent(
  event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent
): event is React.MouseEvent {
  return (event as React.MouseEvent).clientX !== undefined
}

export function isBarTask(task: Task | BarTask): task is BarTask {
  return (task as BarTask).start !== undefined
}

export function removeHiddenTasks(tasks: Task[]) {
  const groupedTasks = tasks?.filter((t) => t.hideChildren && t.type === 'project')
  if (groupedTasks?.length > 0) {
    for (let i = 0; groupedTasks.length > i; i++) {
      const groupedTask = groupedTasks[i]
      // const children = getChildren(tasks, groupedTask)
      const children = tasks.filter((t) => t.groupId && t.groupId === groupedTask.id)
      tasks = tasks.filter((t) => children.indexOf(t) === -1)
    }
  }
  return tasks
}

function getChildren(taskList: Task[], task: Task) {
  let tasks: Task[] = []
  tasks = taskList.filter((t) => t.groupId && t.groupId === task.id)
  var taskChildren: Task[] = []
  tasks.forEach((t) => {
    taskChildren.push(...getChildren(taskList, t))
  })
  tasks = tasks.concat(tasks, taskChildren)
  return tasks
}

export const sortTasks = (taskA: Task, taskB: Task) => {
  const orderA = taskA.displayOrder || Number.MAX_VALUE
  const orderB = taskB.displayOrder || Number.MAX_VALUE
  if (orderA > orderB) {
    return 1
  } else if (orderA < orderB) {
    return -1
  } else {
    return 0
  }
}

export const getDurationScale = (task: Task, amplifiedFactor: number) => {
  if (task?.groupsNumber) {
    return amplifiedFactor / task.groupsNumber
  } else {
    return 1
  }
}



export const checkCanAddPredecessor = (tasks: Task[], parentTaskId: string, childTaskId: string) => {
  let listChildTask: string[] = []
  let listParentTask: string[] = []
  let listTasks: Task[] = []

  tasks.forEach((task) => {
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        if (stepWork?.predecessors) {
          stepWork.predecessors.map((st) => listChildTask.push(st.id))
          listTasks.push(stepWork)
        }
      })
    } else {
      if (task?.predecessors) {
        task.predecessors.map((st) => listChildTask.push(st.id))
        listTasks.push(task)
      }
    }
  })

  listTasks.forEach((task) => {
    if (task.type === 'task' && !listChildTask.includes(task.id) && task?.predecessors?.length !== 0) {
      listParentTask.push(task.id)
    }
  })

  const recursion = (taskIds: string[]): any => {
    let arrayResult: string[] = []
    for (let index = 0; index < taskIds.length; index++) {
      const taskId = taskIds[index]
      const task = listTasks.find((t) => t.id === taskId)
      if (task?.predecessors?.length === 0 || task === undefined) {
        if (taskIds.length === 1) {
          return arrayResult
        } else {
          continue
        }
      } else {
        const newTaskId = task?.predecessors?.map((x) => x.id)
        if (newTaskId) {
          arrayResult = arrayResult.concat(newTaskId)
          return arrayResult.concat(recursion(newTaskId))
        } else {
          return arrayResult
        }
      }
    }
  }

  for (let index = 0; index < listParentTask.length; index++) {
    const taskId = listParentTask[index]
    let listIds = [taskId].concat(recursion([taskId]))
    listIds = listIds.filter(function (element) {
      return element !== undefined
    })

    if (listIds.includes(parentTaskId) && listIds.includes(childTaskId)) {
      return false
    }
  }
  return true
}

export const getTrueParentToCalculatePosition = (tasks: Task[], childTaskId: string) => {
  let predecessorsIds: Predecessors[] = []
  let listTasks: Task[] = []
  //get all tasks and predecessors task id to check childtask have one or more parent task
  for (let index = 0; index < tasks.length; index++) {
    const task = tasks[index]
    if (task?.predecessors) {
      predecessorsIds = predecessorsIds.concat(task.predecessors)
    }
    if (task?.stepworks) {
      for (let i = 0; i < task.stepworks.length; i++) {
        const st = task.stepworks[i]
        if (st?.predecessors) {
          listTasks.push(st)
          predecessorsIds = predecessorsIds.concat(st.predecessors)
        }
      }
    } else {
      listTasks.push(task)
    }
  }

  // check and get list parent tasks
  if (predecessorsIds.filter((pre) => pre.id === childTaskId).length > 1) {
    let listParentTask: Task[] = []

    listTasks.forEach((t) => {
      if (t?.predecessors) {
        const foundTask = t.predecessors.find((pr) => pr.id === childTaskId)
        if (foundTask) {
          listParentTask.push(t)
        }
      }
      if (t?.stepworks) {
        for (let i = 0; i < t.stepworks.length; i++) {
          const st = t.stepworks[i]
          if (st?.predecessors) {
            const foundTask = st.predecessors.find((pr) => pr.id === childTaskId)
            if (foundTask) {
              listParentTask.push(st)
            }
          }
        }
      }
    })

    // get true parent task.
    const parentTask = listParentTask.reduce((a, b) => {
      let firstLocation: number = 0
      let secondLocation: number = 0
      const parentA = a.predecessors?.filter((p) => p.id === childTaskId)[0].type
      const parentB = b.predecessors?.filter((p) => p.id === childTaskId)[0].type
      if (parentA?.charAt(0) === 'F') {
        firstLocation = a.end
      } else {
        firstLocation = a.start
      }
      if (parentB?.charAt(0) === 'F') {
        secondLocation = b.end
      } else {
        secondLocation = b.start
      }
      return firstLocation > secondLocation ? a : b
    })
    const childtask = listTasks.find((x) => x.id === childTaskId)
    console.log(parentTask.name, childtask?.name)
    return parentTask
  }
  // lỗi khi chia stepworks
  return undefined
}
