import React, { useEffect, useRef, useState } from 'react'
import styles from './TaskListTableCustom.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router'
import { getDurationScale } from 'Z_SERVICES/j_MainChart/grant-base/helpers/other-helper'
import { selectedTaskSlice } from 'C_REDUX_STORE/scheduleTool/selectedTaskSlice'
import { cloneDeep } from 'lodash'

const TaskListCell = ({ rowWidth, content, style, isHidden }) => {
  return (
    <>
      <div
        className={styles.taskListCell}
        style={{
          ...style,
          width: rowWidth,
          minWidth: rowWidth,
          textAlign: 'center',
          borderLeft: '0.1px solid rgb(196, 196, 196)',
          opacity: '1',
          display: isHidden ? 'none' : ''
        }}
      >
        &nbsp;{content}
      </div>
    </>
  )
}

const TextInputTaskList = ({
  label,
  task,
  value,
  onChange,
  style,
  columnWidth,
  isHidden,
  isTaskColumn,
  isNumber = false,
  isInteger = false,
  isShowContent = true
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const inputRef = React.useRef(null)

  const location = useLocation()

  useEffect(() => {
    if (isNumber) {
      if (isInteger) {
        if (isNaN(Number(value))) {
          setCurrentValue('-')
        } else {
          setCurrentValue(Number(value).toFixed(0))
        }
      } else {
        setCurrentValue(Number(value).toFixed(1))
      }
    } else {
      setCurrentValue(value)
    }

    if (location.pathname.includes('custom-view')) {
      var disableLabels = ['grouptask', 'durationDays', 'groupsNumber']
      if (disableLabels.includes(label)) {
        inputRef.current.readOnly = true
        if (!isShowContent) {
          setCurrentValue('')
        }
      } else {
        inputRef.current.readOnly = false
      }
    } else {
      inputRef.current.readOnly = false
    }
  }, [location.pathname, value])

  const handlePressEnter = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      inputRef.current.blur()
    }
  }
  return (
    <>
      {isTaskColumn ? (
        <input
          ref={inputRef}
          type='text'
          value={currentValue}
          className={styles.taskListName}
          style={{
            minWidth: `${columnWidth - 50}px`,
            maxWidth: `${columnWidth - 50}px`,
            maxHeight: '50px',
            border: 'none',
            height: '24px',
            display: isHidden ? 'none' : '',
            ...style
          }}
          onKeyDown={handlePressEnter}
          onChange={(e) => {
            setCurrentValue(e.target.value)
          }}
          onBlur={(e) => {
            if (value !== e.target.value) {
              // string
              if (!isNumber) {
                onChange(e.target.value, task)
              } else {
                if (Number(e.target.value) < 0) {
                  setCurrentValue(Number(value).toFixed(1))
                  return
                }
                if (isInteger && !Number.isInteger(e.target.value)) {
                  setCurrentValue(Number(value).toFixed(1))
                  return
                }
                //number
                if (isNaN(Number(e.target.value))) {
                  setCurrentValue(Number(value).toFixed(1))
                } else {
                  onChange(Number(e.target.value).toFixed(1), task)
                  setCurrentValue(Number(e.target.value).toFixed(1))
                }
              }
            }
          }}
        />
      ) : (
        <div
          style={{
            textAlign: 'center',
            lineHeight: '1',
            borderLeft: '0.1px solid rgb(196, 196, 196)'
          }}
          className={styles.taskListCell}
        >
          <input
            ref={inputRef}
            type='text'
            value={currentValue || ''}
            className={styles.taskListName}
            style={{
              minWidth: `${columnWidth - 20}px`,
              maxWidth: `${columnWidth - 20}px`,
              maxHeight: '50px',
              border: 'none',
              height: '24px',
              display: isHidden ? 'none' : '',
              ...style
            }}
            onKeyDown={handlePressEnter}
            onChange={(e) => {
              setCurrentValue(e.target.value)
            }}
            onBlur={(e) => {
              if (value !== e.target.value) {
                // string
                if (!isNumber) {
                  onChange(e.target.value, task)
                } else {
                  if (Number(e.target.value) <= 0) {
                    if (isInteger) {
                      if (Number(e.target.value) <= 0) {
                        setCurrentValue('-')
                        onChange(0, task)
                      } else {
                        setCurrentValue(Number(value).toFixed(0))
                      }
                    } else {
                      setCurrentValue(Number(value).toFixed(1))
                    }
                    return
                  }
                  if (isInteger && !Number.isInteger(Number(e.target.value))) {
                    if (Number(value)) {
                      setCurrentValue(Number(value).toFixed(0))
                    } else {
                      setCurrentValue('-')
                    }

                    return
                  }
                  //number
                  if (isNaN(Number(e.target.value))) {
                    if (isInteger) {
                      setCurrentValue(Number(value).toFixed(0))
                    } else {
                      setCurrentValue(Number(value).toFixed(1))
                    }
                  } else {
                    if (isInteger) {
                      setCurrentValue(Number(e.target.value).toFixed(0))
                      onChange(Number(e.target.value).toFixed(0), task)
                    } else {
                      setCurrentValue(Number(e.target.value).toFixed(1))
                      onChange(Number(e.target.value).toFixed(1), task)
                    }
                  }
                }
              }
            }}
          />
        </div>
      )}
    </>
  )
}

const TextInputEmpty = ({ columnWidth, isHidden }) => {
  return (
    <>
      {!isHidden && (
        <div
          className={styles.taskListCell2}
          style={{
            minWidth: `${columnWidth - 60}px`,
            maxWidth: `${columnWidth - 60}px`
          }}
        ></div>
      )}
    </>
  )
}

export const TaskListTableCustom = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
  onRightClickCell,
  onLeftClickCell,
  onChangeTaskName
}) => {
  const [highlightedRowsCtrl, setHighlightedRowsCtrl] = useState([])
  const [highlightedRows, setHighlightedRows] = useState([])
  const columnsInfo = useSelector((state) => state.columnsInfo)
  const selectedTaskIds = useSelector((state) => state.selectedTask)
  const amplifiedFactor = useSelector((state) => state.versionSetting.current.amplifiedFactor)
  const dispatch = useDispatch()

  const detailInfo = columnsInfo.find((x) => x.name === 'detail')
  const taskColumnInfo = columnsInfo.find((x) => x.name === 'task')
  const durationInfo = columnsInfo.find((x) => x.name === 'duration')
  const groupsInfo = columnsInfo.find((x) => x.name === 'groups')
  const amplifiedInfo = columnsInfo.find((x) => x.name === 'amplified')
  const assemblyInfo = columnsInfo.find((x) => x.name === 'assembly')
  const removalInfo = columnsInfo.find((x) => x.name === 'removal')
  const noteInfo = columnsInfo.find((x) => x.name === 'note')
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const rowsRef = useRef([])
  const location = useLocation()

  useEffect(() => {
    rowsRef.current = []
  }, [tasks])

  useEffect(() => {
    tasks.forEach((task, index) => {
      if (task?.stepworks) {
        const haveSubStepWork = task.stepworks.some((st) => st?.isSubStepWork)
        if (!haveSubStepWork) {
          const newListRows = cloneDeep(rowsRef.current.filter((row) => row.id !== task.id + 'SubStepWork'))
          rowsRef.current = newListRows
        }
      }
    })
    if (selectedTaskIds.length > 0) {
      rowsRef.current.forEach((x) => {
        if (x.element) {
          x.element.classList.remove(styles.SelectedRow)
        }
      })
      selectedTaskIds.forEach((taskId) => {
        tasks.forEach((task, index) => {
          if (task?.id === taskId) {
            const selectedTask = rowsRef.current.find((t) => t.id === taskId)
            if (selectedTask) {
              selectedTask.element.classList.add(styles.SelectedRow)
            }
          }
          if (task?.stepworks) {
            for (let i = 0; i < task?.stepworks?.length; i++) {
              const stepWork = task?.stepworks[i]
              const haveSubStepWork = task.stepworks.some((st) => st?.isSubStepWork)
              if (haveSubStepWork && task?.id === taskId) {
                const selectedTask = rowsRef.current.find((t) => t.id === task.id + 'SubStepWork')
                if (selectedTask) {
                  selectedTask.element.classList.add(styles.SelectedRow)
                }
              }
              if (stepWork.id === taskId) {
                if (stepWork?.isSubStepWork) {
                  const selectedTask = rowsRef.current.find((t) => t.id === task.id + 'SubStepWork')
                  if (selectedTask) {
                    selectedTask.element.classList.add(styles.SelectedRow)
                  }
                } else {
                  const selectedTask = rowsRef.current.find((t) => t.id === task.id)
                  if (selectedTask) {
                    selectedTask.element.classList.add(styles.SelectedRow)
                  }
                }
              }
            }
          }
        })
      })
    }
    if (highlightedRowsCtrl.length > 1 && selectedTaskIds.length === 1) {
      setHighlightedRowsCtrl([])
    }
  }, [selectedTaskIds, tasks])

  const handleOnContextMenu = (e, task) => {
    e.preventDefault()
    if (selectedTaskIds.length === 0) {
      rowsRef.current.forEach((x) => {
        if (x.element) {
          x.element.classList.remove(styles.SelectedRow)
        }
      })
      const selectedTask = rowsRef.current.find((t) => t.id === task.id)
      if (selectedTask) {
        selectedTask.element.classList.add(styles.SelectedRow)
      }
    }
    onRightClickCell(e, task)
  }

  const handleOnContextMenuEmpty = (e) => {
    e.preventDefault()
  }

  const handleOnLeftClick = (e, task) => {
    if (task.type === 'project') {
      rowsRef.current.forEach((x) => {
        if (x.element) {
          x.element.classList.remove(styles.SelectedRow)
        }
      })
      const selectedTask = rowsRef.current.find((t) => t.id === task.id)
      if (selectedTask) {
        selectedTask.element.classList.add(styles.SelectedRow)
      }
      dispatch(selectedTaskSlice.actions.setSelectedTaskId([]))
      setHighlightedRowsCtrl([])
      onLeftClickCell(e, task)
    }
  }

  const handleOnMouseDown = (event, task, ChannelName) => {
    if (task.type === 'project') return
    if (event.button === 2) {
      if (selectedTaskIds.length === 0) {
        dispatch(selectedTaskSlice.actions.setSelectedTaskId([task.id]))
      }
      return
    }
    if (event.ctrlKey) {
      // add to highlighted
      setHighlightedRowsCtrl((current) => {
        const copyCurrentIds = [...current]

        highlightedRows.forEach((row) => {
          if (!copyCurrentIds.includes(row)) {
            copyCurrentIds.push(row)
          }
        })

        if (copyCurrentIds.length !== 0 && task.groupId !== copyCurrentIds[0].groupId) {
          return current
        }

        if (copyCurrentIds.includes(task)) {
          return copyCurrentIds.filter((entry) => entry !== task)
        } else {
          return [...copyCurrentIds, task]
        }
      })
      setHighlightedRows([])
    } else {
      setHighlightedRows([task])
      onLeftClickCell(event, task)
      setHighlightedRowsCtrl([])
      if (!compareArrays([task.id], selectedTaskIds)) {
        dispatch(selectedTaskSlice.actions.setSelectedTaskId([task.id]))
      }
    }
  }

  useEffect(() => {
    const highlightedRowsCtrlId = highlightedRowsCtrl.map((task) => task.id)
    if (highlightedRowsCtrlId.length !== 0 && !compareArrays(highlightedRowsCtrlId, selectedTaskIds)) {
      dispatch(selectedTaskSlice.actions.setSelectedTaskId(highlightedRowsCtrlId))
    } else {
      dispatch(selectedTaskSlice.actions.setSelectedTaskId(highlightedRows.map((task) => task.id)))
    }
  }, [highlightedRowsCtrl])

  const compareArrays = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2)
  }

  const onChangeTextNameInput = (newName, task) => {
    if (newName !== task.name) {
      const copyTasks = [...tasks]
      const currentTask = copyTasks.find((a) => a.id === task.id)
      if (currentTask) {
        currentTask.name = newName
        onChangeTaskName(task, copyTasks, null)
      }
    }
  }

  const onChangeTextDurationDayInput = (duration, task) => {
    if (duration !== task.duration && !isNaN(Number(duration))) {
      const copyTasks = [...tasks]
      const currentTask = copyTasks.find((a) => a.id === task.id)
      if (currentTask) {
        currentTask.duration = Number(duration)
        onChangeTaskName(task, copyTasks, null)
      }
    }
  }

  const onChangeTextDetailInput = (detail, task) => {
    if (detail !== task.detail) {
      const copyTasks = [...tasks]
      const currentTask = copyTasks.find((a) => a.id === task.id)
      if (currentTask) {
        currentTask.detail = detail
        onChangeTaskName(task, copyTasks, null)
      }
    }
  }

  const onChangeTextNoteInput = (note, task) => {
    if (note !== task.note) {
      const copyTasks = [...tasks]
      const currentTask = copyTasks.find((a) => a.id === task.id)
      if (currentTask) {
        currentTask.note = note
        onChangeTaskName(task, copyTasks, null)
      }
    }
  }

  const onChangeGroupNumber = (groupsNumber, task) => {
    if (groupsNumber !== task.groupsNumber && !isNaN(Number(groupsNumber))) {
      const copyTasks = [...tasks]
      const currentTask = copyTasks.find((a) => a.id === task.id)
      if (currentTask) {
        currentTask.groupsNumber = Number(groupsNumber)
        onChangeTaskName(task, copyTasks, null)
      }
    }
  }

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize
      }}
    >
      {tasks.map((t, index) => {
        let expanderSymbol = ''
        if (t.hideChildren === false) {
          expanderSymbol = (
            <FontAwesomeIcon icon={faChevronDown} className={styles.taskListTable_icon}></FontAwesomeIcon>
          )
        } else if (t.hideChildren === true) {
          expanderSymbol = (
            <FontAwesomeIcon icon={faChevronRight} className={styles.taskListTable_icon}></FontAwesomeIcon>
          )
        }
        let isCustomView = false
        if (location.pathname.includes('custom-view')) {
          isCustomView = true
        }
        const scale = getDurationScale(t, amplifiedFactor)
        const durationDays = t.duration || 0
        const amplifiedDays = durationDays * scale
        const assemblyColorId = stepWorkColors?.find((st) => st.isInstall === 0)?.colorId || -1
        const removalColorId = stepWorkColors?.find((st) => st.isInstall === 1)?.colorId || -1
        let assemblyDays = ''
        let removalDays = ''
        let isSubStepWork = false
        if (t?.stepworks) {
          assemblyDays = 0
          removalDays = 0
          t?.stepworks
            .filter((x) => x.colorId === assemblyColorId)
            .forEach((stepWork) => {
              assemblyDays = assemblyDays + (amplifiedDays * stepWork?.percentStepWork) / 100
            })

          t?.stepworks
            .filter((x) => x.colorId === removalColorId)
            .forEach((stepWork) => {
              removalDays = removalDays + (amplifiedDays * stepWork?.percentStepWork) / 100
            })
          if (assemblyDays !== 0) assemblyDays = assemblyDays?.toFixed(1)
          if (removalDays !== 0) removalDays = removalDays?.toFixed(1)

          if (assemblyDays === 0 || removalDays === 0) {
            assemblyDays = ''
            removalDays = ''
          }
          const filter = t.stepworks.filter((x) => x.isSubStepWork)
          if (filter.length > 0) {
            isSubStepWork = true
          }
        }
        console.log(t?.isDayFormat)
        return (
          <>
            <div
              className={styles.taskListTableRow}
              style={{ height: rowHeight }}
              key={`${t.id}row${t.name}`}
              onContextMenu={(e) => handleOnContextMenu(e, t)}
              onClick={(e) => handleOnLeftClick(e, t)}
              onMouseDown={(e) => handleOnMouseDown(e, t)}
              ref={(el) => {
                const foundTask = rowsRef.current.find((x) => x.id === t.id)
                if (!foundTask && el !== null) {
                  rowsRef.current.push({ id: t.id, element: el })
                }
              }}
            >
              <div
                className={styles.taskListCell}
                style={{
                  minWidth: taskColumnInfo.width,
                  maxWidth: taskColumnInfo.width
                }}
                title={t.displayName}
              >
                <div className={styles.taskListNameWrapper}>
                  <div
                    className={expanderSymbol ? styles.taskListExpander : styles.taskListEmptyExpander}
                    onClick={() => onExpanderClick(t)}
                  >
                    {expanderSymbol}
                  </div>
                  {t.type === 'task' ? (
                    <TextInputTaskList
                      label='name'
                      task={t}
                      value={t.name}
                      onChange={onChangeTextNameInput}
                      style={{
                        marginLeft: '35px',
                        minWidth: `${taskColumnInfo.width - 60}px`,
                        maxWidth: `${taskColumnInfo.width - 60}px`
                      }}
                      columnWidth={taskColumnInfo.width}
                      isHidden={false}
                      isTaskColumn={true}
                    ></TextInputTaskList>
                  ) : (
                    <TextInputTaskList
                      label='grouptask'
                      task={t}
                      value={t.name}
                      onChange={onChangeTextNameInput}
                      columnWidth={taskColumnInfo.width}
                      isHidden={false}
                      isTaskColumn={true}
                    ></TextInputTaskList>
                  )}
                </div>
              </div>
              {t.type === 'task' ? (
                <>
                  {!detailInfo.isHidden && (
                    <TextInputTaskList
                      label='detail'
                      task={t}
                      value={t.detail}
                      onChange={onChangeTextDetailInput}
                      columnWidth={detailInfo.width}
                      isHidden={detailInfo.isHidden}
                      isTaskColumn={false}
                      isShowContent={false}
                    ></TextInputTaskList>
                  )}

                  {!durationInfo.isHidden && (
                    <TextInputTaskList
                      label='durationDays'
                      task={t}
                      value={isCustomView ? '' : durationDays?.toFixed(1)}
                      onChange={onChangeTextDurationDayInput}
                      columnWidth={durationInfo.width}
                      isHidden={durationInfo.isHidden}
                      isTaskColumn={false}
                      isNumber={true}
                      style={{ textAlign: 'center' }}
                      isShowContent={false}
                    ></TextInputTaskList>
                  )}

                  {!groupsInfo.isHidden && (
                    <TextInputTaskList
                      task={t}
                      label='groupsNumber'
                      value={isCustomView ? '' : t?.groupsNumber || '-'}
                      onChange={onChangeGroupNumber}
                      columnWidth={groupsInfo.width}
                      isHidden={groupsInfo.isHidden}
                      isTaskColumn={false}
                      isNumber={true}
                      style={{ textAlign: 'center' }}
                      isInteger={true}
                      isShowContent={false}
                    ></TextInputTaskList>
                  )}

                  {!amplifiedInfo.isHidden && (
                    <TaskListCell
                      rowWidth={amplifiedInfo.width + 'px'}
                      isHidden={amplifiedInfo.isHidden}
                      // content={amplifiedDays.toFixed(1)}
                      content={
                        t?.isDayFormat === false ? (amplifiedDays / 30).toFixed(1) + 'ヶ月' : amplifiedDays.toFixed(1)
                      }
                    ></TaskListCell>
                  )}

                  {!assemblyInfo.isHidden && (
                    <TaskListCell
                      rowWidth={assemblyInfo.width + 'px'}
                      isHidden={assemblyInfo.isHidden}
                      content={isCustomView ? '' : assemblyDays}
                    ></TaskListCell>
                  )}

                  {!removalInfo.isHidden && (
                    <TaskListCell
                      rowWidth={removalInfo.width + 'px'}
                      isHidden={removalInfo.isHidden}
                      content={isCustomView ? '' : removalDays}
                    ></TaskListCell>
                  )}
                  {!noteInfo.isHidden && (
                    <TextInputTaskList
                      task={t}
                      label='note'
                      value={t.note}
                      onChange={onChangeTextNoteInput}
                      columnWidth={noteInfo.width}
                      isHidden={noteInfo.isHidden}
                      isTaskColumn={false}
                    ></TextInputTaskList>
                  )}
                </>
              ) : (
                <>
                  <TaskListCell rowWidth={detailInfo.width + 'px'} isHidden={detailInfo.isHidden}></TaskListCell>
                  <TaskListCell rowWidth={durationInfo.width + 'px'} isHidden={durationInfo.isHidden}></TaskListCell>
                  <TaskListCell rowWidth={groupsInfo.width + 'px'} isHidden={groupsInfo.isHidden}></TaskListCell>
                  <TaskListCell
                    rowWidth={amplifiedInfo.width + 1 + 'px'}
                    isHidden={amplifiedInfo.isHidden}
                  ></TaskListCell>
                  <TaskListCell
                    rowWidth={assemblyInfo.width + 1 + 'px'}
                    isHidden={assemblyInfo.isHidden}
                  ></TaskListCell>
                  <TaskListCell rowWidth={removalInfo.width + 1 + 'px'} isHidden={removalInfo.isHidden}></TaskListCell>
                  <TaskListCell rowWidth={noteInfo.width + 'px'} isHidden={noteInfo.isHidden}></TaskListCell>
                </>
              )}
            </div>
            {isSubStepWork && (
              <div
                className={styles.taskListTableRow}
                style={{ height: rowHeight }}
                key={`${t.id}row${t.name} isSubStepWork`}
                onContextMenu={handleOnContextMenuEmpty}
                ref={(el) => {
                  const foundTask = rowsRef.current.find((x) => x.id === t.id + 'SubStepWork')
                  if (!foundTask && el !== null) {
                    rowsRef.current.push({ id: t.id + 'SubStepWork', element: el })
                  }
                }}
              >
                <TextInputEmpty rowWidth={detailInfo.width + 'px'} isHidden={detailInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty rowWidth={durationInfo.width + 'px'} isHidden={durationInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty rowWidth={groupsInfo.width + 'px'} isHidden={groupsInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty
                  rowWidth={amplifiedInfo.width + 'px'}
                  isHidden={amplifiedInfo.isHidden}
                ></TextInputEmpty>
                <TextInputEmpty rowWidth={assemblyInfo.width + 'px'} isHidden={assemblyInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty rowWidth={removalInfo.width + 'px'} isHidden={removalInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty rowWidth={noteInfo.width + 'px'} isHidden={noteInfo.isHidden}></TextInputEmpty>
                <TextInputEmpty columnWidth={taskColumnInfo.width}></TextInputEmpty>
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}
