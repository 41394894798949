import React, { useEffect, useRef } from 'react'
import { getStartEndDateForProject } from './initData'
import PopoverGridComponent from './components/PopoverGridComponent'
import PopoverHeaderComponent from './components/PopoverHeaderComponent'
import PopoverProjectComponent from './components/PopoverProjectComponent'
import PopoverTaskComponent from './components/PopoverTaskComponent'
import { TaskListHeader } from './components/TaskListHeader'
import { TaskListTableCustom } from './components/TaskListTableCustom'
import { Gantt, ViewMode } from './grant-base'
import styles from './MainChart.module.scss'
import MainChartHeader from './components/MainChartHeader'
import HiddenColumnModal from './components/Modals/HiddenColumnModal'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import ColorPicker from './components/Modals/VersionSettingComponent/components/ColorPicker'
import { ToastContainer } from 'react-toastify'
import { getVersionSetting } from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'
import PopoverTaskBarComponent from './components/PopoverTaskBarComponent'
import SetStepWorkComponent from './components/Modals/SetStepWorkComponent'
import SetPredecessorsComponent from './components/Modals/SetPredecessorsComponent'
import ImportDataModal from './components/Modals/ImportDataModal'
import CreateViewModal from './components/Modals/CreateViewModal'
import MoveGroupTaskModal from './components/Modals/MoveGroupTaskModal'
import { SortTasksByProjects } from 'F_UTILS/SchedulingUtils/GroupTaskUtils'
import { columnsSlice } from 'C_REDUX_STORE/scheduleTool/columnsSlice'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import VersionSettingComponent from './components/Modals/VersionSettingComponent'
import { Skeleton } from 'antd'
import { isValidateTasks } from './grant-base/helpers/bar-helper'
import { usePrevious } from './hooks/usePrevious'
import { ToastError } from 'F_UTILS/ToastUtils'
import { ErrorBoundary } from 'A_SHARED_COMPONENT/ErrorBoundary'
import ImportUpdateDataModal from './components/Modals/ImportUpdateDataModal'

export default function MainChart() {
  const { setWithPrevious, getPrevious } = usePrevious([])
  const [tasks, setTasks] = React.useState([])
  const [taskCell, setTaskCell] = React.useState(null)
  const [event, setEvent] = React.useState(null)
  const [selectedTask, setSelectedTask] = React.useState(null)
  const [index, setIndex] = React.useState()

  const [excelInfo, setExcelInfo] = React.useState({})

  const [headerName, setHeaderName] = React.useState(null)

  const [isRightTaskClick, setRightTaskClick] = React.useState(false)
  const [isRightHeaderClick, setRightHeaderClick] = React.useState(false)
  const [isRightCellClick, setRightCellClick] = React.useState(false)
  const [isRightGridClick, setRightGridClick] = React.useState(false)
  const [isSetStepWork, setIsSetStepWork] = React.useState(false)
  const [isSetPredecessor, setIsSetPredecessor] = React.useState(false)
  const [showWindowUnhideColumns, setShowWindowUnhideColumns] = React.useState(false)
  const [showCreateView, setShowCreateView] = React.useState(false)
  const [showMoveGroupTask, setShowMoveGroupTask] = React.useState(false)

  const currentVersion = useSelector((state) => state.currentVersion)
  const currentSetting = useSelector((state) => state.versionSetting.current)

  const showModal = useSelector((state) => state.showModal)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoadingData, setIsLoadingData] = React.useState(false)
  const tableWidth = useSelector((state) => state.tableWidth)
  const [isNotiValidateTasks, setNotiValidateTasks] = React.useState(false)
  useEffect(() => {
    const { columnWidth, numberOfMonths } = currentSetting
    const svgWidth = columnWidth * numberOfMonths
    const previousValue = getPrevious()
    if (previousValue.length !== 0) {
      if (!isValidateTasks(tasks, svgWidth)) {
        setNotiValidateTasks(true)
      } else {
        setNotiValidateTasks(false)
      }
    }
    setWithPrevious(tasks)
  }, [tasks, currentSetting])

  useEffect(() => {
    if (isNotiValidateTasks) {
      ToastError('チャトレの範囲に外れているタスクがあります。もう一度チェックしてください。')
    }
  }, [isNotiValidateTasks])

  useEffect(() => {
    if (currentVersion.versionId === -1) {
      navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
    }
    const fetchData = async () => {
      try {
        const actions = await getVersionSetting(currentVersion.versionId)
        await dispatch(actions)
      } catch (error) {}
    }
    fetchData()

    dispatch(
      columnsSlice.actions.resetColumn({
        tableWidth: tableWidth
      })
    )
    const alertUser = (e) => {
      e.preventDefault()
      e.returnValue = ''
    }

    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataSetting = {
          columnWidth: currentSetting.columnWidth || -1,
          amplifiedFactor: currentSetting.amplifiedFactor || -1
        }
        setIsLoadingData(true)
        const versionTasksDetail = await VersionApi.GetVersionTaskDetails(currentVersion.versionId, dataSetting)
        const serverTasks = versionTasksDetail.data.map((t) => {
          if (t?.stepworks) {
            const newStepWorks = t.stepworks.map((x) => {
              return { ...x, groupsNumber: t.groupsNumber }
            })
            return { ...t, stepworks: newStepWorks }
          } else {
            return t
          }
        })
        setIsLoadingData(false)
        setTasks(serverTasks)
        setWithPrevious(serverTasks)
      } catch (error) {
        navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
      }
    }
    fetchData()
  }, [currentSetting.columnWidth, currentSetting.amplifiedFactor, currentVersion.versionId, navigate])
  //
  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t))
    if (task.groupId) {
      const [start, end] = getStartEndDateForProject(newTasks, task.groupId)
      const project = newTasks[newTasks.findIndex((t) => t.id === task.groupId)]
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) {
        const changedProject = { ...project, start, end }
        newTasks = newTasks.map((t) => (t.id === task.groupId ? changedProject : t))
      }
    }
    setTasks(newTasks)
    setRightTaskClick(false)
  }

  const handleTaskDelete = (task) => {}

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)))
  }

  const handleDblClick = (task) => {
    setRightTaskClick(false)
  }

  const handleClick = (task) => {
    setRightTaskClick(false)
    setRightGridClick(false)
    setRightHeaderClick(false)
    setRightCellClick(false)
    console.log('click')
  }

  const handleSelect = (task, isSelected) => {
    setRightTaskClick(false)
    setRightGridClick(false)
    setRightHeaderClick(false)
    setRightCellClick(false)
  }

  const handleExpanderClick = (task) => {
    let newTask = tasks.map((t) => (t.id === task.id ? task : t))
    if (task?.type === 'project' && task?.hideChildren === false) {
      newTask = SortTasksByProjects(newTask)
    }
    setTasks(newTask)
  }

  const handleRightTaskClick = (task, event) => {
    setEvent(event)
    setSelectedTask(task)
    setRightTaskClick(true)
    setRightGridClick(false)
    setRightHeaderClick(false)
    setRightCellClick(false)
  }

  const handleGridClick = () => {
    setRightGridClick(false)
    setRightTaskClick(false)
    setRightHeaderClick(false)
    setRightCellClick(false)
  }

  const handleRightGridClick = (index, event) => {
    setIndex(index)
    setEvent(event)
    setRightGridClick(true)
    setRightTaskClick(false)
    setRightHeaderClick(false)
    setRightCellClick(false)
  }

  const handleRightHeaderClick = (headerName, event) => {
    setEvent(event)
    setHeaderName(headerName)
    setRightHeaderClick(true)
    setRightTaskClick(false)
    setRightGridClick(false)
    setRightCellClick(false)
  }

  const handleRightCellClick = (event, task) => {
    setEvent(event)
    setTaskCell(task)
    setRightCellClick(true)
    setRightHeaderClick(false)
    setRightTaskClick(false)
    setRightGridClick(false)
  }

  const handleLeftHeaderClick = () => {
    setRightCellClick(false)
    setRightHeaderClick(false)
    setRightTaskClick(false)
    setRightGridClick(false)
    setEvent(null)
  }

  const handleLeftCellClick = (event, task) => {
    setTaskCell(task)
    setRightCellClick(false)
    setRightHeaderClick(false)
    setRightTaskClick(false)
    setRightGridClick(false)
    setEvent(null)
  }

  const handleOnChangeTaskName = (task, eventTasks, event) => {
    setTasks(eventTasks)
  }

  return (
    <div className={styles.wrapper}>
      {/* Modal and Popover */}
      {isRightTaskClick && (
        <PopoverTaskBarComponent
          event={event}
          tasks={tasks}
          task={selectedTask}
          setTasks={setTasks}
          setRightTaskClick={setRightTaskClick}
          setIsSetPredecessor={setIsSetPredecessor}
        ></PopoverTaskBarComponent>
      )}
      {isRightGridClick &&
        //temporary close => false
        false && (
          <PopoverGridComponent
            event={event}
            index={index}
            setRightGridClick={setRightGridClick}
          ></PopoverGridComponent>
        )}

      {isRightHeaderClick && (
        <PopoverHeaderComponent
          event={event}
          headerName={headerName}
          index={index}
          setRightHeaderClick={setRightHeaderClick}
          setShowWindowUnhideColumns={setShowWindowUnhideColumns}
        ></PopoverHeaderComponent>
      )}

      {isRightCellClick && taskCell.type === 'project' && (
        <PopoverProjectComponent
          event={event}
          tasks={tasks}
          setTasks={setTasks}
          task={taskCell}
          setTaskCell={setTaskCell}
          setRightCellClick={setRightCellClick}
          setShowMoveGroupTask={setShowMoveGroupTask}
        ></PopoverProjectComponent>
      )}

      {isRightCellClick && taskCell.type === 'task' && (
        <PopoverTaskComponent
          event={event}
          tasks={tasks}
          setTasks={setTasks}
          task={taskCell}
          setTaskCell={setTaskCell}
          setRightCellClick={setRightCellClick}
          setIsSetStepWork={setIsSetStepWork}
          setShowMoveGroupTask={setShowMoveGroupTask}
        ></PopoverTaskComponent>
      )}
      {/* Modal */}
      {showWindowUnhideColumns && (
        <HiddenColumnModal setShowWindowUnhideColumns={setShowWindowUnhideColumns}></HiddenColumnModal>
      )}
      {showModal.isShow && showModal.windowName === 'VersionSettingComponent' && (
        <VersionSettingComponent tasks={tasks}></VersionSettingComponent>
      )}
      {showModal.isShow && showModal.windowName === 'VersionSettingComponent' && showModal.event !== null && (
        <ColorPicker
          event={showModal.event}
          id={showModal.id}
          nameColor={showModal.name}
          isTaskColor={showModal.isTaskColor}
        ></ColorPicker>
      )}
      {isSetStepWork && (
        <SetStepWorkComponent
          task={taskCell}
          setIsSetStepWork={setIsSetStepWork}
          tasks={tasks}
          setTasks={setTasks}
        ></SetStepWorkComponent>
      )}
      {isSetPredecessor && (
        <SetPredecessorsComponent
          tasks={tasks}
          task={selectedTask}
          setTasks={setTasks}
          setIsSetPredecessor={setIsSetPredecessor}
        ></SetPredecessorsComponent>
      )}
      {showCreateView && <CreateViewModal tasks={tasks} setShowCreateView={setShowCreateView}></CreateViewModal>}
      {showMoveGroupTask && (
        <MoveGroupTaskModal
          task={taskCell}
          tasks={tasks}
          setTasks={setTasks}
          setShowMoveGroupTask={setShowMoveGroupTask}
        ></MoveGroupTaskModal>
      )}

      {excelInfo?.isOpen && excelInfo.type === 'import' && (
        <ImportDataModal
          tasks={tasks}
          setExcelInfo={setExcelInfo}
          excelInfo={excelInfo}
          setTasks={setTasks}
        ></ImportDataModal>
      )}

      {excelInfo?.isOpen && excelInfo.type === 'update' && (
        <ImportUpdateDataModal
          tasks={tasks}
          setExcelInfo={setExcelInfo}
          excelInfo={excelInfo}
          setTasks={setTasks}
        ></ImportUpdateDataModal>
      )}

      <MainChartHeader
        task={taskCell}
        tasks={tasks}
        setTasks={setTasks}
        setTaskCell={setTaskCell}
        setExcelInfo={setExcelInfo}
        setShowCreateView={setShowCreateView}
        setShowMoveGroupTask={setShowMoveGroupTask}
        setRightCellClick={setRightCellClick}
        showCreateView={showCreateView}
      ></MainChartHeader>
      <ErrorBoundary
        fallback={
          <div className='my-44 mx-auto font-medium text-2xl'>Something went wrong! Please contact to the admin.</div>
        }
      >
        {!isLoadingData && (
          <Gantt
            locale='jpn'
            handleWidth={3}
            tasks={tasks}
            setTasks={setTasks}
            viewMode={ViewMode.Month}
            TaskListHeader={TaskListHeader}
            TaskListTable={TaskListTableCustom}
            onClickEvent={handleGridClick}
            onRightClickGrid={handleRightGridClick}
            onRightClickHeader={handleRightHeaderClick}
            onRightClickCell={handleRightCellClick}
            onLeftClickCell={handleLeftCellClick}
            onLeftClickHeader={handleLeftHeaderClick}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onRightClick={handleRightTaskClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            onChangeTaskName={handleOnChangeTaskName}
            listCellWidth={true ? '155px' : ''}
            ganttHeight={700}
            barBackgroundColor={currentSetting?.stepworkColors?.at(0)?.code || '#0000FF'}
            columnWidth={currentSetting?.columnWidth}
          />
        )}
      </ErrorBoundary>
      {isLoadingData && (
        <>
          <Skeleton title={false} paragraph={{ rows: 40, width: '100%' }} />
        </>
      )}
      <ToastContainer style={{ width: '650px', zIndex: 10000000 }} />
    </div>
  )
}
