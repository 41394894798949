import React from 'react'
import ReactDOM from 'react-dom'
import style from './ConfirmModal.module.scss'

export default function ConfirmModal({ open = false, handleClose = () => {}, handleOK, handleCancel, setOpenModal }) {
  const clickHandleOK = () => {
    if (handleOK) handleOK()
    if (setOpenModal) setOpenModal(false)
  }

  const clickHandleCancel = () => {
    if (handleCancel) handleCancel()
    if (setOpenModal) setOpenModal(false)
  }

  if (typeof document === 'undefined') return <div className='modal'></div>
  if (open) {
    return ReactDOM.createPortal(
      <div className='overlay' style={{ zIndex: 1000001 }}>
        <div style={{ position: 'relative' }}>
          <div className={style.dialog_container}>
            <h4 style={{ marginTop: '40px', fontSize: '20px' }}>プロジェクトを保存したいですか。</h4>
          </div>
          <div
            style={{
              width: '100%',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              marginTop: '25px',
              position: 'absolute',
              top: '65px'
            }}
          >
            <button className='btn-primary' onClick={clickHandleOK}>
              OK
            </button>
            <button className='btn-secondary' onClick={clickHandleCancel}>
              キャンセル
            </button>
          </div>
        </div>
      </div>,
      document.querySelector('body')
    )
  }
}
