import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import { ConvertStringMonthsToArray, convertArrayToString } from 'F_UTILS/SchedulingUtils/ConvertArrayMonthsToString'

// createAsyncThunk cái này sử dụng cho projectsetting
export const getVersionSetting = createAsyncThunk('VersionSetting/GetAllVersionSetting', async (payload) => {
  try {
    const response = await VersionApi.GetVersionSettingById(payload)
    var data = response.data

    data.stepworkColors = data.stepworkColors.map((d) => {
      return { ...d, id: nanoid() }
    })
    data.backgroundColors = data.backgroundColors.map((d) => {
      var dateRanges = convertDateSettingServerToLocal(d?.dateRanges || [], data?.includeYear || false)
      return { ...d, dateRanges, id: nanoid() }
    })
    return data
  } catch (error) {
    console.log(error)
    return error.message
  }
})

const convertDateSettingServerToLocal = (dateRanges, includeYear) => {
  let newDateRanges = []
  for (let index = 0; index < dateRanges?.length; index++) {
    const range = dateRanges[index]
    let startDay = 1
    if (range.startDate == 10) startDay = 1
    if (range.startDate == 20) startDay = 10
    if (range.startDate == 30) startDay = 20

    let endDay = 10
    if (range.toDate == 10) endDay = 10
    if (range.toDate == 20) endDay = 20
    if (range.toDate == 30) endDay = 30

    var displayTextFull = `${range.startYear}/${range.startMonth}/${startDay} - ${range.toYear}/${range.toMonth}/${endDay}`
    var displayText = `${range.startMonth}/${startDay} - ${range.toMonth}/${endDay}`
    var dateRange = {
      checked: range.checked,
      displayText: includeYear ? displayTextFull : displayText,
      startDate: startDay,
      startMonth: range.startMonth,
      startYear: range.startYear,
      toDate: endDay,
      toMonth: range.toMonth,
      toYear: range.toYear
    }
    newDateRanges.push(dateRange)
  }

  return newDateRanges
}

export const updateVersionSetting = createAsyncThunk('VersionSetting/updateVersionSetting', async (projectSetting) => {
  try {
    const response = await VersionApi.UpdateVersionSettingById(projectSetting.versionId, projectSetting)
    return response.status
  } catch (error) {
    return error.message
  }
})

const versionSettingSlice = createSlice({
  name: 'versionSetting',
  initialState: {
    status: 'idle',
    current: {
      projectSettingId: 0,
      //projectId
      versionId: 0,
      separateGroupTask: false,
      assemblyDurationRatio: 0.6,
      removalDurationRatio: 0.4,
      amplifiedFactor: 1.7,
      stepworkColors: [],
      backgroundColors: [],
      numberOfMonths: 10,
      columnWidth: 70,
      includeYear: false,
      startYear: 2023,
      startMonth: 1
    },
    past: {}
  },
  reducers: {
    backupState: (state) => {
      state.past = { ...state.current }
    },
    undoState: (state) => {
      state.current = { ...state.past }
    },
    changeAssembly: (state, action) => {
      state.current.assemblyDurationRatio = action.payload
    },
    changeAmplifiedFactor: (state, action) => {
      state.current.amplifiedFactor = action.payload
    },
    changeRemoval: (state, action) => {
      state.current.removalDurationRatio = action.payload
    },
    changeSeparate: (state, action) => {
      state.current.separateGroupTask = action.payload
    },
    removeColorInTaskColorList: (state, action) => {
      state.current.stepworkColors = state.current.stepworkColors.filter((x) => x.id !== action.payload)
      //state.current.stepworkColors = state.current.stepworkColors.filter((x) => x.name !== action.payload)
    },
    updateInputVersionSetting: (state, action) => {
      // Change assemblyDurationRatio, removalDurationRatio, numberOfMonths, columnWidth, amplifiedFactor
      state.current.assemblyDurationRatio = action.payload.assemblyDurationRatio
      state.current.removalDurationRatio = action.payload.removalDurationRatio
      state.current.numberOfMonths = action.payload.numberOfMonths
      state.current.columnWidth = action.payload.columnWidth
      state.current.amplifiedFactor = action.payload.amplifiedFactor
      state.current.includeYear = action.payload.includeYear
      state.current.startYear = action.payload.startYear
      state.current.startMonth = action.payload.startMonth
    },
    renameColorInTaskColorList: (state, action) => {
      if (action.payload?.id === undefined) {
        state.current.stepworkColors = state.current.stepworkColors.map((x) => {
          if (x.id === action.payload.id) {
            return { ...x, name: action.payload.name }
          } else {
            return x
          }
        })
      }
    },
    modifyColorInTaskColorList: (state, action) => {
      if (action.payload?.id !== undefined) {
        state.current.stepworkColors = state.current.stepworkColors.map((x) => {
          if (x.id === action.payload.id) {
            return { ...x, code: action.payload.color }
          } else {
            return x
          }
        })
      }
    },
    addColorInTaskColorList: (state, action) => {
      const index = state.current.stepworkColors.findIndex((x) => x.name === action.payload.name)
      if (index === -1) {
        state.current.stepworkColors.push({
          name: action.payload.name,
          code: action.payload.color,
          id: action.payload.id
        })
      }
    },
    // Background color
    removeColorInBackgroundColorList: (state, action) => {
      state.current.backgroundColors = state.current.backgroundColors.filter((x) => x.id !== action.payload)
    },
    renameBackgroundColorList: (state, action) => {
      if (action.payload?.id !== undefined) {
        state.current.backgroundColors = state.current.backgroundColors.map((x) => {
          if (x.id === action.payload.id) {
            return { ...x, name: action.payload.name }
          } else {
            return x
          }
        })
      }
    },
    modifyColorInBackgroundColorList: (state, action) => {
      if (action.payload?.id !== undefined) {
        state.current.backgroundColors = state.current.backgroundColors.map((x) => {
          if (x.id === action.payload.id) {
            return { ...x, code: action.payload.color }
          } else {
            return x
          }
        })
      }
    },
    addColorInBackgroundColorList: (state, action) => {
      const index = state.current.backgroundColors.findIndex((x) => x.name === action.payload.name)
      if (index === -1) {
        state.current.backgroundColors.push({
          name: action.payload.name,
          code: action.payload.color,
          id: action.payload.id
        })
      }
    },
    changeBackgroundColorMonth: (state, action) => {
      if (action.payload?.id !== undefined) {
        state.current.backgroundColors = state.current.backgroundColors.map((x) => {
          if (x.id === action.payload.id) {
            const arrMonths = ConvertStringMonthsToArray(action.payload.months)
            return { ...x, displayMonths: action.payload.months, months: arrMonths }
          } else {
            return x
          }
        })
      }
    },
    updateBackgroundList: (state, action) => {
      state.current.backgroundColors = action.payload
    },
    changeIncludeYear: (state, action) => {
      state.current.includeYear = action.payload
    },
    changeBackgroundColor: (state, action) => {
      const displayText = action.payload.displayText
      const background = action.payload.background
      state.current.backgroundColors = state.current.backgroundColors.map((bc) => {
        bc.dateRanges = bc?.dateRanges?.map((date) => {
          if (date.displayText === displayText) {
            return background
          }
          return date
        })
        return bc
      })
    },

    addNewBackgroundColor: (state, action) => {
      const backgroundName = action.payload.backgroundName
      const background = action.payload.background
      state.current.backgroundColors = state.current.backgroundColors.map((bc) => {
        if (backgroundName === bc.name) {
          if (bc?.dateRanges == null) {
            bc = { ...bc, dateRanges: [] }
          }
          bc?.dateRanges?.push(background)
        }
        return bc
      })
    },
    changeBackgroundColorMonthInChart: (state, action) => {
      var month = action.payload.month
      var colorId = action.payload.id
      var listColorInState = state.current.backgroundColors
      if (month !== undefined && colorId !== undefined) {
        for (let i = 0; i < listColorInState.length; i++) {
          const color = listColorInState[i]
          const index = color?.months?.indexOf(month)
          if (index > -1 && index !== undefined) {
            color.months.splice(index, 1)
            color.displayMonths = convertArrayToString(color.months)
          }
        }
        state.current.backgroundColors = state.current.backgroundColors.map((x) => {
          if (x.id === action.payload.id) {
            if (x.months) {
              x.months.push(month)
            } else {
              x.months = [month]
            }
            x.displayMonths = convertArrayToString(x.months)
            return x
          } else {
            return x
          }
        })
      }
    },
    changeNumberOfMonths: (state, action) => {
      state.current.numberOfMonths = action.payload
    },
    changeColumnWidth: (state, action) => {
      state.current.columnWidth = action.payload
    }
  },
  extraReducers: {
    [getVersionSetting.pending]: (state, action) => {
      state.status = 'loading'
    },
    [getVersionSetting.fulfilled]: (state, action) => {
      state.current = action.payload
      state.status = 'succeeded'
    },
    [getVersionSetting.rejected]: (state, action) => {
      state.status = action.error.message
    },
    [updateVersionSetting.fulfilled]: (state, action) => {
      state.status = 'succeeded'
    }
  }
})

const { actions, reducer } = versionSettingSlice
export const {} = actions
export const {
  backupState,
  undoState,
  changeAssembly,
  changeRemoval,
  changeSeparate,
  changeAmplifiedFactor,
  changeNumberOfMonths,
  updateInputVersionSetting,
  removeColorInTaskColorList,
  renameColorInTaskColorList,
  modifyColorInTaskColorList,
  addColorInTaskColorList,
  updateBackgroundList,
  removeColorInBackgroundColorList,
  renameBackgroundColorList,
  modifyColorInBackgroundColorList,
  addColorInBackgroundColorList,
  changeBackgroundColorMonth,
  changeBackgroundColorMonthInChart,
  changeColumnWidth,
  changeIncludeYear,
  changeBackgroundColor,
  addNewBackgroundColor
} = actions
export default reducer
