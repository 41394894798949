import convertFile from "../../X_ASSETS/ConvertFile.png";

const ConvertFileServices = () => {
  const nameTool = "Convert File";
  const version = "バージョン 1.0.0";
  return (
    <div>
      <div className="main-container">
        <div className="content--container">
          <img src={convertFile} alt={nameTool} className="class--image"></img>
          <div className="text-swapper">
            <h3>{nameTool}</h3>
            <p>{version}</p>
          </div>
        </div>
        <h3>{"We are developing. Please visit later."}</h3>
      </div>
    </div>
  );
};
export default ConvertFileServices;
