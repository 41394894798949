import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { BarTask } from '../../types/bar-task'
import { Task } from '../../types/public-types'
import styles from './task-list.module.css'
import { useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { isBuffer } from 'lodash'
import { VerticalScroll } from '../other/vertical-scroll'

export type TaskListProps = {
  headerHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
  rowHeight: number
  ganttHeight: number
  scrollY: number
  locale: string
  tasks: Task[]
  taskListRef: React.RefObject<HTMLDivElement>
  horizontalContainerClass?: string
  selectedTask: BarTask | undefined
  setSelectedTask: (task: string) => void
  onExpanderClick: (task: Task) => void
  onRightClickHeader: (headerName: string, event: React.MouseEvent) => void
  onRightClickCell: (task: Task, event: React.MouseEvent) => void
  onLeftClickHeader: (headerName: string, event: React.MouseEvent) => void
  onLeftClickCell: (task: Task, event: React.MouseEvent) => void
  onChangeTaskName: (task: Task, tasks: Task[], event: React.MouseEvent) => void
  onScroll: (event: SyntheticEvent<HTMLDivElement>) => void
  scroll: number
  ganttFullHeight: number
  setScrollY: React.Dispatch<React.SetStateAction<number>>
  setIgnoreScrollEvent: React.Dispatch<React.SetStateAction<boolean>>
  handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  TaskListHeader: React.FC<{
    headerHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: string
  }>
  TaskListTable: React.FC<{
    rowHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: string
    locale: string
    tasks: Task[]
    selectedTaskId: string
    setSelectedTask: (taskId: string) => void
    onExpanderClick: (task: Task) => void
  }>
}

export const TaskList: React.FC<TaskListProps> = ({
  headerHeight,
  fontFamily,
  fontSize,
  rowWidth,
  rowHeight,
  scrollY,
  tasks,
  selectedTask,
  setSelectedTask,
  onExpanderClick,
  onRightClickHeader,
  onRightClickCell,
  onLeftClickCell,
  onLeftClickHeader,
  onChangeTaskName,
  locale,
  ganttHeight,
  taskListRef,
  horizontalContainerClass,
  TaskListHeader,
  TaskListTable,
  scroll,
  ganttFullHeight,
  setScrollY,
  setIgnoreScrollEvent
}) => {
  const columnsInfo = useSelector((state: RootState) => state.columnsInfo)
  const horizontalContainerRef = useRef<HTMLDivElement>(null)
  const tableWidth = useSelector((state: RootState) => state.tableWidth)

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY
    }
  }, [scrollY, columnsInfo, tableWidth])

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scroll
    }
  }, [scroll])

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      console.log(1212)
      if (ganttHeight) {
        let newScrollY = scrollY + event.deltaY
        if (newScrollY < 0) {
          newScrollY = 0
        } else if (newScrollY > ganttFullHeight - ganttHeight) {
          newScrollY = ganttFullHeight - ganttHeight
        }
        if (newScrollY !== scrollY) {
          setScrollY(newScrollY)
          event.preventDefault()
        }
      }
      setIgnoreScrollEvent(true)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (this !== event.target) return
      // event.preventDefault()
      let newScrollY = scrollY

      let isX = true
      switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          newScrollY += rowHeight
          isX = false
          break
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          newScrollY -= rowHeight
          isX = false
          break
      }
      if (!isX) {
        if (newScrollY < 0) {
          newScrollY = 0
        } else if (newScrollY > ganttFullHeight - ganttHeight) {
          newScrollY = ganttFullHeight - ganttHeight
        }
        setScrollY(newScrollY)
      }
      setIgnoreScrollEvent(true)
    }
    // subscribe if scroll is necessary
    horizontalContainerRef.current?.addEventListener('wheel', handleWheel, {
      passive: false
    })
    return () => {
      horizontalContainerRef.current?.removeEventListener('wheel', handleWheel)
    }
  }, [horizontalContainerRef, scrollY, ganttHeight, ganttFullHeight])

  const headerProps = {
    headerHeight,
    fontFamily,
    fontSize,
    rowWidth,
    onRightClickHeader,
    onLeftClickHeader,
    tableWidth
  }
  const selectedTaskId = selectedTask ? selectedTask.id : ''
  const tableProps = {
    rowHeight,
    rowWidth,
    fontFamily,
    fontSize,
    tasks,
    locale,
    selectedTaskId: selectedTaskId,
    setSelectedTask,
    onExpanderClick,
    onRightClickCell,
    onLeftClickCell,
    onChangeTaskName,
    tableWidth
  }

  return (
    <div ref={taskListRef} className={styles.taskListScroll}>
      <TaskListHeader {...headerProps} />
      <div
        ref={horizontalContainerRef}
        className={horizontalContainerClass}
        style={ganttHeight ? { height: ganttHeight } : {}}
      >
        <TaskListTable {...tableProps} />
      </div>
    </div>
  )
}
