import React from "react";
import ForgeAPI from "../../../../B_API/ForgeAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import StorageKeys from "../../../../F_UTILS/Constants/StorageKeys";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenameLayerApi from "../../../../B_API/RenameLayerApi";
import { RemoveColNewLayerName } from "../../../../F_UTILS/CSVRenameLayerUtils";
import { setJobId } from "../../../../C_REDUX_STORE/jobSlice";
import { useDispatch, useSelector } from "react-redux";

export default function HeaderComponent({
  setProgressIsRunning,
  setMessageProgress,
  setMessageCompleted,
  setShowDialog,
  setListNames,
  setOriginName,
  setViewInfor,
  setBtnDisable,
}) {
  const jobId = useSelector((state) => state.jobId.current);
  const dispatch = useDispatch();

  function handleInputDwgClick() {
    const realFileInput = document.getElementById("input-file-dwg");
    realFileInput.value = "";
  }
  async function handleUploadFile() {
    const realFileInput = document.getElementById("input-file-dwg");
    realFileInput.click();
  }
  async function handleInputDwgChange() {
    const realFileInput = document.getElementById("input-file-dwg");
    if (!realFileInput?.files[0]?.name?.includes(".dwg")) {
      toast.error("ファイルが無効です。 もう一度アップロードしてください。", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return;
    }

    var file = new FormData();
    file.append("file", realFileInput.files[0]);
    const labelFileName = document.querySelector(".file--name label");

    setProgressIsRunning(true);
    setMessageProgress("しばらくお待ちください。");
    setShowDialog(true);
    const res = await uploadFile(file);
    RemoveColNewLayerName();
    if (res?.status === 200) {
      const jobId = res.data.jobId;
      var action = setJobId(jobId);
      dispatch(action);

      const response = await runGetLayerNameList(jobId);
      // Tạo job thất bại
      if (response?.status !== 200 || response?.data?.status !== 1) {
        setProgressIsRunning(false);
        setMessageCompleted("アップロード出来ませんでした。");
        setBtnDisable(true);
        return;
      }

      // set interval để lấy việc thông tin lấy được list block name
      let workId = setInterval(async () => {
        try {
          const responseWorkStatus = await ForgeAPI.GetWorkStatus(
            response.data.id
          );
          //work thất bại
          if (responseWorkStatus.status !== 200) {
            clearInterval(workId);
            setProgressIsRunning(false);
            setMessageCompleted("アップロード出来ませんでした。");
            setBtnDisable(true);
          }

          const listSuccessStatus = [1, 2, 9];
          if (!listSuccessStatus.includes(responseWorkStatus.data.status)) {
            clearInterval(workId);
            setProgressIsRunning(false);
            setMessageCompleted("アップロード出来ませんでした。");
            setBtnDisable(true);
          } else {
            if (responseWorkStatus.data.status === 9) {
              clearInterval(workId);

              const response = await RenameLayerApi.GetAllLayerName(jobId);
              let listLayers = response.data;

              listLayers.forEach(function (element) {
                element.NewLayerName = "";
                element.NewIndexColor = "";
              });
              setListNames(listLayers);
            }
          }
        } catch (error) {
          clearInterval(workId);
          setProgressIsRunning(false);
          setMessageCompleted("アップロード出来ませんでした。");
          setBtnDisable(true);
        }
      }, 3000);

      // set interval để lấy thông tin việc convert viewer
      let id = setInterval(async () => {
        try {
          const translationRes = await getTranslationStatus(jobId);

          if (translationRes?.data?.progress === "complete") {
            const manifestID = setInterval(async () => {
              const status = await getManifest(res.data.encodedSourceUrn);

              if (status === "success") {
                labelFileName.innerHTML =
                  "ファイル名: " + realFileInput.files[0].name;

                setOriginName(realFileInput?.files[0]?.name);
                setProgressIsRunning(false);
                setMessageCompleted("アップロードが完了しました。");
                setBtnDisable(false);
                const urn = "urn:" + res.data.encodedSourceUrn;
                const accessToken = await getAccessToken();
                setViewInfor({
                  urn: urn,
                  accessToken: accessToken,
                });

                clearInterval(manifestID);
              }
            }, 1000);

            clearInterval(id);
            id = 0;
          }
        } catch (error) {
          clearInterval(id);
          setProgressIsRunning(false);
          setMessageCompleted("アップロード出来ませんでした。");
          setBtnDisable(true);
        }
      }, 1000);

      const idTimeOut = setTimeout(() => {
        console.log(id);
        if (id !== 0) {
          clearInterval(workId);
          clearInterval(id);
          clearTimeout(idTimeOut);
          setProgressIsRunning(false);
          setMessageCompleted("アップロード出来ませんでした。");
          setBtnDisable(true);
        }
      }, 60000 * 3);
    } else {
      setProgressIsRunning(false);
      setMessageCompleted("アップロード出来ませんでした。");
      setBtnDisable(true);
    }
  }

  async function uploadFile(file) {
    try {
      const response = await RenameLayerApi.CreateJob(file);
      return response;
    } catch (error) {
      console.log("upload file ", error);
      return null;
    }
  }
  async function runGetLayerNameList(jobId) {
    try {
      const response = await RenameLayerApi.RunGetLayerNameList(jobId);
      return response;
    } catch (error) {
      console.log("Run Get BlockName List ", error);
      return null;
    }
  }

  async function getTranslationStatus(jobId) {
    try {
      const response = await ForgeAPI.GetTranslationStatus(jobId);
      return response;
    } catch (error) {
      console.log("Get Translation Status ", error);
      return null;
    }
  }

  async function getAccessToken() {
    try {
      const response = await ForgeAPI.GetViewerToken();
      return response.data.accessToken;
    } catch (error) {
      console.log("Get Viewer Token ", error);
      return null;
    }
  }

  async function getManifest(urn) {
    try {
      const response = await ForgeAPI.GetManifest(urn);
      return response.data.status;
    } catch (error) {
      console.log("Get Manifest ", error);
      return null;
    }
  }

  return (
    <div className="header-upper">
      <div className="upload--class">
        <input
          type="file"
          id="input-file-dwg"
          accept=".dwg"
          onClick={handleInputDwgClick}
          onChange={handleInputDwgChange}
        />

        <button
          className="button-class"
          style={{ width: "135px", marginLeft: "36px" }}
          onClick={handleUploadFile}
        >
          <FontAwesomeIcon className="icon-class" icon={faUpload} />
          アップロード
        </button>
      </div>

      <div className="subfix--class">
        <div>
          <label>接頭: </label>
          <input id="pre"></input>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <label>接尾: </label>
          <input id="sub"></input>
        </div>
      </div>
    </div>
  );
}
