import React, { useEffect, useState } from 'react'
import styles from './TaskTableRow.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import TextInput from 'A_SHARED_COMPONENT/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import { renameColorInTaskColorList, removeColorInTaskColorList } from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'
import { showModalSlice } from 'C_REDUX_STORE/scheduleTool/showModalSlice'
import { toast } from 'react-toastify'

export default function TaskTableRow({ colorObject }) {
  const [name, setName] = useState('')
  const showModal = useSelector((state) => state.showModal)
  const taskColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const dispatch = useDispatch()

  const handleDeleteColor = async () => {
    await dispatch(removeColorInTaskColorList(colorObject.id))
  }

  useEffect(() => {
    if (colorObject?.name) {
      setName(colorObject?.name)
    } else {
      setName(showModal?.name)
    }
  }, [colorObject])

  const handleSelectColor = (event) => {
    // sửa color
    if (name === '') {
      return
    }
    if (colorObject?.id === undefined) {
      dispatch(
        showModalSlice.actions.ShowWindowAddColor({ name: 'VersionSettingComponent', event: event, nameColor: name })
      )
    } else {
      dispatch(showModalSlice.actions.ShowWindow({ name: 'VersionSettingComponent', event: event, id: colorObject.id }))
    }
  }
  const handleTextBoxChange = async (value) => {
    if (value === '') {
      return
    }
    if (value === colorObject.name) {
      return
    }
    const index = taskColors.findIndex((x) => x.name === value)
    if (index !== -1) {
      toast.error('名は既に存在します。もう一度やり直してください。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      return
    }
    if (colorObject.id !== undefined) {
      await dispatch(renameColorInTaskColorList({ id: colorObject.id, name: value }))
    }
    setName(value)
  }
  return (
    <>
      <tr>
        <td>
          <TextInput
            value={name || ''}
            style={{ height: '20px', borderRadius: '3px' }}
            disable={colorObject?.isDefault ? true : false}
            onChange={handleTextBoxChange}
          ></TextInput>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <button
              type='button'
              style={{
                backgroundColor: colorObject?.code,
                height: '20px',
                width: '60px',
                borderRadius: '3px'
              }}
              onClick={(event) => handleSelectColor(event)}
            ></button>
            {!colorObject?.isDefault ? (
              <FontAwesomeIcon
                icon={faTrashAlt}
                onClick={handleDeleteColor}
                opacity={colorObject?.isDefault ? 0 : 1}
                className={styles.deleteIcon}
              ></FontAwesomeIcon>
            ) : (
              <div style={{ height: '12px', width: '12px' }}></div>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}
