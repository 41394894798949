import Checkbox from 'A_SHARED_COMPONENT/Checkbox'
import { DialogBase } from 'A_SHARED_COMPONENT/DialogComponent'
import { useEffect, useState } from 'react'
import { Scrollbar } from 'smooth-scrollbar-react'

export default function HiddenRowsModal({ tasks, setTasks, showHiddenRowsModal, setShowHiddenRowsModal }) {
  const [hiddenTasks, setHiddenTasks] = useState([])
  useEffect(() => {
    const listHiddenTasks = tasks?.filter((t) => t.isHidden === true)
    setHiddenTasks(listHiddenTasks)
  }, [tasks])

  var handleOkClick = () => {
    var newTasks = tasks.map((t) => {
      var hdTask = hiddenTasks?.find((ht) => ht.id === t.id)
      if (!!hdTask) return hdTask
      return t
    })

    setTasks(newTasks)
    setShowHiddenRowsModal(false)
  }

  var handleCancelClick = () => {
    setShowHiddenRowsModal(false)
  }

  var handleInputChange = (event, task) => {
    setHiddenTasks(
      hiddenTasks.map((t) => {
        if (t.id === task.id) {
          return { ...t, isHidden: !event.target.checked }
        } else {
          return t
        }
      })
    )
  }

  const renderTextContent = (t) => {
    var name = t.name
    var description = t.detail || ''
    var durationFormat = t.isDayFormat ? t.duration.toFixed(1) + '日' : (t.duration / 30).toFixed(1) + 'ヶ月'
    return `${name} (${description ? description + ' - ' : ''}${durationFormat})`
  }
  return (
    <>
      <DialogBase
        open={showHiddenRowsModal}
        handleOK={handleOkClick}
        handleClose={handleCancelClick}
        title={'タスクを非表示'}
        width={700}
      >
        <div className=' flex-col flex gap-3 items-center w-[500px]'>
          <h3 className='w-full font-medium select-none'>表示したいタスクを選択</h3>
          <div className=' w-full flex items-center flex-col gap-3 pl-3 pr-1 py-3 pt-2 h-[360px] overflow-y-auto border rounded-md justify-start overflow-x-hidden Scrollbar-hidden-x'>
            <span className='border-b border-b-black w-full pb-1 select-none'>表示したいタスクを選択</span>
            <Scrollbar
              plugins={{
                overscroll: {
                  effect: 'glow'
                }
              }}
              className='w-full'
            >
              {hiddenTasks.map((task) => (
                <Checkbox
                  name={renderTextContent(task)}
                  key={task.id}
                  handleCheckBoxChange={(e) => handleInputChange(e, task)}
                ></Checkbox>
              ))}
            </Scrollbar>
          </div>
        </div>
      </DialogBase>
    </>
  )
}
