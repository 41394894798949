import { Autodesk } from "./Autodesk";

export function HightLineBlockByBlockName(viewer, propertyValue) {
  function getAlldbIds(rootId) {
    var alldbId = [];
    if (!rootId) {
      return alldbId;
    }
    var queue = [];
    queue.push(rootId);
    while (queue.length > 0) {
      var node = queue.shift();
      alldbId.push(node);
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    return alldbId;
  }

  function hasPropertyTask(model, dbId, propName, propValue, matches) {
    model.getProperties(dbId, function (result) {
      if (result.properties) {
        for (var i = 0; i < result.properties.length; ++i) {
          var prop = result.properties[i];
          //check if we have a match
          if (prop.displayName === propName) {
            if (prop.displayValue === propValue) {
              matches.push(dbId);
              viewer.select(matches);
              viewer.fitToView(matches);
            } else {
              return false;
            }
          }
        }
      }
    });
  }
  var instanceTree = viewer.model.getData().instanceTree;
  var rootId = instanceTree.getRootId();
  var allDbIds = getAlldbIds(rootId);
  var matches = [];
  viewer.select(matches);
  for (const id of allDbIds) {
    hasPropertyTask(viewer.model, id, "Name", propertyValue, matches);
  }
  async function hasPropertyTaskV2(
    viewer,
    allDbIds,
    propName,
    propValue,
    matches
  ) {
    const getPropertiesAsync = (id) => {
      return new Promise((resolve, reject) => {
        viewer.model.getProperties(
          id,
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    };
    const propTasks = allDbIds.map((id) => {
      return getPropertiesAsync(id);
    });
    const results = await Promise.all(propTasks);

    results.forEach((element, index) => {
      const property = element.properties.filter(
        (pro) => pro.displayName === "Name" && pro.displayValue === propValue
      );
      if (property !== null && property[0]?.displayName === "Name") {
        matches.push(element.dbId);
        viewer.select(
          matches,
          viewer.model,
          Autodesk.Viewing.SelectionType.OVERLAYED
        );
      }
    });
  }
}

export function IsolateObjectByLayerName(viewer, propertyValue) {
  function getAlldbIds(rootId) {
    var alldbId = [];
    if (!rootId) {
      return alldbId;
    }
    var queue = [];
    queue.push(rootId);
    while (queue.length > 0) {
      var node = queue.shift();
      alldbId.push(node);
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    return alldbId;
  }

  function hasPropertyTask(model, dbId, propName, propValue, matches) {
    model.getProperties(dbId, function (result) {
      if (result.properties) {
        for (var i = 0; i < result.properties.length; ++i) {
          var prop = result.properties[i];
          //check if we have a match
          if (prop.displayName === propName) {
            if (prop.displayValue === propValue) {
              matches.push(dbId);
              viewer.fitToView(matches);
              viewer.isolate(matches);
            } else {
              return false;
            }
          }
        }
      }
    });
  }
  var instanceTree = viewer.model.getData().instanceTree;
  var rootId = instanceTree.getRootId();
  var allDbIds = getAlldbIds(rootId);
  var matches = [];
  viewer.showAll();
  for (const id of allDbIds) {
    hasPropertyTask(viewer.model, id, "Layer", propertyValue, matches);
  }
}

export function IsolateObjectByBlockName(viewer, propertyValue) {
  function getAlldbIds(rootId) {
    var alldbId = [];
    if (!rootId) {
      return alldbId;
    }
    var queue = [];
    queue.push(rootId);
    while (queue.length > 0) {
      var node = queue.shift();
      alldbId.push(node);
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    return alldbId;
  }

  function hasPropertyTask(model, dbId, propName, propValue, matches) {
    model.getProperties(dbId, function (result) {
      if (result.properties) {
        for (var i = 0; i < result.properties.length; ++i) {
          var prop = result.properties[i];
          //check if we have a match
          if (prop.displayName === propName) {
            if (prop.displayValue === propValue) {
              matches.push(dbId);
              viewer.fitToView(matches);
              viewer.isolate(matches);
            } else {
              return false;
            }
          }
        }
      }
    });
  }
  var instanceTree = viewer.model.getData().instanceTree;
  var rootId = instanceTree.getRootId();
  var allDbIds = getAlldbIds(rootId);
  var matches = [];
  viewer.showAll();
  for (const id of allDbIds) {
    hasPropertyTask(viewer.model, id, "Name", propertyValue, matches);
  }
}

async function GetPropertiesValueByName(viewer, objectID, propName) {
  const getPropertiesAsync = (id) => {
    return new Promise((resolve, reject) => {
      viewer.model.getProperties(
        id,
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };
  const propTasks = getPropertiesAsync(objectID);

  const results = await propTasks.then((result) => result.properties);
  return results;
}

export async function GetPropertyValueByName(viewer, objectID, propName) {
  const values = await GetPropertiesValueByName(viewer, objectID);
  for (let index = 0; index < values.length; index++) {
    const element = values[index];
    if (element.displayName === propName) {
      return element.displayValue;
    }
  }
  return null;
}

export function GetAllObjectID(viewer) {
  function getAlldbIds(rootId) {
    var alldbId = [];
    if (!rootId) {
      return alldbId;
    }
    var queue = [];
    queue.push(rootId);
    while (queue.length > 0) {
      var node = queue.shift();
      alldbId.push(node);
      instanceTree.enumNodeChildren(node, function (childrenIds) {
        queue.push(childrenIds);
      });
    }
    return alldbId;
  }
  var instanceTree = viewer.model.getData().instanceTree;
  var rootId = instanceTree.getRootId();
  var allDbIds = getAlldbIds(rootId);
  viewer.fitToView(allDbIds);
}
