import React, { useEffect, useState } from 'react'
import { EventOption, Predecessors } from '../../types/public-types'
import { BarTask } from '../../types/bar-task'
import { Arrow } from '../other/arrow'
import { handleTaskBySVGMouseEvent } from '../../helpers/bar-helper'
import { checkCanAddPredecessor, isKeyboardEvent } from '../../helpers/other-helper'
import { TaskItem } from '../task-item/task-item'
import { GanttContentMoveAction, GanttEvent } from '../../types/gantt-task-actions'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { predecessorSlice } from 'C_REDUX_STORE/scheduleTool/predecessorSlice'
import { selectedTaskSlice } from 'C_REDUX_STORE/scheduleTool/selectedTaskSlice'

export type TaskGanttContentProps = {
  tasks: BarTask[]
  ganttEvent: GanttEvent
  selectedTask: BarTask | undefined
  rowHeight: number
  columnWidth: number
  timeStep: number
  svg?: React.RefObject<SVGSVGElement>
  svgWidth: number
  taskHeight: number
  arrowColor: string
  arrowIndent: number
  fontSize: string
  fontFamily: string
  rtl: boolean
  setGanttEvent: (value: GanttEvent) => void
  setFailedTask: (value: BarTask | null) => void
  setSelectedTask: (taskId: string) => void
} & EventOption

export const TaskGanttContent: React.FC<TaskGanttContentProps> = ({
  tasks,
  ganttEvent,
  selectedTask,
  rowHeight,
  columnWidth,
  timeStep,
  svg,
  taskHeight,
  arrowColor,
  arrowIndent,
  fontFamily,
  fontSize,
  rtl,
  setGanttEvent,
  setFailedTask,
  setSelectedTask,
  onDateChange,
  onProgressChange,
  onDoubleClick,
  onClick,
  onDelete,
  onRightClick
}) => {
  const point = svg?.current?.createSVGPoint()
  const selectedTaskIds = useSelector((state: RootState) => state.selectedTask)
  const [xStep, setXStep] = useState(0)
  const [initEventX1Delta, setInitEventX1Delta] = useState(0)
  const [isMoving, setIsMoving] = useState(false)
  const month = useSelector((state: RootState) => state.versionSetting.current.numberOfMonths)
  const columnWidthSetting = useSelector((state: RootState) => state.versionSetting.current.columnWidth)
  const amplifiedFactor = useSelector((state: RootState) => state.versionSetting.current.amplifiedFactor)
  const predecessorParent = useSelector((state: RootState) => state.predecessor)
  const dispatch = useDispatch()
  // create xStep
  useEffect(() => {
    const newXStep = 0.1
    setXStep(newXStep)
  }, [columnWidth, timeStep])

  useEffect(() => {
    const handleMouseMove = async (event: MouseEvent) => {
      let canMove = true
      for (let index = 0; index < tasks.length; index++) {
        const task = tasks[index]
        if (task.stepworks) {
          // hiep sửa chỗ này lọc vào predecessors
          const taskPredecessors = task.stepworks
          for (let index = 0; index < taskPredecessors.length; index++) {
            const t = taskPredecessors[index]
            const indexStepWork = t.predecessors?.findIndex((x) => x.id === ganttEvent.changedTask?.id)
            if (indexStepWork !== undefined && indexStepWork !== -1) {
              canMove = false
              break
            }
          }
        } else {
          const indexStepWork = task.predecessors?.findIndex((x) => x.id === ganttEvent.changedTask?.id)
          if (indexStepWork !== undefined && indexStepWork !== -1) {
            canMove = false
            break
          }
        }
      }
      if (!canMove) {
        return
      }
      if (!ganttEvent.changedTask || !point || !svg?.current) return
      event.preventDefault()
      point.x = event.clientX
      const cursor = point.matrixTransform(svg?.current.getScreenCTM()?.inverse())
      const otherSelectedTask = tasks.filter(
        (task) =>
          task.id !== ganttEvent.changedTask?.id &&
          (selectedTaskIds.includes(task.id) || selectedTaskIds.includes(task.parentTaskId))
      )
      if (
        selectedTaskIds.length === 1 ||
        !(
          selectedTaskIds.includes(ganttEvent.changedTask?.id) ||
          selectedTaskIds.includes(ganttEvent.changedTask?.parentTaskId)
        )
      ) {
        const { isChanged, changedTask, otherChangedTask } = handleTaskBySVGMouseEvent(
          cursor.x,
          ganttEvent.changedTask,
          [],
          xStep,
          initEventX1Delta,
          month,
          columnWidthSetting,
          amplifiedFactor
        )
        if (isChanged) {
          setGanttEvent({ action: ganttEvent.action, changedTask, otherChangedTask })
        }
      } else {
        const { isChanged, changedTask, otherChangedTask } = handleTaskBySVGMouseEvent(
          cursor.x,
          ganttEvent.changedTask,
          otherSelectedTask,
          xStep,
          initEventX1Delta,
          month,
          columnWidthSetting,
          amplifiedFactor
        )
        if (isChanged) {
          setGanttEvent({ action: ganttEvent.action, changedTask, otherChangedTask })
        }
      }
    }

    const handleMouseUp = async (event: MouseEvent) => {
      const { action, originalSelectedTask, changedTask } = ganttEvent
      if (!changedTask || !point || !svg?.current || !originalSelectedTask) return
      event.preventDefault()
      point.x = event.clientX
      const cursor = point.matrixTransform(svg?.current.getScreenCTM()?.inverse())
      const otherSelectedTask = tasks.filter(
        (task) => task.id !== ganttEvent.changedTask?.id && selectedTaskIds.includes(task.id)
      )
      const { changedTask: newChangedTask } = handleTaskBySVGMouseEvent(
        cursor.x,
        changedTask,
        otherSelectedTask,
        xStep,
        initEventX1Delta,
        month,
        columnWidthSetting,
        amplifiedFactor
      )
      const isNotLikeOriginal =
        originalSelectedTask.start !== newChangedTask.start ||
        originalSelectedTask.end !== newChangedTask.end ||
        originalSelectedTask.predecessors?.length !== newChangedTask.predecessors?.length

      // remove listeners
      svg.current.removeEventListener('mousemove', handleMouseMove)
      svg.current.removeEventListener('mouseup', handleMouseUp)
      setGanttEvent({ action: '' })
      setIsMoving(false)

      // custom operation start
      let operationSuccess = true
      if ((action === 'move' || action === 'end' || action === 'start') && onDateChange && isNotLikeOriginal) {
        try {
          const result = await onDateChange(newChangedTask, newChangedTask.barChildren)
          if (result !== undefined) {
            operationSuccess = result
          }
        } catch (error) {
          operationSuccess = false
        }
      } else if (onProgressChange && isNotLikeOriginal) {
        try {
          const result = await onProgressChange(newChangedTask, newChangedTask.barChildren)
          if (result !== undefined) {
            operationSuccess = result
          }
        } catch (error) {
          operationSuccess = false
        }
      }

      // If operation is failed - return old state
      if (!operationSuccess) {
        setFailedTask(originalSelectedTask)
      }
    }

    if (
      !isMoving &&
      (ganttEvent.action === 'move' ||
        ganttEvent.action === 'end' ||
        ganttEvent.action === 'start' ||
        ganttEvent.action === 'progress') &&
      svg?.current
    ) {
      svg.current.addEventListener('mousemove', handleMouseMove)
      svg.current.addEventListener('mouseup', handleMouseUp)
      setIsMoving(true)
    }
  }, [
    ganttEvent,
    xStep,
    initEventX1Delta,
    onProgressChange,
    timeStep,
    onDateChange,
    svg,
    isMoving,
    point,
    rtl,
    setFailedTask,
    setGanttEvent
  ])

  /**
   * Method is Start point of task change
   */
  const handleBarEventStart = async (
    action: GanttContentMoveAction,
    task: BarTask,
    event?: React.MouseEvent | React.KeyboardEvent
  ) => {
    if (action === 'click') {
      !!onClick && onClick(task)
      if (event?.ctrlKey) {
        if (selectedTaskIds.includes(task.id)) {
          const newSelectedTaskIds = selectedTaskIds.filter((x: any) => x !== task.id)
          dispatch(selectedTaskSlice.actions.setSelectedTaskId(newSelectedTaskIds))
        } else {
          dispatch(selectedTaskSlice.actions.pushSelectedTaskIdToState([task.id]))
        }
      } else {
        dispatch(selectedTaskSlice.actions.setSelectedTaskId([task.id]))
      }
      return
    }

    if (!event) {
      if (action === 'select') {
        const parentTask = tasks.find((x) => x.id === predecessorParent)
        // if (parentTask?.parentTaskId && task.parentTaskId && parentTask.parentTaskId === task.parentTaskId) {
        // if (parentTask?.parentTaskId && task.parentTaskId) {
        //   setSelectedTask(task.id)
        //   return
        // }
        if (predecessorParent !== '-1' && predecessorParent !== task.id) {
          if (!checkCanAddPredecessor(tasks, predecessorParent, task.id)) {
            dispatch(predecessorSlice.actions.setParentStepWorkId('-1'))
            setSelectedTask(task.id)
            return
          }

          if (parentTask?.predecessors) {
            const containTrue = parentTask.predecessors.some((x) => x.id === task.id)
            if (!containTrue) {
              let predecessors = parentTask.predecessors
              predecessors.push({
                type: 'FS',
                id: task.id,
                lagDays: 0
              })
              setGanttEvent({ action, changedTask: { ...parentTask, predecessors: predecessors } })
            }
          } else {
            let predecessors: Predecessors[] = []
            predecessors.push({
              type: 'FS',
              id: task.id,
              lagDays: 0
            })
            if (parentTask) {
              parentTask.predecessors = predecessors
              setGanttEvent({ action, changedTask: { ...parentTask, predecessors: predecessors } })
            }
          }
        }
        dispatch(predecessorSlice.actions.setParentStepWorkId('-1'))
        setSelectedTask(task.id)
      }
    }
    // Keyboard events
    else if (isKeyboardEvent(event)) {
      if (action === 'delete') {
        if (onDelete) {
          try {
            const result = await onDelete(task)
            if (result !== undefined && result) {
              setGanttEvent({ action, changedTask: task })
            }
          } catch (error) {
            console.error('Error on Delete. ' + error)
          }
        }
      }
    }
    // Mouse Events
    else if (action === 'mouseenter') {
      if (!ganttEvent.action) {
        setGanttEvent({
          action,
          changedTask: task,
          originalSelectedTask: task
        })
      }
    } else if (action === 'mouseleave') {
      if (ganttEvent.action === 'mouseenter') {
        setGanttEvent({ action: '' })
      }
    } else if (action === 'dblclick') {
      !!onDoubleClick && onDoubleClick(task)
    } else if (action === 'contextmenu') {
      !!onRightClick && onRightClick(task, event)
    }
    // Change task event start
    else if (action === 'move') {
      if (!svg?.current || !point) return
      point.x = event.clientX
      const cursor = point.matrixTransform(svg.current.getScreenCTM()?.inverse())
      setInitEventX1Delta(cursor.x - task.start)
      setGanttEvent({
        action,
        changedTask: task,
        originalSelectedTask: task
      })
    } else {
      setGanttEvent({
        action,
        changedTask: task,
        originalSelectedTask: task
      })
    }
  }
  return (
    <g className='content'>
      <g className='bar' fontFamily={fontFamily} fontSize={fontSize}>
        {tasks.map((task) => {
          return (
            <>
              <TaskItem
                task={task}
                arrowIndent={arrowIndent}
                taskHeight={taskHeight}
                isProgressChangeable={!!onProgressChange && !task.isDisabled}
                isDateChangeable={!!onDateChange && !task.isDisabled}
                isDelete={!task.isDisabled}
                onEventStart={handleBarEventStart}
                key={task.id}
                isSelected={!!selectedTask && task.id === selectedTask.id}
                rtl={rtl}
              />
            </>
          )
        })}
      </g>
      <g className='arrows' fill={arrowColor} stroke={arrowColor}>
        {tasks.map((task) => {
          return task.barChildren.map((child) => {
            const taskTo2 = tasks.find((x) => x.id === child.id) || child
            if (taskTo2?.y === task?.y) {
              return null
            }
            return (
              <Arrow
                key={`Arrow from ${task.id} to ${child.id}`}
                taskFrom={task}
                taskTo={taskTo2}
                rowHeight={rowHeight}
                taskHeight={taskHeight}
                arrowIndent={arrowIndent}
                rtl={rtl}
                color='blue'
                setGanttEvent={setGanttEvent}
              />
            )
          })
        })}
      </g>
    </g>
  )
}
