import React from 'react'
import CardComponent from '../../../A_SHARED_COMPONENT/CardComponent'
import searchIcon from '../../../X_ASSETS/search.png'

import convertFile from '../../../X_ASSETS/ConvertFile.png'
import createLayer from '../../../X_ASSETS/CreateLayer.png'
import dataExtraction from '../../../X_ASSETS/DataExtraction.png'
import exportBlock from '../../../X_ASSETS/ExportBlock.png'
import renameBlock from '../../../X_ASSETS/RenameBlock.png'
import renameLayer from '../../../X_ASSETS/RenameLayer.png'
import { RouterConstants } from '../../../F_UTILS/Constants/RouterConstants'

function ListTool() {
  const listTool = [
    {
      img: createLayer,
      name: 'CREATE LAYERS',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_CREATE_LAYERS}`
    },
    {
      img: renameLayer,
      name: 'RENAME LAYERS',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_RENAME_LAYERS}`
    },
    {
      img: renameBlock,
      name: 'RENAME BLOCKS',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_RENAME_BLOCKS}`
    },
    {
      img: convertFile,
      name: 'CONVERT FILE',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_CONVERT_FILE}`
    },
    {
      img: exportBlock,
      name: 'EXPORT BLOCKS',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_EXPORT_BLOCKS}`
    },
    {
      img: dataExtraction,
      name: 'DATA EXTRACTION',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_DATA_EXTRACTION}`
    },
    {
      img: dataExtraction,
      name: 'SCHEDULING TOOL',
      version: 'バージョン 1.0.0',
      navi: `${RouterConstants.APP_MENU_SCHEDULING_TOOL}`
    }
  ]
  const [listApp, setListApp] = React.useState(listTool)

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      const searchKey = e.target?.value
      if (searchKey && searchKey !== '') {
        const listFilterdApp = listApp.filter((app) => app.name.toLowerCase().includes(searchKey.toLowerCase()))
        setListApp(listFilterdApp)
      }
    }
  }

  function handleButtonClick() {
    const searchKey = document.getElementById('search-input').value
    if (searchKey && searchKey !== '') {
      const listFilterdApp = listApp.filter((app) => app.name.toLowerCase().includes(searchKey.toLowerCase()))
      setListApp(listFilterdApp)
    }
  }

  function handleKeyChange(e) {
    const searchKey = e.target?.value
    if (!searchKey || searchKey === '') {
      setListApp(listTool)
    }
  }

  return (
    <div className='wrapper'>
      <div action='' className='search-bar'>
        <input
          type={'text'}
          placeholder='検索 ... '
          name=''
          id='search-input'
          onKeyDown={handleKeyDown}
          onChange={handleKeyChange}
        ></input>
        <button onClick={handleButtonClick}>
          <img src={searchIcon} style={{ height: '26px' }}></img>
        </button>
      </div>

      <div className='menu--wrapper'>
        {listApp.map((element, index) => {
          return (
            <CardComponent
              key={index}
              src={element.img}
              name={element.name}
              version={element.version}
              navi={element.navi}
            ></CardComponent>
          )
        })}
      </div>
    </div>
  )
}

export default ListTool
