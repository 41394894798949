import React, { useEffect, useState } from 'react'
import styles from './HiddenColumnModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { columnsSlice } from 'C_REDUX_STORE/scheduleTool/columnsSlice'

export default function HiddenColumnModal({ setShowWindowUnhideColumns }) {
  var dispatch = useDispatch()
  const columnsInfo = useSelector((state) => state.columnsInfo)
  const [hidedColumns, setHidedColumns] = useState([])
  const tableWidth = useSelector((state) => state.tableWidth)
  useEffect(() => {
    var hidedCo = columnsInfo.filter((x) => x.isHidden === true && x.name !== 'task')
    setHidedColumns(hidedCo)
  }, [])

  var handleOkClick = () => {
    hidedColumns.forEach((element) => {
      if (element.isHidden === false) {
        dispatch(
          columnsSlice.actions.unhideColumn({
            name: element.name,
            tableWidth: tableWidth
          })
        )
      }
    })
    setShowWindowUnhideColumns(false)
  }

  var handleCancelClick = () => {
    setShowWindowUnhideColumns(false)
  }

  var handleInputChange = (event, column) => {
    setHidedColumns(
      hidedColumns.map((co) => {
        if (co.name === column.name) {
          return { ...co, isHidden: !event.target.checked }
        } else {
          return co
        }
      })
    )
  }
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className={styles.heading}>例を非表示</div>
        <div className={styles.desc}>表示したい例を選択</div>
        <div className={styles.list_columns}>
          <div className={styles.detail}>例</div>
          {hidedColumns.map((co) => {
            return (
              <div className={styles.column_item} key={co.name}>
                <input
                  type='checkbox'
                  name=''
                  id={co.name}
                  className={styles.checkbox_input}
                  onChange={(e) => handleInputChange(e, co)}
                ></input>
                <label htmlFor={co.name} className={styles.checkbox_label}>
                  {co.displayName}
                </label>
              </div>
            )
          })}
        </div>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOkClick}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
           キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
