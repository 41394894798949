import React from 'react'
import DropDown from '../DropDown'
import TextInput from 'A_SHARED_COMPONENT/TextInput'
import { nanoid } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

export default function StepWorkRow({ index, task, stepWork, setStepWork, isDisableDropdown, isDisablePercentInput }) {
  const [colorId, setColorId] = React.useState(stepWork?.colorId || '')
  const [percent, setPercent] = React.useState(stepWork?.percentStepWork.toFixed(1) || '')
  React.useEffect(() => {
    if (colorId !== stepWork?.colorId) {
      setStepWork((prev) => {
        let copyArr = [...prev]
        const newStepWorks = copyArr.map((sw) => {
          if (sw.id === stepWork?.id) {
            return { ...sw, colorId: colorId }
          } else {
            return sw
          }
        })
        return newStepWorks
      })
    }
  }, [colorId])
  const handPercentChange = (percent) => {
    if (!colorId) {
      return
    }

    if (isNaN(Number(percent))) {
      setPercent(stepWork?.percentStepWork)
      toast.error('設定の更新に成功しました。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      return
    }
    if (stepWork?.id) {
      setStepWork((prev) => {
        let copyArr = [...prev]
        const newStepWorks = copyArr.map((sw) => {
          if (sw.id === stepWork?.id) {
            return { ...sw, percentStepWork: Number(percent) }
          } else {
            return sw
          }
        })
        return newStepWorks
      })
    } else {
      const newStepWork = {
        start: 0,
        duration: task.duration,
        name: task.name + nanoid(),
        percentStepWork: Number(percent),
        parentTaskId: task.id,
        id: nanoid(),
        type: 'task',
        groupId: task.groupId,
        displayOrder: task.displayOrder,
        predecessors: [],
        colorId: colorId
      }
      setStepWork((prev) => {
        let copyArr = [...prev]
        copyArr.push(newStepWork)
        if (copyArr.length < 8) {
          return copyArr
        }
        setColorId('')
        setPercent('')
        return prev
      })
    }
  }
  const handleDelete = () => {
    if (!colorId) {
      return
    }
    if (!stepWork.id) {
      return
    }
    if (isDisableDropdown) {
      return
    }
    setStepWork((prev) => {
      let copyArr = [...prev]
      const newStepWorks = copyArr.filter((sw) => sw.id !== stepWork.id)
      return newStepWorks
    })
  }
  return (
    <>
      <tr style={{ height: '44px' }}>
        <td style={{ height: '44px' }}>{index}</td>
        <td style={{ height: '44px' }}>
          <DropDown
            colorId={stepWork?.colorId || colorId}
            setColorId={setColorId}
            isDisable={isDisableDropdown}
          ></DropDown>
        </td>
        <td style={{ height: '44px' }}>
          <div>
            <TextInput
              value={stepWork?.percentStepWork.toFixed(1) || percent}
              onChange={handPercentChange}
              style={{ textAlign: 'center', width: '50px' }}
              disable={!colorId || isDisablePercentInput}
              isNumber={true}
            ></TextInput>
            <span
              className='fas fa-sharp fa-trash'
              style={isDisableDropdown ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
              onClick={handleDelete}
            ></span>
          </div>
        </td>
      </tr>
    </>
  )
}
