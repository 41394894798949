import React from 'react'
import styles from './SetPredecessorsComponent.module.scss'
import PredecessorRow from './components/PredecessorRow'
import { checkCanAddPredecessor } from 'Z_SERVICES/j_MainChart/grant-base/helpers/other-helper'

export default function SetPredecessorsComponent({ task, tasks, setTasks, setIsSetPredecessor }) {
  const [predecessors, setPredecessors] = React.useState(task?.predecessors)

  const handleOkClick = () => {
    let canAdd = true
    // predecessors.forEach((predecessor) => {
    //   if (!checkCanAddPredecessor(tasks, task.id, predecessor.id)) {
    //     canAdd = false
    //     return
    //   }
    // })
    if (canAdd) {
      setTasks((prev) => {
        let copyPrev = [...prev]
        const newTasks = copyPrev.map((t) => {
          if (t?.stepworks) {
            const newStepWorks = t.stepworks.map((x) => {
              if (x.id === task.id) {
                return { ...x, predecessors: predecessors }
              } else {
                return x
              }
            })
            return { ...t, stepworks: newStepWorks }
          } else {
            if (t.id === task.id) {
              return { ...t, predecessors: predecessors }
            } else {
              return t
            }
          }
        })
        return newTasks
      })
    }
    setIsSetPredecessor(false)
  }

  const handleCancelClick = () => {
    setIsSetPredecessor(false)
  }

  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <h4 className='text-xl font-medium pl-8 mt-5'>ステープワークを分ける</h4>
        <div className={styles.tableContainer}>
          <table className={styles.tableContent}>
            <thead>
              <tr>
                <th style={{ width: '35%' }}>タスク名</th>
                <th style={{ width: '30%' }}>ステープワーク</th>
                <th style={{ width: '25%' }}>リンク種類</th>
                <th style={{ width: '10%' }}>Lag (days)</th>
              </tr>
            </thead>
            <tbody>
              {predecessors?.map((pre, index) => {
                if (index === predecessors.length - 1) {
                  return (
                    <>
                      <PredecessorRow
                        task={task}
                        key={pre.id}
                        tasks={tasks}
                        predecessor={pre}
                        setPredecessors={setPredecessors}
                      ></PredecessorRow>
                      {predecessors.length < 7 ? (
                        <PredecessorRow
                          task={task}
                          key={10000}
                          tasks={tasks}
                          setPredecessors={setPredecessors}
                        ></PredecessorRow>
                      ) : (
                        ''
                      )}
                    </>
                  )
                } else {
                  return (
                    <>
                      <PredecessorRow
                        task={task}
                        key={pre.id}
                        tasks={tasks}
                        predecessor={pre}
                        setPredecessors={setPredecessors}
                      ></PredecessorRow>
                    </>
                  )
                }
              })}
              {predecessors?.length === 0 ? (
                <>
                  <PredecessorRow
                    task={task}
                    key={10000}
                    tasks={tasks}
                    setPredecessors={setPredecessors}
                  ></PredecessorRow>
                </>
              ) : (
                ''
              )}
              {predecessors === undefined || predecessors === null ? (
                <>
                  <PredecessorRow
                    task={task}
                    key={10000}
                    tasks={tasks}
                    setPredecessors={setPredecessors}
                  ></PredecessorRow>
                </>
              ) : (
                ''
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOkClick}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
