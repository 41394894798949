import React from 'react'

export default function TableComponent({ rowCount }) {
  return (
    <div className='outer-wrapper'>
      <div className='table-wrapper'>
        <table className='table--header' style={{ width: '100%', position: 'sticky', top: '0px' }}>
          <thead>
            <th
              style={{
                borderRight: 'none',
                borderLeft: 'none',
                outline: 'none',
                width: '40%',
                minWidth: '40%',
                maxWidth: '40%'
              }}
            >
              現在の画層名
            </th>

            <th
              style={{
                outline: 'none',
                width: '40%',
                minWidth: '40%',
                maxWidth: '40%'
              }}
            >
              新しい画層名
            </th>

            <th
              style={{
                borderLeft: 'none',
                outline: 'none',
                width: '18%',
                minWidth: '18%',
                maxWidth: '18%'
              }}
            >
              ソリッドの数量
            </th>
          </thead>
        </table>
        <table id='contents-table'>
          <tbody>
            {new Array(rowCount).fill(null).map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    style={{
                      borderRight: 'none',
                      height: '31px',
                      width: '20%',
                      minWidth: '20%',
                      maxWidth: '20%'
                    }}
                  ></td>
                  <td
                    style={{
                      borderRight: 'none',
                      height: '31px',
                      width: '5.5%',
                      minWidth: '5.5%',
                      maxWidth: '5.5%'
                    }}
                  ></td>
                  <td
                    style={{
                      height: '31px',
                      width: '15%',
                      minWidth: '15%',
                      maxWidth: '15%',
                      borderLeft: 'none'
                    }}
                  ></td>
                  <td
                    className='editAbleRow'
                    style={{
                      borderLeft: 'none',
                      height: '31px',
                      width: '20%',
                      minWidth: '20%',
                      maxWidth: '20%'
                    }}
                  ></td>
                  <td
                    className='clickAble'
                    style={{
                      borderRight: 'none',
                      height: '31px',
                      width: '5.5%',
                      minWidth: '5.5%',
                      maxWidth: '5.5%'
                    }}
                    contentEditable='false'
                  ></td>
                  <td
                    className='clickAble'
                    style={{
                      borderLeft: 'none',
                      height: '31px',
                      width: '15%',
                      minWidth: '15%',
                      maxWidth: '15%'
                    }}
                    contentEditable='false'
                  ></td>
                  <td
                    style={{
                      textAlign: 'center',
                      height: '31px',
                      width: '18%',
                      minWidth: '18%',
                      maxWidth: '18%'
                    }}
                  ></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
