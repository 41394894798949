import axiosClientSchedule from './axiosClientSchedule'

const ViewsApi = {
  async GetCustomViewsByVersionId(versionId) {
    const url = `/versions/${versionId}/views`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },
  async CreateCustomView(versionId, data) {
    const url = `/versions/${versionId}/views`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url,
      data: data
    })
  },
  async UpdateCustomView(versionId, viewId, data) {
    const url = `/versions/${versionId}/views/${viewId}`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  },
  async DeleteCustomView(viewId) {
    const url = `/views/${viewId}`
    return axiosClientSchedule.request({
      method: 'DELETE',
      url: url
    })
  },
  async GetViewDetails(versionId, viewId) {
    const url = `/versions/${versionId}/views/${viewId}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  async SaveViewDetails(versionId, viewId, data) {
    const url = `/versions/${versionId}/views/${viewId}/details`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  }
}

export default ViewsApi
