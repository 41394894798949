import React, { useEffect, useState } from 'react'
import styles from './VersionSettingComponent.module.scss'
import {
  backupState,
  changeIncludeYear,
  changeSeparate,
  getVersionSetting,
  undoState,
  updateBackgroundList,
  updateInputVersionSetting
} from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'
import { useDispatch, useSelector } from 'react-redux'
import TaskTableRow from './components/TaskTableRow'
import BackgroundTableRow from './components/BackgroundTableRow'
import { showModalSlice } from 'C_REDUX_STORE/scheduleTool/showModalSlice'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInputForm from './components/TextInputForm'
import ConfirmModal from '../ConfirmModal'
import { calculateTaskPositionForSendDataToServer } from 'Z_SERVICES/j_MainChart/grant-base/helpers/bar-helper'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import BackgroundCheckbox from './components/BackgroundCheckbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { cloneDeep } from 'lodash'
import { ToastError } from 'F_UTILS/ToastUtils'

const loginSchema = yup.object({
  //Không cần dịch phần này, do ko hiện thị ra ngoài.
  assemblyDurationRatio: yup
    .number()
    .required('Hãy nhập assembly duration ratio')
    .min(0.1, 'giá trị từ 0.1 tới 0.9')
    .max(0.9, 'giá trị từ 0.1 tới 0.9'),
  removalDurationRatio: yup
    .number()
    .required('Hãy nhập removal duration ratio')
    .min(0.1, 'giá trị từ 0.1 tới 0.9')
    .max(0.9, 'giá trị từ 0.1 tới 0.9'),
  numberOfMonths: yup
    .number()
    .integer('Hãy nhập số chẵn')
    .required('Hãy nhập number of months')
    .min(1, 'Số tháng từ 1 tới 100')
    .max(100, 'Số tháng từ 1 tới 100'),
  columnWidth: yup
    .number()
    .integer('Hãy nhập số chẵn')
    .required('Hãy nhập column width')
    .min(1, 'Số tháng từ 1 tới 100')
    .max(100, 'Số tháng từ 1 tới 100'),
  amplifiedFactor: yup
    .number()
    .required('Hãy nhập amplified factor')
    .min(1, 'Chiều dài từ 5 tới 160')
    .max(10, 'Chiều dài từ 5 tới 160'),
  startYear: yup.number().integer().min(0).required('Hãy nhập start Year'),
  startMonth: yup.number().integer().min(1).max(12).required('Hãy nhập start Month')
})

export default function VersionSettingComponent({ tasks }) {
  const [toggleState, setToggleState] = useState(1)

  const btnSaveRef = React.useRef(null)

  const dispatch = useDispatch()
  const versionSettingState = useSelector((state) => state.versionSetting.current)
  const currentVersion = useSelector((state) => state.currentVersion)
  const showModal = useSelector((state) => state.showModal)
  const {
    assemblyDurationRatio,
    removalDurationRatio,
    numberOfMonths,
    columnWidth,
    amplifiedFactor,
    includeYear,
    startYear,
    startMonth,
    backgroundColors
  } = versionSettingState
  const [includeYearState, setIncludeYear] = useState(includeYear)
  const [background, setBackground] = useState([])
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      assemblyDurationRatio: assemblyDurationRatio,
      removalDurationRatio: removalDurationRatio,
      numberOfMonths: numberOfMonths,
      columnWidth: columnWidth,
      amplifiedFactor: amplifiedFactor,
      startYear,
      startMonth
    },
    resolver: yupResolver(loginSchema),
    mode: 'onBlur'
  })

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error('入力さたデータが間違っています。もう一度確認てください。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }
  }, [errors])

  useEffect(() => {
    dispatch(backupState())
  }, [])

  const assemblyRatioWatch = watch('assemblyDurationRatio')
  useEffect(() => {
    if (assemblyRatioWatch < 1 && assemblyRatioWatch > 0) {
      const removalRatio = 1 - assemblyRatioWatch
      setValue('removalDurationRatio', removalRatio.toFixed(1))
      clearErrors('removalDurationRatio')
    }
  }, [assemblyRatioWatch])

  useEffect(() => {
    let backgroundColors = cloneDeep(versionSettingState.backgroundColors)
    backgroundColors = backgroundColors.map((color) => {
      if (color?.dateRanges) {
        color.dateRanges = color?.dateRanges?.map((date) => ({ ...date, checked: false }))
      }
      return { ...color, checked: false }
    })
    setBackground(backgroundColors)
  }, [versionSettingState])

  const handleOK = () => {
    setOpenModal(true)
  }

  const handleSubmitClick = handleSubmit(async (data) => {
    if (data.assemblyDurationRatio + data.removalDurationRatio > 1) {
      setError('assemblyDurationRatio', {
        message: 'ステープワークのパーセント合計は100％です。もう一度確認してください。',
        type: 'max'
      })
      setError('removalDurationRatio', {
        message: 'ステープワークのパーセント合計は100％です。もう一度確認してください。',
        type: 'max'
      })
    }

    const convertedTasks = calculateTaskPositionForSendDataToServer([...tasks], columnWidth, amplifiedFactor)

    const versionSetting = { ...versionSettingState, ...data, includeYear: includeYearState }

    versionSetting.backgroundColors = versionSetting.backgroundColors.map((bg) => {
      const dateRanges = convertDateSettingToServer(bg?.dateRanges || [], includeYearState)
      const DisplayDateRanges = dateRanges?.map((x) => x.displayText) || []
      return { ...bg, DisplayDateRanges, dateRanges }
    })
    console.log('update version setting', versionSetting)

    try {
      const res = await VersionApi.UpdateVersionTaskDetails(currentVersion.versionId, convertedTasks)

      if (data) {
        dispatch(updateInputVersionSetting({ ...data, includeYear: includeYearState }))
      }

      btnSaveRef.current.classList.add('button_disabled')

      ///here

      const response = await VersionApi.UpdateVersionSettingById(currentVersion.versionId, versionSetting)

      if (response.status === 204) {
        setOpenModal(false)
        toast.success('設定の更新に成功しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })

        const actions = getVersionSetting(currentVersion.versionId)
        await dispatch(actions)
        dispatch(showModalSlice.actions.HideWindow())
      } else {
        toast.error('設定の更新に失敗しました。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    } catch (error) {
      toast.error('設定の更新に失敗しました。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }
    setTimeout(() => {
      if (btnSaveRef.current) {
        btnSaveRef.current?.classList?.remove('button_disabled')
      }
    }, 3000)
  })

  const handleCancelClick = async () => {
    dispatch(undoState())
    dispatch(showModalSlice.actions.HideWindow())
    setOpenModal(false)
  }

  const handleOpenTab = (tabIndex) => {
    setToggleState(tabIndex)
    if (showModal.id !== -1) {
      dispatch(showModalSlice.actions.HideWindowChild())
    }
  }

  const handleRemovalDurationRatio = async (event) => {
    await dispatch(changeSeparate(event.target.checked))
  }
  const [openModal, setOpenModal] = useState(false)

  const handleDeleteBackground = async () => {
    let newBackgroundList = []
    cloneDeep(background).forEach((color) => {
      color.dateRanges = color?.dateRanges?.filter((x) => x.checked === false)
      color['DisplayDateRanges'] = color?.dateRanges?.map((x) => x.displayText)
      newBackgroundList.push(color)
    })
    await dispatch(updateBackgroundList(newBackgroundList))
  }

  const handleIncludeYear = async (e) => {
    const checked = e.currentTarget.checked
    setIncludeYear(e.currentTarget.checked)
    await dispatch(changeIncludeYear(e.currentTarget.checked))
    const newBackgroundColors = backgroundColors.map((bg) => {
      return { ...bg, dateRanges: [] }
    })
    dispatch(updateBackgroundList(newBackgroundColors))
  }

  const convertDateSettingToServer = (dateRanges, includeYearState) => {
    let newDateRanges = []
    for (let index = 0; index < dateRanges?.length; index++) {
      const r = dateRanges[index]

      if (r.startYear == -1) {
        var st = new Date(2020, r.startMonth, r.startDate)
        var end = new Date(2020, r.toMonth, r.toDate)
        if (st >= end) continue
        if (countDays(st, end) < 5) continue
      } else {
        var st = new Date(r.startYear, r.startMonth, r.startDate)
        var end = new Date(r.toYear, r.toMonth, r.toDate)
        if (st >= end) continue
        if (countDays(st, end) < 5) continue
      }
      newDateRanges.push(convertStartEnd(r, includeYearState))
    }

    return newDateRanges
  }

  const convertStartEnd = (range, includeYearState) => {
    let startDay = Number(range.startDate)
    let startM = Number(range.startMonth)
    let startY = Number(range.startYear)

    if (range.startDate == 1) startDay = 10
    if (range.startDate == 10) startDay = 20
    if (range.startDate == 20) startDay = 30
    if (range.startDate == 30) {
      startDay = 10
      startM = includeYear && startM == 12 ? 1 : startM + 1
      startY = includeYear && startM == 1 ? startY + 1 : startY
    }
    let endDay = Number(range.toDate)
    let endMonth = Number(range.toMonth)
    let endYear = Number(range.toYear)

    if (range.toDate == 1) {
      endDay = 30
      endMonth = includeYear && endMonth == 1 ? 12 : endMonth - 1
      endYear = includeYear && endMonth == 12 ? endYear - 1 : endYear
    }
    if (range.toDate == 10) endDay = 10
    if (range.toDate == 20) endDay = 20
    if (range.toDate == 30) endDay = 30

    var displayTextFull = `${startY}/${startM}/${startDay} - ${endYear}/${endMonth}/${endDay}`
    var displayText = `${startM}/${startDay} - ${endMonth}/${endDay}`

    var dateRange = {
      checked: range.checked,
      displayText: includeYearState ? displayTextFull : displayText,
      startDate: startDay,
      startMonth: startM,
      startYear: startY,
      toDate: endDay,
      toMonth: endMonth,
      toYear: endYear
    }
    return dateRange
  }
  const countDays = (firstDate, secondDate) => {
    const oneDay = 24 * 60 * 60 * 1000
    return Math.round(Math.abs((firstDate - secondDate) / oneDay))
  }

  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <ConfirmModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleOK={handleSubmitClick}
        handleCancel={handleCancelClick}
        setOpenModal={setOpenModal}
      ></ConfirmModal>
      <div className={styles.container}>
        <div className={styles.heading}>プロジェクト設定</div>
        <form className={styles.tabContainer}>
          <div className={styles.tabListItems}>
            <button
              type='button'
              className={toggleState === 1 ? `${styles.tabItem} ${styles.tabItem_active}` : styles.tabItem}
              onClick={() => handleOpenTab(1)}
            >
              タスク
            </button>

            <button
              type='button'
              className={toggleState === 3 ? `${styles.tabItem} ${styles.tabItem_active}` : styles.tabItem}
              onClick={() => handleOpenTab(3)}
            >
              月
            </button>
            <button
              type='button'
              className={toggleState === 2 ? `${styles.tabItem} ${styles.tabItem_active}` : styles.tabItem}
              onClick={() => handleOpenTab(2)}
            >
              背景
            </button>
          </div>
          {/* <!-- Tab content --> */}
          <div className={styles.tabContents}>
            {/* tab task */}
            <div className={toggleState === 1 ? `${styles.tabTask} ${styles.tabTask_active}` : styles.tabTask}>
              <div className={styles.tabTaskWrapper}>
                <h5 className={styles.tabLabelInput}>設置の尺度：</h5>
                <TextInputForm
                  value={versionSettingState.assemblyDurationRatio}
                  style={{
                    width: '100px',
                    border: '1px solid var(--header-color)',
                    borderRadius: '3px',
                    height: '30px',
                    margin: '0px'
                  }}
                  errorMassage={errors.assemblyDurationRatio?.message}
                  name='assemblyDurationRatio'
                  register={register}
                ></TextInputForm>
              </div>
              <div className={styles.tabTaskWrapper}>
                <h5 className={styles.tabLabelInput}>撤去の尺度：</h5>
                <TextInputForm
                  value={versionSettingState.removalDurationRatio}
                  disable={true}
                  style={{
                    width: '100px',
                    border: '1px solid var(--header-color)',
                    borderRadius: '3px',
                    height: '30px',
                    margin: '0px'
                  }}
                  name='removalDurationRatio'
                  register={register}
                  errorMassage={errors.removalDurationRatio?.message}
                ></TextInputForm>
              </div>

              <div className={styles.tabTaskWrapper}>
                <h5 className={styles.tabLabelInput}>安全の尺度：</h5>
                <TextInputForm
                  value={versionSettingState.amplifiedFactor.toFixed(1)}
                  style={{
                    width: '100px',
                    border: '1px solid var(--header-color)',
                    borderRadius: '3px',
                    height: '30px',
                    margin: '0px'
                  }}
                  name='amplifiedFactor'
                  register={register}
                  errorMassage={errors.amplifiedFactor?.message}
                ></TextInputForm>
              </div>
              <input
                type='checkbox'
                id='separateCheckbox'
                className={styles.tabCheckbox}
                value={versionSettingState.removalDurationRatio}
                onChange={(event) => handleRemovalDurationRatio(event)}
              ></input>

              <h4 style={{ fontSize: '16px', marginBottom: '8px', fontWeight: '400' }}>タスクの種類リスト： </h4>
              <div className={`${styles.tabTaskTableWrapper} scroll-bar-custom`}>
                <table className={`${styles.tabTaskTableContent} scroll-bar-custom`} style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '60%' }}>タスクリスト</th>
                      <th style={{ width: '40%' }}>色</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versionSettingState.stepworkColors.map((x) => {
                      return (
                        <>
                          <TaskTableRow colorObject={x} key={x.id}></TaskTableRow>
                        </>
                      )
                    })}
                    <TaskTableRow colorObject={{ name: '' }}></TaskTableRow>
                  </tbody>
                </table>
              </div>
            </div>
            {/* tab background */}
            <div
              className={
                toggleState === 2
                  ? `${styles.tabBackground} ${styles.tabBackground_active} h-full`
                  : `${styles.tabBackground} h-full`
              }
            >
              <h5 style={{ fontSize: '16px', marginBottom: '8px', fontWeight: '400' }} className='select-none　mt-2'>
                色リスト：
              </h5>

              <div className={`${styles.tabBackgroundTableWrapper} scroll-bar-custom scrollbar-track-gray-100 `}>
                <table className={styles.tabBackgroundTableContent} style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '50%' }}>背景</th>
                      <th style={{ width: '50%' }}>色</th>
                    </tr>
                  </thead>
                  <tbody>
                    {versionSettingState.backgroundColors.map((x, i) => {
                      return (
                        <>
                          <BackgroundTableRow colorObject={x} key={x.id}></BackgroundTableRow>
                        </>
                      )
                    })}
                    <BackgroundTableRow colorObject={{ name: '' }}></BackgroundTableRow>
                  </tbody>
                </table>
              </div>
              <div className='mb-2 flex items-center justify-between mt-3'>
                <span className='text-base font-normal'>背景の色リスト</span>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='text-[#02457a] pr-4 cursor-pointer'
                  onClick={handleDeleteBackground}
                ></FontAwesomeIcon>
              </div>

              <div className=' w-full h-[120px] border border-[#D9D9D9] rounded-md overflow-y-auto scroll-bar-custom pb-3 '>
                {background &&
                  background?.map((bg) => (
                    <>
                      <BackgroundCheckbox
                        getValues={getValues}
                        backgroundColors={bg}
                        setBackground={setBackground}
                      ></BackgroundCheckbox>
                    </>
                  ))}
              </div>
            </div>

            {/* tab Month */}
            <div className={toggleState === 3 ? `${styles.tabMonth} ${styles.tabMonth_active}` : styles.tabMonth}>
              <div className={styles.tabTaskWrapper} style={{ marginTop: '10px' }}>
                <label htmlFor='monthInput' className={styles.tabLabelInput}>
                  月数：
                </label>
                <TextInputForm
                  value={versionSettingState.numberOfMonths}
                  style={{
                    width: '100px',
                    border: '1px solid var(--header-color)',
                    borderRadius: '3px',
                    height: '30px',
                    margin: '0px'
                  }}
                  errorMassage={errors.numberOfMonths?.message}
                  name='numberOfMonths'
                  register={register}
                ></TextInputForm>
              </div>
              <div className={styles.tabTaskWrapper}>
                <h5 className={styles.tabLabelInput}>列の幅：</h5>
                <TextInputForm
                  value={versionSettingState?.columnWidth}
                  style={{
                    width: '100px',
                    border: '1px solid var(--header-color)',
                    borderRadius: '3px',
                    height: '30px',
                    margin: '0px'
                  }}
                  name='columnWidth'
                  register={register}
                  errorMassage={errors.columnWidth?.message}
                ></TextInputForm>
              </div>

              <div className='w-full flex gap-2 items-center mt-5 mb-4'>
                <input
                  type='checkbox'
                  id='includeYear'
                  className='h-5 w-5 m-0 cursor-pointer'
                  checked={includeYearState}
                  onChange={handleIncludeYear}
                ></input>
                <label htmlFor='includeYear' className='select-none m-0 font-medium cursor-pointer text-base'>
                  年を追加
                </label>
              </div>
              {includeYearState && (
                <>
                  <div className='w-full flex gap-2 items-center justify-between mb-4' style={{ marginBottom: '5px' }}>
                    <label className='select-none m-0 font-normal cursor-pointer text-base'>開始年：</label>
                    <TextInputForm
                      value={versionSettingState?.startYear}
                      style={{
                        width: '100px',
                        border: '1px solid var(--header-color)',
                        borderRadius: '3px',
                        height: '30px',
                        margin: '0px'
                      }}
                      name='startYear'
                      register={register}
                      errorMassage={errors.startYear?.message}
                    ></TextInputForm>
                  </div>

                  <div className='w-full flex gap-2 items-center justify-between mb-4'>
                    <label className='select-none m-0 font-normal cursor-pointer text-base'>開始月：</label>
                    <TextInputForm
                      value={versionSettingState?.startMonth}
                      style={{
                        width: '100px',
                        border: '1px solid var(--header-color)',
                        borderRadius: '3px',
                        height: '30px',
                        margin: '0px'
                      }}
                      name='startMonth'
                      register={register}
                      errorMassage={errors.startMonth?.message}
                    ></TextInputForm>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOK} ref={btnSaveRef}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
