

function downloadCSV(csv, filename) {
  var csvFile;
  var downloadLink;
  const textEncoder = new TextEncoder("windows-1252");
  var csvContentEncoded = textEncoder.encode([csv]);
  // CSV file
  csvFile = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContentEncoded], {
    type: "text/csv;charset=utf-8",
  });

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();
}

export default function ExportTableToCSV(filename) {
  var csv = [];
  const preWord = document.getElementById("pre").value;
  const subWord = document.getElementById("sub").value;
  var data = [];

  //Nếu có prefix và subfix
  if (preWord !== "" || subWord !== "") {
    var listNewNames = [];

    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[1].classList.remove("error-cell");
      cols[1].classList.remove("warning-cell");
      const valueColOldName = cols[0].innerText;
      const valueColNewName = cols[1].innerText;
      if (valueColNewName !== "" && valueColOldName !== "") {
        data.push({
          oldName: valueColOldName,
          newName: preWord + valueColNewName + subWord,
        });
        listNewNames.push(preWord + valueColNewName + subWord);
      } else {
        if (valueColOldName !== "") {
          data.push({
            oldName: valueColOldName,
            newName: preWord + valueColOldName + subWord,
          });
          listNewNames.push(preWord + valueColOldName + subWord);
        }
      }
    }
  } else {
    var listNewNames = [];

    var rows = document.querySelectorAll("#contents-table tr");

    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[1].classList.remove("error-cell");
      cols[1].classList.remove("warning-cell");
      const valueColOldName = cols[0].innerText;
      const valueColNewName = cols[1].innerText;
      if (valueColOldName !== "") {
        data.push({
          oldName: valueColOldName,
          newName: valueColNewName,
        });
      }
    }
  }
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    csv.push(element.oldName + "," + element.newName);
  }
  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
}

export function ImportCSVToTable() {
  const inputElement = document.getElementById("input");
  inputElement.addEventListener("change", () => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      let r = fileReader.result.split("\n").map((e) => {
        return e.split(",");
      });
      r.forEach((e) => {
        let m = e
          .map((e) => {
            return `${e}`;
          })
          .join("");
        const ce = document.createElement("tr");
        ce.innerHTML = m;
        if (ce.innerText !== "") {
          document.querySelector("table").append(ce);
        }
      });
    };
    fileReader.readAsText(inputElement.files[0]);
  });
}

export function WriteDataToTable(data) {
  console.log(data);
  // data.unshift(["", ""]);
  var table = document.getElementById("contents-table");
  var rowCount = table.rows.length;
  if (data.length > rowCount) {
    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < rowCount; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[1].classList.remove("error-cell");
      cols[1].classList.remove("warning-cell");
      cols[0].innerText = data[i][0].replace(/[\r\n]/g, "");
      cols[1].innerText = data[i][1].replace(/[\r\n]/g, "");
      cols[1].setAttribute("contenteditable", true);
    }

    for (let index = rowCount; index < data.length; index++) {
      if (data[index][1]) {
        console.log(data[index][1].replace(/[\r\n]/g, ""));
        const content = `<td style="cursor: pointer">${data[index][0].replace(
          /[\r\n]/g,
          ""
        )}</td> <td class='editAbleRow' contenteditable='true'>${data[
          index
        ][1].replace(/[\r\n]/g, "")}</td>`;
        const trElement = document.createElement("tr");

        trElement.innerHTML = content;
        if (trElement.innerText !== "") {
          document.querySelector("tbody").append(trElement);
        }
      }
    }
  } else {
    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < data.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[1].classList.remove("error-cell");
      cols[1].classList.remove("warning-cell");
      cols[1].setAttribute("contenteditable", true);
      if (data[i][0] && data[i][1]) {
        cols[0].innerText = data[i][0].replace(/[\r\n]/g, "");
        cols[1].innerText = data[i][1].replace(/[\r\n]/g, "");
      }
    }
    if (rowCount !== data.length) {
      for (let index = rowCount -1 ; index >= data.length - 1; index--) {
        table.deleteRow(index);
      }
    }
  }
}

export function WriteDataToTableWhenLoadFile(dataLeft, dataRight) {
  if (dataLeft.length === 0) {
    return;
  }
  console.log(dataLeft);
  var table = document.getElementById("contents-table");
  var rowCount = table.rows.length;
  
  if (dataLeft.length > rowCount) {
    var rows = document.querySelectorAll("#contents-table tr");

    for (var i = 0; i < rowCount; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      cols[0].innerText = dataLeft[i];
      cols[1].innerText = dataRight[i] || "";
      cols[1].setAttribute("contenteditable", true);
    }

    for (let index = rowCount; index < dataLeft.length; index++) {
      if (dataLeft[index]) {
        const content = `<td style="cursor: pointer">${dataLeft[index]}</td> <td class='editAbleRow' contenteditable='true'></td>`;
        const trElement = document.createElement("tr");

        trElement.innerHTML = content;
        if (trElement.innerText !== "") {
          document.querySelector("tbody").append(trElement);
        }
      }
    }
  } else {
    var rows = document.querySelectorAll("#contents-table tr");
    for (var i = 0; i < dataLeft.length; i++) {
      var cols = rows[i].querySelectorAll("td, th");
      if (dataLeft[i]) {
        cols[0].innerText = dataLeft[i];
        cols[1].innerText = dataRight[i] || "";
        cols[1].setAttribute("contenteditable", true);
      }
    }
    if (rowCount !== dataLeft.length) {
      for (let index = rowCount - 1; index >= dataLeft.length; index--) {
        table.deleteRow(index);
      }
    }
  }
}
