import React from "react";
import "./CardComponent.css";
import { useNavigate } from "react-router-dom";
import { RouterConstants } from "../../F_UTILS/Constants/RouterConstants";

export default function CardComponent({
  src,
  name,
  version,
  navi,
}) {
  const navigate = useNavigate();

  function handleCardClick(params) {
    if (navi && navi !== "") {
      navigate(`/${RouterConstants.MENU_PAGE}/${navi}`);
    }
  }

  return (
    <>
      <div className="card--wrapper" onClick={handleCardClick}>
        <div className="card--image-boder">
          <img src={src} alt={name} className="card-image"></img>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <p className="card--name">{name}</p>
          <p className="card--version">{version}</p>
        </div>
      </div>
    </>
  );
}
