import React from 'react'

export default function ButtonPrimary({ children, onClick }) {
  return (
    <button
      onClick={onClick}
      className='bg-[#0199fa] h-[36px] w-[96px] rounded-md font-semibold text-[14px] hover:bg-[#018de6] text-[#ffff]'
    >
      {children}
    </button>
  )
}
