import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import FolderIcon from '@mui/icons-material/Folder'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import DeleteIcon from '@mui/icons-material/Delete'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'

const CustomNavLink = ({ to, children }) => {
  // Lấy đường dẫn hiện tại từ window.location
  const location = useLocation()
  const isActive = location.pathname === to
  return (
    <NavLink
      to={to}
      className={`${
        !isActive
          ? 'flex items-center lg:justify-start justify-center gap-4 py-3 px-4 rounded-md hover:bg-[#53A7EA] hover:no-underline'
          : 'flex items-center lg:justify-start justify-center gap-4 py-3 px-4 rounded-md bg-[#53A7EA] hover:no-underline'
      }`}
      style={{padding: "6px"}}
    >
      {children}
    </NavLink>
  )
}

export default function SideBar() {
  return (
    <div className=' lg:w-[360px] w-[100px] h-full bg-[#0D64A9] px-5 py-14 text-white text-lg font-normal flex flex-col gap-[6px] '>
      <CustomNavLink to={`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`}>
        <FolderIcon className='!h-7 !w-7'></FolderIcon>
        <span className='hidden lg:!block'>個人プロジェクト</span>
      </CustomNavLink>

      <CustomNavLink to={`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL_SHARED}`}>
        <FolderSharedIcon className='!h-7 !w-7'></FolderSharedIcon>
        <span className='hidden lg:!block'>共有プロジェクト</span>
      </CustomNavLink>

      <CustomNavLink to={`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL_TRASHED}`}>
        <DeleteIcon className='!h-7 !w-7'></DeleteIcon>
        <span className='hidden lg:!block'>ごみ箱</span>
      </CustomNavLink>
    </div>
  )
}
