import React from 'react'
import styles from './RadioButton.module.scss'

export default function RadioButton({ name, id, handleCheckBoxChange }) {
  return (
    <div className='h-full flex'>
      <input
        type='radio'
        name={name}
        id={id}
        className={styles.checkbox_input}
        onChange={(e) => handleCheckBoxChange(e, id)}
      ></input>
      <label htmlFor={id} className={styles.checkbox_label}>
        {id}
      </label>
    </div>
  )
}
