import { createSlice } from '@reduxjs/toolkit'
const currentVersion = {
  versionId: -1,
  projectName: 'Project 1',
  userId: 1,
  createdDate: '2023-03-01',
  modifiedDate: null,
  isActivated: true,
  numberOfMonths: 10
}
export const currentVersionSlice = createSlice({
  name: 'currentVersion',
  initialState: currentVersion,
  reducers: {
    setCurrentProject: (state, action) => {
      return action.payload
    }
  }
})
const { actions, reducer } = currentVersionSlice
export default reducer
