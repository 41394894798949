import { MouseEventHandler } from 'react'

export enum ViewMode {
  Hour = 'Hour',
  QuarterDay = 'Quarter Day',
  HalfDay = 'Half Day',
  Day = 'Day',
  /** ISO-8601 week */
  Week = 'Week',
  Month = 'Month',
  QuarterYear = 'QuarterYear',
  Year = 'Year'
}
export type TaskType = 'task' | 'milestone' | 'project'
export type PredecessorType = 'FS' | 'SS' | 'FF'
export interface Predecessors {
  type: PredecessorType
  id: string
  lagDays: number
}
export interface Task {
  id: string
  type: TaskType
  name: string
  stepworks?: Task[]
  predecessors?: Predecessors[] | undefined
  parentTaskId?: string
  colorId?: number
  styles?: {
    backgroundColor?: string
    backgroundSelectedColor?: string
  }
  groupsNumber: number
  isDisabled?: boolean
  groupId?: string
  hideChildren?: boolean
  displayOrder?: number
  isSubStepWork?: boolean
  start: number
  end: number
  duration: number
  percentStepWork?: number
  detail?: string
  note?: string
}

export interface DateRange {
  checked: boolean
  displayText: string
  startDate: any
  startMonth: any
  startYear: any
  toDate: any
  toMonth: any
  toYear: any
}

export interface EventOption {
  /**
   * Time step value for date changes.
   */
  timeStep?: number
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>
  onClickEvent?: MouseEventHandler<SVGGElement> | undefined
  onRightClickGrid: (index: number, event: React.MouseEvent<SVGRectElement, MouseEvent>) => void
  onRightClickHeader: (headerName: string, event: React.MouseEvent) => void
  onLeftClickHeader: (headerName: string, event: React.MouseEvent) => void
  onRightClickCell: (task: Task, event: React.MouseEvent) => void
  onLeftClickCell: (task: Task, event: React.MouseEvent) => void

  onChangeTaskName: (task: Task, tasks: Task[], event: React.MouseEvent) => void
  /**
   * Invokes on bar select on unselect.
   */
  onRightClick?: (task: Task, event: React.MouseEvent | React.KeyboardEvent) => void

  onSelect?: (task: Task, isSelected: boolean) => void
  /**
   * Invokes on bar double click.
   */
  onDoubleClick?: (task: Task) => void
  /**
   * Invokes on bar click.
   */
  onClick?: (task: Task) => void
  /**
   * Invokes on end and start time change. Chart undoes operation if method return false or error.
   */
  onDateChange?: (task: Task, children: Task[]) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on progress change. Chart undoes operation if method return false or error.
   */
  onProgressChange?: (task: Task, children: Task[]) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on delete selected task. Chart undoes operation if method return false or error.
   */
  onDelete?: (task: Task) => void | boolean | Promise<void> | Promise<boolean>
  /**
   * Invokes on expander on task list
   */
  onExpanderClick?: (task: Task) => void
}

export interface DisplayOption {
  viewMode?: ViewMode
  viewDate?: Date
  preStepsCount?: number
  /**
   * Specifies the month name language. Able formats: ISO 639-2, Java Locale
   */
  locale?: string
  rtl?: boolean
}

export interface StylingOption {
  headerHeight?: number
  columnWidth?: number
  listCellWidth?: string
  rowHeight?: number
  ganttHeight?: number
  barCornerRadius?: number
  handleWidth?: number
  fontFamily?: string
  fontSize?: string
  /**
   * How many of row width can be taken by task.
   * From 0 to 100
   */
  barFill?: number
  barProgressColor?: string
  barProgressSelectedColor?: string
  barBackgroundColor?: string
  barBackgroundSelectedColor?: string
  projectProgressColor?: string
  projectProgressSelectedColor?: string
  projectBackgroundColor?: string
  projectBackgroundSelectedColor?: string
  milestoneBackgroundColor?: string
  milestoneBackgroundSelectedColor?: string
  arrowColor?: string
  arrowIndent?: number
  todayColor?: string
  TooltipContent?: React.FC<{
    task: Task
    fontSize: string
    fontFamily: string
  }>
  TaskListHeader?: React.FC<{
    headerHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: string
  }>
  TaskListTable?: React.FC<{
    rowHeight: number
    rowWidth: string
    fontFamily: string
    fontSize: string
    locale: string
    tasks: Task[]
    selectedTaskId: string
    /**
     * Sets selected task by id
     */
    setSelectedTask: (taskId: string) => void
    onExpanderClick: (task: Task) => void
  }>
}

export interface GanttProps extends EventOption, DisplayOption, StylingOption {
  tasks: Task[]
}
