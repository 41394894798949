import React from 'react'
import styles from './DropDown.module.scss'

export default function DropDown({ listItems, value, placeholder, setValueChange, isDisable, isError }) {
  const [isActive, setIsActive] = React.useState(false)
  const dropDownRef = React.useRef(null)
  React.useEffect(() => {
    const handleClick = (e) => {
      if (!dropDownRef.current?.contains(e.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })

  const handleValueChange = (selectedValue) => {
    setValueChange(selectedValue)
    setIsActive(false)
  }
  console.log(value)
  return (
    <div className={styles.dropdown} ref={dropDownRef}>
      <div className={styles.dropdown_btn} onClick={() => setIsActive(!isActive)}>
        {/* Choose Color */}
        {value ? (
          <div className={styles.dropdown_selectedItem}>
            <span
              className={isDisable ? `${styles.dropdown_name} font-normal text-base` : ' font-normal text-base'}
              style={isError ? { color: 'red' } : { color: 'black' }}
            >
              {value.name || ''}
            </span>
            {value?.percentStepWork ? (
              <span className='font-medium' style={isError ? { color: 'red' } : { color: 'black' }}>
                {Math.round(value?.percentStepWork) + ' %'}
              </span>
            ) : (
              ''
            )}
            <div className={styles.dropdown_color} style={{ backgroundColor: value.code, marginRight: '3px' }}></div>
          </div>
        ) : (
          <span
            style={{ lineHeight: '36px', paddingLeft: '10px', fontSize: '16px', fontWeight: '400' }}
            className={isDisable || !listItems?.length ? styles.placeholder_disable : ''}
          >
            {placeholder || 'Choose Item'}
          </span>
        )}
        <span className={isDisable ? `${styles.placeholder_disable} fas fa-caret-down` : 'fas fa-caret-down'}></span>
      </div>
      {isActive && !isDisable && listItems?.length && (
        <div
          className={styles.dropdown_content}
          style={listItems?.length > 10 ? { height: '300px' } : { height: `${listItems?.length * 40 + 20}px` }}
        >
          <div className='w-full h-full overflow-y-auto'>
            {listItems?.map((item) => {
              console.log(item)
              return (
                <div key={item?.id} className={styles.dropdown_item} onClick={() => handleValueChange(item)}>
                  <span>{item?.name}</span>
                  {item?.percentStepWork ? <span>{Math.round(item?.percentStepWork) + ' %'}</span> : ''}
                  {item?.code && <div className={styles.dropdown_color} style={{ backgroundColor: item.code }}></div>}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
