import { nanoid } from '@reduxjs/toolkit'
import { omit, uniq } from 'lodash'

const sortTasks = (taskA, taskB) => {
  const orderA = taskA.displayOrder || Number.MAX_VALUE
  const orderB = taskB.displayOrder || Number.MAX_VALUE
  if (orderA > orderB) {
    return 1
  } else if (orderA < orderB) {
    return -1
  } else {
    return 0
  }
}

export const DeleteTask = (task, tasks) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  let newTasks = []
  let countDisplayOrder = 1
  for (let index = 0; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    if (element.id !== task.id) {
      element.displayOrder = countDisplayOrder
      countDisplayOrder++
      newTasks.push(element)
    }
  }
  newTasks = newTasks.sort(sortTasks)
  return newTasks
}

export const checkIsCanMove = (tasks, task) => {
  let listPredecessors = []

  tasks.forEach((task) => {
    if (task?.stepworks) {
      task.stepworks.forEach((stepWork) => {
        if (stepWork?.predecessors) {
          stepWork.predecessors.map((st) => listPredecessors.push(st.id))
        }
      })
    } else {
      if (task?.predecessors) {
        task.predecessors.map((st) => listPredecessors.push(st.id))
      }
    }
  })
  return !listPredecessors.includes(task.id)
}

export const DeleteListTasks = (listTaskId, tasks) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  let newTasks = []
  let countDisplayOrder = 1
  for (let index = 0; index < copyTasks.length; index++) {
    const element = copyTasks[index]
    if (!listTaskId.includes(element.id)) {
      element.displayOrder = countDisplayOrder
      countDisplayOrder++
      newTasks.push(element)
    }
  }
  newTasks = newTasks.sort(sortTasks)
  return newTasks
}

export const CheckCanMoveTask = (currentTask, tasks, isUp) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  const currentGroupIndex = copyTasks.indexOf(currentTask)
  const currentGroupChildTask = copyTasks.filter((x) => x.groupId === currentTask.groupId)
  if (currentGroupChildTask[0]?.displayOrder === currentGroupIndex + 1 && isUp) {
    return false
  }

  if (currentGroupChildTask.at(-1).displayOrder === currentGroupIndex + 1 && !isUp) {
    return false
  }
  return true
}

export const MoveTask = (currentTask, tasks, isUp) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  const currentGroupIndex = copyTasks.indexOf(currentTask)
  const currentGroupChildTask = copyTasks.filter((x) => x.groupId === currentTask.groupId)
  if (currentGroupChildTask[0]?.displayOrder === currentGroupIndex + 1 && isUp) {
    return tasks
  }

  if (currentGroupChildTask.at(-1).displayOrder === currentGroupIndex + 1 && !isUp) {
    return tasks
  }

  if (isUp) {
    copyTasks[currentGroupIndex].displayOrder = copyTasks[currentGroupIndex].displayOrder - 1
    copyTasks[currentGroupIndex - 1].displayOrder = copyTasks[currentGroupIndex - 1].displayOrder + 1
  } else {
    copyTasks[currentGroupIndex].displayOrder = copyTasks[currentGroupIndex].displayOrder + 1
    copyTasks[currentGroupIndex + 1].displayOrder = copyTasks[currentGroupIndex + 1].displayOrder - 1
  }

  copyTasks.sort(sortTasks)
  return copyTasks
}

export const CheckCanMoveTask2 = (currentTask, tasks, isUp) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  const currentGroup = copyTasks.filter((x) => x.id === currentTask.groupId)
  const currentGroupChildTask = copyTasks.filter((x) => x.groupId === currentTask.groupId)
  if (currentTask.displayOrder === currentGroup[0].displayOrder + 1 && isUp) {
    return false
  }

  if (currentTask.displayOrder === currentGroup[[0]].displayOrder + currentGroupChildTask.length && !isUp) {
    return false
  }
  return true
}

export const CheckListTaskId = (listCurrentTaskIds, tasks) => {
  function onlyUniqueGroupId(value, index, array) {
    return array.indexOf(value) === index
  }

  const currentTasks = tasks.filter((task) => listCurrentTaskIds.includes(task.id))
  const listGroupIds = currentTasks.map((task) => task.groupId).filter(onlyUniqueGroupId)
  return listGroupIds.length > 1 ? false : true
}

export const MoveListTasks = (listCurrentTaskId, tasks, isUp) => {
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  let listSelectedTasks = copyTasks.filter((task) => listCurrentTaskId.includes(task.id))
  listSelectedTasks = listSelectedTasks.sort(sortTasks)
  if (isUp) {
    // find initTask by Id
    const initTask = listSelectedTasks[0]
    const currentTaskIndex = copyTasks.indexOf(initTask)
    if (!CheckCanMoveTask2(initTask, tasks, isUp)) return tasks
    console.log(CheckCanMoveTask2(initTask, tasks, isUp))
    // plus 1 display Order for task
    let nextDisplayOrder = copyTasks[currentTaskIndex].displayOrder
    listSelectedTasks.forEach((t) => {
      const task = copyTasks.find((x) => x.id === t.id)
      if (task) {
        task.displayOrder = nextDisplayOrder - 1
        nextDisplayOrder++
      }
    })
    nextDisplayOrder--
    copyTasks[currentTaskIndex - 1].displayOrder = nextDisplayOrder
    for (let index = currentTaskIndex; index < tasks.length; index++) {
      const task = tasks[index]

      if (!listCurrentTaskId.includes(task.id)) {
        nextDisplayOrder++
        task.displayOrder = nextDisplayOrder
      }
    }
    // for từ vị trí iniTask => +1
    copyTasks.sort(sortTasks)
    return copyTasks
  } else {
    // find initTask by Id
    const initTask = listSelectedTasks[listCurrentTaskId.length - 1]
    const currentTaskIndex = copyTasks.indexOf(initTask)
    if (!CheckCanMoveTask2(initTask, tasks, isUp)) return tasks
    console.log(CheckCanMoveTask2(initTask, tasks, isUp))
    let nextDisplayOrder = copyTasks[currentTaskIndex].displayOrder
    listSelectedTasks.reverse().forEach((t) => {
      const task = copyTasks.find((x) => x.id === t.id)
      if (task) {
        task.displayOrder = nextDisplayOrder + 1
        nextDisplayOrder--
      }
    })
    nextDisplayOrder++
    copyTasks[currentTaskIndex + 1].displayOrder = nextDisplayOrder

    for (let index = currentTaskIndex; index > 0; index--) {
      const task = tasks[index]

      if (!listCurrentTaskId.includes(task.id)) {
        nextDisplayOrder--
        task.displayOrder = nextDisplayOrder
      }
    }
    // for từ vị trí iniTask => +1
    copyTasks.sort(sortTasks)
    return copyTasks
  }
}

function array_move(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])

  if (new_index === 0) {
    const temp = arr[1]
    arr[1] = arr[0]
    arr[0] = temp
  }
  return arr
}

export const MoveTaskTo = (currentTask, toTask, tasks) => {
  tasks.sort(sortTasks)
  let copyTasks = [...tasks]
  const currentTaskIndex = copyTasks.indexOf(currentTask)
  const toTaskIndex = copyTasks.indexOf(toTask)
  if (toTask.type === 'task') {
    copyTasks[currentTaskIndex].groupId = copyTasks[toTaskIndex].groupId
  } else {
    copyTasks[currentTaskIndex].groupId = copyTasks[toTaskIndex].id
  }

  copyTasks = array_move(copyTasks, currentTaskIndex, toTaskIndex)
  let countDisplayOrder = 1
  copyTasks.forEach((task) => {
    task.displayOrder = countDisplayOrder
    countDisplayOrder++
  })
  copyTasks.sort(sortTasks)
  return copyTasks
}

export const AddTask = (currentTask, tasks, isAddBefore, defaultColorId) => {
  const groupId = currentTask.groupId
  const taskDisplayOrders = currentTask.displayOrder
  tasks.sort(sortTasks)
  const copyTasks = [...tasks]
  let displayOrder = 0
  if (isAddBefore) {
    displayOrder = taskDisplayOrders
    const newTask = {
      start: 0,
      end: 70,
      duration: 30,
      groupsNumber: 1,
      name: '新しいタスク' + getMaxTaskName(tasks),
      id: nanoid(),
      predecessors: [],
      type: 'task',
      groupId: groupId,
      colorId: defaultColorId,
      displayOrder: displayOrder
    }

    for (let index = taskDisplayOrders - 1; index < copyTasks.length; index++) {
      const element = copyTasks[index]
      element.displayOrder = element.displayOrder + 1
    }
    copyTasks.push(newTask)
    copyTasks.sort(sortTasks)
    return copyTasks
  } else {
    displayOrder = taskDisplayOrders + 1
    const newTask = {
      start: 0,
      end: 70,
      duration: 30,
      groupsNumber: 1,
      name: '新しいタスク' + getMaxTaskName(tasks),
      id: nanoid(),
      progress: 100,
      predecessors: [],
      type: 'task',
      groupId: groupId,
      colorId: defaultColorId,
      displayOrder: displayOrder
    }

    for (let index = taskDisplayOrders; index < copyTasks.length; index++) {
      const element = copyTasks[index]
      element.displayOrder = element.displayOrder + 1
    }
    copyTasks.push(newTask)
    copyTasks.sort(sortTasks)
    return copyTasks
  }
}

export const getMaxTaskName = (tasks) => {
  const listTaskNameNewTask = tasks.filter((t) => t.type === 'task' && t.name.includes('新しいタスク'))

  const listNumbers = listTaskNameNewTask.map((task) => {
    const number = task.name.replace('新しいタスク', '').replace(/ /g, '')
    if (isNaN(number)) {
      return 0
    } else {
      return Number(number)
    }
  })
  if (listNumbers.length === 0) {
    return 0
  } else {
    return Math.max(...listNumbers) + 1
  }
}

//Clear step Works

export const findParentTask = (childTask, tasks) => {
  let parentTask
  const childTaskId = childTask.id
  tasks.forEach((task) => {
    if (task?.predecessors) {
      const findTask = task.predecessors.filter((t) => t.id === childTaskId)
      if (findTask && findTask.length !== 0) {
        parentTask = task
      }
    }
    if (task?.stepworks) {
      task.stepworks.forEach((st) => {
        const findTask = st?.predecessors?.filter((t) => t.id === childTaskId)
        if (findTask && findTask.length !== 0) {
          parentTask = st
        }
      })
    }
  })
  return parentTask
}

export const removeParentTask = (parentTasks, childTask, tasks) => {
  const copyTasks = [...tasks]
  const listStepWorkTaskId = childTask.stepworks.map((st) => st.id)
  parentTasks.forEach((parentTask) => {
    const findTask = copyTasks.find((t) => t.id === parentTask.id)
    if (findTask) {
      findTask.predecessors = findTask.predecessors.filter((pre) => !listStepWorkTaskId.includes(pre.id))
    }
  })
}

export const resetTask = (task, tasks, defaultColorId) => {
  const copyTasks = [...tasks]
  const findTask = copyTasks.find((t) => t.id === task.id)
  if (findTask) {
    findTask.stepworks = undefined
    findTask.start = 0
    findTask.colorId = defaultColorId
    findTask.predecessors = undefined
  }
}

export const calculateGridHeight = (tasks) => {
  const copyTasks = [...tasks]
  //lấy tất các task có groupid !== undefined nghĩa là lấy các task có k phải project
  const listTaskHaveParentId = copyTasks.filter((x) => x.parentTaskId !== undefined)
  //lấy tất các task có groupid !== undefined nghĩa là lấy các task có k phải project
  const listParentId = listTaskHaveParentId.map(function (obj) {
    return obj.parentTaskId
  })
  // lấy ra các task có subtask
  // thuật toán => lọc ra task có isSubStepWork => lọc ra parentId để tránh lặp lại
  const countTaskIsSubStepWorks = uniq(copyTasks.filter((task) => task?.isSubStepWork).map((task) => task.parentTaskId))
  if (listParentId === undefined) {
    return copyTasks.length + countTaskIsSubStepWorks.length
  } else {
    const ids = []
    for (let index = 0; index < listParentId.length; index++) {
      const element = listParentId[index]
      if (element === undefined) {
        continue
      }
      if (ids.includes(element)) {
        continue
      } else {
        ids.push(element)
      }
    }
    // tổng số task trừ đi
    return copyTasks.length - (listParentId.length - ids.length) + countTaskIsSubStepWorks.length
  }
}


