export const convertArrayToString = (array) => {
  function compareNumbers(a, b) {
    return a - b
  }
  if (array.length === 1) {
    return array[0].toString()
  } else {
    array.sort(compareNumbers)
    const result = array.reduce((r, n) => {
      const lastSubArray = r[r.length - 1]
      if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
        r.push([])
      }
      r[r.length - 1].push(n)
      return r
    }, [])
    let numberString = ''
    result.forEach((subArr) => {
      if (subArr.length === 1) {
        numberString = numberString + `,${subArr[0]}`
      } else if (subArr.length === 2) {
        numberString = numberString + `,${subArr[0]},${subArr[1]}`
      } else {
        numberString = numberString + `,${subArr[0]}-${subArr.at(-1)}`
      }
    })
    return numberString.substring(1)
  }
}

const arrayRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)
export const ConvertStringMonthsToArray = (content) => {
  console.log('====================================')
  console.log(1221)
  console.log('====================================')
  let arrayResult = []
  var monthGroup = content.split(',')
  monthGroup.forEach((group) => {
    const arrMonths = group.split('-')
    if (arrMonths.length === 1) {
      arrayResult.push(Number(arrMonths[0]))
    } else if (arrMonths.length === 2) {
      const arr = arrayRange(Number(arrMonths[0]), Number(arrMonths[1]), 1)
      arrayResult = arrayResult.concat(arr)
    }
  })
  return arrayResult
}
