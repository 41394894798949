import React, { StrictMode } from 'react'
import store from './C_REDUX_STORE/store'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { ThemeProvider, createTheme } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#0199fa'
    },
    secondary: {
      main: '#edf2ff'
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </HashRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
