import React from 'react'
import styles from './SetStepWorkComponent.module.scss'
import { useSelector } from 'react-redux'
import StepWorkRow from './components/StepWorkRow'
import { nanoid } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getDurationScale } from 'Z_SERVICES/j_MainChart/grant-base/helpers/other-helper'
import { findParentTask, removeParentTask, resetTask } from 'F_UTILS/SchedulingUtils/TaskUtils'
import { calculateTaskPositionForSendDataToServer } from 'Z_SERVICES/j_MainChart/grant-base/helpers/bar-helper'

export default function SetStepWorkComponent({ task, tasks, setTasks, setIsSetStepWork }) {
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const currentSetting = useSelector((state) => state.versionSetting.current)
  const amplifiedFactor = useSelector((state) => state.versionSetting.current.amplifiedFactor)
  const [stepWorks, setStepWork] = React.useState([])

  React.useEffect(() => {
    function GetInitStepWorks() {
      if (task?.stepworks && task?.stepworks?.length) {
        return task.stepworks
      } else {
        const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
        const removalColor = stepWorkColors?.find((st) => st.isInstall === 1)

        const assemblyStepWork = {
          duration: task.duration,
          percentStepWork: currentSetting.assemblyDurationRatio * 100 || 60,
          parentTaskId: task.id,
          name: task.name + nanoid(),
          id: nanoid(),
          type: 'task',
          groupId: task.groupId,
          displayOrder: task.displayOrder,
          predecessors: [],
          colorId: assemblyColor?.colorId || 0
        }
        const removalStepWork = {
          duration: task.duration,
          percentStepWork: currentSetting.removalDurationRatio * 100 || 40,
          parentTaskId: task.id,
          name: task.name + nanoid(),
          id: nanoid(),
          type: 'task',
          groupId: task.groupId,
          displayOrder: task.displayOrder,
          predecessors: [],
          colorId: removalColor?.colorId || 0
        }
        let resultArr = []
        resultArr.push(assemblyStepWork)
        resultArr.push(removalStepWork)
        return resultArr
      }
    }
    setStepWork(GetInitStepWorks())
  }, [task])

  const handleOkClick = () => {
    const totalPercent = stepWorks.map((stepWork) => stepWork.percentStepWork).reduce((prev, curr) => prev + curr, 0)
    if (Math.round(totalPercent * 100) / 100 !== 100) {
      toast.error('ステープワークのパーセント合計は100％です。もう一度確認してください。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      return
    }
    const columnWidth = currentSetting?.columnWidth
    const copyStepWorks = [...stepWorks]
    const durationScale = getDurationScale(task, amplifiedFactor)
    // step wroks đầu sẽ được thừa hưởng toàn bộ thuộc tính vị trí predecessors của thằng cha.
    if (!task.stepworks || task?.stepworks?.length === 0) {
      copyStepWorks[0].predecessors = task.predecessors
      copyStepWorks[0].start = task.start || 0
      copyStepWorks[0].end =
        task.start +
        (((copyStepWorks[0].duration * durationScale * copyStepWorks[0].percentStepWork) / 100) * columnWidth) / 30
    }

    // tính toán lại vị trí cho các thằng stepwork khác.
    for (let index = 1; index < copyStepWorks.length; index++) {
      const sw = copyStepWorks[index]
      if (!sw?.start) {
        sw.start = copyStepWorks[index - 1].end
      }
      sw.end = sw?.start + (((sw.duration * durationScale * sw.percentStepWork) / 100) * columnWidth) / 30
    }
    // update step works
    let copyPrev = [...tasks]
    const newTasks = copyPrev.map((sw) => {
      if (sw.id === task?.id) {
        return { ...sw, stepworks: copyStepWorks }
      } else {
        return sw
      }
    })

    // update predecessors id
    for (let i = 0; i < newTasks.length; i++) {
      const ta = newTasks[i]
      if (ta?.stepworks) {
        const taskPredecessors = ta.stepworks
        for (let index = 0; index < taskPredecessors.length; index++) {
          const taskPredecessor = taskPredecessors[index]
          const found = taskPredecessor.predecessors?.findIndex((x) => x.id === task.id)
          if (found !== undefined && found !== -1) {
            taskPredecessor.predecessors[found].id = copyStepWorks[0].id
          }
        }
      } else {
        const found = ta.predecessors?.find((x) => x.id === task.id)
        if (found !== undefined && found !== -1) {
          found.id = copyStepWorks[0].id
        }
      }
    }
    setTasks(newTasks)
    toast.success('設定の更新に成功しました。', {
      position: 'bottom-center',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored'
    })
    setIsSetStepWork(false)
  }
  const handleCancelClick = () => {
    setIsSetStepWork(false)
  }
  const handleDeleteClick = () => {
    if (!task?.stepworks) {
      return
    }
    let parentTasks = []
    if (task?.stepworks) {
      task.stepworks.forEach((t) => {
        const parentTask = findParentTask(t, tasks)
        if (parentTask) {
          parentTasks.push(parentTask)
        }
      })
    }
    removeParentTask(parentTasks, task, tasks)
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    resetTask(task, tasks, assemblyColor.colorId)
    //const convertedTasks = calculateTaskPositionForSendDataToServer([...tasks], columnWidth, amplifiedFactor)
    //setTasks(convertedTasks)
    setIsSetStepWork(false)
  }
  return (
    <div className={`overlay-modal ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className='flex justify-between items-center'>
          <span className='text-[20px]'>ステープワークを分ける</span>
          <button className='btn-secondary' onClick={handleDeleteClick}>
            すべて削除
          </button>
        </div>

        <div className={styles.tableContainer}>
          <table className={styles.tableContent}>
            <thead className='border-t border-t-gray-300'>
              <tr className='border-t border-t-gray-300 text-base'>
                <th style={{ width: '15%' }} className='text-sm'>
                  No.
                </th>
                <th style={{ width: '55%' }} className='text-sm'>
                  色
                </th>
                <th style={{ width: '30%' }} className='text-sm'>
                  パーセント(%)
                </th>
              </tr>
            </thead>
            <tbody>
              {!task?.stepworks && stepWorks?.length === 2 && (
                <>
                  <StepWorkRow
                    key={stepWorks[0].id}
                    task={task}
                    index={1}
                    stepWork={stepWorks[0]}
                    setStepWork={setStepWork}
                    isDisableDropdown={true}
                    isDisablePercentInput={true}
                  ></StepWorkRow>
                  <StepWorkRow
                    key={stepWorks[1].id}
                    task={task}
                    index={2}
                    stepWork={stepWorks[1]}
                    setStepWork={setStepWork}
                    isDisableDropdown={true}
                    isDisablePercentInput={true}
                  ></StepWorkRow>
                  <StepWorkRow index={stepWorks.length + 1} task={task} setStepWork={setStepWork}></StepWorkRow>
                </>
              )}
              {!task?.stepworks &&
                stepWorks?.length > 2 &&
                stepWorks?.map((stepWork, index) => {
                  if (index === stepWorks.length - 1) {
                    return (
                      <>
                        <StepWorkRow
                          key={stepWork.id}
                          task={task}
                          index={index + 1}
                          stepWork={stepWork}
                          setStepWork={setStepWork}
                          isDisableDropdown={false}
                        ></StepWorkRow>
                        {stepWorks.length < 7 ? (
                          <StepWorkRow index={stepWorks.length + 1} task={task} setStepWork={setStepWork}></StepWorkRow>
                        ) : (
                          ''
                        )}
                      </>
                    )
                  } else {
                    return (
                      <>
                        <StepWorkRow
                          key={stepWork.id}
                          task={task}
                          index={index + 1}
                          stepWork={stepWork}
                          setStepWork={setStepWork}
                          isDisableDropdown={false}
                        ></StepWorkRow>
                      </>
                    )
                  }
                })}

              {task?.stepworks?.length !== 0 &&
                task?.stepworks &&
                stepWorks?.map((stepWork, index) => {
                  if (index === stepWorks.length - 1) {
                    return (
                      <>
                        <StepWorkRow
                          key={stepWork.id}
                          task={task}
                          index={index + 1}
                          stepWork={stepWork}
                          setStepWork={setStepWork}
                          isDisableDropdown={false}
                        ></StepWorkRow>
                        {stepWorks.length < 7 ? (
                          <StepWorkRow index={stepWorks.length + 1} task={task} setStepWork={setStepWork}></StepWorkRow>
                        ) : (
                          ''
                        )}
                      </>
                    )
                  } else {
                    return (
                      <>
                        <StepWorkRow
                          key={stepWork.id}
                          task={task}
                          index={index + 1}
                          stepWork={stepWork}
                          setStepWork={setStepWork}
                          isDisableDropdown={false}
                        ></StepWorkRow>
                      </>
                    )
                  }
                })}
            </tbody>
          </table>
        </div>
        <div className={styles.button_container}>
          <button className='btn-primary' onClick={handleOkClick}>
            OK
          </button>
          <button className='btn-secondary' onClick={handleCancelClick}>
            キャンセル
          </button>
        </div>
      </div>
    </div>
  )
}
