import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import StorageKeys from "../F_UTILS/Constants/StorageKeys";
import AuthApi from "../B_API/AuthAPI";
import TokenServices from "../B_API/TokenServices";

export const login = createAsyncThunk(
  "authentication/Login",
  async (payload) => {
    try {
      const response = await AuthApi.Login(payload.userInfo);
      TokenServices.addToken(response.data);
      const responseUser = await AuthApi.GetUserCredentialDetail();

      var rememberMe = payload.rememberMe;
      TokenServices.setLocalStorageRemember(rememberMe);
      if (responseUser.status === 200 && rememberMe === true)
        TokenServices.setUser(responseUser?.data);

      return responseUser?.data;
    } catch (error) {
      console.log("Authentication Failed: ", error);
      return error.message;
    }
  }
);

export const refreshToken = createAsyncThunk(
  "authentication/refresh",
  async (payload) => {
    try {
      const response = await AuthApi.RefreshToken(payload);
      if (response.status === 200) TokenServices.addToken(response.data);
      return response.data;
    } catch (error) {
      console.log("Refresh Token Failed: ", error);
      TokenServices.removeUser();
      return error.message;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    current: TokenServices.getUser() || {},
    status: "idle",
    error: null,
  },

  reducers: {
    logout(state) {
      state.current = {};
      TokenServices.removeUser();
    },
  },

  extraReducers: {
    [login.pending]: (state, action) => {
      state.status = "loading";
    },
    [login.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.status = "succeeded";
    },
    [login.rejected]: (state, action) => {
      state.current = {};
      state.status = action.error.message;
      TokenServices.removeUser();
    },

    [refreshToken.pending]: (state, action) => {
      state.status = "loading";
    },
    [refreshToken.fulfilled]: (state, action) => {
      if (!!action.payload.accessToken === true) {
        TokenServices.addToken(action.payload);
        state.status = "succeeded";
      } else {
        state.current = {};
        state.status = action.payload;
        TokenServices.removeUser();
      }
    },
    [refreshToken.rejected]: (state, action) => {
      state.current = {};
      state.status = action.error.message;
      TokenServices.removeUser();
    },
  },
});

const { actions, reducer } = userSlice;
export const { logout } = actions;
export default reducer;
