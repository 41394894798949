import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import './LoginServices.scss'
import TokenServices from '../../B_API/TokenServices'
import { login } from '../../C_REDUX_STORE/userSlice'
import { RouterConstants } from '../../F_UTILS/Constants/RouterConstants'
import LoginDialogComponent from '../../A_SHARED_COMPONENT/LoginDialogComponent'
import AuthApi from '../../B_API/AuthAPI'
import { DialogConfirm } from 'A_SHARED_COMPONENT/DialogComponent'

const LoginForm = () => {
  let navigate = useNavigate()
  const [type, setType] = useState('password')
  const [showDialog, setShowDialog] = useState({
    show: false,
    callBackLoginFunc: null
  })

  const [loginFailed, setLoginFailed] = useState(false)
  const [isOpenIcon, setIsOpenIcon] = useState(true)

  var initRememberMe = TokenServices.getLocalStorageRemember()
  const [rememberMe, setRememberMe] = useState(!!initRememberMe ? initRememberMe === 'true' : true)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().email('この電子メールは認識されません').required('電子メールを入力してください'),
      password: Yup.string().required('パスワードを入力してください')
    }),
    onSubmit: (values) => {
      handleOnSubmit(values)
    }
  })

  const handleOnSubmit = async (values) => {
    try {
      TokenServices.removeUser()
      TokenServices.setLocalStorageRemember(rememberMe)
      var userInfo = {
        userName: values?.username,
        password: values?.password
      }

      const response = await AuthApi.CheckOnline(userInfo)

      if (response.status === 200) {
        var user = response.data

        if (user?.isOnline) {
          setShowDialog({
            show: true,
            callBackLoginFunc: () => callBackLogin(userInfo)
          })
        } else {
          callBackLogin(userInfo)
        }
      } else {
        setLoginFailed(true)
      }
    } catch (error) {
      console.error(error)
      setLoginFailed(true)
    }
  }

  const callBackLogin = async (userInfo) => {
    const actions = login({
      userInfo: userInfo,
      rememberMe: rememberMe
    })
    const resultAction = await dispatch(actions)
    const userUnwrap = unwrapResult(resultAction)

    if (userUnwrap?.userId) {
      navigate(`/${RouterConstants.MENU_PAGE}`)
      setLoginFailed(false)
    } else {
      setLoginFailed(true)
    }
  }

  const handleClickRememberAccount = (e) => {
    setRememberMe(e?.target?.checked)
  }

  const handleIconClick = () => {
    if (type === 'password') {
      setType('text')
      setIsOpenIcon(false)
    } else {
      setType('password')
      setIsOpenIcon(true)
    }
  }

  const handleOKButton = () => {
    if (showDialog) {
      showDialog.callBackLoginFunc()
    }
  }

  const handleCancelButton = () => {
    setShowDialog({
      show: false,
      callBackLoginFunc: null
    })
  }
  return (
    <div className='container-login'>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='form-container'>
          <h3 className='form_header'>AutoCAD Cloud Add-in</h3>
          <div className='form_wrapper'>
            <label className='form_label' htmlFor='email'>
              メール :{' '}
            </label>
            <input
              type={'username'}
              name='username'
              value={formik.values.username}
              onChange={formik.handleChange}
              className='form_input'
            ></input>
            <div className='error_container'>
              {formik.errors.username && formik.touched.username && (
                <p className='error_content'>{formik.errors.username}</p>
              )}
            </div>
          </div>

          <div className='form_wrapper'>
            <label className='form_label' htmlFor='password'>
              パスワード :{' '}
            </label>
            <div className='form_password-field'>
              <input
                type={type}
                name='password'
                autoComplete='on'
                value={formik.values.password}
                onChange={formik.handleChange}
                className='form_input'
              ></input>

              {isOpenIcon === true && (
                <svg
                  onClick={handleIconClick}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='form_input_eye'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z'
                  />
                  <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
                </svg>
              )}
              {isOpenIcon === false && (
                <svg
                  onClick={handleIconClick}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='form_input_eye'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88'
                  />
                </svg>
              )}
            </div>
            <div className='error_container'>
              {formik.errors.password && formik.touched.password && (
                <p className='error_content'>{formik.errors.password}</p>
              )}
            </div>
          </div>

          <div className='form_remember'>
            <input
              type='checkbox'
              name='remember'
              id='remember'
              onClick={handleClickRememberAccount}
              checked={rememberMe}
            />
            <label htmlFor='remember'>サインイン保持</label>
          </div>
          <div className='error_container'>
            {loginFailed && <p className='error_login'>電子メール アドレスとパスワードが一致しません。</p>}
          </div>

          <div className='login-submit'>
            <button className='form_button' type='submit'>
              ログイン
            </button>
          </div>

          <p className='form_noti'>初めてご使用になる場合、管理者に連絡してください。</p>
        </div>
      </form>

      <DialogConfirm
        open={showDialog.show}
        handleOK={handleOKButton}
        handleClose={handleCancelButton}
        title={'ログイン'}
      >
        <span className='text-base text-center'>
          現在、このアカウントは他のデバイスにログインされています。変更しますか？
        </span>
      </DialogConfirm>
    </div>
  )
}
export default LoginForm
