import React, { useRef, useEffect } from 'react'
import styles from './PopoverTaskComponent.module.scss'
import {
  AddTask,
  CheckListTaskId,
  DeleteListTasks,
  DeleteTask,
  MoveListTasks,
  MoveTask
} from '../../../../F_UTILS/SchedulingUtils/TaskUtils'
import { useSelector } from 'react-redux'
import { ToastError } from 'F_UTILS/ToastUtils'

export default function PopoverTaskComponent({
  event,
  task,
  setRightCellClick,
  tasks,
  setTasks,
  setTaskCell,
  setIsSetStepWork,
  setShowMoveGroupTask,
  setShowHiddenRowsModal,
  isViewPopup = false
}) {
  const tooltipRef = useRef(null)
  const selectedTaskIds = useSelector((state) => state.selectedTask)
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)

  const handleStepWork = () => {
    if (selectedTaskIds.length > 1) {
      setRightCellClick(false)
      return
    }
    setIsSetStepWork(true)
    setRightCellClick(false)
  }

  const handleDeleteTask = () => {
    if (selectedTaskIds.length > 1) {
      const newTasks = DeleteListTasks(selectedTaskIds, tasks)
      setTasks(newTasks)
      setTaskCell(null)
      setRightCellClick(false)
      setTaskCell(null)
      return
    }
    var newTasks = DeleteTask(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
    setTaskCell(null)
  }

  const handleAddTaskBefore = () => {
    if (selectedTaskIds.length > 1) {
      setRightCellClick(false)
      return
    }
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    var newTasks = AddTask(task, tasks, true, assemblyColor.colorId)
    setTasks(newTasks)
    setRightCellClick(false)
  }

  const handleAddTaskAfter = () => {
    if (selectedTaskIds.length > 1) {
      setRightCellClick(false)
      return
    }
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    var newTasks = AddTask(task, tasks, false, assemblyColor.colorId)
    setTasks(newTasks)
    setRightCellClick(false)
  }

  const handleMoveUp = () => {
    if (selectedTaskIds.length > 1 && CheckListTaskId(selectedTaskIds, tasks)) {
      const newTasks = MoveListTasks(selectedTaskIds, tasks, true)
      setRightCellClick(false)
      setTasks(newTasks)
      return
    }
    var newTasks = MoveTask(task, tasks, true)
    setTasks(newTasks)
    setRightCellClick(false)
  }

  const handleMoveDown = () => {
    if (selectedTaskIds.length > 1 && CheckListTaskId(selectedTaskIds, tasks)) {
      const newTasks = MoveListTasks(selectedTaskIds, tasks, false)
      setTasks(newTasks)
      setRightCellClick(false)
      return
    }
    var newTasks = MoveTask(task, tasks, false)
    setTasks(newTasks)
    setRightCellClick(false)
  }

  const handleMoveTo = () => {
    if (selectedTaskIds.length > 1) {
      setRightCellClick(false)
      return
    }
    setShowMoveGroupTask(true)
    setRightCellClick(false)
  }

  const handleChangeUnit = () => {
    var selectedIds = [...selectedTaskIds]
    if (selectedIds.length == 0) {
      selectedIds.push(task.id)
    }

    var checked = !task.isDayFormat;

    var newTasks = tasks.map((t) => {
      return { ...t, isDayFormat: selectedIds.includes(t.id) && t.type == 'task' ? checked : t.isDayFormat }
    })

    setTasks(newTasks)
    setRightCellClick(false)
  }
  
  const handleHidden = () => {
    var selectedIds = [...selectedTaskIds]
    if (selectedIds.length == 0) {
      selectedIds.push(task.id)
    }
    var newTasks = tasks.map((t) => {
      return { ...t, isHidden: selectedIds.includes(t.id) && t.type == 'task' ? true : t.isHidden }
    })
    var groups = newTasks.filter((t) => t.type == 'project')

    let hasError = false
    groups.forEach((g) => {
      var taskInGroup = newTasks.filter((t) => t.groupId == g.id)
      if (
        taskInGroup.filter((t) => t.isHidden && t.type == 'task').length ===
        taskInGroup.filter((t) => t.type == 'task').length
      ) {
        hasError = true
      }
    })

    if (hasError) {
      ToastError('すべてのタスクを非表示にすることはできません。')
    } else {
      setTasks(newTasks)
    }
    setRightCellClick(false)
  }


  const handleUnHidden = () => {
    if (setShowHiddenRowsModal) setShowHiddenRowsModal(true)
    setRightCellClick(false)
  }



  useEffect(() => {
    let tbHeight = window.innerHeight
    let locationY = event.clientY + 10
    let locationX = event.clientX - 20
    if (locationY + 245 > tbHeight) {
      locationY = tbHeight - 245 - 10
    }
    tooltipRef.current.style.top = `${locationY}px`
    tooltipRef.current.style.left = `${locationX}px`
  }, [event])
  return (
    <>
      <div className={styles.popoverTaskMenu} ref={tooltipRef}>
        {isViewPopup && (
          <>
            <div
              className={`${styles.popoverTaskItem} ${
                !CheckListTaskId(selectedTaskIds, tasks) ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleHidden}
            >
              非表示
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                !CheckListTaskId(selectedTaskIds, tasks) ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleUnHidden}
            >
              タスクを表示
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                !CheckListTaskId(selectedTaskIds, tasks) ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleChangeUnit}
            >
              {task.isDayFormat ? '月単位' : '日単位'}
            </div>
          </>
        )}
        {!isViewPopup && (
          <>
            <div
              className={`${styles.popoverTaskItem} ${
                selectedTaskIds.length > 1 ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleStepWork}
            >
              ステープワーク
            </div>
            <div className={styles.popoverTaskItem} onClick={handleDeleteTask}>
              タスク削除
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                selectedTaskIds.length > 1 ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleAddTaskBefore}
            >
              上にタスクを追加
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                selectedTaskIds.length > 1 ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleAddTaskAfter}
            >
              下にタスクを追加
            </div>

            <div
              className={`${styles.popoverTaskItem} ${
                !CheckListTaskId(selectedTaskIds, tasks) ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleMoveUp}
            >
              タスクを上げする
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                !CheckListTaskId(selectedTaskIds, tasks) ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleMoveDown}
            >
              タスクを下げする
            </div>
            <div
              className={`${styles.popoverTaskItem} ${
                selectedTaskIds.length > 1 ? styles.popoverTaskItem_disable : ''
              }`}
              onClick={handleMoveTo}
            >
              タスク移動
            </div>
          </>
        )}
      </div>
    </>
  )
}
