import React, { useState } from "react";
import ColorComponent from "./component/ColorComponent";
import "./main.css";
import {
  ListAllColorsIndex,
  ListColorsEvenIndex,
  ListColorsOddIndex,
  ListColorsOther,
} from "../../F_UTILS/Constants/ListColorsIndex";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ColorSelectorComponent({
  initLayer,
  setShowComponent,
  NodeColor,
  NodeIndex,
}) {
  const [color, setColor] = useState(initLayer?.index);
  const [colorRgb, setColorRgb] = useState(initLayer?.color);

  useEffect(() => {
    hideShowSpliter(true);
    return () => {
      hideShowSpliter(false);
    };
  }, []);

  const inputColorOnChange = (e) => {
    const listTableColor = document.querySelectorAll(".table--color td");
    listTableColor.forEach((element) => {
      if (element.classList.contains("color--selected")) {
        element.classList.remove("color--selected");
      }
    });
    setColor(e.target.value);
    function isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    }

    if (e.target.value.includes(",")) {
      const arrColors = e.target.value.split(",");
      if (
        !(
          !isNumeric(arrColors[0]?.replace("\r", "")) ||
          Number(arrColors[0]?.replace("\r", "")) < 0 ||
          Number(arrColors[0]?.replace("\r", "")) > 255 ||
          !isNumeric(arrColors[1]?.replace("\r", "")) ||
          Number(arrColors[1]?.replace("\r", "")) < 0 ||
          Number(arrColors[1]?.replace("\r", "")) > 255 ||
          !isNumeric(arrColors[2]?.replace("\r", "")) ||
          Number(arrColors[2]?.replace("\r", "")) < 0 ||
          Number(arrColors[2]?.replace("\r", "")) > 255 ||
          arrColors[0].includes(".") ||
          arrColors[1].includes(".") ||
          arrColors[2].includes(".")
        )
      ) {
        setColor(e.target.value);
        setColorRgb(e.target.value);
      }
    } else {
      const index = Number(e.target.value);
      if (!(isNaN(index) || index < 0 || index > 255)) {
        const colorInput = ListAllColorsIndex.find(
          (element) => element.index === index.toString()
        );
        if (colorInput !== undefined) {
          setColor(colorInput.index);
          setColorRgb(colorInput.color);
        } else {
          setColor("");
          setColorRgb("");
        }
      }
    }
  };
  const handleOk = () => {
    function isNumeric(str) {
      if (typeof str != "string") return false;
      return !isNaN(str) && !isNaN(parseFloat(str));
    }

    if (color.includes(",")) {
      const arrColors = color.split(",");
      if (arrColors.length !== 3) {
        toast.error("色が間違っています。もう一度チェックしてください。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
      if (
        !isNumeric(arrColors[0]?.replace("\r", "")) ||
        Number(arrColors[0]?.replace("\r", "")) < 0 ||
        Number(arrColors[0]?.replace("\r", "")) > 255 ||
        !isNumeric(arrColors[1]?.replace("\r", "")) ||
        Number(arrColors[1]?.replace("\r", "")) < 0 ||
        Number(arrColors[1]?.replace("\r", "")) > 255 ||
        !isNumeric(arrColors[2]?.replace("\r", "")) ||
        Number(arrColors[2]?.replace("\r", "")) < 0 ||
        Number(arrColors[2]?.replace("\r", "")) > 255 ||
        arrColors[0].includes(".") ||
        arrColors[1].includes(".") ||
        arrColors[2].includes(".")
      ) {
        toast.error("色が間違っています。もう一度チェックしてください。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      } else {
        const colorIndex =
          Number(arrColors[0])?.toString().replace("\r", "").replace('"', "") +
          "," +
          Number(arrColors[1])?.toString().replace("\r", "").replace('"', "") +
          "," +
          Number(arrColors[2])?.toString().replace("\r", "").replace('"', "");

        if (colorIndex.includes("rgb")) {
          NodeColor.innerHTML = `<div style="background-color:${colorIndex};height: 18px;width: 18px;border: 0.5px solid black; "></div>`;
        } else {
          NodeColor.innerHTML = `<div style="background-color:rgb(${colorIndex});height: 18px;width: 18px;border: 0.5px solid black; "></div>`;
        }
        if (NodeIndex) {
          NodeIndex.innerText = colorIndex.toString().replace("\r", "");
        }
        setShowComponent(false);
      }
    } else {
      const index = Number(color);
      if (isNaN(index) || index < 0 || index > 255) {
        toast.error("色が間違っています。もう一度チェックしてください。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      } else {
        setShowComponent(false);
        if (NodeColor) {
          if (colorRgb.includes("rgb")) {
            NodeColor.innerHTML = `<div style="background-color:${colorRgb};height: 18px;width: 18px;border: 0.5px solid black; "></div>`;
          } else {
            NodeColor.innerHTML = `<div style="background-color:rgb(${colorRgb});height: 18px;width: 18px;border: 0.5px solid black; "></div>`;
          }
        }
        if (NodeIndex) {
          NodeIndex.innerText = color.toString().replace("\r", "");
        }
      }
    }
  };

  const handleCancel = () => {
    setShowComponent(false);
  };

  const hideShowSpliter = (isHide) => {
    let sp = document.getElementsByClassName("customSplitter")[0];
    if (isHide) {
      sp.style.display = "none";
    } else {
      sp.style.display = "";
    }
  };

  return (
    <div className="overlay" style={{ top: "-10px" }}>
      <ToastContainer />
      <div className="main-color" style={{ zIndex: "1" }}>
        <p>インデックス色：</p>
        <table
          className="table--color"
          style={{ width: "384px", height: "50px", marginTop: "30px" }}
        >
          <tbody>
            {ListColorsEvenIndex.map(function (colors) {
              return (
                <tr>
                  {colors.map(function (c) {
                    return (
                      <ColorComponent
                        color={c}
                        setColor={setColor}
                        setColorRgb={setColorRgb}
                      ></ColorComponent>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <table
          className="table--color"
          style={{ width: "384px", height: "50px", marginTop: "30px" }}
        >
          <tbody>
            {ListColorsOddIndex.map(function (colors) {
              return (
                <tr>
                  {colors.map(function (c) {
                    return (
                      <ColorComponent
                        color={c}
                        setColor={setColor}
                        setColorRgb={setColorRgb}
                      ></ColorComponent>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <p>インデックス：{color.includes(",") ? "" : color}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "120px",
            }}
          >
            <p>RGB：</p>
            <p>{colorRgb.replace("rgb(", "").replace(")", "")}</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <table className="table--color" style={{ width: "144px" }}>
              <tbody>
                {ListColorsOther.map(function (colors) {
                  return (
                    <tr>
                      {colors.map(function (c) {
                        return (
                          <ColorComponent
                            color={c}
                            setColor={setColor}
                            setColorRgb={setColorRgb}
                          ></ColorComponent>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p style={{ marginTop: "15px" }}>インデックスまたはRGB：</p>
            <input
              onChange={inputColorOnChange}
              type="text"
              style={{ marginTop: "2px" }}
              value={color}
            ></input>
          </div>
          <div
            style={{
              width: "75px",
              height: "75px",
              backgroundColor: colorRgb.includes("rgb")
                ? colorRgb
                : `rgb(${colorRgb})`,
              border: "0.5px solid gray",
              marginRight: "58px",
            }}
          ></div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="button-class"
            onClick={handleOk}
            style={{ width: "90px" }}
          >
            OK
          </button>
          <button
            className="button-class"
            onClick={handleCancel}
            style={{ marginLeft: "10px", width: "90px" }}
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
}
