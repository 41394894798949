import React from 'react'

export default function MainHeaderContainer({ children, groupName }) {
  return (
    <div className='relative flex items-center justify-center border gap-4 px-2 rounded-lg shadow-sm'>
      <span className='absolute top-0 left-[6px] -translate-y-[12px] bg-white text-sm font-medium '>{groupName}</span>
      {children}
    </div>
  )
}
