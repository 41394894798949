import React, { useEffect, useRef, useState } from 'react'
import styles from './TableRow.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProjectApi from '../../../../B_API/scheduleApis/ProjectApi'
import { useDispatch } from 'react-redux'
import { currentVersionSlice } from 'C_REDUX_STORE/scheduleTool/currentVersionSlice'
import { useNavigate } from 'react-router'
import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import TextInput from '../TextInput'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import VersionApi from 'B_API/scheduleApis/VersionApi'
import { ReactComponent as IconCopy } from '../../../../X_ASSETS/icons/icon_copy.svg'
import { ReactComponent as IconOpen } from '../../../../X_ASSETS/icons/icon_open.svg'

export default function TableRow({
  project,
  isChecked,
  ListProject,
  setListProject,
  setCurrentPage,
  currentPage,
  disable
}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectCheckBoxRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const listVersionNames = project.versions.map((ver) => ver.versionName)

  useEffect(() => {
    const listVersionsChecked = project.versions.map((ver) => (ver?.isChecked ? true : false))
    const filtered = listVersionsChecked.reduce((unique, o) => {
      if (!unique.includes(o)) {
        unique.push(o)
      }
      return unique
    }, [])
    if (filtered.length > 1 && projectCheckBoxRef.current) {
      projectCheckBoxRef.current.indeterminate = true
    } else if (filtered.length === 1 && projectCheckBoxRef.current) {
      projectCheckBoxRef.current.indeterminate = false
      if (filtered[0] !== project?.isChecked) {
        projectCheckBoxRef.current.indeterminate = false
        const copyProject = ListProject.map((pro) => {
          if (pro.projectId === project.projectId) {
            return {
              ...pro,
              isChecked: filtered[0]
            }
          } else {
            return pro
          }
        })
        setListProject(copyProject)
      }
    }
  }, [project, ListProject, setListProject])

  const handleCheckboxProjectChange = (event) => {
    const isCheck = event.target.checked
    const copyProject = ListProject.map((pro) => {
      if (pro.projectId === project.projectId) {
        const versions = pro.versions.map((version) => ({ ...version, isChecked: isCheck }))
        return {
          ...pro,
          versions: versions,
          isChecked: isCheck
        }
      } else {
        return pro
      }
    })
    setListProject(copyProject)
  }

  const handleCheckboxVersionChange = (version) => {
    var copyProject = ListProject.map((pro) => {
      const newVersions = pro.versions.map((ver) => {
        if (ver.versionId === version.versionId) {
          if (version.isChecked === undefined) {
            return {
              ...ver,
              isChecked: true
            }
          } else {
            return {
              ...ver,
              isChecked: !version.isChecked
            }
          }
        } else {
          return ver
        }
      })
      return {
        ...pro,
        versions: newVersions
      }
    })
    setListProject(copyProject)
  }

  const handleChangeProjectName = async (newName) => {
    if (newName !== project.projectName) {
      try {
        const resProjectName = await ProjectApi.GetAllProjectName()
        const listProjectName = resProjectName.data.map((pr) => pr.projectName)
        if (listProjectName.includes(newName)) {
          ToastError('プロジェクト名は既に存在します。もう一度やり直してください。')
          return
        }

        if (newName === '') {
          ToastError('入力されたプロジェクト名が間違っています。もう一度確認てください。')
          return
        }
        if (project) {
          const newProject = {
            projectName: newName,
            NumberOfMonths: project.numberOfMonths
          }
          var res = await ProjectApi.UpdateProjectNameById(project.projectId, newProject)
          if (res.status === 200) {
            ToastSuccess('プロジェクト名の更新に成功しました。')
            const response = await ProjectApi.GetActiveProjectsByPage(currentPage, 10)
            setListProject(response.data.data)
          } else {
            ToastError('プロジェクト名の更新に失敗しました。')
          }
        }
      } catch (error) {
        ToastError('プロジェクト名の更新に失敗しました。')
      }
    }
  }

  const handleChangeVersionName = async (newName, version) => {
    if (newName !== version.versionName) {
      const listVersionsName = project.versions.map((x) => x.versionName)
      if (listVersionsName.includes(newName)) {
        ToastError('バージョン名は既に存在します。もう一度やり直してください。')
        return
      }

      if (newName === '') {
        ToastError('入力されたバージョン名が間違っています。もう一度確認てください。')
        return
      }
      if (project) {
        const newVersion = {
          versionName: newName
        }
        try {
          var res = await VersionApi.UpdateVersionNameById(version.versionId, newVersion)
          if (res.status === 200) {
            ToastSuccess('バージョン名の更新に成功しました。')
            const response = await ProjectApi.GetActiveProjectsByPage(currentPage, 10)
            setListProject(response.data.data)
          } else {
            ToastError('バージョン名の更新に失敗しました。')
          }
        } catch (error) {
          ToastError('バージョン名の更新に失敗しました。')
        }
      }
    }
  }

  const handleCopyProject = async (version) => {
    if (project && version) {
      try {
        var res = await VersionApi.DuplicateVersion(project.projectId, version.versionId)
        if (res.status === 200) {
          ToastSuccess('バージョンのコピーに成功しました。')
          setCurrentPage(1)
          const response = await ProjectApi.GetActiveProjectsByPage(1, 10)
          setListProject(response.data.data)
        } else {
          ToastError('バージョンのコピーに失敗しました。')
        }
      } catch (error) {
        ToastError('バージョンのコピーに失敗しました。')
      }
    }
  }

  const handleOpenProject = (version) => {
    const currentVersion = { ...version, projectName: project.projectName }
    dispatch(currentVersionSlice.actions.setCurrentProject(currentVersion))
    navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_TOOL}`)
  }
  const handleExpandVersion = () => {
    setIsOpen(!isOpen)
  }

  const convertUTCToLocalTime = (time) => {
    var localDate = new Date(time + 'Z')
    return localDate.toLocaleString('ja-JP')
  }
  return (
    <>
      <tr>
        <td style={{ margin: 'auto', textAlign: 'center', height: '55px' }}>
          <div className={styles.row}>
            <FontAwesomeIcon
              className={`${styles.row_expandIcon} ${isOpen ? styles.row_expandIcon_open : ''}`}
              icon={faChevronRight}
              onClick={handleExpandVersion}
            />
            {project.projectName && (
              <input
                type='checkbox'
                checked={isChecked}
                style={{
                  height: '18px',
                  width: '18px',
                  cursor: 'pointer',
                  accentColor: '#0199fa',
                  marginRight: '5px',
                  marginLeft: '0px',
                  marginTop: '0px'
                }}
                ref={projectCheckBoxRef}
                onChange={handleCheckboxProjectChange}
              ></input>
            )}
            <TextInput
              disable={disable}
              value={project.projectName}
              onChange={(name) => {
                handleChangeProjectName(name)
              }}
            ></TextInput>
          </div>
        </td>
        {/* <td style={{ textAlign: 'center' }}>{project.modifiedDate.replace('T', ' ')}</td> */}
        <td style={{ textAlign: 'center' }}>{convertUTCToLocalTime(project.modifiedDate)}</td>
      </tr>
      {isOpen &&
        project.versions &&
        project.versions.length &&
        project.versions.map((version) => (
          <tr key={version.versionId}>
            <td style={{ margin: 'auto', textAlign: 'center', height: '55px', paddingLeft: '85px' }}>
              <div className={styles.row}>
                <input
                  type='checkbox'
                  checked={version?.isChecked ? true : false}
                  style={{
                    height: '18px',
                    width: '18px',
                    cursor: 'pointer',
                    marginTop: '0px',
                    accentColor: '#0199fa'
                  }}
                  onChange={(e) => handleCheckboxVersionChange(version)}
                ></input>
                <TextInput
                  disable={disable}
                  value={version.versionName}
                  listData={listVersionNames}
                  onChange={(name) => {
                    handleChangeVersionName(name, version)
                  }}
                ></TextInput>
                {!disable && (
                  <div className={styles.row_action}>
                    <span onClick={(e) => handleCopyProject(version)}>
                      <IconCopy className={styles.row_item}></IconCopy>
                    </span>
                    <span onClick={(e) => handleOpenProject(version)}>
                      <IconOpen className={styles.row_item}></IconOpen>
                    </span>
                  </div>
                )}
              </div>
            </td>
            <td style={{ textAlign: 'center' }}>{convertUTCToLocalTime(version.modifiedDate)}</td>
          </tr>
        ))}
    </>
  )
}
