import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import allBlockNameReducer from './allBlockNameSlice'
import jobReducer from './jobSlice'
import columnsInfoReducer from './scheduleTool/columnsSlice'
import currentVersionReducer from './scheduleTool/currentVersionSlice'
import tableWidthSlice from './scheduleTool/tableWidthSlice'
import versionSettingSlice from './scheduleTool/versionSettingSlice'
import showModalSlice from './scheduleTool/showModalSlice'
import predecessorSlice from './scheduleTool/predecessorSlice'
import selectedTaskSlice from './scheduleTool/selectedTaskSlice'

const rootReducer = {
  user: userReducer,
  allBlockName: allBlockNameReducer,
  jobId: jobReducer,
  columnsInfo: columnsInfoReducer,
  currentVersion: currentVersionReducer,
  tableWidth: tableWidthSlice,
  versionSetting: versionSettingSlice,
  showModal: showModalSlice,
  predecessor: predecessorSlice,
  selectedTask: selectedTaskSlice,
}

const store = configureStore({
  reducer: rootReducer
})

export default store
