import React, { useEffect } from 'react'
import './main.css'

export default function ProgressBarComponent({ isRunning, setShowDialog, messageCompleted, messageProgress }) {
  const [done, setDone] = React.useState(false)

  useEffect(() => {
    hideShowSpliter(true)
    if (isRunning === false) {
      setDone(true)
      const overLayElement = document.getElementsByClassName('overlay')[0]
      overLayElement.addEventListener('click', () => {
        setShowDialog(false)
      })

      const dialogElement = document.getElementsByClassName('progress-container')[0]
      if (dialogElement) {
        dialogElement.addEventListener('click', (event) => {
          event.stopPropagation()
        })
      }

      const button = document.querySelector('.progress-content button')
      if (button)
        button.addEventListener('click', (event) => {
          hideShowSpliter(false)
          setShowDialog(false)
        })
    }
    return () => {
      hideShowSpliter(false)
    }
  }, [isRunning, done, setShowDialog])

  const handleOKButton = () => {
    hideShowSpliter(false)
    setShowDialog(false)
  }
  const hideShowSpliter = (isHide) => {
    let sp = document.getElementsByClassName('customSplitter')[0]
    if (sp) {
      if (isHide) {
        sp.style.display = 'none'
      } else {
        sp.style.display = ''
      }
    }
  }
  return (
    <div className='overlay'>
      {!done ? (
        <div className='progress-container'>
          <div className='progress-content'>
            <h3 className='progress-title'>ファイルを処理しています。</h3>
            <p style={{ marginTop: '0px' }}>{messageProgress}</p>
            <div className='progress'></div>
          </div>
        </div>
      ) : (
        <div className='progress-container'>
          <div className='progress-content'>
            {messageCompleted === '' ? (
              <h3 className='progress-title'>Process completed!</h3>
            ) : (
              <h3 className='progress-title'>{messageCompleted}</h3>
            )}
            <div style={{  marginRight: '9px', textAlign: 'right', width: '100%' }}>
              <button onClick={handleOKButton} className='progress-button'>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
