import React from 'react'
import Dialog from '@mui/material/Dialog'
import { Button } from '@mui/material'
import ButtonPrimary, { ButtonSecondary } from 'A_SHARED_COMPONENT/Button'

export default function DialogConfirm({ open, handleOK, handleClose, title, children, lable1 = null, lable2 = null,  width = 500 }) {
  return (
    <Dialog open={open} onClose={handleClose} sx={{ zIndex: 1000001 }}>
      <div className='rounded' style={{ maxWidth: `${width}px` }}>
        {/* title */}
        <div className='px-4 h-11 flex items-center'>
          <h3 className='text-lg font-bold'>{title}</h3>
        </div>

        {/* content */}
        <div className='px-8 py-6 w-full border-y flex justify-center items-center'>{children}</div>
        {/* action */}
        <div className=' p-4 flex flex-row-reverse items-center gap-4'>
          <ButtonSecondary onClick={handleClose}>{lable1 || "キャンセル"}</ButtonSecondary>
          <ButtonPrimary onClick={handleOK}>{lable2 || "OK"}</ButtonPrimary>
        </div>
      </div>
    </Dialog>
  )
}
