import React, { useEffect, useState } from 'react'
import styles from './TextInput.module.scss'

export default function TextInput({ value, onChange, style, disable, isNumber = false, validateFunction }) {
  const [currentValue, setCurrentValue] = useState(value)
  const inputRef = React.useRef(null)

  useEffect(() => {
    if (isNumber) {
      if (isNaN(Number(value))) {
        setCurrentValue(value)
      } else {
        setCurrentValue(Number(value).toFixed(1))
      }
    } else {
      setCurrentValue(value)
    }

    return () => {
      setCurrentValue('')
    }
  }, [value])

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur()
    }
  }
  return (
    <input
      type='text'
      ref={inputRef}
      style={{ ...style }}
      value={currentValue}
      className={styles.input}
      disabled={disable}
      onChange={(e) => {
        setCurrentValue(e.target.value)
      }}
      onKeyDown={handlePressEnter}
      onBlur={(e) => {
        if (isNumber) {
          if (isNaN(Number(e.target.value))) {
            setCurrentValue(value)
          } else {
            if (Number(e.target.value) <= 0) {
              setCurrentValue(value)
            } else {
              if (validateFunction) {
                if (validateFunction(e.target.value)) {
                  onChange(Number(e.target.value).toFixed(1))
                } else {
                  setCurrentValue(value)
                }
              } else {
                onChange(Number(e.target.value).toFixed(1))
              }
            }
          }
        } else {
          if (validateFunction) {
            if (validateFunction(e.target.value)) {
              onChange(e.target.value)
            } else {
              setCurrentValue(value)
            }
          } else {
            onChange(e.target.value)
          }
        }
      }}
    />
  )
}
