export const RouterConstants = {
  HOME_PAGE: '/',
  //AUTHENTICATION
  SIGN_IN: 'signin',

  //MENU PAGE
  MENU_PAGE: 'menu-app',

  //APP PAGE
  APP_RENAME_BLOCKS: 'rename-block',
  APP_CREATE_LAYERS: 'create-layer',
  APP_RENAME_LAYERS: 'rename-layer',
  APP_CONVERT_FILE: 'convert-file',
  APP_EXPORT_BLOCKS: 'export-block',
  APP_DATA_EXTRACTION: 'data-extraction',
  APP_MENU_SCHEDULING_TOOL: 'menu-scheduling-tool',
  APP_MENU_SCHEDULING_TOOL_SHARED: 'menu-scheduling-tool/shared-project',
  APP_MENU_SCHEDULING_TOOL_TRASHED: 'menu-scheduling-tool/trash',
  APP_SCHEDULING_TOOL: 'scheduling-tool',
  APP_SCHEDULING_CUSTOM_CHART: 'scheduling-tool/custom-view',
  VERSION: 'version'
}
