import axiosClientSchedule from './axiosClientSchedule'

const ExcelApi = {
  async ImportExcel(versionId, data) {
    const url = `/versions/${versionId}/import`
    return axiosClientSchedule.request({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: url,
      data: data
    })
  },
  async UpdateImportExcel(versionId, data) {
    const url = `/versions/${versionId}/update-from-excel`
    return axiosClientSchedule.request({
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: url,
      data: data
    })
  }
}

export default ExcelApi
