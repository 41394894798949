import React from 'react'
import styles from './CreateProjectComponent.module.scss'
import ProjectApi from '../../../../B_API/scheduleApis/ProjectApi'
import { Formik } from 'formik'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'

export default function CreateProjectComponent({ setIsNewProject, isShared }) {
  const handleSubmit = async (values) => {
    try {
      let resProjectName
      if (isShared) {
        resProjectName = await ProjectApi.GetAllSharedProjectName()
      } else {
        resProjectName = await ProjectApi.GetAllProjectName()
      }
      const listProjectName = resProjectName.data.map((pr) => pr.projectName)
      const { projectName, versionName, numberOfMonths } = values
      if (projectName == null || projectName === '') {
        ToastError('入力されたプロジェクト名が間違っています。もう一度確認てください')
        return
      }

      if (versionName == null || versionName === '') {
        ToastError('入力されたバージョン名が間違っています。もう一度確認てください。')
        return
      }

      if (numberOfMonths === null || numberOfMonths === '' || isNaN(Number(numberOfMonths))) {
        ToastError('入力された月数が間違っています。もう一度確認てください。')
        return
      }

      if (listProjectName.includes(projectName)) {
        ToastError('プロジェクト名は既に存在します。もう一度やり直してください。')
        return
      }

      var res = await ProjectApi.CreateProject({ ...values, isShared })
      if (res.status !== 200) {
        ToastError('プロジェクトの作成に失敗しました。')
        return
      } else {
        ToastSuccess('プロジェクトの作成に成功しました。')
      }
    } catch (error) {
      ToastError('プロジェクトの作成に失敗しました。')
      return
    }
    setIsNewProject(false)
  }

  const handleCancelClick = () => {
    setIsNewProject(false)
  }
  return (
    <div className='overlay-modal'>
      <div className={styles.container}>
        <Formik
          initialValues={{
            projectName: '',
            versionName: '',
            numberOfMonths: 24
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values }) => (
            <>
              <div className={styles.heading}>新しいプロジェクト</div>
              <div className={styles.contents}>
                <div className={styles.item}>
                  <label className={styles.item_label} htmlFor='name'>
                    プロジェクト名
                  </label>
                  <input
                    className={styles.item_input}
                    name='projectName'
                    id='name'
                    value={values.projectName}
                    onChange={handleChange}
                  ></input>
                </div>
                <div className={styles.item}>
                  <label className={styles.item_label} htmlFor='versionName'>
                    バージョン名
                  </label>
                  <input
                    className={styles.item_input}
                    id='versionName'
                    name='versionName'
                    value={values.versionName}
                    onChange={handleChange}
                  ></input>
                </div>
                <div className={styles.item}>
                  <label className={styles.item_label} htmlFor='months'>
                    月数
                  </label>
                  <input
                    className={styles.item_input}
                    id='months'
                    name='numberOfMonths'
                    value={values.numberOfMonths}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
              <div className={styles.button_container}>
                <button className='btn-primary' onClick={(event) => handleSubmit(values)} type='submit'>
                  作成
                </button>
                <button className='btn-secondary' type='button' onClick={handleCancelClick}>
                  キャンセル
                </button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}
