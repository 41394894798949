import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { useSelector } from 'react-redux'
import './App.scss'
import { RouterConstants } from './F_UTILS/Constants/RouterConstants'
import LoginForm from './Z_SERVICES/a_LoginServices'
import MenuServices from './Z_SERVICES/b_MenuServices'

function App() {
  const user = useSelector((state) => state.user.current)
  return (
    <>
      {!user?.userId ? (
        <LoginForm />
      ) : (
        <>
          <Routes>
            <Route path={`/${RouterConstants.SIGN_IN}`} element={<LoginForm />} />

            <Route path={`/${RouterConstants.MENU_PAGE}/*`} element={<MenuServices />} />

            <Route path='/' element={<Navigate replace to={`/${RouterConstants.MENU_PAGE}`} />} />

            <Route path={`/${RouterConstants.VERSION}`} element={<h1>1.0.0</h1>} />
          </Routes>
        </>
      )}
    </>
  )
}

export default App
