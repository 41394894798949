import React, { useEffect, useRef, useState } from 'react'
import './MainRenameLayer.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { WriteDataToTableLayerWhenLoadFile } from '../../../../F_UTILS/CSVRenameLayerUtils'
import ColorSelectorComponent from '../../../../A_SHARED_COMPONENT/ColorSelectorComponent'
import { ListAllColorsIndex } from '../../../../F_UTILS/Constants/ListColorsIndex'
import TableComponent from '../TableComponent'
import HeaderComponent from '../HeaderComponent'
import FooterComponent from '../FooterComponent'
import SwitchComponent from '../../../../A_SHARED_COMPONENT/SwitchComponent'

export default function MainRenameLayer({
  setViewInfor,
  setProgressIsRunning,
  setShowDialog,
  setMessageProgress,
  setMessageCompleted
}) {
  const [listNames, setListNames] = useState([])
  const initLayer = useRef({})
  const [originName, setOriginName] = useState('')
  const [showColor, setShowColor] = useState(false)
  const [btnDisable, setBtnDisable] = useState(true)
  const windowCurrentWidth = window.innerWidth
  const [nodeColor, setNodeColor] = useState(null)
  const [nodeIndex, setNodeIndex] = useState(null)

  console.log('component rendering ...')
  useEffect(() => {
    WriteDataToTableLayerWhenLoadFile(listNames)

    const clickRowEvent = (e) => {
      var oldLayerName = e.target.parentNode.getElementsByTagName('td')[0]
      var colorIndexEle = e.target.parentNode.getElementsByTagName('td')[5]
      setShowColor(true)
      const colorIndex = ListAllColorsIndex.find((element) => element.index === colorIndexEle.innerHTML.toString())
      initLayer.current = {
        LayerName: oldLayerName.innerHTML,
        index: colorIndexEle.innerHTML,
        color: colorIndex?.color || ''
      }
      console.log('init', initLayer.current)
      setNodeColor(e.target.parentNode.getElementsByTagName('td')[4])
      setNodeIndex(colorIndexEle)
    }

    const elements = document.getElementsByClassName('clickAble')
    for (let index = 0; index < elements.length; index++) {
      elements[index].addEventListener('click', clickRowEvent)
    }

    var rows = document.querySelectorAll('#contents-table tr')
    for (var i = 0; i < rows.length; i++) {
      var cols = rows[i].querySelectorAll('td')
      for (let index = 0; index < cols.length; index++) {
        const element = cols[index]
        const brEle = element.querySelectorAll('br')
        if (brEle?.length) {
          brEle?.forEach((e) => element.removeChild(e))
        }
      }
    }

    return () => {
      const elements = document.getElementsByClassName('clickAble')
      for (let index = 0; index < elements.length; index++) {
        elements[index].removeEventListener('click', clickRowEvent)
      }
    }
  }, [listNames])

  return (
    <>
      <div className='main--windows'>
        {showColor && (
          <ColorSelectorComponent
            initLayer={initLayer.current}
            setShowComponent={setShowColor}
            NodeColor={nodeColor}
            NodeIndex={nodeIndex}
            setListNames={setListNames}
          ></ColorSelectorComponent>
        )}

        <ToastContainer style={{ width: '650px' }} />

        <HeaderComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setMessageCompleted={setMessageCompleted}
          setShowDialog={setShowDialog}
          setListNames={setListNames}
          setOriginName={setOriginName}
          setViewInfor={setViewInfor}
          setBtnDisable={setBtnDisable}
        ></HeaderComponent>

        <div className='file--name'>
          <label>ファイル名: </label>
          <SwitchComponent label={'ハイライト'}></SwitchComponent>
        </div>

        <TableComponent rowCount={windowCurrentWidth > 1919 ? 22 : windowCurrentWidth > 1365 ? 10 : 9}></TableComponent>

        <FooterComponent
          setProgressIsRunning={setProgressIsRunning}
          setMessageProgress={setMessageProgress}
          setMessageCompleted={setMessageCompleted}
          setShowDialog={setShowDialog}
          originName={originName}
          setListNames={setListNames}
          setViewInfor={setViewInfor}
          btnDisable={btnDisable}
        ></FooterComponent>
      </div>
    </>
  )
}
