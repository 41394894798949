import ViewCreateLayer from "./components/ViewCreateLayer";

const CreateLayersServices = () => {
  return (
    <>
      <ViewCreateLayer></ViewCreateLayer>
    </>
  );
};
export default CreateLayersServices;
