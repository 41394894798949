
import "./main.css";
import dataExtraction from "../../X_ASSETS/DataExtraction.png";

const DataExtractionServices = () => {
  const nameTool = "Data Extraction";
  const version = "バージョン 1.0.0";
  return (
    <div>
      <div className="main-container">
        <div className="content--container">
          <img
            src={dataExtraction}
            alt={nameTool}
            className="class--image"
          ></img>
          <div className="text-swapper">
            <h3>{nameTool}</h3>
            <p>{version}</p>
          </div>
        </div>
        <h3>{"We are developing. Please visit later."}</h3>
      </div>
    </div>
  );
};
export default DataExtractionServices;
