import { RouterConstants } from 'F_UTILS/Constants/RouterConstants'
import MainChartHeader from 'Z_SERVICES/j_MainChart/components/MainChartHeader'
import { TaskListHeader } from 'Z_SERVICES/j_MainChart/components/TaskListHeader'
import { TaskListTableCustom } from 'Z_SERVICES/j_MainChart/components/TaskListTableCustom'
import { Gantt, ViewMode } from 'Z_SERVICES/j_MainChart/grant-base'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomView.module.scss'
import ViewsApi from 'B_API/scheduleApis/ViewsApi'
import CreateViewModal from 'Z_SERVICES/j_MainChart/components/Modals/CreateViewModal'
import PopoverTaskComponent from 'Z_SERVICES/j_MainChart/components/PopoverTaskComponent'
import { selectedTaskSlice } from 'C_REDUX_STORE/scheduleTool/selectedTaskSlice'

import { ToastContainer } from 'react-toastify'
import HiddenRowsModal from './components/HiddenRowsModal'
// import HiddenRowsModal from './HiddenRowsModal'

export default function CustomView() {
  const [tasks, setTasks] = React.useState([])
  console.log('🚀 ~ file: index.js:20 ~ CustomView ~ tasks:', tasks)
  const [taskCell, setTaskCell] = React.useState(null)
  const [showCreateView, setShowCreateView] = React.useState(false)
  const [showHiddenRowsModal, setShowHiddenRowsModal] = React.useState(false)

  const [event, setEvent] = React.useState(null)
  const [selectedTask, setSelectedTask] = React.useState(null)

  const { viewId } = useParams()
  const navigate = useNavigate()

  const currentVersion = useSelector((state) => state.currentVersion)
  const currentSetting = useSelector((state) => state.versionSetting.current)

  const [isRightCellClick, setRightCellClick] = React.useState(false)

  const selectedTaskIds = useSelector((state) => state.selectedTask)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentVersion.versionId === -1) {
      navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
    }

    const fetchData = async () => {
      try {
        const versionTasksDetail = await ViewsApi.GetViewDetails(currentVersion.versionId, viewId)
        setTasks(versionTasksDetail.data)
      } catch (error) {
        navigate(`/menu-app/${RouterConstants.APP_SCHEDULING_TOOL}`)
        //navigate(`/menu-app/${RouterConstants.APP_MENU_SCHEDULING_TOOL}`)
      }
    }
    fetchData()
  }, [
    viewId,
    showCreateView,
    currentSetting.assemblyDurationRatio,
    currentSetting.columnWidth,
    currentSetting.amplifiedFactor,
    currentVersion.versionId,
    navigate
  ])

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)))

    setRightCellClick(false)
  }
  const handleOnChangeTaskName = (task, eventTasks, event) => {
    setTasks(eventTasks)
  }
  const handleLeftCellClick = (event, task) => {
    setTaskCell(task)
    setRightCellClick(false)
  }

  const handleRightCellClick = (event, task) => {
    setEvent(event)
    setRightCellClick(true)
    setSelectedTask(task)
    setTaskCell(task)
    if (!selectedTaskIds.includes(task.id)) {
      dispatch(selectedTaskSlice.actions.setSelectedTaskId([task.id]))
    }
  }

  const handleClick = (task) => {
    setRightCellClick(false)
  }

  var filterTask = tasks
    .filter((x) => !x.isHidden)
    .map((t) => {
      if (t.type == 'project') {
        var taskHd = tasks.find((i) => i.type == 'task' && i.groupId == t.id && i.isHidden == true)
        if (!!taskHd) {
          return { ...t, name: `${t.name} (*)` }
        }
      }
      return t
    })

  return (
    <div className={styles.wrapper}>
      {isRightCellClick && (
        <PopoverTaskComponent
          event={event}
          tasks={tasks}
          setTasks={setTasks}
          task={selectedTask}
          setTaskCell={setTaskCell}
          setRightCellClick={setRightCellClick}
          setIsSetStepWork={null}
          setShowMoveGroupTask={null}
          isViewPopup={true}
          setShowHiddenRowsModal={setShowHiddenRowsModal}
        ></PopoverTaskComponent>
      )}
      <HiddenRowsModal
        tasks={tasks}
        setTasks={setTasks}
        setShowHiddenRowsModal={setShowHiddenRowsModal}
        showHiddenRowsModal={showHiddenRowsModal}
      ></HiddenRowsModal>
      {showCreateView && <CreateViewModal tasks={tasks} setShowCreateView={setShowCreateView}></CreateViewModal>}
      <MainChartHeader
        task={taskCell}
        tasks={tasks}
        setTasks={setTasks}
        setTaskCell={setTaskCell}
        setShowCreateView={setShowCreateView}
        showCreateView={showCreateView}
      ></MainChartHeader>
      <Gantt
        locale='jpn'
        handleWidth={3}
        tasks={filterTask}
        setTasks={setTasks}
        viewMode={ViewMode.Month}
        TaskListHeader={TaskListHeader}
        TaskListTable={TaskListTableCustom}
        onExpanderClick={handleExpanderClick}
        onChangeTaskName={handleOnChangeTaskName}
        onLeftClickCell={handleLeftCellClick}
        onRightClickCell={handleRightCellClick}
        onClick={handleClick}
        listCellWidth={true ? '155px' : ''}
        ganttHeight={700}
        barBackgroundColor={currentSetting?.stepworkColors?.at(0)?.code || '#0000FF'}
        columnWidth={currentSetting?.columnWidth}
      />
      <ToastContainer style={{ width: '650px', zIndex: 10000000 }} />
    </div>
  )
}
