import React from "react";
import './main.css';

export default function SwitchComponent({label}) {
  return (
    <div className="switch-swapper">
      <label className="switch">
        <input type="checkbox" id='switch-highline' defaultChecked ='true'></input>
        <span className="slider round"></span>
      </label>
      <p>{label}</p>
    </div>
  );
}
