import React, { useRef, useEffect, useState } from 'react'
import styles from './PopoverComponent.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { predecessorSlice } from 'C_REDUX_STORE/scheduleTool/predecessorSlice'
import { DialogConfirm } from 'A_SHARED_COMPONENT/DialogComponent'
import TextField from '@mui/material/TextField'
import * as Yup from 'yup'
import { ToastError, ToastSuccess } from 'F_UTILS/ToastUtils'
import { isValidateTasks } from 'Z_SERVICES/j_MainChart/grant-base/helpers/bar-helper'
import TextInputComponent from 'A_SHARED_COMPONENT/TextInputComponent'
import { checkIsCanMove } from '../../../../F_UTILS/SchedulingUtils/TaskUtils'

export default function PopoverTaskBarComponent({
  event,
  tasks,
  task,
  setTasks,
  setRightTaskClick,
  setIsSetPredecessor
}) {
  const taskColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const numberOfMonths = useSelector((state) => state.versionSetting.current.numberOfMonths)
  const columnWidth = useSelector((state) => state.versionSetting.current.columnWidth)
  const [offsetValue, setOffsetValue] = useState('')
  var dispatch = useDispatch()
  const [displayColor, setDisplayColor] = useState(false)
  const [showSetOffset, setShowSetOffset] = useState(false)
  const menuRef = useRef(null)
  const colorRef = useRef(null)

  useEffect(() => {
    const windowCurrentWidth = window.innerWidth
    const windowCurrentHeight = window.innerHeight
    let locationTop = event.clientY + 10
    let locationLeft = event.clientX - 20
    if (locationLeft > windowCurrentWidth - 205) {
      locationLeft = windowCurrentWidth - 205
      if (colorRef !== undefined) {
        colorRef.current.style.left = '-205px'
      }
    }
    if (locationTop > windowCurrentHeight - taskColors.length * 35) {
      locationTop = windowCurrentHeight - taskColors.length * 35
    }
    if (locationTop > windowCurrentHeight - 150) {
      locationTop = windowCurrentHeight - 150
    }

    if (menuRef !== undefined) {
      menuRef.current.style.top = `${locationTop}px`
      menuRef.current.style.left = `${locationLeft}px`
    }
    if (taskColors.length > 0 && colorRef !== undefined) {
      colorRef.current.style.height = `${taskColors.length * 35}px`
    }
    if (colorRef !== undefined) {
      if (displayColor) {
        colorRef.current.style.display = ''
      } else {
        colorRef.current.style.display = 'none'
      }
    }
  }, [event, displayColor])

  useEffect(() => {
    const startDate = Math.round((task.start * 10) / (columnWidth / 30)) / 10
    setOffsetValue(startDate)
  }, [task, columnWidth])

  const handleAddPredecessor = () => {
    dispatch(predecessorSlice.actions.setParentStepWorkId(task.id))
    setRightTaskClick(false)
  }

  const handleSet = () => {
    setIsSetPredecessor(true)
    setRightTaskClick(false)
  }

  const handleOffSetClick = () => {
    if (checkIsCanMove(tasks, task)) {
      setShowSetOffset(true)
    } else {
      setRightTaskClick(false)
    }
  }

  const handleHoverColor = () => {
    if (!displayColor) {
      setDisplayColor(true)
    }
  }
  const handleMouseLeave = () => {
    if (displayColor) {
      setDisplayColor(false)
    }
  }
  const handleSelectColor = (color) => {
    setTasks((prev) => {
      const copyTasks = [...prev]
      let currentTask
      copyTasks.forEach((t) => {
        if (t.id === task.id) {
          currentTask = t
        }
        if (t.stepworks) {
          t.stepworks.forEach((stepWork) => {
            if (stepWork.id === task.id) {
              currentTask = stepWork
            }
          })
        }
      })
      currentTask.colorId = color.colorId
      return copyTasks
    })
    setRightTaskClick(false)
  }

  const notificationWhenTaskHaveError = (tasks) => {
    const svgWidth = columnWidth * numberOfMonths
    if (!isValidateTasks(tasks, svgWidth)) {
      ToastError('チャトレの範囲に外れているタスクがあります。もう一度チェックしてください。')
    }
  }
  const handleOffset = (event) => {
    const maxWidth = 30 * numberOfMonths - 10
    const schema = Yup.number().required().min(0).max(maxWidth)
    try {
      schema.validateSync(offsetValue)
      setTasks((prev) => {
        const newState = [...prev]
        const foundTask = newState.find((t) => t.id === task.id)
        if (foundTask) {
          foundTask.start = (columnWidth / 30) * Number(offsetValue)
          notificationWhenTaskHaveError(newState)
          return newState
        } else {
          const listStepWorks = []
          newState.forEach((t) => {
            if (t.stepworks) {
              Array.prototype.push.apply(listStepWorks, t.stepworks)
            }
          })
          const foundTask = listStepWorks.find((t) => t.id === task.id)
          if (foundTask) {
            foundTask.start = (columnWidth / 30) * Number(offsetValue)
            notificationWhenTaskHaveError(newState)
            return newState
          }
          return prev
        }
      })
      setShowSetOffset(true)
      setRightTaskClick(false)
    } catch (error) {
      ToastError('入力された文字が間違っています。もう一度やり直してください')
    }
  }

  const handleAddNewLineClick = () => {
    if (task?.parentTaskId) {
      setTasks((prev) => {
        let newState = [...prev]
        newState = newState.map((t) => {
          if (t?.stepworks) {
            t.stepworks = t?.stepworks.map((st) => {
              if (st.id === task.id) {
                if (st?.isSubStepWork) {
                  return { ...st, isSubStepWork: false }
                } else {
                  return { ...st, isSubStepWork: true }
                }
              }
              return st
            })
            return t
          } else {
            return t
          }
        })
        return newState
      })
      setShowSetOffset(true)
      setRightTaskClick(false)
    }
    setRightTaskClick(false)
  }

  return (
    <div className={styles.popoverContainer} ref={menuRef}>
      <DialogConfirm
        open={showSetOffset}
        handleOK={handleOffset}
        handleClose={() => {
          setShowSetOffset(false)
          setRightTaskClick(false)
        }}
        title={'ステープワークの開始日設定'}
      >
        <div className='flex items-center justify-around gap-3 w-full'>
          <label htmlFor='offset' className='font-normal text-base'>
            開始日（日）
          </label>
          <TextInputComponent
            value={offsetValue}
            onChange={(event) => setOffsetValue(event.currentTarget.value)}
            id='offset'
          ></TextInputComponent>
        </div>
      </DialogConfirm>
      <div className={styles.popoverMenu}>
        <div className={styles.popoverMenu_item} onMouseOver={handleHoverColor} onMouseLeave={handleMouseLeave}>
          ステープワークの種類を設定
          {taskColors && (
            <div className={styles.popoverMenuColor} ref={colorRef}>
              {taskColors.map((color) => {
                return (
                  <button
                    key={color.colorId}
                    className={styles.popoverMenu_item}
                    onClick={() => handleSelectColor(color)}
                  >
                    {color.name}
                  </button>
                )
              })}
            </div>
          )}
        </div>
        <div className={styles.popoverMenu_item} onClick={handleAddPredecessor}>
          イナズマ線を追加
        </div>
        <div className={styles.popoverMenu_item} onClick={handleSet}>
          イナズマ線を設定
        </div>
        <div className={styles.popoverMenu_item} onClick={handleOffSetClick}>
          開始日の設定
        </div>
        <div className={styles.popoverMenu_item} onClick={handleAddNewLineClick}>
          {task.isSubStepWork ? '行を削減' : '行を追加'}
        </div>
      </div>
    </div>
  )
}
