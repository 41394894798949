import React, { useRef, useEffect } from 'react'
import styles from './PopoverProjectComponent.module.scss'
import {
  AddGroupAfter,
  AddGroupBefore,
  AddTaskInGroup,
  DeleteGroup,
  MoveGroupDown,
  MoveGroupTo,
  MoveGroupUp
} from '../../../../F_UTILS/SchedulingUtils/GroupTaskUtils'
import { MoveTask } from 'F_UTILS/SchedulingUtils/TaskUtils'
import { useSelector } from 'react-redux'

export default function PopoverProjectComponent({
  event,
  task,
  setRightCellClick,
  tasks,
  setTasks,
  setTaskCell,
  setShowMoveGroupTask
}) {
  const tooltipRef = useRef(null)
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)

  const handleAddTask = () => {
    const assemblyColor = stepWorkColors?.find((st) => st.isInstall === 0)
    var newTasks = AddTaskInGroup(task, tasks, assemblyColor.colorId)
    setTasks(newTasks)
    setRightCellClick(false)
  }

  const handleDeleteGroup = () => {
    var newTasks = DeleteGroup(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
    setTaskCell(null)
  }

  const handleAddGroupBefore = () => {
    var newTasks = AddGroupBefore(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
  }
  const handleAddGroupAfter = () => {
    var newTasks = AddGroupAfter(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
  }
  const handleMoveUp = () => {
    var newTasks = MoveGroupUp(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
  }
  const handleMoveDown = () => {
    var newTasks = MoveGroupDown(task, tasks)
    setTasks(newTasks)
    setRightCellClick(false)
  }
  const handleMoveTo = () => {
    setShowMoveGroupTask(true)
    setRightCellClick(false)
  }
  useEffect(() => {
    let tbHeight = window.innerHeight
    let locationY = event.clientY + 10
    let locationX = event.clientX - 20
    if (locationY + 245 > tbHeight) {
      locationY = tbHeight - 245 - 10
    }
    tooltipRef.current.style.top = `${locationY}px`
    tooltipRef.current.style.left = `${locationX}px`
  }, [event])
  return (
    <>
      <div className={styles.popoverProjectMenu} ref={tooltipRef}>
        <div className={styles.popoverProjectItem} onClick={handleAddTask}>
          新タスクを追加
        </div>
        <div className={styles.popoverProjectItem} onClick={handleDeleteGroup}>
          グループを削除
        </div>
        <div className={styles.popoverProjectItem} onClick={handleAddGroupBefore}>
          上にグループを追加
        </div>
        <div className={styles.popoverProjectItem} onClick={handleAddGroupAfter}>
          下にグループを追加
        </div>
        <div className={styles.popoverProjectItem} onClick={handleMoveUp}>
          上にグループを追加
        </div>
        <div className={styles.popoverProjectItem} onClick={handleMoveDown}>
          下にグループを追加
        </div>
        <div className={styles.popoverProjectItem} onClick={handleMoveTo}>
          グループ移動
        </div>
      </div>
    </>
  )
}
