import { ToastError } from 'F_UTILS/ToastUtils'
import * as yup from 'yup'

export const generalListYears = (startYear, startMonth, monthCount) => {
  try {
    const startYearSchema = yup.number().integer().min(0).required()
    const startMonthSchema = yup.number().integer().min(1).max(12).required()
    if (
      !startYearSchema.isValidSync(startYear) ||
      !startMonthSchema.isValidSync(startMonth) ||
      !startYearSchema.isValidSync(monthCount)
    ) {
      return [2023, 2024]
    }
    const countMonthFirsYear = 12 - startMonth + 1
    const countYears = Math.floor((monthCount - countMonthFirsYear) / 12)
    if (countYears <= 0) {
      return [startYear]
    }
    const result = Array(countYears)
      .fill(0)
      .map((_, index) => {
        return startYear + index + 1
      })
    result.unshift(startYear)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const generalListYearsV2 = (startYear, startMonth, monthCount) => {
  var listYears = [Number(startYear)]
  var count = Math.ceil((Number(monthCount) - (12 - Number(startMonth))) / 12)
  for (let index = 1; index <= count; index++) {
    listYears.push(Number(startYear) + index)
  }
  return listYears
}

export const getAllLocationHaveBackgroundColor = (dateRange, startYear, startMonth, monthCount) => {
  let startDate
  let endDate

  if (dateRange.startDate == 30) {
    startDate = new Date(dateRange.startYear, dateRange.startMonth, 1)
  } else {
    startDate = new Date(dateRange.startYear, dateRange.startMonth - 1, dateRange.startDate)
  }

  if (dateRange.toDate == 30) {
    endDate = new Date(dateRange.toYear, dateRange.toMonth, 1)
  } else {
    endDate = new Date(dateRange.toYear, dateRange.toMonth - 1, dateRange.toDate)
  }

  let specialDatesInRange = []

  let currentDate = new Date(startDate)
  let endDateToCheck = new Date(dateRange.toYear, dateRange.toMonth + 2, dateRange.toDate)
  while (currentDate <= endDateToCheck) {
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1 // Tháng trong JavaScript tính từ 0
    specialDatesInRange.push(new Date(year, month - 1, 1))
    specialDatesInRange.push(new Date(year, month - 1, 10))
    specialDatesInRange.push(new Date(year, month - 1, 20))
    // specialDatesInRange.push(new Date(year, month, 0))
    currentDate.setMonth(currentDate.getMonth() + 1)
  }
  specialDatesInRange = specialDatesInRange.filter((x) => x >= startDate && x <= endDate)

  const listAllTime = getCalendarValuesWithIncludeYear(startYear, startMonth, monthCount)

  const result = specialDatesInRange.map((x) => {
    return listAllTime.findIndex((t) => t.getTime() === x.getTime())
  })
  result.splice(-1)
  return result
}

export const validateBackgroundColorYear = (backgroundColors, displayText, values) => {
  let result = true
  let listDate = []
  backgroundColors.forEach((background) => {
    const dateRanges = background?.dateRanges
    dateRanges?.forEach((date) => {
      if (displayText !== date.displayText) {
        listDate.push({
          start: new Date(`${date.startYear}-${date.startMonth}-${date.startDate}`),
          to: new Date(`${date.toYear}-${date.toMonth}-${date.toDate}`)
        })
      }
    })
  })
  const start = new Date(`${values.startYear}-${values.startMonth}-${values.startDate}`)
  const to = new Date(`${values.toYear}-${values.toMonth}-${values.toDate}`)
  listDate.forEach((date) => {
    if (date.start <= start && start < date.to) {
      result = false
    }
    if (date.start < to && to <= date.to) {
      result = false
    }
  })
  return result
}
//tạo ra list các mốc thời gian có của toàn bộ bảng
export const getCalendarValuesWithIncludeYear = (startYear, startMonth, monthCount) => {
  let listDate = []
  for (let index = startMonth - 1; index <= 12; index++) {
    listDate.push(new Date(startYear, index, 1))
    listDate.push(new Date(startYear, index, 10))
    listDate.push(new Date(startYear, index, 20))
    // listDate.push(new Date(startYear, index + 1, 0))
  }
  const countMonthFirsYear = 12 - startMonth + 1
  const countYears = Math.floor((monthCount - countMonthFirsYear) / 12)

  const lastMonth = (monthCount - countMonthFirsYear) % 12
  for (let index = 0; index <= countYears; index++) {
    if (index !== countYears) {
      Array(12)
        .fill(1)
        // eslint-disable-next-line no-loop-func
        .forEach((_, i) => {
          listDate.push(new Date(startYear + index + 1, i + 1, 1))
          listDate.push(new Date(startYear + index + 1, i + 1, 10))
          listDate.push(new Date(startYear + index + 1, i + 1, 20))
          // listDate.push(new Date(startYear + index + 1, i + 1 + 1, 0))
        })
    } else if (lastMonth !== 0) {
      Array(lastMonth - 1)
        .fill(1)
        // eslint-disable-next-line no-loop-func
        .forEach((_, i) => {
          listDate.push(new Date(startYear + index + 1, i + 1, 1))
          listDate.push(new Date(startYear + index + 1, i + 1, 10))
          listDate.push(new Date(startYear + index + 1, i + 1, 20))
          if (i === lastMonth - 1 - 1) {
            listDate.push(new Date(startYear + index + 1, i + 1 + 1, 0))
          }
        })
    }
  }
  return listDate
}
//tạo ra list các mốc thời gian có của toàn bộ bảng
export const getAllLocationInChartMonth = (monthCount) => {
  let listDate = []
  for (let index = 1; index <= monthCount; index++) {
    listDate.push(`${index}-5`)
    listDate.push(`${index}-15`)
    listDate.push(`${index}-25`)
  }
  return listDate
}
//tạo ra list các mốc thời gian có của dateRange
export const getCalendarValuesMonthDateRange = (dateRange) => {
  let listDate = []
  if (dateRange.startMonth == dateRange.toMonth) {
    for (let index = dateRange.startDate; index <= dateRange.toDate; index = index + 10) {
      listDate.push(`${dateRange.startMonth}-${index}`)
    }
    return listDate
  }

  for (let index = dateRange.startDate; index <= 30; index = index + 10) {
    listDate.push(`${dateRange.startMonth}-${index}`)
  }

  for (let index = dateRange.startMonth + 1; index < dateRange.toMonth; index++) {
    listDate.push(`${index}-1`)
    listDate.push(`${index}-10`)
    listDate.push(`${index}-20`)
    listDate.push(`${index}-30`)
  }

  for (let index = 0; index <= dateRange.toDate; index = index + 10) {
    listDate.push(`${dateRange.toMonth}-${index}`)
  }
  return listDate
}

export const getCalendarValuesMonthDateRangeV2 = (dateRange) => {
  let listDate = []
  if (Number(dateRange.startMonth) == Number(dateRange.toMonth)) {
    for (let index = Number(dateRange.startDate); index < Number(dateRange.toDate); index = index + 10) {
      if (index === 1) {
        index = 0
      }
      if (index !== 30) {
        listDate.push(`${Number(dateRange.startMonth)}-${index + 5}`)
      }
    }
    return listDate
  }

  for (let index = Number(dateRange.startDate); index < 30; index = index + 10) {
    if (index === 1) {
      index = 0
    }
    listDate.push(`${Number(dateRange.startMonth)}-${index + 5}`)
  }

  for (let index = Number(dateRange.startMonth) + 1; index < Number(dateRange.toMonth); index++) {
    listDate.push(`${index}-5`)
    listDate.push(`${index}-15`)
    listDate.push(`${index}-25`)
  }

  if (Number(dateRange.toDate) === 1) {
    return listDate
  }
  for (let index = 0; index < Number(dateRange.toDate); index = index + 10) {
    if (index !== 30) {
      if (index === 1) {
        index = 0
      }
      listDate.push(`${Number(dateRange.toMonth)}-${index + 5}`)
    }
  }
  return listDate
}

export const validateBackgroundColorMonth = (dateRange, monthCount, backgroundColors, displayText) => {
  let allValues = []
  const valuesInNewMonth = getCalendarValuesMonthDateRangeV2(dateRange)
  backgroundColors.forEach((backgroundColor) => {
    backgroundColor?.dateRanges?.forEach((date) => {
      if (displayText !== date.displayText) {
        allValues = [...allValues, ...getCalendarValuesMonthDateRangeV2(date)]
      }
    })
  })
  const result = valuesInNewMonth.filter((value) => allValues.includes(value))
  return result.length < 1
}

export const getAllLocationHaveMonthBackgroundColor = (dateRange, monthCount) => {
  const allLocations = getAllLocationInChartMonth(monthCount)
  const allLocationDateRange = getCalendarValuesMonthDateRangeV2(dateRange)
  const result = allLocationDateRange.map((x) => {
    return allLocations.findIndex((t) => t === x)
  })
  return result
}
