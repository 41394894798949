import React from 'react'
import { BarTask } from '../../types/bar-task'
import styles from './arrow.module.css'
import { GanttEvent } from '../../types/gantt-task-actions'
import { useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'

type ArrowProps = {
  taskFrom: BarTask
  taskTo: BarTask
  rowHeight: number
  taskHeight: number
  arrowIndent: number
  rtl: boolean
  color: string
  setGanttEvent: (value: GanttEvent) => void
}
export const Arrow: React.FC<ArrowProps> = ({
  taskFrom,
  taskTo,
  rowHeight,
  taskHeight,
  arrowIndent,
  rtl,
  color,
  setGanttEvent
}) => {
  let path: string
  let trianglePoints: string
  const [pathWidth, setPathWidth] = React.useState<number>(1.5)
  const [lineWidth, setLineWidth] = React.useState<number>(1.2)
  const [isClick, setIsClick] = React.useState<boolean>(false)

  const stepWorkColors = useSelector((state: RootState) => state.versionSetting.current.stepworkColors)
  const arrowRef = React.useRef<SVGPathElement>(null)
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!arrowRef.current?.contains(e.target as HTMLInputElement)) {
        setIsClick(false)
      }
    }
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })

  const predecessorType = taskFrom?.predecessors?.find((pre) => pre.id === taskTo.id)?.type || 'FF'

  const getArrowColor = () => {
    if (taskTo?.colorId) {
      const color = stepWorkColors?.find((x: any) => x.colorId === taskTo?.colorId)
      if (color) {
        return color.code
      } else {
        if (taskTo?.styles?.backgroundColor) {
          return taskTo?.styles?.backgroundColor
        } else {
          return 'blue'
        }
      }
    } else {
      if (taskTo?.styles?.backgroundColor) {
        return taskTo?.styles?.backgroundColor
      } else {
        return 'blue'
      }
    }
  }
  const getArrowEndPoint = () => {
    if (predecessorType === 'FF') {
      return taskTo.end + 4
    } else {
      return taskTo.start + 4
    }
  }
  if (rtl) {
    ;[path, trianglePoints] = drownPathAndTriangleRTL(taskFrom, taskTo, rowHeight, taskHeight, arrowIndent)
  } else {
    ;[path, trianglePoints] = drownPathAndTriangle(
      taskFrom,
      taskTo,
      rowHeight,
      taskHeight,
      arrowIndent,
      predecessorType
    )
  }

  const handleDelete = () => {
    const predecessors = taskFrom.predecessors
    if (predecessors !== undefined) {
      const newPredecessors = predecessors.filter((p) => p.id !== taskTo.id)
      setGanttEvent({
        action: 'deletePredecessor',
        changedTask: { ...taskFrom, predecessors: newPredecessors },
        originalSelectedTask: taskFrom
      })
    }
  }
  return (
    <>
      <g
        ref={arrowRef}
        className={styles.arrow}
        onMouseOut={() => {
          setPathWidth(1.5)
        }}
        onClick={() => setIsClick(true)}
      >
        <svg cursor={'pointer'}>
          <path
            strokeWidth={pathWidth}
            d={path}
            fill='none'
            stroke={getArrowColor() || color}
            onMouseOver={() => setPathWidth(2.5)}
            style={{ cursor: 'pointer' }}
          />
        </svg>

        {isClick && (
          <>
            <circle
              cx={getArrowEndPoint() - 4}
              cy={taskTo.y + 10}
              r='8'
              stroke='#5aabed'
              stroke-width='2'
              fill='white'
              onMouseDown={() => handleDelete()}
              onMouseOver={() => setLineWidth(1.5)}
              onMouseOut={() => setLineWidth(1.2)}
            />
            <line
              className={styles.icon_delete}
              x1={getArrowEndPoint()}
              y1={taskTo.y + taskHeight / 2 + 4}
              x2={getArrowEndPoint() - 8}
              y2={taskTo.y + taskHeight / 2 - 4}
              stroke='rgb(255,0,0)'
              cursor={'pointer'}
              strokeWidth={lineWidth}
              onMouseOver={() => setLineWidth(1.5)}
              onMouseOut={() => setLineWidth(1.2)}
              onMouseDown={() => handleDelete()}
            />
            <line
              className={styles.icon_delete}
              x1={getArrowEndPoint()}
              y1={taskTo.y + taskHeight / 2 - 4}
              x2={getArrowEndPoint() - 8}
              y2={taskTo.y + taskHeight / 2 + 4}
              stroke='rgb(255,0,0)'
              cursor={'pointer'}
              strokeWidth={lineWidth}
              fill='white'
              onMouseOver={() => setLineWidth(1.5)}
              onMouseOut={() => setLineWidth(1.2)}
              onMouseDown={() => handleDelete()}
            />
          </>
        )}
        {!isClick && (
          <>
            <polygon points={trianglePoints} fill='#636363' stroke='#353535' />
          </>
        )}
        {/* <polygon points={trianglePoints} /> */}
      </g>
    </>
  )
}

const drawArrowWithDirection = (pointX: number, pointY: number, direction: string) => {
  let trianglePoints
  if (direction === 'bottom') {
    trianglePoints = `${pointX},${pointY}
        ${pointX - 3},${pointY - 5}
        ${pointX + 3},${pointY - 5}`
  } else if (direction === 'top') {
    trianglePoints = `${pointX},${pointY}
        ${pointX - 3},${pointY + 5}
        ${pointX + 3},${pointY + 5}`
  } else if (direction === 'left') {
    trianglePoints = `${pointX},${pointY}
        ${pointX + 5},${pointY - 3}
        ${pointX + 5},${pointY + 3}`
  } else {
    trianglePoints = `${pointX},${pointY}
        ${pointX - 5},${pointY - 3}
        ${pointX - 5},${pointY + 3}`
  }
  return trianglePoints
}

const drownPathAndTriangle = (
  taskFrom: BarTask,
  taskTo: BarTask,
  rowHeight: number,
  taskHeight: number,
  arrowIndent: number,
  predecessorType: string
): string[] => {
  if (predecessorType === 'FS') {
    if (!isEquals(taskFrom.end, taskTo.start)) {
      const indexCompare = taskFrom.index > taskTo.index ? -1 : 1
      const taskToEndPosition = taskTo.y + taskHeight / 2
      const taskFromEndPosition = taskFrom.end + arrowIndent * 2
      const taskFromHorizontalOffsetValue = taskFromEndPosition < taskTo.start ? '' : `H ${taskTo.start - arrowIndent}`
      const taskToHorizontalOffsetValue =
        taskFromEndPosition > taskTo.start ? arrowIndent : taskTo.start - taskFrom.end - arrowIndent

      let path
      let trianglePoints
      if (taskFrom.end > taskTo.start) {
        path = `M ${taskFrom.end} ${taskFrom.y + taskHeight / 2} 
        h ${arrowIndent} 
        v ${(indexCompare * rowHeight) / 2} 
        ${taskFromHorizontalOffsetValue}
        V ${taskToEndPosition} 
        h ${taskToHorizontalOffsetValue}
        `
        trianglePoints = `${taskTo.start},${taskToEndPosition}
        ${taskTo.start - 5},${taskToEndPosition - 3}
        ${taskTo.start - 5},${taskToEndPosition + 3}`
      } else {
        if (taskFrom.y < taskTo.y) {
          path = `M ${taskFrom.end} ${taskFrom.y + taskHeight / 2} 
          h ${-(taskFrom.end - taskTo.start)} 
          v ${Math.abs(taskFrom.y - taskTo.y)} 
          `
          trianglePoints = `${taskTo.start},${taskToEndPosition}
          ${taskTo.start - 3},${taskToEndPosition - 5}
          ${taskTo.start + 3},${taskToEndPosition - 5}`
        } else {
          path = `M ${taskFrom.end} ${taskFrom.y + taskHeight / 2} 
          h ${-(taskFrom.end - taskTo.start)} 
          v ${-Math.abs(taskFrom.y - taskTo.y)} 
          `
          trianglePoints = `${taskTo.start},${taskToEndPosition}
          ${taskTo.start - 3},${taskToEndPosition + 5}
          ${taskTo.start + 3},${taskToEndPosition + 5}`
        }
      }

      return [path, trianglePoints]
    } else {
      const taskToEndPosition = taskTo.y + taskHeight / 2

      const path = `M${taskTo.start},${taskTo.y + taskHeight / 2} L${taskFrom.end},${taskFrom.y + taskHeight / 2}`
      let trianglePoints
      if (taskFrom.y < taskTo.y) {
        trianglePoints = `${taskTo.start},${taskToEndPosition}
        ${taskTo.start - 3},${taskToEndPosition - 5}
        ${taskTo.start + 3},${taskToEndPosition - 5}`
      } else {
        trianglePoints = `${taskTo.start},${taskToEndPosition}
        ${taskTo.start - 3},${taskToEndPosition + 5}
        ${taskTo.start + 3},${taskToEndPosition + 5}`
      }

      return [path, trianglePoints]
    }
  } else if (predecessorType === 'FF') {
    if (!isEquals(taskFrom.end, taskTo.end)) {
      const taskToEndPosition = taskTo.y + taskHeight / 2
      const taskFromEndPosition = taskFrom.end + arrowIndent * 2
      const taskFromHorizontalOffsetValue =
        taskFrom.end < taskTo.end ? `${taskTo.end - taskFrom.end}` : `${arrowIndent}`
      const taskToHorizontalOffsetValue = taskFromEndPosition < taskTo.end ? '' : `H ${taskTo.end}`

      let path
      if (taskFrom.y > taskTo.y) {
        path = `M ${taskFrom.end} ${taskFrom.y + taskHeight / 2} 
        h ${taskFromHorizontalOffsetValue} 
        v ${-Math.abs(taskFrom.y - taskTo.y)} 
        ${taskToHorizontalOffsetValue}
        `
      } else {
        path = `M ${taskFrom.end} ${taskFrom.y + taskHeight / 2} 
        h ${taskFromHorizontalOffsetValue} 
        v ${Math.abs(taskFrom.y - taskTo.y)} 
        ${taskToHorizontalOffsetValue}
        `
      }

      let trianglePoints
      if (taskFrom.end > taskTo.end) {
        trianglePoints = drawArrowWithDirection(taskTo.end, taskToEndPosition, 'left')
      } else {
        if (taskFrom.y > taskTo.y) {
          trianglePoints = drawArrowWithDirection(taskTo.end, taskToEndPosition, 'top')
        } else {
          trianglePoints = drawArrowWithDirection(taskTo.end, taskToEndPosition, 'bottom')
        }
      }
      return [path, trianglePoints]
    } else {
      const taskToEndPosition = taskTo.y + taskHeight / 2
      const path = `M${taskTo.end},${taskTo.y + taskHeight / 2} L${taskFrom.end},${taskFrom.y + taskHeight / 2}`
      let trianglePoints

      if (taskFrom.y < taskTo.y) {
        trianglePoints = drawArrowWithDirection(taskTo.end, taskToEndPosition, 'bottom')
      } else {
        trianglePoints = drawArrowWithDirection(taskTo.end, taskToEndPosition, 'top')
      }

      return [path, trianglePoints]
    }
  } else {
    if (!isEquals(taskFrom.start, taskTo.start)) {
      const taskToEndPosition = taskTo.y + taskHeight / 2

      const taskFromHorizontal =
        taskFrom.start > taskTo.start ? `h ${-Math.abs(taskFrom.start - taskTo.start)}` : `h ${-arrowIndent}`
      const takFromVertical = taskFrom.y > taskTo.y ? -Math.abs(taskFrom.y - taskTo.y) : Math.abs(taskFrom.y - taskTo.y)

      const path = `M ${taskFrom.start} ${taskFrom.y + taskHeight / 2} 
      ${taskFromHorizontal} 
      v ${takFromVertical}
      L${taskTo.start},${taskTo.y + taskHeight / 2}
      `

      let trianglePoints
      if (taskFrom.start > taskTo.start) {
        if (taskFrom.y > taskTo.y) {
          trianglePoints = drawArrowWithDirection(taskTo.start, taskToEndPosition, 'top')
        } else {
          trianglePoints = drawArrowWithDirection(taskTo.start, taskToEndPosition, 'bottom')
        }
      } else {
        trianglePoints = drawArrowWithDirection(taskTo.start, taskToEndPosition, 'right')
      }

      return [path, trianglePoints]
    } else {
      const taskToEndPosition = taskTo.y + taskHeight / 2
      const path = `M${taskTo.start},${taskTo.y + taskHeight / 2} L${taskFrom.start},${taskFrom.y + taskHeight / 2}`
      let trianglePoints
      if (taskFrom.y < taskTo.y) {
        trianglePoints = drawArrowWithDirection(taskTo.start, taskToEndPosition, 'bottom')
      } else {
        trianglePoints = drawArrowWithDirection(taskTo.start, taskToEndPosition, 'top')
      }
      return [path, trianglePoints]
    }
  }
}

const isEquals = (start: number, end: number) => {
  if (Math.abs(start - end) <= 0.1) {
    return true
  } else {
    return false
  }
}

const drownPathAndTriangleRTL = (
  taskFrom: BarTask,
  taskTo: BarTask,
  rowHeight: number,
  taskHeight: number,
  arrowIndent: number
) => {
  const indexCompare = taskFrom.index > taskTo.index ? -1 : 1
  const taskToEndPosition = taskTo.y + taskHeight / 2
  const taskFromEndPosition = taskFrom.start - arrowIndent * 2
  const taskFromHorizontalOffsetValue = taskFromEndPosition > taskTo.end ? '' : `H ${taskTo.end + arrowIndent}`
  const taskToHorizontalOffsetValue =
    taskFromEndPosition < taskTo.end ? -arrowIndent : taskTo.end - taskFrom.start + arrowIndent

  const path = `M ${taskFrom.start} ${taskFrom.y + taskHeight / 2} 
  h ${-arrowIndent} 
  v ${(indexCompare * rowHeight) / 2} 
  ${taskFromHorizontalOffsetValue}
  V ${taskToEndPosition} 
  h ${taskToHorizontalOffsetValue}
  `

  const trianglePoints = `${taskTo.end},${taskToEndPosition} 
  ${taskTo.end + 5},${taskToEndPosition + 5} 
  ${taskTo.end + 5},${taskToEndPosition - 5}`
  return [path, trianglePoints]
}
