import { createSlice } from '@reduxjs/toolkit'

export const tableWidthSlice = createSlice({
  name: 'columnsWidth',
  initialState: 789,
  reducers: {
    setTableWidth: (state, action) => {
      return action.payload
    }
  }
})
const { actions, reducer } = tableWidthSlice
export default reducer
