import React from "react";
import ForgeAPI from "../../../../B_API/ForgeAPI";
import ExportTableToCSV, {
  WriteDataToTable,
} from "../../../../F_UTILS/CSVUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEdit,
  faFileExport,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import StorageKeys from "../../../../F_UTILS/Constants/StorageKeys";
import RenameBlockApi from "../../../../B_API/RenameBlockApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fileDownload from "js-file-download";
import classNames from "classnames";
import { useSelector } from "react-redux";

export default function FooterComponent({
  setProgressIsRunning,
  setMessageProgress,
  setMessageCompleted,
  setShowDialog,
  originName,
  setListNames,
  setListReNames,
  listNameNotInModel,
  setViewInfor,
  btnDisable,
}) {
  const jobId = useSelector((state) => state.jobId.current);

  function handleInputClick() {
    const realFileInput = document.getElementById("input-file");
    realFileInput.value = "";
  }

  function handleInputChange() {
    const realFileInput = document.getElementById("input-file");
    if (realFileInput.value) {
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        let dataCSV = fileReader.result.split("\n").map((e) => {
          return e.split(",");
        });
        WriteDataToTable(dataCSV);
        toast.success("CSV読み込み完了。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      };
      fileReader.readAsText(realFileInput.files[0]);
    }
  }

  function handleImportClick() {
    const realFileInput = document.getElementById("input-file");
    realFileInput.click();
  }

  function handleExportCSV() {
    console.log("run");
    const labelFileName = document.querySelector(".file--name label");
    console.log(labelFileName.innerHTML);
    if (labelFileName.innerHTML !== "ファイル名: ") {
      ExportTableToCSV(
        labelFileName.innerHTML
          .replace("ファイル名: ", "")
          .replace(".dwg", "") + ".csv"
      );
    } else {
      ExportTableToCSV("renameBlocks.csv");
    }
  }

  async function handleInputDownloadFolder(e) {
    var files = e.target.files;
    var path = files[0].webkitRelativePath;
    const dirHandle = await window.showDirectoryPicker();
    console.log(files[0], dirHandle);
    var Folder = path.split("/");
    console.log(Folder[0]);
  }

  const handleRename = async () => {
    const preWord = document.getElementById("pre").value;
    const subWord = document.getElementById("sub").value;
    var data = [];
    var listDuplicate = [];
    var listNewNames = [];

    var listCurrentNewName = [];
    //Nếu có prefix và subfix
    if (preWord !== "" || subWord !== "") {
      var rows = document.querySelectorAll("#contents-table tr");
      for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll("td, th");
        cols[1].classList.remove("error-cell");
        cols[1].classList.remove("warning-cell");
        const valueColOldName = cols[0].innerText;
        const valueColNewName = cols[1].innerText;
        listCurrentNewName.push(valueColNewName || "");

        if (valueColNewName !== "") {
          data.push({
            OldBlockName: valueColOldName,
            NewBlockName: preWord + valueColNewName + subWord,
          });
          listNewNames.push(preWord + valueColNewName + subWord);
        } else {
          if (valueColOldName !== "") {
            data.push({
              OldBlockName: valueColOldName,
              NewBlockName: preWord + valueColOldName + subWord,
            });
            listNewNames.push(preWord + valueColOldName + subWord);
          }
        }
      }
      setListReNames(listCurrentNewName);
      if (listNewNames.length !== 0) {
        listDuplicate = FindTheSameBlockNameInArray(listNewNames);
      }
    } else {
      var rows = document.querySelectorAll("#contents-table tr");

      for (var i = 0; i < rows.length; i++) {
        var cols = rows[i].querySelectorAll("td, th");
        cols[1].classList.remove("error-cell");
        const valueColOldName = cols[0].innerText;
        const valueColNewName = cols[1].innerText;
        listCurrentNewName.push(valueColNewName || "");

        if (valueColNewName !== "") {
          data.push({
            OldBlockName: valueColOldName,
            NewBlockName: valueColNewName,
          });
          listNewNames.push(valueColNewName);
        } else {
          if (valueColOldName !== "") {
            listNewNames.push(valueColOldName);
          }
        }
      }
      setListReNames(listCurrentNewName);
      if (listNewNames.length !== 0) {
        listDuplicate = FindTheSameBlockNameInArray(listNewNames);
      }
    }

    if (listDuplicate.length > 0) {
      console.log("error");
    } else {
      const labelFileName = document.querySelector(".file--name label");

      if (labelFileName.innerHTML === "ファイル名: ") {
        toast.error(
          "AutoCADファイルを見つかりません。ファイルを選択してください。",
          {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      if (data.length === 0) {
        toast.error(
          "データが変換されていません。もう一度チェックしてください。",
          {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }

      const inputParams = { InputParams: data };
      console.log(inputParams);
      // tạo task...
      setProgressIsRunning(true);
      setMessageProgress(
        "ブロック名を変換しています。しばらくお待ちください。"
      );
      setShowDialog(true);

      const responseRunReNamBlock = await runRenameBlocks(jobId, inputParams);
      if (responseRunReNamBlock?.status !== 200) {
        setProgressIsRunning(false);
        setMessageCompleted("ブロック名を変換出来ませんでした。");
        return;
      }
      // check status by id
      const workId = responseRunReNamBlock.data.id;

      const checkIntervalID = setInterval(async () => {
        try {
          const responseWorkStatus = await ForgeAPI.GetWorkStatus(workId);

          if (responseWorkStatus.status !== 200) {
            clearInterval(checkIntervalID);
            setProgressIsRunning(false);
            setMessageCompleted("ブロック名を変換出来ませんでした。");
          }

          const listSuccessStatus = [1, 2, 9];
          if (!listSuccessStatus.includes(responseWorkStatus.data.status)) {
            clearInterval(checkIntervalID);
            setProgressIsRunning(false);
            setMessageCompleted("ブロック名を変換出来ませんでした。");
          } else {
            if (responseWorkStatus.data.status === 9) {
              console.log("responseWorkStatus", responseWorkStatus);
              clearInterval(checkIntervalID);
              await UpdateViewWhenWorkDone();
              // ToDo Work success
            }
          }
        } catch (error) {
          console.log(error);
          clearInterval(checkIntervalID);
          setProgressIsRunning(false);
          setMessageCompleted("ブロック名を変換出来ませんでした。");
        }
      }, 3000);

      async function UpdateViewWhenWorkDone() {
        //call api mới get new urn
        let newUrn = "";
        const response = await getResultUrn(jobId);
        console.log(response);
        if (response?.status === 200) {
          newUrn = response.data.encodedSourceUrn;
        } else {
          console.log(newUrn);
          setProgressIsRunning(false);
          setMessageCompleted("ブロック名を変換出来ませんでした。");
        }
        // interval get manifest
        const manifestID = setInterval(async () => {
          console.log("manifestID");
          if (newUrn !== "") {
            const status = await getManifest(newUrn);

            if (status === "success") {
              console.log("update list name");
              setListNames(listNewNames);
              setProgressIsRunning(false);
              setMessageCompleted("ブロック名の変換が完了しました。");
              const urn = "urn:" + newUrn;
              const accessToken = await getAccessToken();
              setViewInfor({
                urn: urn,
                accessToken: accessToken,
              });

              // thay đổi bảng block
              setListNames(listNewNames);
              setListReNames([]);
              clearInterval(manifestID);
            }
          }
        }, 3000);

        // Clear Interval when failed
        const idTimeOut = setTimeout(() => {
          clearInterval(checkIntervalID);
          clearInterval(manifestID);
          clearTimeout(idTimeOut);
          setProgressIsRunning(false);
          setMessageCompleted("ブロック名を変換出来ませんでした。");
        }, 60000 * 3);
      }
    }
    document.getElementById("pre").value = "";
    document.getElementById("sub").value = "";
  };

  const handleDownload = async () => {
    try {
      console.log("click");
      setProgressIsRunning(true);
      setMessageProgress("ダウンロードの準備中。しばらくお待ちください。");
      setShowDialog(true);

      const res = await RenameBlockApi.DownloadFile(jobId);
      console.log(res.data);
      if (res.status === 200) {
        var date = new Date();
        var newName = `${date.getFullYear()}${
          date.getMonth() + 1
        }${date.getDate()}${date.getHours()}${date.getMinutes()}_${originName}`;
        fileDownload(res.data, newName);

        setProgressIsRunning(false);
        setMessageCompleted("ダウンロードが完了しました。");
      } else {
        toast.error("ダウンロード出来ませんでした。", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setProgressIsRunning(false);
        setMessageCompleted("ダウンロード出来ませんでした。");
      }
    } catch (error) {
      console.log(error);
      toast.error("ダウンロード出来ませんでした。", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setProgressIsRunning(false);
      setMessageCompleted("ダウンロード出来ませんでした。");
    }
  };

  // function API

  async function runRenameBlocks(jobId, data) {
    try {
      const response = await RenameBlockApi.RunRenameBlocks(jobId, data);
      return response;
    } catch (error) {
      console.log("Run Rename BlockName", error);
      return null;
    }
  }

  async function getResultUrn(jobId) {
    try {
      const response = await RenameBlockApi.GetResultUrn(jobId);
      return response;
    } catch (error) {
      console.log("get Result Urn", error);
      return null;
    }
  }

  async function getAccessToken() {
    try {
      const response = await ForgeAPI.GetViewerToken();
      return response.data.accessToken;
    } catch (error) {
      console.log("Get Viewer Token ", error);
      return null;
    }
  }

  async function getManifest(urn) {
    try {
      const response = await ForgeAPI.GetManifest(urn);
      return response.data.status;
    } catch (error) {
      console.log("Get Manifest ", error);
      return null;
    }
  }

  const FindTheSameBlockNameInArray = (listBlockNames) => {
    const toFindDuplicates = (listBlockNames) => {
      let lowerList = [...listBlockNames];
      lowerList = lowerList.map((x) => x.toLowerCase());
      return listBlockNames.filter(
        (item, index) => lowerList.indexOf(item.toLowerCase()) !== index
      );
    };
    function getAllIndexes(arr, val) {
      let arrLowerCase = [...arr].map((x) => x.toLowerCase());
      var indexes = [],
        i = -1;
      while ((i = arrLowerCase.indexOf(val.toLowerCase(), i + 1)) !== -1) {
        indexes.push(i);
      }
      return indexes;
    }
    var newArray = listBlockNames.concat(listNameNotInModel);
    const duplicateElement = toFindDuplicates(newArray);
    console.log(newArray);

    function validateName(listNames) {
      let listNameError = [];
      var format = /[*="|<>?,./\\;]/;
      for (let index = 0; index < listNames.length; index++) {
        const element = listNames[index];
        if (format.test(element)) {
          listNameError.push(element);
        }
      }
      return listNameError;
    }

    const listNameError = validateName(listBlockNames);

    if (listNameError?.length > 0) {
      toast.error(
        "ブロック名が間違っています。もう一度チェックしてください。",
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      var rows = document.querySelectorAll("#contents-table tr");
      for (let index = 0; index < listNameError.length; index++) {
        const value = listNameError[index];
        const listIndex = getAllIndexes(listBlockNames, value);
        console.log(listIndex);
        for (let jIndex = 0; jIndex < listIndex.length; jIndex++) {
          const j = listIndex[jIndex];
          var cell = rows[j].querySelectorAll("td, th")[1];
          cell.className += " warning-cell";
        }
      }
    }

    if (duplicateElement?.length > 0) {
      toast.error(
        "指定された新しいブロック名が他と重複しています。もう一度チェックしてください。",
        {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }

    var rows2 = document.querySelectorAll("#contents-table tr");
    for (let index = 0; index < duplicateElement.length; index++) {
      const value = duplicateElement[index];
      const listIndex = getAllIndexes(listBlockNames, value);
      console.log(listIndex);
      for (let jIndex = 0; jIndex < listIndex.length; jIndex++) {
        const j = listIndex[jIndex];
        var cell = rows2[j].querySelectorAll("td, th")[1];
        cell.className += " error-cell";
        console.log("error-cell", cell.className);
      }
    }

    return duplicateElement.concat(listNameError);
  };

  return (
    <div className="other--btn">
      <div className="import-csv">
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{ width: "135px" }}
          onClick={handleExportCSV}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faFileExport} />
          CSV書き出し
        </button>
        <input
          type="file"
          id="input-file"
          accept=".csv"
          onClick={handleInputClick}
          onChange={handleInputChange}
        />
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          id="custom-button"
          style={{ width: "135px" }}
          onClick={handleImportClick}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faFileImport} />
          CSV読み込み
        </button>
      </div>

      <div className="btn-api">
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{
            width: "135px",
            minWidth: "135px",
          }}
          onClick={handleRename}
          disabled={btnDisable}
        >
          変換
        </button>
        <input
          type="file"
          id="download-file"
          webkitdirectory="true"
          multiple
          onChange={handleInputDownloadFolder}
        />
        <button
          className={classNames({
            "button-class": true,
            "button-disable": btnDisable,
          })}
          style={{ width: "135px", minWidth: "135px" }}
          onClick={handleDownload}
          disabled={btnDisable}
        >
          <FontAwesomeIcon className="icon-class" icon={faDownload} />
          ダウンロード
        </button>
      </div>
    </div>
  );
}
