export const ListColorsEvenIndex = [
  [
    {
      index: "18",
      color: "rgb(76,0,0)",
    },
    {
      index: "28",
      color: "rgb(76,19,0)",
    },
    {
      index: "38",
      color: "rgb(76,38,0)",
    },
    {
      index: "48",
      color: "rgb(76,57,0)",
    },
    {
      index: "58",
      color: "rgb(76,76,0)",
    },
    {
      index: "68",
      color: "rgb(57,76,0)",
    },
    {
      index: "78",
      color: "rgb(38,76,0)",
    },
    {
      index: "88",
      color: "rgb(19,76,0)",
    },
    {
      index: "98",
      color: "rgb(0,76,0)",
    },
    {
      index: "108",
      color: "rgb(0,76,19)",
    },
    {
      index: "118",
      color: "rgb(0,76,38)",
    },
    {
      index: "128",
      color: "rgb(0,76,57)",
    },
    {
      index: "138",
      color: "rgb(0,76,76)",
    },
    {
      index: "148",
      color: "rgb(0,57,76)",
    },
    {
      index: "158",
      color: "rgb(0,38,76)",
    },
    {
      index: "168",
      color: "rgb(0,19,76)",
    },
    {
      index: "178",
      color: "rgb(0,0,76)",
    },
    {
      index: "188",
      color: "rgb(19,0,76)",
    },
    {
      index: "198",
      color: "rgb(38,0,76)",
    },
    {
      index: "208",
      color: "rgb(57,0,76)",
    },
    {
      index: "218",
      color: "rgb(76,0,76)",
    },
    {
      index: "228",
      color: "rgb(76,0,57)",
    },
    {
      index: "238",
      color: "rgb(76,0,38)",
    },
    {
      index: "248",
      color: "rgb(76,0,19)",
    },
  ],
  [
    {
      index: "16",
      color: "rgb(127,0,0)",
    },
    {
      index: "26",
      color: "rgb(127,31,0)",
    },
    {
      index: "36",
      color: "rgb(127,63,0)",
    },
    {
      index: "46",
      color: "rgb(127,95,0)",
    },
    {
      index: "56",
      color: "rgb(127,127,0)",
    },
    {
      index: "66",
      color: "rgb(95,127,0)",
    },
    {
      index: "76",
      color: "rgb(63,127,0)",
    },
    {
      index: "86",
      color: "rgb(31,127,0)",
    },
    {
      index: "96",
      color: "rgb(0,127,0)",
    },
    {
      index: "106",
      color: "rgb(0,127,31)",
    },
    {
      index: "116",
      color: "rgb(0,127,63)",
    },
    {
      index: "126",
      color: "rgb(0,127,95)",
    },
    {
      index: "136",
      color: "rgb(0,127,127)",
    },
    {
      index: "146",
      color: "rgb(0,95,127)",
    },
    {
      index: "156",
      color: "rgb(0,63,127)",
    },
    {
      index: "166",
      color: "rgb(0,31,127)",
    },
    {
      index: "176",
      color: "rgb(0,0,127)",
    },
    {
      index: "186",
      color: "rgb(31,0,127)",
    },
    {
      index: "196",
      color: "rgb(63,0,127)",
    },
    {
      index: "206",
      color: "rgb(95,0,127)",
    },
    {
      index: "216",
      color: "rgb(127,0,127)",
    },
    {
      index: "226",
      color: "rgb(127,0,95)",
    },
    {
      index: "236",
      color: "rgb(127,0,63)",
    },
    {
      index: "246",
      color: "rgb(127,0,31)",
    },
  ],
  [
    {
      index: "14",
      color: "rgb(153,0,0)",
    },
    {
      index: "24",
      color: "rgb(153,38,0)",
    },
    {
      index: "34",
      color: "rgb(153,76,0)",
    },
    {
      index: "44",
      color: "rgb(153,114,0)",
    },
    {
      index: "54",
      color: "rgb(153,153,0)",
    },
    {
      index: "64",
      color: "rgb(114,153,0)",
    },
    {
      index: "74",
      color: "rgb(76,153,0)",
    },
    {
      index: "84",
      color: "rgb(38,153,0)",
    },
    {
      index: "94",
      color: "rgb(0,153,0)",
    },
    {
      index: "104",
      color: "rgb(0,153,38)",
    },
    {
      index: "114",
      color: "rgb(0,153,76)",
    },
    {
      index: "124",
      color: "rgb(0,153,114)",
    },
    {
      index: "134",
      color: "rgb(0,153,153)",
    },
    {
      index: "144",
      color: "rgb(0,114,153)",
    },
    {
      index: "154",
      color: "rgb(0,76,153)",
    },
    {
      index: "164",
      color: "rgb(0,38,153)",
    },
    {
      index: "174",
      color: "rgb(0,0,153)",
    },
    {
      index: "184",
      color: "rgb(38,0,153)",
    },
    {
      index: "194",
      color: "rgb(76,0,153)",
    },
    {
      index: "204",
      color: "rgb(114,0,153)",
    },
    {
      index: "214",
      color: "rgb(153,0,153)",
    },
    {
      index: "224",
      color: "rgb(153,0,114)",
    },
    {
      index: "234",
      color: "rgb(153,0,76)",
    },
    {
      index: "244",
      color: "rgb(153,0,38)",
    },
  ],
  [
    {
      index: "12",
      color: "rgb(204,0,0)",
    },
    {
      index: "22",
      color: "rgb(204,51,0)",
    },
    {
      index: "32",
      color: "rgb(204,102,0)",
    },
    {
      index: "42",
      color: "rgb(204,153,0)",
    },
    {
      index: "52",
      color: "rgb(204,204,0)",
    },
    {
      index: "62",
      color: "rgb(153,204,0)",
    },
    {
      index: "72",
      color: "rgb(102,204,0)",
    },
    {
      index: "82",
      color: "rgb(51,204,0)",
    },
    {
      index: "92",
      color: "rgb(0,204,0)",
    },
    {
      index: "102",
      color: "rgb(0,204,51)",
    },
    {
      index: "112",
      color: "rgb(0,204,102)",
    },
    {
      index: "122",
      color: "rgb(0,204,153)",
    },
    {
      index: "132",
      color: "rgb(0,204,204)",
    },
    {
      index: "142",
      color: "rgb(0,153,204)",
    },
    {
      index: "152",
      color: "rgb(0,102,204)",
    },
    {
      index: "162",
      color: "rgb(0,51,204)",
    },
    {
      index: "172",
      color: "rgb(0,0,204)",
    },
    {
      index: "182",
      color: "rgb(51,0,204)",
    },
    {
      index: "192",
      color: "rgb(102,0,204)",
    },
    {
      index: "202",
      color: "rgb(153,0,204)",
    },
    {
      index: "212",
      color: "rgb(204,0,204)",
    },
    {
      index: "222",
      color: "rgb(204,0,153)",
    },
    {
      index: "232",
      color: "rgb(204,0,102)",
    },
    {
      index: "242",
      color: "rgb(204,0,51)",
    },
  ],
  [
    {
      index: "10",
      color: "rgb(255,0,0)",
    },
    {
      index: "20",
      color: "rgb(255,63,0)",
    },
    {
      index: "30",
      color: "rgb(255,127,0)",
    },
    {
      index: "40",
      color: "rgb(255,191,0)",
    },
    {
      index: "50",
      color: "rgb(255,255,0)",
    },
    {
      index: "60",
      color: "rgb(191,255,0)",
    },
    {
      index: "70",
      color: "rgb(127,255,0)",
    },
    {
      index: "80",
      color: "rgb(63,255,0)",
    },
    {
      index: "90",
      color: "rgb(0,255,0)",
    },
    {
      index: "100",
      color: "rgb(0,255,63)",
    },
    {
      index: "110",
      color: "rgb(0,255,127)",
    },
    {
      index: "120",
      color: "rgb(0,255,191)",
    },
    {
      index: "130",
      color: "rgb(0,255,255)",
    },
    {
      index: "140",
      color: "rgb(0,191,255)",
    },
    {
      index: "150",
      color: "rgb(0,127,255)",
    },
    {
      index: "160",
      color: "rgb(0,63,255)",
    },
    {
      index: "170",
      color: "rgb(0,0,255)",
    },
    {
      index: "180",
      color: "rgb(63,0,255)",
    },
    {
      index: "190",
      color: "rgb(127,0,255)",
    },
    {
      index: "200",
      color: "rgb(191,0,255)",
    },
    {
      index: "210",
      color: "rgb(255,0,255)",
    },
    {
      index: "220",
      color: "rgb(255,0,191)",
    },
    {
      index: "230",
      color: "rgb(255,0,127)",
    },
    {
      index: "240",
      color: "rgb(255,0,63)",
    },
  ],
];

export const ListColorsOddIndex = [
  [
    {
      index: "11",
      color: "rgb(255,127,127)",
    },
    {
      index: "21",
      color: "rgb(255,159,127)",
    },
    {
      index: "31",
      color: "rgb(255,191,127)",
    },
    {
      index: "41",
      color: "rgb(255,223,127)",
    },
    {
      index: "51",
      color: "rgb(255,255,127)",
    },
    {
      index: "61",
      color: "rgb(223,255,127)",
    },
    {
      index: "71",
      color: "rgb(191,255,127)",
    },
    {
      index: "81",
      color: "rgb(159,255,127)",
    },
    {
      index: "91",
      color: "rgb(127,255,127)",
    },
    {
      index: "101",
      color: "rgb(127,255,159)",
    },
    {
      index: "111",
      color: "rgb(127,255,191)",
    },
    {
      index: "121",
      color: "rgb(127,255,223)",
    },
    {
      index: "131",
      color: "rgb(127,255,255)",
    },
    {
      index: "141",
      color: "rgb(127,223,255)",
    },
    {
      index: "151",
      color: "rgb(127,191,255)",
    },
    {
      index: "161",
      color: "rgb(127,159,255)",
    },
    {
      index: "171",
      color: "rgb(127,127,255)",
    },
    {
      index: "181",
      color: "rgb(159,127,255)",
    },
    {
      index: "191",
      color: "rgb(191,127,255)",
    },
    {
      index: "201",
      color: "rgb(223,127,255)",
    },
    {
      index: "211",
      color: "rgb(255,127,255)",
    },
    {
      index: "221",
      color: "rgb(255,127,223)",
    },
    {
      index: "231",
      color: "rgb(255,127,191)",
    },
    {
      index: "241",
      color: "rgb(255,127,159)",
    },
  ],
  [
    {
      index: "13",
      color: "rgb(204,102,102)",
    },
    {
      index: "23",
      color: "rgb(204,127,102)",
    },
    {
      index: "33",
      color: "rgb(204,153,102)",
    },
    {
      index: "43",
      color: "rgb(204,178,102)",
    },
    {
      index: "53",
      color: "rgb(204,204,102)",
    },
    {
      index: "63",
      color: "rgb(178,204,102)",
    },
    {
      index: "73",
      color: "rgb(153,204,102)",
    },
    {
      index: "83",
      color: "rgb(127,204,102)",
    },
    {
      index: "93",
      color: "rgb(102,204,102)",
    },
    {
      index: "103",
      color: "rgb(102,204,127)",
    },
    {
      index: "113",
      color: "rgb(102,204,153)",
    },
    {
      index: "123",
      color: "rgb(102,204,178)",
    },
    {
      index: "133",
      color: "rgb(102,204,204)",
    },
    {
      index: "143",
      color: "rgb(102,178,204)",
    },
    {
      index: "153",
      color: "rgb(102,153,204)",
    },
    {
      index: "163",
      color: "rgb(102,127,204)",
    },
    {
      index: "173",
      color: "rgb(102,102,204)",
    },
    {
      index: "183",
      color: "rgb(127,102,204)",
    },
    {
      index: "193",
      color: "rgb(153,102,204)",
    },
    {
      index: "203",
      color: "rgb(178,102,204)",
    },
    {
      index: "213",
      color: "rgb(204,102,204)",
    },
    {
      index: "223",
      color: "rgb(204,102,178)",
    },
    {
      index: "233",
      color: "rgb(204,102,153)",
    },
    {
      index: "243",
      color: "rgb(204,102,127)",
    },
  ],
  [
    {
      index: "15",
      color: "rgb(153,76,76)",
    },
    {
      index: "25",
      color: "rgb(153,95,76)",
    },
    {
      index: "35",
      color: "rgb(153,114,76)",
    },
    {
      index: "45",
      color: "rgb(153,133,76)",
    },
    {
      index: "55",
      color: "rgb(153,153,76)",
    },
    {
      index: "65",
      color: "rgb(133,153,76)",
    },
    {
      index: "75",
      color: "rgb(114,153,76)",
    },
    {
      index: "85",
      color: "rgb(95,153,76)",
    },
    {
      index: "95",
      color: "rgb(76,153,76)",
    },
    {
      index: "105",
      color: "rgb(76,153,95)",
    },
    {
      index: "115",
      color: "rgb(76,153,114)",
    },
    {
      index: "125",
      color: "rgb(76,153,133)",
    },
    {
      index: "135",
      color: "rgb(76,153,153)",
    },
    {
      index: "145",
      color: "rgb(76,133,153)",
    },
    {
      index: "155",
      color: "rgb(76,114,153)",
    },
    {
      index: "165",
      color: "rgb(76,95,153)",
    },
    {
      index: "175",
      color: "rgb(76,76,153)",
    },
    {
      index: "185",
      color: "rgb(95,76,153)",
    },
    {
      index: "195",
      color: "rgb(114,76,153)",
    },
    {
      index: "205",
      color: "rgb(133,76,153)",
    },
    {
      index: "215",
      color: "rgb(153,76,153)",
    },
    {
      index: "225",
      color: "rgb(153,76,133)",
    },
    {
      index: "235",
      color: "rgb(153,76,114)",
    },
    {
      index: "245",
      color: "rgb(153,76,95)",
    },
  ],
  [
    {
      index: "17",
      color: "rgb(127,63,63)",
    },
    {
      index: "27",
      color: "rgb(127,79,63)",
    },
    {
      index: "37",
      color: "rgb(127,95,63)",
    },
    {
      index: "47",
      color: "rgb(127,111,63)",
    },
    {
      index: "57",
      color: "rgb(127,127,63)",
    },
    {
      index: "67",
      color: "rgb(111,127,63)",
    },
    {
      index: "77",
      color: "rgb(95,127,63)",
    },
    {
      index: "87",
      color: "rgb(79,127,63)",
    },
    {
      index: "97",
      color: "rgb(63,127,63)",
    },
    {
      index: "107",
      color: "rgb(63,127,79)",
    },
    {
      index: "117",
      color: "rgb(63,127,95)",
    },
    {
      index: "127",
      color: "rgb(63,127,111)",
    },
    {
      index: "137",
      color: "rgb(63,127,127)",
    },
    {
      index: "147",
      color: "rgb(63,111,127)",
    },
    {
      index: "157",
      color: "rgb(63,95,127)",
    },
    {
      index: "167",
      color: "rgb(63,79,127)",
    },
    {
      index: "177",
      color: "rgb(63,63,127)",
    },
    {
      index: "187",
      color: "rgb(79,63,127)",
    },
    {
      index: "197",
      color: "rgb(95,63,127)",
    },
    {
      index: "207",
      color: "rgb(111,63,127)",
    },
    {
      index: "217",
      color: "rgb(127,63,127)",
    },
    {
      index: "227",
      color: "rgb(127,63,111)",
    },
    {
      index: "237",
      color: "rgb(127,63,95)",
    },
    {
      index: "247",
      color: "rgb(127,63,79)",
    },
  ],
  [
    {
      index: "19",
      color: "rgb(76,38,38)",
    },
    {
      index: "29",
      color: "rgb(76,47,38)",
    },
    {
      index: "39",
      color: "rgb(76,57,38)",
    },
    {
      index: "49",
      color: "rgb(76,66,38)",
    },
    {
      index: "59",
      color: "rgb(76,76,38)",
    },
    {
      index: "69",
      color: "rgb(66,76,38)",
    },
    {
      index: "79",
      color: "rgb(57,76,38)",
    },
    {
      index: "89",
      color: "rgb(47,76,38)",
    },
    {
      index: "99",
      color: "rgb(38,76,38)",
    },
    {
      index: "109",
      color: "rgb(38,76,47)",
    },
    {
      index: "119",
      color: "rgb(38,76,57)",
    },
    {
      index: "129",
      color: "rgb(38,76,66)",
    },
    {
      index: "139",
      color: "rgb(38,76,76)",
    },
    {
      index: "149",
      color: "rgb(38,66,76)",
    },
    {
      index: "159",
      color: "rgb(38,57,76)",
    },
    {
      index: "169",
      color: "rgb(38,47,76)",
    },
    {
      index: "179",
      color: "rgb(38,38,76)",
    },
    {
      index: "189",
      color: "rgb(47,38,76)",
    },
    {
      index: "199",
      color: "rgb(57,38,76)",
    },
    {
      index: "209",
      color: "rgb(66,38,76)",
    },
    {
      index: "219",
      color: "rgb(76,38,76)",
    },
    {
      index: "229",
      color: "rgb(76,38,66)",
    },
    {
      index: "239",
      color: "rgb(76,38,57)",
    },
    {
      index: "249",
      color: "rgb(76,38,47)",
    },
  ],
];

export const ListAllColorsIndex = [
  {
    index: "1",
    color: "rgb(255,0,0)",
  },
  {
    index: "2",
    color: "rgb(255,255,0)",
  },
  {
    index: "3",
    color: "rgb(0,255,0)",
  },
  {
    index: "4",
    color: "rgb(0,255,255)",
  },
  {
    index: "5",
    color: "rgb(0,0,255)",
  },
  {
    index: "6",
    color: "rgb(255,0,255)",
  },
  {
    index: "7",
    color: "rgb(255,255,255)",
  },
  {
    index: "8",
    color: "rgb(128,128,128)",
  },
  {
    index: "9",
    color: "rgb(192,192,192)",
  },
  {
    index: "10",
    color: "rgb(255,0,0)",
  },
  {
    index: "11",
    color: "rgb(255,127,127)",
  },
  {
    index: "12",
    color: "rgb(204,0,0)",
  },
  {
    index: "13",
    color: "rgb(204,102,102)",
  },
  {
    index: "14",
    color: "rgb(153,0,0)",
  },
  {
    index: "15",
    color: "rgb(153,76,76)",
  },
  {
    index: "16",
    color: "rgb(127,0,0)",
  },
  {
    index: "17",
    color: "rgb(127,63,63)",
  },
  {
    index: "18",
    color: "rgb(76,0,0)",
  },
  {
    index: "19",
    color: "rgb(76,38,38)",
  },
  {
    index: "20",
    color: "rgb(255,63,0)",
  },
  {
    index: "21",
    color: "rgb(255,159,127)",
  },
  {
    index: "22",
    color: "rgb(204,51,0)",
  },
  {
    index: "23",
    color: "rgb(204,127,102)",
  },
  {
    index: "24",
    color: "rgb(153,38,0)",
  },
  {
    index: "25",
    color: "rgb(153,95,76)",
  },
  {
    index: "26",
    color: "rgb(127,31,0)",
  },
  {
    index: "27",
    color: "rgb(127,79,63)",
  },
  {
    index: "28",
    color: "rgb(76,19,0)",
  },
  {
    index: "29",
    color: "rgb(76,47,38)",
  },
  {
    index: "30",
    color: "rgb(255,127,0)",
  },
  {
    index: "31",
    color: "rgb(255,191,127)",
  },
  {
    index: "32",
    color: "rgb(204,102,0)",
  },
  {
    index: "33",
    color: "rgb(204,153,102)",
  },
  {
    index: "34",
    color: "rgb(153,76,0)",
  },
  {
    index: "35",
    color: "rgb(153,114,76)",
  },
  {
    index: "36",
    color: "rgb(127,63,0)",
  },
  {
    index: "37",
    color: "rgb(127,95,63)",
  },
  {
    index: "38",
    color: "rgb(76,38,0)",
  },
  {
    index: "39",
    color: "rgb(76,57,38)",
  },
  {
    index: "40",
    color: "rgb(255,191,0)",
  },
  {
    index: "41",
    color: "rgb(255,223,127)",
  },
  {
    index: "42",
    color: "rgb(204,153,0)",
  },
  {
    index: "43",
    color: "rgb(204,178,102)",
  },
  {
    index: "44",
    color: "rgb(153,114,0)",
  },
  {
    index: "45",
    color: "rgb(153,133,76)",
  },
  {
    index: "46",
    color: "rgb(127,95,0)",
  },
  {
    index: "47",
    color: "rgb(127,111,63)",
  },
  {
    index: "48",
    color: "rgb(76,57,0)",
  },
  {
    index: "49",
    color: "rgb(76,66,38)",
  },
  {
    index: "50",
    color: "rgb(255,255,0)",
  },
  {
    index: "51",
    color: "rgb(255,255,127)",
  },
  {
    index: "52",
    color: "rgb(204,204,0)",
  },
  {
    index: "53",
    color: "rgb(204,204,102)",
  },
  {
    index: "54",
    color: "rgb(153,153,0)",
  },
  {
    index: "55",
    color: "rgb(153,153,76)",
  },
  {
    index: "56",
    color: "rgb(127,127,0)",
  },
  {
    index: "57",
    color: "rgb(127,127,63)",
  },
  {
    index: "58",
    color: "rgb(76,76,0)",
  },
  {
    index: "59",
    color: "rgb(76,76,38)",
  },
  {
    index: "60",
    color: "rgb(191,255,0)",
  },
  {
    index: "61",
    color: "rgb(223,255,127)",
  },
  {
    index: "62",
    color: "rgb(153,204,0)",
  },
  {
    index: "63",
    color: "rgb(178,204,102)",
  },
  {
    index: "64",
    color: "rgb(114,153,0)",
  },
  {
    index: "65",
    color: "rgb(133,153,76)",
  },
  {
    index: "66",
    color: "rgb(95,127,0)",
  },
  {
    index: "67",
    color: "rgb(111,127,63)",
  },
  {
    index: "68",
    color: "rgb(57,76,0)",
  },
  {
    index: "69",
    color: "rgb(66,76,38)",
  },
  {
    index: "70",
    color: "rgb(127,255,0)",
  },
  {
    index: "71",
    color: "rgb(191,255,127)",
  },
  {
    index: "72",
    color: "rgb(102,204,0)",
  },
  {
    index: "73",
    color: "rgb(153,204,102)",
  },
  {
    index: "74",
    color: "rgb(76,153,0)",
  },
  {
    index: "75",
    color: "rgb(114,153,76)",
  },
  {
    index: "76",
    color: "rgb(63,127,0)",
  },
  {
    index: "77",
    color: "rgb(95,127,63)",
  },
  {
    index: "78",
    color: "rgb(38,76,0)",
  },
  {
    index: "79",
    color: "rgb(57,76,38)",
  },
  {
    index: "80",
    color: "rgb(63,255,0)",
  },
  {
    index: "81",
    color: "rgb(159,255,127)",
  },
  {
    index: "82",
    color: "rgb(51,204,0)",
  },
  {
    index: "83",
    color: "rgb(127,204,102)",
  },
  {
    index: "84",
    color: "rgb(38,153,0)",
  },
  {
    index: "85",
    color: "rgb(95,153,76)",
  },
  {
    index: "86",
    color: "rgb(31,127,0)",
  },
  {
    index: "87",
    color: "rgb(79,127,63)",
  },
  {
    index: "88",
    color: "rgb(19,76,0)",
  },
  {
    index: "89",
    color: "rgb(47,76,38)",
  },
  {
    index: "90",
    color: "rgb(0,255,0)",
  },
  {
    index: "91",
    color: "rgb(127,255,127)",
  },
  {
    index: "92",
    color: "rgb(0,204,0)",
  },
  {
    index: "93",
    color: "rgb(102,204,102)",
  },
  {
    index: "94",
    color: "rgb(0,153,0)",
  },
  {
    index: "95",
    color: "rgb(76,153,76)",
  },
  {
    index: "96",
    color: "rgb(0,127,0)",
  },
  {
    index: "97",
    color: "rgb(63,127,63)",
  },
  {
    index: "98",
    color: "rgb(0,76,0)",
  },
  {
    index: "99",
    color: "rgb(38,76,38)",
  },
  {
    index: "100",
    color: "rgb(0,255,63)",
  },
  {
    index: "101",
    color: "rgb(127,255,159)",
  },
  {
    index: "102",
    color: "rgb(0,204,51)",
  },
  {
    index: "103",
    color: "rgb(102,204,127)",
  },
  {
    index: "104",
    color: "rgb(0,153,38)",
  },
  {
    index: "105",
    color: "rgb(76,153,95)",
  },
  {
    index: "106",
    color: "rgb(0,127,31)",
  },
  {
    index: "107",
    color: "rgb(63,127,79)",
  },
  {
    index: "108",
    color: "rgb(0,76,19)",
  },
  {
    index: "109",
    color: "rgb(38,76,47)",
  },
  {
    index: "110",
    color: "rgb(0,255,127)",
  },
  {
    index: "111",
    color: "rgb(127,255,191)",
  },
  {
    index: "112",
    color: "rgb(0,204,102)",
  },
  {
    index: "113",
    color: "rgb(102,204,153)",
  },
  {
    index: "114",
    color: "rgb(0,153,76)",
  },
  {
    index: "115",
    color: "rgb(76,153,114)",
  },
  {
    index: "116",
    color: "rgb(0,127,63)",
  },
  {
    index: "117",
    color: "rgb(63,127,95)",
  },
  {
    index: "118",
    color: "rgb(0,76,38)",
  },
  {
    index: "119",
    color: "rgb(38,76,57)",
  },
  {
    index: "120",
    color: "rgb(0,255,191)",
  },
  {
    index: "121",
    color: "rgb(127,255,223)",
  },
  {
    index: "122",
    color: "rgb(0,204,153)",
  },
  {
    index: "123",
    color: "rgb(102,204,178)",
  },
  {
    index: "124",
    color: "rgb(0,153,114)",
  },
  {
    index: "125",
    color: "rgb(76,153,133)",
  },
  {
    index: "126",
    color: "rgb(0,127,95)",
  },
  {
    index: "127",
    color: "rgb(63,127,111)",
  },
  {
    index: "128",
    color: "rgb(0,76,57)",
  },
  {
    index: "129",
    color: "rgb(38,76,66)",
  },
  {
    index: "130",
    color: "rgb(0,255,255)",
  },
  {
    index: "131",
    color: "rgb(127,255,255)",
  },
  {
    index: "132",
    color: "rgb(0,204,204)",
  },
  {
    index: "133",
    color: "rgb(102,204,204)",
  },
  {
    index: "134",
    color: "rgb(0,153,153)",
  },
  {
    index: "135",
    color: "rgb(76,153,153)",
  },
  {
    index: "136",
    color: "rgb(0,127,127)",
  },
  {
    index: "137",
    color: "rgb(63,127,127)",
  },
  {
    index: "138",
    color: "rgb(0,76,76)",
  },
  {
    index: "139",
    color: "rgb(38,76,76)",
  },
  {
    index: "140",
    color: "rgb(0,191,255)",
  },
  {
    index: "141",
    color: "rgb(127,223,255)",
  },
  {
    index: "142",
    color: "rgb(0,153,204)",
  },
  {
    index: "143",
    color: "rgb(102,178,204)",
  },
  {
    index: "144",
    color: "rgb(0,114,153)",
  },
  {
    index: "145",
    color: "rgb(76,133,153)",
  },
  {
    index: "146",
    color: "rgb(0,95,127)",
  },
  {
    index: "147",
    color: "rgb(63,111,127)",
  },
  {
    index: "148",
    color: "rgb(0,57,76)",
  },
  {
    index: "149",
    color: "rgb(38,66,76)",
  },
  {
    index: "150",
    color: "rgb(0,127,255)",
  },
  {
    index: "151",
    color: "rgb(127,191,255)",
  },
  {
    index: "152",
    color: "rgb(0,102,204)",
  },
  {
    index: "153",
    color: "rgb(102,153,204)",
  },
  {
    index: "154",
    color: "rgb(0,76,153)",
  },
  {
    index: "155",
    color: "rgb(76,114,153)",
  },
  {
    index: "156",
    color: "rgb(0,63,127)",
  },
  {
    index: "157",
    color: "rgb(63,95,127)",
  },
  {
    index: "158",
    color: "rgb(0,38,76)",
  },
  {
    index: "159",
    color: "rgb(38,57,76)",
  },
  {
    index: "160",
    color: "rgb(0,63,255)",
  },
  {
    index: "161",
    color: "rgb(127,159,255)",
  },
  {
    index: "162",
    color: "rgb(0,51,204)",
  },
  {
    index: "163",
    color: "rgb(102,127,204)",
  },
  {
    index: "164",
    color: "rgb(0,38,153)",
  },
  {
    index: "165",
    color: "rgb(76,95,153)",
  },
  {
    index: "166",
    color: "rgb(0,31,127)",
  },
  {
    index: "167",
    color: "rgb(63,79,127)",
  },
  {
    index: "168",
    color: "rgb(0,19,76)",
  },
  {
    index: "169",
    color: "rgb(38,47,76)",
  },
  {
    index: "170",
    color: "rgb(0,0,255)",
  },
  {
    index: "171",
    color: "rgb(127,127,255)",
  },
  {
    index: "172",
    color: "rgb(0,0,204)",
  },
  {
    index: "173",
    color: "rgb(102,102,204)",
  },
  {
    index: "174",
    color: "rgb(0,0,153)",
  },
  {
    index: "175",
    color: "rgb(76,76,153)",
  },
  {
    index: "176",
    color: "rgb(0,0,127)",
  },
  {
    index: "177",
    color: "rgb(63,63,127)",
  },
  {
    index: "178",
    color: "rgb(0,0,76)",
  },
  {
    index: "179",
    color: "rgb(38,38,76)",
  },
  {
    index: "180",
    color: "rgb(63,0,255)",
  },
  {
    index: "181",
    color: "rgb(159,127,255)",
  },
  {
    index: "182",
    color: "rgb(51,0,204)",
  },
  {
    index: "183",
    color: "rgb(127,102,204)",
  },
  {
    index: "184",
    color: "rgb(38,0,153)",
  },
  {
    index: "185",
    color: "rgb(95,76,153)",
  },
  {
    index: "186",
    color: "rgb(31,0,127)",
  },
  {
    index: "187",
    color: "rgb(79,63,127)",
  },
  {
    index: "188",
    color: "rgb(19,0,76)",
  },
  {
    index: "189",
    color: "rgb(47,38,76)",
  },
  {
    index: "190",
    color: "rgb(127,0,255)",
  },
  {
    index: "191",
    color: "rgb(191,127,255)",
  },
  {
    index: "192",
    color: "rgb(102,0,204)",
  },
  {
    index: "193",
    color: "rgb(153,102,204)",
  },
  {
    index: "194",
    color: "rgb(76,0,153)",
  },
  {
    index: "195",
    color: "rgb(114,76,153)",
  },
  {
    index: "196",
    color: "rgb(63,0,127)",
  },
  {
    index: "197",
    color: "rgb(95,63,127)",
  },
  {
    index: "198",
    color: "rgb(38,0,76)",
  },
  {
    index: "199",
    color: "rgb(57,38,76)",
  },
  {
    index: "200",
    color: "rgb(191,0,255)",
  },
  {
    index: "201",
    color: "rgb(223,127,255)",
  },
  {
    index: "202",
    color: "rgb(153,0,204)",
  },
  {
    index: "203",
    color: "rgb(178,102,204)",
  },
  {
    index: "204",
    color: "rgb(114,0,153)",
  },
  {
    index: "205",
    color: "rgb(133,76,153)",
  },
  {
    index: "206",
    color: "rgb(95,0,127)",
  },
  {
    index: "207",
    color: "rgb(111,63,127)",
  },
  {
    index: "208",
    color: "rgb(57,0,76)",
  },
  {
    index: "209",
    color: "rgb(66,38,76)",
  },
  {
    index: "210",
    color: "rgb(255,0,255)",
  },
  {
    index: "211",
    color: "rgb(255,127,255)",
  },
  {
    index: "212",
    color: "rgb(204,0,204)",
  },
  {
    index: "213",
    color: "rgb(204,102,204)",
  },
  {
    index: "214",
    color: "rgb(153,0,153)",
  },
  {
    index: "215",
    color: "rgb(153,76,153)",
  },
  {
    index: "216",
    color: "rgb(127,0,127)",
  },
  {
    index: "217",
    color: "rgb(127,63,127)",
  },
  {
    index: "218",
    color: "rgb(76,0,76)",
  },
  {
    index: "219",
    color: "rgb(76,38,76)",
  },
  {
    index: "220",
    color: "rgb(255,0,191)",
  },
  {
    index: "221",
    color: "rgb(255,127,223)",
  },
  {
    index: "222",
    color: "rgb(204,0,153)",
  },
  {
    index: "223",
    color: "rgb(204,102,178)",
  },
  {
    index: "224",
    color: "rgb(153,0,114)",
  },
  {
    index: "225",
    color: "rgb(153,76,133)",
  },
  {
    index: "226",
    color: "rgb(127,0,95)",
  },
  {
    index: "227",
    color: "rgb(127,63,111)",
  },
  {
    index: "228",
    color: "rgb(76,0,57)",
  },
  {
    index: "229",
    color: "rgb(76,38,66)",
  },
  {
    index: "230",
    color: "rgb(255,0,127)",
  },
  {
    index: "231",
    color: "rgb(255,127,191)",
  },
  {
    index: "232",
    color: "rgb(204,0,102)",
  },
  {
    index: "233",
    color: "rgb(204,102,153)",
  },
  {
    index: "234",
    color: "rgb(153,0,76)",
  },
  {
    index: "235",
    color: "rgb(153,76,114)",
  },
  {
    index: "236",
    color: "rgb(127,0,63)",
  },
  {
    index: "237",
    color: "rgb(127,63,95)",
  },
  {
    index: "238",
    color: "rgb(76,0,38)",
  },
  {
    index: "239",
    color: "rgb(76,38,57)",
  },
  {
    index: "240",
    color: "rgb(255,0,63)",
  },
  {
    index: "241",
    color: "rgb(255,127,159)",
  },
  {
    index: "242",
    color: "rgb(204,0,51)",
  },
  {
    index: "243",
    color: "rgb(204,102,127)",
  },
  {
    index: "244",
    color: "rgb(153,0,38)",
  },
  {
    index: "245",
    color: "rgb(153,76,95)",
  },
  {
    index: "246",
    color: "rgb(127,0,31)",
  },
  {
    index: "247",
    color: "rgb(127,63,79)",
  },
  {
    index: "248",
    color: "rgb(76,0,19)",
  },
  {
    index: "249",
    color: "rgb(76,38,47)",
  },
  {
    index: "250",
    color: "rgb(51,51,51)",
  },
  {
    index: "251",
    color: "rgb(91,91,91)",
  },
  {
    index: "252",
    color: "rgb(132,132,132)",
  },
  {
    index: "253",
    color: "rgb(173,173,173)",
  },
  {
    index: "254",
    color: "rgb(214,214,214)",
  },
  {
    index: "255",
    color: "rgb(255,255,255)",
  },
];

export const ListColorsOther = [
  [
    {
      index: "1",
      color: "rgb(255,0,0)",
    },
    {
      index: "2",
      color: "rgb(255,255,0)",
    },
    {
      index: "3",
      color: "rgb(0,255,0)",
    },
    {
      index: "4",
      color: "rgb(0,255,255)",
    },
    {
      index: "5",
      color: "rgb(0,0,255)",
    },
    {
      index: "6",
      color: "rgb(255,0,255)",
    },
    {
      index: "7",
      color: "rgb(255,255,255)",
    },
    {
      index: "8",
      color: "rgb(128,128,128)",
    },
    {
      index: "9",
      color: "rgb(192,192,192)",
    },
  ],
  [
    {
      index: "250",
      color: "rgb(51,51,51)",
    },
    {
      index: "251",
      color: "rgb(91,91,91)",
    },
    {
      index: "252",
      color: "rgb(132,132,132)",
    },
    {
      index: "253",
      color: "rgb(173,173,173)",
    },
    {
      index: "254",
      color: "rgb(214,214,214)",
    },
    {
      index: "255",
      color: "rgb(255,255,255)",
    },
  ],
];
