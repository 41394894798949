import React, { useState, useRef, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import styles from './ColorPicker.module.scss'
import { useDispatch } from 'react-redux'
import { showModalSlice } from 'C_REDUX_STORE/scheduleTool/showModalSlice'
import {
  addColorInBackgroundColorList,
  addColorInTaskColorList,
  modifyColorInBackgroundColorList,
  modifyColorInTaskColorList
} from 'C_REDUX_STORE/scheduleTool/versionSettingSlice'
import { nanoid } from '@reduxjs/toolkit'

export default function ColorPicker({ event, id, nameColor, isTaskColor }) {
  const [color, setColor] = useState('#fff')
  const tooltipRef = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    tooltipRef.current.style.top = `${event.clientY}px`
    tooltipRef.current.style.left = `${event.clientX}px`
  })

  const handleChangeComplete = (color) => {
    setColor(color.hex)
  }
  const handleOkClick = async () => {
    if (isTaskColor) {
      if (id !== -1) {
        await dispatch(modifyColorInTaskColorList({ id: id, color: color }))
      } else {
        await dispatch(
          addColorInTaskColorList({
            name: nameColor,
            color: color,
            id: nanoid()
          })
        )
      }
    } else {
      if (id !== -1) {
        await dispatch(modifyColorInBackgroundColorList({ id: id, color: color }))
      } else {
        await dispatch(
          addColorInBackgroundColorList({
            name: nameColor,
            color: color,
            id: nanoid()
          })
        )
      }
    }
    dispatch(showModalSlice.actions.HideWindowChild())
  }
  const handleCancelClick = () => {
    dispatch(showModalSlice.actions.HideWindowChild())
  }
  return (
    <div ref={tooltipRef} className={styles.wrapper}>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} disableAlpha={true} />
      <div className={styles.button_container}>
        <button className='btn-primary' onClick={handleOkClick}>
          OK
        </button>
        <button className='btn-secondary' onClick={handleCancelClick}>
          キャンセル
        </button>
      </div>
    </div>
  )
}
