import React, { useRef, useEffect, useState } from 'react'
import { GridProps, Grid } from '../grid/grid'
import { CalendarProps, Calendar } from '../calendar/calendar'
import { TaskGanttContentProps, TaskGanttContent } from './task-gantt-content'
import styles from './gantt.module.css'
import { useSelector } from 'react-redux'
import { RootState } from 'C_REDUX_STORE/RootState'
import { uniq } from 'lodash'

export type TaskGanttProps = {
  gridProps: GridProps
  calendarProps: CalendarProps
  barProps: TaskGanttContentProps
  ganttHeight: number
  scrollY: number
  scrollX: number
}
export const TaskGantt: React.FC<TaskGanttProps> = ({
  gridProps,
  calendarProps,
  barProps,
  ganttHeight,
  scrollY,
  scrollX
}) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null)
  const horizontalContainerRef = useRef<HTMLDivElement>(null)
  const verticalGanttContainerRef = useRef<HTMLDivElement>(null)
  const newBarProps = { ...barProps, svg: ganttSVGRef }
  const [rowCount, setRowCount] = useState<number>(0)
  const versionSetting = useSelector((state: RootState) => state.versionSetting.current)
  const month = versionSetting.numberOfMonths
  const svgWidth = gridProps.columnWidth * month
  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY
    }
  }, [scrollY])

  useEffect(() => {
    if (verticalGanttContainerRef.current) {
      verticalGanttContainerRef.current.scrollLeft = scrollX
    }
  }, [scrollX])
  useEffect(() => {
    var copyTasks = [...barProps.tasks]
    //lấy tất các task có groupid !== undefined nghĩa là lấy các task có k phải project
    var listTaskHaveParentId = copyTasks.filter((x) => x.parentTaskId !== undefined)
    //lấy tất các task có groupid !== undefined nghĩa là lấy các task có k phải project
    var listParentId = listTaskHaveParentId.map(function (obj) {
      return obj.parentTaskId
    })
    // lấy ra các task có subtask
    // thuật toán => lọc ra task có isSubStepWork => lọc ra parentId để tránh lặp lại
    const countTaskIsSubStepWorks = uniq(
      copyTasks.filter((task) => task?.isSubStepWork).map((task) => task.parentTaskId)
    )
    if (listParentId === undefined) {
      setRowCount(copyTasks.length + countTaskIsSubStepWorks.length)
    } else {
      var ids: string[] = []
      for (let index = 0; index < listParentId.length; index++) {
        const element = listParentId[index]
        if (element === undefined) {
          continue
        }
        if (ids.includes(element)) {
          continue
        } else {
          ids.push(element)
        }
      }
      // tổng số task trừ đi
      setRowCount(copyTasks.length - (listParentId.length - ids.length) + countTaskIsSubStepWorks.length)
    }
  }, [barProps.tasks])

  return (
    <div className={styles.ganttVerticalContainer} ref={verticalGanttContainerRef} dir='ltr'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={svgWidth || 100}
        height={calendarProps.headerHeight + 1}
        fontFamily={barProps.fontFamily}
      >
        <Calendar {...calendarProps} />
      </svg>
      <div style={{ height: '1px', width: `${svgWidth || 100}px`, borderBottom: '2px solid rgb(76, 100, 157)' }}></div>
      <div
        ref={horizontalContainerRef}
        className={styles.horizontalContainer}
        style={
          ganttHeight
            ? { height: ganttHeight, width: svgWidth || 100, minWidth: svgWidth || 100, maxWidth: svgWidth || 100 }
            : { width: svgWidth || 100, minWidth: svgWidth || 100, maxWidth: svgWidth || 100 }
        }
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={svgWidth || 100}
          height={barProps.rowHeight * rowCount}
          fontFamily={barProps.fontFamily}
          ref={ganttSVGRef}
        >
          <Grid {...gridProps} gridHeight={barProps.rowHeight * rowCount} barTasks={barProps.tasks} />
          <TaskGanttContent {...newBarProps} />
        </svg>
      </div>
    </div>
  )
}
