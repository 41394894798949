import TextInput from 'A_SHARED_COMPONENT/TextInput'
import React, { useEffect } from 'react'
import DropDown from '../DropDown'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checkCanAddPredecessor } from 'Z_SERVICES/j_MainChart/grant-base/helpers/other-helper'

export default function PredecessorRow({ task, tasks, predecessor, setPredecessors }) {
  const [listTasksName, setListTasksName] = React.useState([])
  const [listStepWork, setListStepWork] = React.useState([])

  const [taskDropdown, setTaskDropdown] = React.useState()
  const [stepWork, setStepWork] = React.useState()
  const [predecessorType, setPredecessorType] = React.useState()
  const [lagDays, setLagDays] = React.useState(predecessor?.lagDays || 0)
  const stepWorkColors = useSelector((state) => state.versionSetting.current.stepworkColors)
  const [isTaskError, setTaskError] = React.useState(false)
  const predecessorsType = [
    { name: 'Finish-to-start (FS)', id: 'FS' },
    { name: 'Start-to-start (SS)', id: 'SS' },
    { name: 'Finish-to-finish (FF)', id: 'FF' }
  ]

  useEffect(() => {
    let listTasksName2 = []
    tasks?.forEach((t) => {
      // if (t.type === 'task' && task.id !== t.id && task.parentTaskId !== t.id) {
      if (t.type === 'task' && task.id !== t.id) {
        const groupTaskName = tasks.find((x) => x.type === 'project' && x.id === t.groupId).name
        listTasksName2.push({ name: `${t.name} (${groupTaskName})`, id: t.id })
      }
    })
    setListTasksName(listTasksName2)
  }, [tasks])

  useEffect(() => {
    if (taskDropdown) {
      const groupTaskName = tasks.find((x) => x.id === taskDropdown?.id)
      if (groupTaskName) {
        var listData = groupTaskName?.stepworks?.map((t) => {
          const color = stepWorkColors?.find((c) => c.colorId === Number(t.colorId))
          if (color) {
            return { name: color.name, id: t.id, code: color.code, percentStepWork: t.percentStepWork }
          }
        })
        setListStepWork(listData)

        if (groupTaskName?.stepworks) {
          if (predecessor) {
            let predecessorTask

            tasks.forEach((t) => {
              if (t?.stepworks) {
                const fondTask = t?.stepworks.find((x) => x.id === predecessor.id)
                if (fondTask) {
                  predecessorTask = fondTask
                }
              } else {
                if (t.id === predecessor.id) {
                  predecessorTask = t
                }
              }
            })

            const color = stepWorkColors?.find((c) => c.colorId === Number(predecessorTask?.colorId))
            if (color) {
              setStepWork({
                name: color.name,
                id: predecessorTask.id,
                code: color.code,
                percentStepWork: predecessorTask.percentStepWork
              })
            }

            const fondType = predecessorsType.find((x) => x.id === predecessor.type)
            if (fondType) {
              setPredecessorType(fondType)
            }
          }
        }
      }
    }
  }, [taskDropdown])

  useEffect(() => {
    if (predecessor) {
      let parentTaskId

      tasks.forEach((t) => {
        if (t?.stepworks) {
          const fondTask = t?.stepworks.find((x) => x.id === predecessor.id)
          if (fondTask) {
            parentTaskId = t.id
          }
        } else {
          if (t.id === predecessor.id) {
            parentTaskId = t.id
          }
        }
      })

      const value = listTasksName?.find((x) => x.id === parentTaskId) || ''

      setTaskDropdown(value)
      const fondType = predecessorsType.find((x) => x.id === predecessor.type)
      if (fondType) {
        setPredecessorType(fondType)
      }
      // const color = stepWorkColors?.find((c) => c.colorId === Number(predecessorTask?.colorId))
      // if (color) {
      //   setStepWork({ name: color.name, id: predecessorTask.id, code: color.code })
      // }

      // const fondType = predecessorsType.find((x) => x.id === predecessor.type)
      // if (fondType) {
      //   setPredecessorType(fondType)
      // }
    }
  }, [predecessor, listTasksName])

  const handleTaskChange = (selectedValue) => {
    if (!checkCanAddPredecessor(tasks, task.id, selectedValue.id)) {
      toast.error('リンクが無効です。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      setTaskError(true)
      return
    }
    setTaskDropdown(selectedValue)
    const findIndex = tasks.find((x) => x.id === selectedValue.id)
    if (findIndex.stepworks) {
      return
    }
    if (predecessor) {
      setPredecessors((prev) => {
        let copyPrev = [...prev]
        const indexTask = copyPrev.find((x) => x.id === selectedValue?.id)
        //tìm kiếm selected value có tồn tại trong predecessors hay không
        // có thì return
        if (indexTask) {
          toast.error('タスクは既に存在します。もう一度確認てください。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
          setTaskError(true)
          return prev
        } else {
          setTaskError(false)
          // không có thì thay mới.
          const newPrev = copyPrev.map((p) => {
            if (p.id === predecessor?.id) {
              return { ...p, id: selectedValue.id }
            } else {
              return p
            }
          })
          return newPrev
        }
      })
    } else {
      setPredecessors((prev) => {
        if (!prev || prev?.length === 0) {
          return [{ type: 'FS', id: selectedValue?.id, lagDays: 0 }]
        }
        let copyPrev = [...prev]
        const indexTask = copyPrev.find((x) => x.id === selectedValue?.id)
        //tìm kiếm selected value có tồn tại trong predecessors hay không
        // có thì return
        if (indexTask) {
          toast.error('タスクは既に存在します。もう一度確認てください。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
          setTaskError(true)
          return prev
        } else {
          setTaskError(false)
          return prev
        }
      })
    }
    setStepWork('')
  }

  const handleChangeStepWork = (selectedStepWork) => {
    if (!checkCanAddPredecessor(tasks, task.id, selectedStepWork.id)) {
      toast.error('リンクが無効です。', {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      setTaskError(true)
      return
    }
    if (predecessor) {
      setPredecessors((prev) => {
        let copyPrev = [...prev]
        const indexTask = copyPrev.find((x) => x.id === selectedStepWork?.id)
        //Kiểm tra xem step work có tồn tại trong predecessors hay không
        // có thì return không thay đổi gì cả
        if (indexTask) {
          toast.error('タスクは既に存在します。もう一度確認てください。', {
            position: 'bottom-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored'
          })
          setTaskError(true)
          return prev
        } else {
          setTaskError(false)
          // không có thì ta sẽ update
          const newPrev = copyPrev.map((p) => {
            if (p.id === predecessor?.id) {
              return { ...p, id: selectedStepWork.id }
            } else {
              return p
            }
          })
          return newPrev
        }
      })
    }
    setStepWork(selectedStepWork)
  }

  const handleChangeType = (selectedType) => {
    setPredecessorType(selectedType)
    // nếu có predecessor có nghĩa là mình sửa type
    if (predecessor) {
      setPredecessors((prev) => {
        let copyPrev = [...prev]
        const newPrev = copyPrev.map((p) => {
          if (p.id === predecessor?.id) {
            return { ...p, type: selectedType.id }
          } else {
            return p
          }
        })
        return newPrev
      })
    } else {
      if (stepWork) {
        setPredecessors((prev) => {
          let copyPrev = [...prev]
          const indexTask = copyPrev.find((x) => x.id === stepWork?.id)
          if (indexTask) {
            toast.error('タスクは既に存在します。もう一度確認てください。', {
              position: 'bottom-center',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored'
            })
            setTaskError(true)
            return prev
          }
          setTaskError(false)
          copyPrev.push({ type: selectedType.id, id: stepWork.id, lagDays: lagDays })
          return copyPrev
        })
      } else {
        setTaskError(false)
        // trường hợp 1: k có stepwork thì add stepwork.id
        setPredecessors((prev) => {
          let copyPrev = [...prev]
          const indexTask = copyPrev.find((x) => x.id === taskDropdown?.id)
          if (indexTask) {
            toast.error('タスクは既に存在します。もう一度確認てください。', {
              position: 'bottom-center',
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored'
            })
            setTaskError(true)
            return prev
          }
          setTaskError(false)
          copyPrev.push({ type: selectedType.id, id: taskDropdown.id, lagDays: lagDays })
          return copyPrev
        })
      }
    }
  }

  const handleChangeLagDays = (lagDaysInput) => {
    setLagDays(lagDaysInput)
    // chỉ có trường hợp sửa
    if (predecessor) {
      if (!isNaN(Number(lagDaysInput))) {
        setPredecessors((prev) => {
          let copyPrev = [...prev]
          const newPrev = copyPrev.map((p) => {
            if (p.id === predecessor?.id) {
              return { ...p, lagDays: Number(lagDaysInput) }
            } else {
              return p
            }
          })
          return newPrev
        })
      } else {
        toast.error('入力さた日が間違っています。もう一度確認てください。', {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }
    }
  }

  const handleDelete = () => {
    if (predecessor) {
      setPredecessors((prev) => {
        let copyPrev = [...prev]
        const newPredecessors = copyPrev.filter((x) => x.id !== predecessor.id)
        return newPredecessors
      })
    }
  }
  return (
    <>
      <tr style={{ height: '30px' }}>
        <td style={{ height: '30px' }}>
          <DropDown
            listItems={listTasksName}
            value={taskDropdown}
            setValueChange={handleTaskChange}
            placeholder={'タスク選択'}
            isError={isTaskError}
          ></DropDown>
        </td>
        <td style={{ height: '30px' }}>
          <DropDown
            listItems={listStepWork}
            value={stepWork}
            setValueChange={handleChangeStepWork}
            placeholder={listStepWork?.length ? '選択' : 'データなし'}
            isDisable={!Boolean(taskDropdown)}
            isError={isTaskError}
          ></DropDown>
        </td>
        <td style={{ height: '30px' }}>
          <DropDown
            listItems={predecessorsType}
            value={predecessorType}
            setValueChange={handleChangeType}
            placeholder={'リンク種類を選択'}
            isDisable={!Boolean(taskDropdown)}
          ></DropDown>
        </td>
        <td style={{ height: '30px' }}>
          <div>
            <TextInput
              value={lagDays}
              onChange={handleChangeLagDays}
              style={{ textAlign: 'center', width: '50px' }}
              disable={!Boolean(predecessorType)}
            ></TextInput>
            <span className='fas fa-sharp fa-trash' style={{ cursor: 'pointer' }} onClick={handleDelete}></span>
          </div>
        </td>
      </tr>
    </>
  )
}
