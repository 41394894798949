import React from 'react'
import ReactDOM from 'react-dom'
import styles from './DownloadProcessModal.module.scss'

export default function DownloadProcessModal({
  open = false,
  done,
  handleClose = () => {},
  messageCompleted,
  messageProgress
}) {
  if (typeof document === 'undefined') return <div className={styles.modal_overlay}></div>
  if (open) {
    return ReactDOM.createPortal(
      <div className={styles.modal_overlay} style={{ zIndex: 101 }}>
        {!done ? (
          <div className={styles.modal_container}>
            <h3 className={styles.modal_header}>ファイルを処理しています。</h3>
            <p className={styles.modal_message}>{messageProgress}</p>
            <div className='progress'></div>
          </div>
        ) : (
          <div className={styles.modal_container}>
            {messageCompleted === '' ? (
              <h3 className={styles.modal_header}>Process completed!</h3>
            ) : (
              <h3 className={styles.modal_header}>{messageCompleted}</h3>
            )}
            <button className={styles.modal_button} onClick={handleClose}>
              OK
            </button>
          </div>
        )}
      </div>,
      document.querySelector('body')
    )
  }
}
