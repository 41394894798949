import StorageKeys from "../F_UTILS/Constants/StorageKeys";
import axiosClient from "./axiosClient";

const AuthApi = {
  CheckOnline(data) {
    const url = "authentication/check-online";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },

  Login(data) {
    const url = "authentication/login";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  RefreshToken(data) {
    const url = "/authentication/refresh";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  Logout(data) {
    const url = "authentication/logout";
    return axiosClient.request({
      method: "POST",
      url: url,
      data: data,
    });
  },
  GetUserCredentialDetail() {
    const url = `users/detail`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },

  GetAllUsers() {
    const url = `users`;
    return axiosClient.request({
      method: "GET",
      url: url,
    });
  },
};

export default AuthApi;
