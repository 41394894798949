import axiosClientSchedule from './axiosClientSchedule'

const VersionApi = {
  async GetDeactiveProjectsByPage(pageNumber, pageSize) {
    const url = `/projects/deactive-projects?pageNumber=${pageNumber}&pageSize=${pageSize}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },
  async GetDeactiveSharedProjectsByPage(pageNumber, pageSize) {
    const url = `/projects/shared/deactive-projects?pageNumber=${pageNumber}&pageSize=${pageSize}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  async SendVersionToOtherProject(data) {
    const url = `/versions/send-to-another-project`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url,
      data: data
    })
  },
  async UpdateVersionNameById(projectId, data) {
    const url = `/versions/${projectId}`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  },
  async DeactiveVersions(data) {
    const url = `/versions/deactive-versions`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  },

  async DeleteVersions(data) {
    const url = `/versions/delete-versions`
    return axiosClientSchedule.request({
      method: 'DELETE',
      url: url,
      data: data
    })
  },

  async RestoreVersions(data) {
    const url = `/versions/activate-versions`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: data
    })
  },

  async DuplicateVersion(projectId, versionId) {
    const url = `/projects/${projectId}/versions/${versionId}/duplicate`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url
    })
  },

  //main scheduling

  //GetProjectsSettingById
  async GetVersionSettingById(versionId) {
    const url = `/versions/${versionId}/settings`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },

  //UpdateProjectsSettingById
  async UpdateVersionSettingById(versionId, params) {
    const url = `/versions/${versionId}/settings`
    return axiosClientSchedule.request({
      method: 'PUT',
      url: url,
      data: JSON.stringify(params),
      config: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    })
  },

  //GetProjectTaskDetails
  async GetVersionTaskDetails(versionId, data) {
    const url = `/versions/${versionId}/details?columnWidth=${data.columnWidth}&amplifiedFactor=${data.amplifiedFactor}`
    return axiosClientSchedule.request({
      method: 'GET',
      url: url
    })
  },
  //UpdateProjectTaskDetails
  async UpdateVersionTaskDetails(versionId, data) {
    const url = `/versions/${versionId}/details`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url,
      data: data
    })
  },
  async CalculateDateRanges(data) {
    const url = `/settings/calculate-date-ranges`
    return axiosClientSchedule.request({
      method: 'POST',
      url: url,
      data: data
    })
  }
}

export default VersionApi
