import Viewer from "./components/Viewer";

const RenameBlockServices = () => {
  return (
    <div>
      <div className="main-container">
        <Viewer></Viewer>
      </div>
    </div>
  );
};
export default RenameBlockServices;
